import { Injectable } from '@angular/core';
import { OpportunitiesService } from './opportunities.service';
import * as configs from '../../system/configurations';

@Injectable({
    providedIn: 'root'
})
export class EtapaService {
    opportunityStatusList: Array<any> = [];
    constructor(private opportunitiesService: OpportunitiesService) {
        this.load();
    }

    load() {
        this.opportunitiesService.getOpportunityEtapasList().subscribe(oppList => {
            this.opportunityStatusList = oppList;
        });
    }

    getInitialEtapa(): any {
        return this.opportunityStatusList.filter(x => x.type == configs.etapaTypes.initial)[0];
    }

    getSedeEtapa(): any {
        return this.opportunityStatusList.filter(x => x.type == configs.etapaTypes.confirmSede)[0];
    }
    
    getLostEtapa(): any {
        return this.opportunityStatusList.filter(x => x.type == configs.etapaTypes.lost)[0];
    }
}
