import { Component, OnInit } from '@angular/core';
import { SettingsService } from '../../../core/settings/settings.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CustomValidators } from 'ng2-validation';
import { Headers, Http } from '@angular/http';
import { Endpoints } from '../../../nabia/system/configurations';
import { SessionService } from '../../../nabia/system/services/session.service';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
    LOGIN_STATUS_KEY = 'loginStatus';

    URI_TOKEN_KEY = 'access_token';
    URI_CHALLENGE_KEY = 'login_challenge';
    URI_CSRF_KEY = 'csrf';
    userID: string;

    valForm: FormGroup;

    incorrectPassword = false;
    incorrectEmail = false;

    constructor(
        public sessionService: SessionService,
        public settings: SettingsService,
        public http: Http,
        fb: FormBuilder) {

        this.valForm = fb.group({
            'email': [null, Validators.compose([Validators.required, CustomValidators.email])],
            'password': [null, Validators.compose([Validators.required, Validators.pattern('^[a-zA-Z0-9]{6,10}$')])]
        });
    }

    ngOnInit() {
    }

    submitForm($ev, value: any) {
        $ev.preventDefault();

        for (const c in this.valForm.controls) {
            if (this.valForm.controls.hasOwnProperty(c)) {
                this.valForm.controls[c].markAsTouched();
            }
        }

        if (this.valForm.valid) {

            const loginPayload = {
                email: this.valForm.value.email,
                password: this.valForm.value.password
            };
            const options = {
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                })
            };

            const url = Endpoints.AUTH.LOGIN;

            this.http.post(url, loginPayload, options).subscribe((result) => {

                const token = result['_body'];
                const ttl = ((60000 * 60) * 24);
                this.sessionService.setToken(token, + ttl);
                this.sessionService.getPermissions();
                this.sessionService.getConfigurations();    

            }, e => {
                const lg = JSON.parse(e['_body']);
                if (lg.hasOwnProperty('message') && lg.message === 'Password incorrect') {
                    this.incorrectPassword = true;
                } else {
                    this.incorrectPassword = false;
                }
                if (lg.hasOwnProperty('message') && lg.message === 'User does not exist') {
                    this.incorrectEmail = true;
                } else {
                    this.incorrectEmail = false;
                }

            });
        }
    }


}
