import {Component, forwardRef, Input, NgZone, OnDestroy, OnInit} from '@angular/core';
import {Subject} from 'rxjs/Subject';
import {Subscription} from "rxjs";
import {ClientsLeadsService} from "../../../../nabia/system/services/clientsLeads.service";
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";

@Component({
    selector: 'app-select-clients-users',
    templateUrl: './select-users.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => SelectUsersComponent),
            multi: true
        }
    ]
})
export class SelectUsersComponent implements OnInit, OnDestroy, ControlValueAccessor {
    public items: any[] = [];
    public loading: boolean = true;
    public input$ = new Subject<string>();
    public page = 1;
    private subscription: Subscription = new Subscription();
    public subscribe;
    public _value :any;
    public optionsSearch = [
        {label: 'Nome', value: 'name'},
        {label: 'Telemovel', value: 'phone'},
        {label: 'E-Mail', value: 'mail'},
        {label: 'NIF', value: 'nif'},
    ];
    public optionSelected = this.optionsSearch[3];


    constructor(
        private clientsLeadsService: ClientsLeadsService,
        public zone: NgZone
    ) { }

    ngOnInit() {
        this.get();

        this.subscription.add(this.input$.subscribe(search => this.get(search, true)));
    }
    ngOnDestroy(): void {
        this.subscription.unsubscribe();
        if (this.subscribe) {
            this.subscribe.unsubscribe();
        }
    }

    get(search = null, reset = false, addPage = false){
        try {
            if (reset) {
                this.items = [];
                this.page = 1;
            }
            if (addPage) {
                this.page++;
            }
            this.loading = true;
            const obj = {
                page: this.page,
                orderColumn: 'firstName',
                orderType: 'ASC',
            };
            if (search) {
                obj[this.optionSelected.value] = search;
            }
            if (this.subscribe) {
                this.subscribe.unsubscribe();
            }
           this.subscribe = this.clientsLeadsService.getAllUsers(obj).subscribe(result => {
               if (result.items.length) {
                   result.items = result.items.map(item => {
                       return {
                           ...{
                               value: item.id,
                               label: item.users_names[0].firstName+' '+item.users_names[0].lastName,
                           },
                           ...item,
                       };
                   });

                   this.items = [
                       ...this.items,
                       ...result.items
                   ];
               }
               this.loading = false;
           });
        } catch (e) {
            alert('Erro interno')
            console.error(e);
        }
    }

    changeSearch(value){
        if(!value){
            this.optionSelected = this.optionsSearch[1];
        }
    }

    getTelemovel(item){
        return (item.users_phones && item.users_phones.length) ? item.users_phones[0].number : '';
    }
    getMail(item){
        return (item.users_emails && item.users_emails.length) ? item.users_emails[0].email : '';
    }
    getNif(item){
        return (item.users_vat && item.users_vat.length) ? item.users_vat[0].vat : '';
    }
    get value(): any { return this._value; };
    @Input() set value(v) {
        if (v !== this._value) {
            this._value = v;
            this._onChangeCallback(v);
        }
    }

    updateValue (value: any) {
        this.zone.run(() => {
            this._value = value;

            this.onChange(value);
            this._onTouchedCallback();
        });
    }

    writeValue(value: any): void {
        this._value = value;
    }

    onChange (_: any) {}
    onTouched () {}
    registerOnChange (fn: any) { this.onChange = fn; }
    registerOnTouched (fn: any) { this.onTouched = fn; }
    _onChangeCallback (_: any) {}
    _onTouchedCallback () {}


}
