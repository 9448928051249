import { Injectable } from '@angular/core';

@Injectable()
export class ColorsService {

    APP_COLORS = {
        'green': 'grb(51, 180, 112)',
        'red': 'rgb(229, 65, 84)',
        'orange': 'rgb(227,107,69)',
        'yellow': 'rgb(245, 179, 56)',
        'blue': 'rgb(52, 63, 79)',
        'gray': 'rgb(127, 144, 164)',
        'silver': 'rgb(246, 246, 246)',
        'gray-light': 'rgb(127, 144, 161)',
        'dark': 'rgb(42, 41, 46)',
        'darker': 'rgb(33, 33, 33)',

        'primary': '#5d9cec',
        'success': '#27c24c',
        'info': '#23b7e5',
        'warning': '#ff902b',
        'danger': '#f05050',
        'inverse': '#131e26',
        'pink': '#f532e5',
        'purple': '#7266ba',
        'gray-darker': '#232735',
        'gray-dark': '#3a3f51',
        'gray-lighter': '#edf1f2'
    };

    constructor() { }

    byName(name) {
        // console.log(name +' -> ' + this.APP_COLORS[name])
        return (this.APP_COLORS[name] || '#fff');
    }

}
