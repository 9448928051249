import { getDate } from './../date/date';
import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from 'angularfire2/firestore';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of as observableOf, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { Endpoints, resourceTypes } from '../configurations';
import { SessionService } from './session.service';
import { paramsGetAllRoles } from './models/configurations';
import { IP_REMOTE, PROTOCOL_SAFE } from '../../../../environments/environment';

@Injectable()
export class DataService {
  private database;
  private tenantId: string;

  constructor(private firestore: AngularFirestore, private http: HttpClient, private sessionService: SessionService) {
    this.tenantId = '';
    sessionService.getTenant().subscribe(tId => {
      this.tenantId = tId;
    });
  }

  test() {
    this.database = this.firestore.collection('/entities').valueChanges();
    this.database.subscribe(doc => {
      doc.forEach(element => {});
    });
  }

  getData() {}

  //  ===========================================================================
  //  ============================      Staff      ==============================
  //  ===========================================================================
  getStaff(quantity: number, offset: number, filters: any) {
    const firestoreReply$ = this.firestore.collection('/staff', rep =>
      rep
        // .where('id', '==', filters.id) // TODO: use to apply filters
        .orderBy('modifiedDate', 'desc')
        .startAt(offset)
        .limit(quantity)
    );

    const url = Endpoints.STAFF.STAFF_BATCH;
    const backendReply$ = this.getRealTimeData(firestoreReply$, url).pipe(
      map((l: any[]) => {
        l.map(e => {
          e.modifiedDate = getDate(e.modifiedDate);
          return e;
        });
        return l;
      }), // modifiedDate: string => Date
      map((l: any[]) => l.sort((a, b) => b.modifiedDate - a.modifiedDate))
    ); // sorts by modifiedDate

    return backendReply$;
  }

  getAllStaff() {
    const url = Endpoints.STAFF.STAFF;

    const backendReply$ = this.http.get(url, this.sessionService.getOptions());

    return backendReply$;
  }
  getComments(params?) {
    const url = Endpoints.CONFIGURATIONS.COMMENTS;
    const opts = this.sessionService.getOptions();
    opts['params'] = params;
    const backendReply$ = this.http.get(url, opts);

    return backendReply$;
  }
  postComment(body) {
    const url = Endpoints.CONFIGURATIONS.COMMENTS;
    const opt = this.sessionService.getOptions();
    const reply$ = this.http.post(url, body, opt);

    return reply$;
  }
  putComment(id, body) {
    const url = Endpoints.CONFIGURATIONS.COMMENTS_ID(id);
    const opt = this.sessionService.getOptions();
    const reply$ = this.http.put(url, body, opt);

    return reply$;
  }
  deleteComment(id) {
    const url = Endpoints.CONFIGURATIONS.COMMENTS_ID(id);
    const opt = this.sessionService.getOptions();
    const reply$ = this.http.delete(url, opt);

    return reply$;
  }

  getAvailableResources() {
    const url = Endpoints.CONFIGURATIONS.ROLES_RESOURCES;
    const opt = this.sessionService.getOptions();
    const reply$ = this.http.get(url, opt);

    return reply$;
  }
  getStaffById(id) {
    const url = Endpoints.STAFF.GET_STAFF(id);

    const backendReply$ = this.http.get(url, this.sessionService.getOptions());

    return backendReply$;
  }

  //  ===========================================================================
  //  ============================  Opportunities  ==============================
  //  ===========================================================================

  getUserOpportunities(quantity, offset, id: string) {
    const firestoreReply$ = this.firestore.collection('/opportunities', rep =>
      rep.where('idUser', '==', id).orderBy('modifiedDate', 'desc').startAt(offset).limit(quantity)
    );

    const url = Endpoints.OPPORTUNITIES.OPPORTUNITIES_BATCH;
    const infos$ = this.getRealTimeData(firestoreReply$, url).pipe(
      map((l: any[]) => {
        l.map(e => {
          e.modifiedDate = getDate(e.modifiedDate);
          return e;
        });
        return l;
      }), // modifiedDate: string => Date
      map((l: any[]) => l.sort((a, b) => b.modifiedDate - a.modifiedDate))
    ); // sorts by modifiedDate

    return infos$;
  }
  // getAllUserOpportunities(id: string) {
  //   const firestoreReply$ = this.firestore.collection('/opportunities', rep => rep
  //     .where('idUser', '==', id)
  //     .orderBy('modifiedDate', 'desc')
  //   );

  //   const url = Endpoints.OPPORTUNITIES.OPPORTUNITIES;
  //   const infos$ = this.getRealTimeData(firestoreReply$, url);

  //   return infos$;
  // }

  //  ===========================================================================
  //  ============================     Contact     ==============================
  //  ===========================================================================

  /**
   * Returns a list of all origin types
   */
  getOrigins() {
    const url = Endpoints.CONFIGURATIONS.GENERIC_TYPES.ORIGINS;
    const reply$ = this.http.get(url, this.sessionService.getOptions());

    return reply$;
  }
  getOriginsList() {
    const url = Endpoints.CONFIGURATIONS.ORIGEM;
    const reply$ = this.http.get(url, this.sessionService.getOptions());

    return reply$;
  }

  postOrigins(body: any) {
    const url = Endpoints.CONFIGURATIONS.ORIGEM;
    const opt = this.sessionService.getOptions();
    return this.http.post(url, body, opt).toPromise();
  }

  putOrigins(id: string, body) {
    const url = Endpoints.CONFIGURATIONS.ORIGEM_SPECIFIC(id);
    const opt = this.sessionService.getOptions();
    return this.http.put(url, body, opt).toPromise();
  }

  putListOrigins(body) {
    const url = Endpoints.CONFIGURATIONS.ORIGEM;
    const opt = this.sessionService.getOptions();
    return this.http.put(url, body, opt);
  }

  deleteListOrigins(body) {
    const url = Endpoints.CONFIGURATIONS.ORIGEM_DELETE;
    const opt = this.sessionService.getOptions();
    return this.http.post(url, body, opt);
  }

  deleteOrigins(id: string) {
    const url = Endpoints.CONFIGURATIONS.ORIGEM_SPECIFIC(id);
    const opt = this.sessionService.getOptions();
    return this.http.delete(url, opt).toPromise();
  }

  getLostReasons() {
    const url = Endpoints.CONFIGURATIONS.GENERIC_TYPES.OPPORTUNITIES_LOST_REASON_TYPE;
    const reply$ = this.http.get(url, this.sessionService.getOptions());

    return reply$;
  }

  getResource(id) {
    const url = Endpoints.CONFIGURATIONS.GET_RESOURCE(id);
    const reply$ = this.http.get(url, this.sessionService.getOptions());

    return reply$;
  }
  getRolesResources() {
    const url = Endpoints.CONFIGURATIONS.ROLES_RESOURCES;
    const reply$ = this.http.get(url, this.sessionService.getOptions());

    return reply$;
  }

  /**
   * Returns a list of all Communication Channels
   */
  getCommunicationChannels() {
    const url = Endpoints.CONFIGURATIONS.GENERIC_TYPES.COMMUNICATION_CHANNELS;
    const reply$ = this.http.get(url, this.sessionService.getOptions());

    return reply$;
  }

  getStatusFacebook() {
    const url = Endpoints.CONFIGURATIONS.GENERIC_TYPES.MARKETING_STATUS_FACEBOOK;
    const reply$ = this.http.get(url, this.sessionService.getOptions());

    return reply$;
  }
  getStatusFacebookContent() {
    const url = Endpoints.CONFIGURATIONS.GENERIC_TYPES.MARKETING_STATUS_FACEBOOK_CONTENT;
    const reply$ = this.http.get(url, this.sessionService.getOptions());

    return reply$;
  }
  getStatusGoogle() {
    const url = Endpoints.CONFIGURATIONS.GENERIC_TYPES.MARKETING_STATUS_GOOGLE;
    const reply$ = this.http.get(url, this.sessionService.getOptions());

    return reply$;
  }
  getStatusEgoi() {
    const url = Endpoints.CONFIGURATIONS.GENERIC_TYPES.MARKETING_STATUS_EGOI;
    const reply$ = this.http.get(url, this.sessionService.getOptions());

    return reply$;
  }

  /**
   * Returns the list of all occupations
   */
  getOccupationsList() {
    const url = Endpoints.CONFIGURATIONS.GENERIC_TYPES.PROFESSIONS;
    const reply$ = this.http.get(url, this.sessionService.getOptions());

    return reply$;
  }

  /**
   * Returns the list of clinics
   */
  getClinics() {
    const url = Endpoints.CONFIGURATIONS.CLINICS;
    const opt = this.sessionService.getOptions();
    const reply$ = this.http.get(url, opt);

    return reply$;
  }
  /**
   * Returns the list of clinics
   */
  getClinic(id) {
    const url = Endpoints.CONFIGURATIONS.GET_CLINIC(id);
    const opt = this.sessionService.getOptions();
    const reply$ = this.http.get(url, opt);

    return reply$;
  }
  getClinicsPagination(params?: any) {
    const url = Endpoints.CONFIGURATIONS.CLINICS_PAGINATION;
    const opt = this.sessionService.getOptions();
    if (typeof params === 'object') {
      opt['params'] = params;
    }
    if (typeof params === 'string' && params.length > 1) {
      opt['params'] = {
        name: params,
      };
    }
    const reply$ = this.http.get(url, opt);

    return reply$;
  }

  getTenants() {
    const url = Endpoints.CONFIGURATIONS.TENANT;
    const opt = this.sessionService.getOptions();
    return this.http.get(url, opt).toPromise();
  }

  getTenant(id) {
    const url = Endpoints.CONFIGURATIONS.TENANT_SPECIFIC(id);
    const opt = this.sessionService.getOptions();
    return this.http.get(url, opt).toPromise();
  }

  putTenant(id: string, body) {
    const url = Endpoints.CONFIGURATIONS.TENANT_SPECIFIC(id);
    const opt = this.sessionService.getOptions();
    return this.http.put(url, body, opt).toPromise();
  }

  /**
   * Returns a list of problems
   */
  getProblems(params?) {
    const url = Endpoints.CONFIGURATIONS.PROBLEMS;
    const opt = this.sessionService.getOptions();
    if (params) {
      opt['params'] = params;
    }

    return this.http.get(url, opt).toPromise();
  }

  postProblems(body) {
    const url = Endpoints.CONFIGURATIONS.PROBLEMS;
    const opt = this.sessionService.getOptions();
    return this.http.post(url, body, opt).toPromise();
  }

  putProblems(problemId: string, body) {
    const url = Endpoints.CONFIGURATIONS.PROBLEMS_SPECIFIC(problemId);
    const opt = this.sessionService.getOptions();
    return this.http.put(url, body, opt).toPromise();
  }

  putListProblems(body) {
    const url = Endpoints.CONFIGURATIONS.PROBLEMS;
    const opt = this.sessionService.getOptions();
    return this.http.put(url, body, opt);
  }

  deleteProblems(problemId: string) {
    const url = Endpoints.CONFIGURATIONS.PROBLEMS_SPECIFIC(problemId);
    const opt = this.sessionService.getOptions();
    return this.http.delete(url, opt).toPromise();
  }

  deleteListProblems(body) {
    const url = Endpoints.CONFIGURATIONS.PROBLEMS_DELETE;
    const opt = this.sessionService.getOptions();
    return this.http.post(url, body, opt);
  }

  getOccupations(params = null) {
    const url = Endpoints.CONFIGURATIONS.OCCUPATIONS;
    const opt = this.sessionService.getOptions();
    if (params) {
      opt['params'] = params;
    }
    return this.http.get(url, opt).toPromise();
  }

  postOccupations(body) {
    const url = Endpoints.CONFIGURATIONS.OCCUPATIONS;
    const opt = this.sessionService.getOptions();
    return this.http.post(url, body, opt).toPromise();
  }

  putOccupations(id: string, body) {
    const url = Endpoints.CONFIGURATIONS.OCCUPATIONS_SPECIFIC(id);
    const opt = this.sessionService.getOptions();
    return this.http.put(url, body, opt).toPromise();
  }

  putListOccupations(body) {
    const url = Endpoints.CONFIGURATIONS.OCCUPATIONS;
    const opt = this.sessionService.getOptions();
    return this.http.put(url, body, opt);
  }

  deleteOccupations(id: string) {
    const url = Endpoints.CONFIGURATIONS.OCCUPATIONS_SPECIFIC(id);
    const opt = this.sessionService.getOptions();
    return this.http.delete(url, opt).toPromise();
  }

  deleteListOccupations(body) {
    const url = Endpoints.CONFIGURATIONS.OCCUPATIONS_DELETE;
    const opt = this.sessionService.getOptions();
    return this.http.post(url, body, opt);
  }

  getLossReasons(search?: any) {
    const url = Endpoints.CONFIGURATIONS.LOSS_REASONS;
    const opt = this.sessionService.getOptions();
    if (search) {
      opt['params'] = search;
    }
    return this.http.get(url, opt);
  }

  postLossReasons(body) {
    const url = Endpoints.CONFIGURATIONS.LOSS_REASONS;
    const opt = this.sessionService.getOptions();
    return this.http.post(url, body, opt).toPromise();
  }

  putLossReasons(problemId: string, body) {
    const url = Endpoints.CONFIGURATIONS.LOSS_REASONS_SPECIFIC(problemId);
    const opt = this.sessionService.getOptions();
    return this.http.put(url, body, opt).toPromise();
  }

  deleteLossReasons(problemId: string) {
    const url = Endpoints.CONFIGURATIONS.LOSS_REASONS_SPECIFIC(problemId);
    const opt = this.sessionService.getOptions();
    return this.http.delete(url, opt).toPromise();
  }

  deleteListLossReasons(body) {
    const url = Endpoints.CONFIGURATIONS.LOSS_REASONS_DELETE;
    const opt = this.sessionService.getOptions();
    return this.http.post(url, body, opt);
  }

  putListLossReasons(body) {
    const url = Endpoints.CONFIGURATIONS.LOSS_REASONS;
    const opt = this.sessionService.getOptions();
    return this.http.put(url, body, opt);
  }

  getContraindications(params = null) {
    const url = Endpoints.CONFIGURATIONS.CONTRAINDICATIONS;
    const opt = this.sessionService.getOptions();
    if (params) {
      opt['params'] = params;
    }
    return this.http.get(url, opt).toPromise();
  }

  getOpportunityTypes(params = null) {
    const url = Endpoints.CONFIGURATIONS.OPPORTUNITYTYPE;
    const opt = this.sessionService.getOptions();
    if (params) {
      opt['params'] = params;
    }
    return this.http.get(url, opt).toPromise();
  }

  postOpportunityType(body) {
    const url = Endpoints.CONFIGURATIONS.OPPORTUNITYTYPE;
    const opt = this.sessionService.getOptions();
    return this.http.post(url, body, opt).toPromise();
  }

  deleteOpportunityType(id: string) {
    const url = Endpoints.CONFIGURATIONS.OPPORTUNITYTYPE_SPECIFIC(id);
    const opt = this.sessionService.getOptions();
    return this.http.delete(url, opt).toPromise();
  }

  deleteListOpportunityType(body) {
    const url = Endpoints.CONFIGURATIONS.OPPORTUNITYTYPE_DELETE;
    const opt = this.sessionService.getOptions();
    return this.http.post(url, body, opt);
  }

  putListOpportunityType(body) {
    const url = Endpoints.CONFIGURATIONS.OPPORTUNITYTYPE;
    const opt = this.sessionService.getOptions();
    return this.http.put(url, body, opt);
  }

  putOpportunityType(id: string, body) {
    const url = Endpoints.CONFIGURATIONS.OPPORTUNITYTYPE_SPECIFIC(id);
    const opt = this.sessionService.getOptions();
    return this.http.put(url, body, opt).toPromise();
  }

  postContraindications(body) {
    const url = Endpoints.CONFIGURATIONS.CONTRAINDICATIONS;
    const opt = this.sessionService.getOptions();
    return this.http.post(url, body, opt).toPromise();
  }

  putListContraindications(body) {
    const url = Endpoints.CONFIGURATIONS.CONTRAINDICATIONS;
    const opt = this.sessionService.getOptions();
    return this.http.put(url, body, opt);
  }

  putContraindications(id: string, body) {
    const url = Endpoints.CONFIGURATIONS.CONTRAINDICATIONS_SPECIFIC(id);
    const opt = this.sessionService.getOptions();
    return this.http.put(url, body, opt).toPromise();
  }

  deleteContraindications(id: string) {
    const url = Endpoints.CONFIGURATIONS.CONTRAINDICATIONS_SPECIFIC(id);
    const opt = this.sessionService.getOptions();
    return this.http.delete(url, opt).toPromise();
  }

  deleteListContraindications(body) {
    const url = Endpoints.CONFIGURATIONS.CONTRAINDICATIONS_DELETE;
    const opt = this.sessionService.getOptions();
    return this.http.post(url, body, opt);
  }

  getTaxes(params?) {
    const url = Endpoints.CONFIGURATIONS.TAXES;
    const opt = this.sessionService.getOptions();
    if (params) {
      opt['params'] = params;
    }

    return this.http.get(url, opt).toPromise();
  }

  postTaxes(body) {
    const url = Endpoints.CONFIGURATIONS.TAXES;
    const opt = this.sessionService.getOptions();
    return this.http.post(url, body, opt).toPromise();
  }

  putListTaxes(body) {
    const url = Endpoints.CONFIGURATIONS.TAXES;
    const opt = this.sessionService.getOptions();
    return this.http.put(url, body, opt);
  }

  putTaxes(id: string, body) {
    const url = Endpoints.CONFIGURATIONS.TAXES_SPECIFIC(id);
    const opt = this.sessionService.getOptions();
    return this.http.put(url, body, opt).toPromise();
  }

  deleteTaxes(id: string) {
    const url = Endpoints.CONFIGURATIONS.TAXES_SPECIFIC(id);
    const opt = this.sessionService.getOptions();
    return this.http.delete(url, opt).toPromise();
  }

  deleteListTaxes(body) {
    const url = Endpoints.CONFIGURATIONS.TAXES_DELETE;
    const opt = this.sessionService.getOptions();
    return this.http.post(url, body, opt);
  }



  getProducts(params?) {
    const url = Endpoints.CONFIGURATIONS.PRODUCTS_CRUD;
    const opt = this.sessionService.getOptions();
    if (params) {
      opt['params'] = params;
    }

    return this.http.get(url, opt).toPromise();
  }

  postProducts(body) {
    const url = Endpoints.CONFIGURATIONS.PRODUCTS_CRUD;
    const opt = this.sessionService.getOptions();
    return this.http.post(url, body, opt).toPromise();
  }

  putListProducts(body) {
    const url = Endpoints.CONFIGURATIONS.PRODUCTS_CRUD;
    const opt = this.sessionService.getOptions();
    return this.http.put(url, body, opt);
  }

  putProducts(id: string, body) {
    const url = Endpoints.CONFIGURATIONS.PRODUCTS_CRUD_SPECIFIC(id);
    const opt = this.sessionService.getOptions();
    return this.http.put(url, body, opt).toPromise();
  }

  deleteProducts(id: string) {
    const url = Endpoints.CONFIGURATIONS.PRODUCTS_CRUD_SPECIFIC(id);
    const opt = this.sessionService.getOptions();
    return this.http.delete(url, opt).toPromise();
  }

  deleteListProducts(body) {
    const url = Endpoints.CONFIGURATIONS.PRODUCTS_CRUD_DELETE;
    const opt = this.sessionService.getOptions();
    return this.http.post(url, body, opt);
  }

  getServices(params?) {
    const url = Endpoints.CONFIGURATIONS.SERVICES_CRUD;
    const opt = this.sessionService.getOptions();
    if (params) {
      opt['params'] = params;
    }

    return this.http.get(url, opt).toPromise();
  }

  postServices(body) {
    const url = Endpoints.CONFIGURATIONS.SERVICES_CRUD;
    const opt = this.sessionService.getOptions();
    return this.http.post(url, body, opt).toPromise();
  }

  putListServices(body) {
    const url = Endpoints.CONFIGURATIONS.SERVICES_CRUD;
    const opt = this.sessionService.getOptions();
    return this.http.put(url, body, opt);
  }

  putServices(id: string, body) {
    const url = Endpoints.CONFIGURATIONS.SERVICES_CRUD_SPECIFIC(id);
    const opt = this.sessionService.getOptions();
    return this.http.put(url, body, opt).toPromise();
  }

  deleteServices(id: string) {
    const url = Endpoints.CONFIGURATIONS.SERVICES_CRUD_SPECIFIC(id);
    const opt = this.sessionService.getOptions();
    return this.http.delete(url, opt).toPromise();
  }

  deleteListServices(body) {
    const url = Endpoints.CONFIGURATIONS.SERVICES_CRUD_DELETE;
    const opt = this.sessionService.getOptions();
    return this.http.post(url, body, opt);
  }


  /**
   * Returns a list of PRESCRIPTIONS
   */
  getPrescriptions(name?: string, params = null) {
    const url = Endpoints.CONFIGURATIONS.PRESCRIPTIONS;
    const opt = this.sessionService.getOptions();
    if (params) {
      opt['params'] = params;
    }
    return this.http.get(url, opt);
  }

  getSpecificPrescription(id) {
    const url = Endpoints.CONFIGURATIONS.PRESCRIPTIONS_SPECIFIC(id);
    const opt = this.sessionService.getOptions();
    return this.http.get(url, opt);
  }

  postPrescriptions(body) {
    const url = Endpoints.CONFIGURATIONS.PRESCRIPTIONS;
    const opt = this.sessionService.getOptions();
    return this.http.post(url, body, opt).toPromise();
  }

  postPrescriptionsService(body) {
    const url = Endpoints.CONFIGURATIONS.PRESCRIPTIONS_SERVICE;
    const opt = this.sessionService.getOptions();
    return this.http.post(url, body, opt).toPromise();
  }

  putPrescriptions(id: string, body) {
    const url = Endpoints.CONFIGURATIONS.PRESCRIPTIONS_SPECIFIC(id);
    const opt = this.sessionService.getOptions();
    return this.http.put(url, body, opt);
  }

  putPrescriptionsService(id: string, body) {
    const url = Endpoints.CONFIGURATIONS.PRESCRIPTIONS_SERVICE_SPECIFIC(id);
    const opt = this.sessionService.getOptions();
    return this.http.put(url, body, opt);
  }

  putListPrescriptions(body) {
    const url = Endpoints.CONFIGURATIONS.PRESCRIPTIONS;
    const opt = this.sessionService.getOptions();
    return this.http.put(url, body, opt);
  }

  deletePrescriptions(id: string) {
    const url = Endpoints.CONFIGURATIONS.PRESCRIPTIONS_SPECIFIC(id);
    const opt = this.sessionService.getOptions();
    return this.http.delete(url, opt).toPromise();
  }

  deletePrescriptionsService(id: string) {
    const url = Endpoints.CONFIGURATIONS.PRESCRIPTIONS_SERVICE_SPECIFIC(id);
    const opt = this.sessionService.getOptions();
    return this.http.delete(url, opt).toPromise();
  }

  deleteListPrescriptions(body) {
    const url = Endpoints.CONFIGURATIONS.PRESCRIPTIONS_DELETE;
    const opt = this.sessionService.getOptions();
    return this.http.post(url, body, opt);
  }

  getPhoto(url) {
    const urlPhoto = `${PROTOCOL_SAFE}://${IP_REMOTE}${url}`;
    const opt = this.sessionService.getOptions();
    opt['responseType'] = 'blob';
    return this.http.get(urlPhoto, opt);
  }

  /**
   * Returns a list of rooms
   */
  getRooms(name?: string, params = null) {
    let url;
    if (name) {
      url = Endpoints.CONFIGURATIONS.RESOURCES_SEARCH(name, resourceTypes.room);
    } else {
      url = Endpoints.CONFIGURATIONS.GET_RESOURCES(resourceTypes.room);
    }
    const opt = this.sessionService.getOptions();
    opt['params'] = params;
    const reply$ = this.http.get(url, opt);

    return reply$;
  }

  getAllMachines(params = null) {
    const url = Endpoints.CONFIGURATIONS.MAQUINA;
    const opt = this.sessionService.getOptions();
    if (params) {
      opt['params'] = params;
    }
    return this.http.get(url, opt).toPromise();
  }

  postMachines(body) {
    const url = Endpoints.CONFIGURATIONS.MAQUINA;
    const opt = this.sessionService.getOptions();
    return this.http.post(url, body, opt).toPromise();
  }

  putListMachines(body) {
    const url = Endpoints.CONFIGURATIONS.MAQUINA;
    const opt = this.sessionService.getOptions();
    return this.http.put(url, body, opt);
  }

  putMachines(id: string, body) {
    const url = Endpoints.CONFIGURATIONS.MAQUINA_SPECIFIC(id);
    const opt = this.sessionService.getOptions();
    return this.http.put(url, body, opt).toPromise();
  }

  deleteMachines(id: string) {
    const url = Endpoints.CONFIGURATIONS.MAQUINA_SPECIFIC(id);
    const opt = this.sessionService.getOptions();
    return this.http.delete(url, opt).toPromise();
  }

  deleteListMachines(body) {
    const url = Endpoints.CONFIGURATIONS.MAQUINA_DELETE;
    const opt = this.sessionService.getOptions();
    return this.http.post(url, body, opt);
  }

  getAllRooms(params = null) {
    const url = Endpoints.CONFIGURATIONS.SALA;
    const opt = this.sessionService.getOptions();
    if (params) {
      opt['params'] = params;
    }
    return this.http.get(url, opt).toPromise();
  }

  postRooms(body) {
    const url = Endpoints.CONFIGURATIONS.SALA;
    const opt = this.sessionService.getOptions();
    return this.http.post(url, body, opt).toPromise();
  }

  putListRooms(body) {
    const url = Endpoints.CONFIGURATIONS.SALA;
    const opt = this.sessionService.getOptions();
    return this.http.put(url, body, opt);
  }

  putRooms(id: string, body) {
    const url = Endpoints.CONFIGURATIONS.SALA_SPECIFIC(id);
    const opt = this.sessionService.getOptions();
    return this.http.put(url, body, opt).toPromise();
  }

  deleteRooms(id: string) {
    const url = Endpoints.CONFIGURATIONS.SALA_SPECIFIC(id);
    const opt = this.sessionService.getOptions();
    return this.http.delete(url, opt).toPromise();
  }

  deleteListRooms(body) {
    const url = Endpoints.CONFIGURATIONS.SALA_DELETE;
    const opt = this.sessionService.getOptions();
    return this.http.post(url, body, opt);
  }

  /**
   * Returns a list of machines
   */
  getMachines(name?: string, params = null) {
    let url;
    if (name) {
      url = Endpoints.CONFIGURATIONS.RESOURCES_SEARCH(name, resourceTypes.machine);
    } else {
      url = Endpoints.CONFIGURATIONS.GET_RESOURCES(resourceTypes.machine);
    }
    const opt = this.sessionService.getOptions();
    opt['params'] = params;
    const reply$ = this.http.get(url, opt);

    return reply$;
  }

  /**
   * Returns a list of typified resources' availabilities
   */
  getResourceAvailabilities(id: string) {
    const url = Endpoints.CONFIGURATIONS.RESOURCES_AVAILABILITY(id);

    const opt = this.sessionService.getOptions();
    const reply$ = this.http.get(url, opt);

    return reply$;
  }

  getRole(roleId: string) {
    const url = Endpoints.CONFIGURATIONS.ROLES_SPECIFIC(roleId);
    const opt = this.sessionService.getOptions();
    const reply$ = this.http.get(url, opt);

    return reply$;
  }
  getCompleteRoles() {
    const url = Endpoints.CONFIGURATIONS.ROLES_COMPLETE;
    const opt = this.sessionService.getOptions();
    const reply$ = this.http.get(url, opt);

    return reply$;
  }
  putRole(roleId: string, body) {
    const url = Endpoints.CONFIGURATIONS.ROLES_SPECIFIC(roleId);
    const opt = this.sessionService.getOptions();
    const reply$ = this.http.put(url, body, opt);

    return reply$;
  }
  putRoles(body) {
    const url = Endpoints.CONFIGURATIONS.ROLES_UPDATE_LIST;
    const opt = this.sessionService.getOptions();
    const reply$ = this.http.post(url, body, opt);
    return reply$;
  }
  deleteRole(roleId: string) {
    const url = Endpoints.CONFIGURATIONS.ROLES_SPECIFIC(roleId);
    const opt = this.sessionService.getOptions();
    const reply$ = this.http.delete(url, opt);

    return reply$;
  }

  postRole(body) {
    const url = Endpoints.CONFIGURATIONS.ROLES;
    const opt = this.sessionService.getOptions();
    const reply$ = this.http.post(url, body, opt);

    return reply$;
  }
  postRolePromise(body) {
    const url = Endpoints.CONFIGURATIONS.ROLES;
    const opt = this.sessionService.getOptions();
    const reply$ = this.http.post(url, body, opt).toPromise();

    return reply$;
  }
  /**
   * Returns a list of the clinics unavailabilities
   */
  getClinicUnavailabilities(id: string) {
    const url = Endpoints.CONFIGURATIONS.CLINICS_UNAVAILABILITIES(id);

    const opt = this.sessionService.getOptions();
    const reply$ = this.http.get(url, opt);

    return reply$;
  }

  /**
   * Returns a list of typified resources' availabilities
   */
  getResourceUnavailabilities(id: string) {
    const url = Endpoints.CONFIGURATIONS.RESOURCES_UNAVAILABILITY(id);

    const opt = this.sessionService.getOptions();
    const reply$ = this.http.get(url, opt);

    return reply$;
  }

  /**
   * Returns a single resource
   */
  getSingleResource(id: string) {
    const url = Endpoints.CONFIGURATIONS.GET_RESOURCE(id);

    const opt = this.sessionService.getOptions();
    const reply$ = this.http.get(url, opt);

    return reply$;
  }

  /**
   * Returns a list of RH Staff
   */
  getRHStaff(name?: string, params = null) {
    let url;
    if (name) {
      url = Endpoints.STAFF.STAFF_SEARCH(name) + '&hidden=1';
    } else {
      url = Endpoints.STAFF.STAFF + '?hidden=1';
    }

    const opt = this.sessionService.getOptions();
    opt['params'] = params;
    const reply$ = this.http.get(url, opt);

    return reply$;
  }

  /**
   * Returns a single RH Staff
   */
  getSingleRHStaff(id: string) {
    let url = Endpoints.STAFF.GET_STAFF(id);

    const opt = this.sessionService.getOptions();
    const reply$ = this.http.get(url, opt);

    return reply$;
  }

  /**
   * Returns a list of RH Staff availabilities
   */
  getRHStaffSAvailabilities(id: string) {
    const url = Endpoints.STAFF.STAFF_AVAILABILITY(id);

    const opt = this.sessionService.getOptions();
    const reply$ = this.http.get(url, opt);

    return reply$;
  }

  /**
   * Returns a list of RH Staff availabilities
   */
  getRHStaffUnavailabilities(id: string) {
    const url = Endpoints.STAFF.STAFF_UNAVAILABILITY(id);

    const opt = this.sessionService.getOptions();
    const reply$ = this.http.get(url, opt);

    return reply$;
  }
  /**
   *
   * creates a new staff
   */

  createStaff(payload: any) {
    const options = this.sessionService.getOptions();
    const url = Endpoints.STAFF.STAFF;

    const data$ = this.http.post(url, payload, options);

    return data$;
  }

  /**
   *
   * creates a new resoruce
   */

  createResource(payload: any) {
    const options = this.sessionService.getOptions();
    const url = Endpoints.CONFIGURATIONS.RESOURCES;

    const data$ = this.http.post(url, payload, options);

    return data$;
  }

  createEtapa(body: any) {
    const options = this.sessionService.getOptions();
    const url = Endpoints.CONFIGURATIONS.ETAPAS;

    const data$ = this.http.post(url, body, options);

    return data$;
  }

  createEstadoLeads(body: any) {
    const options = this.sessionService.getOptions();
    const url = Endpoints.CONFIGURATIONS.ESTADO_LEADS;

    const data$ = this.http.post(url, body, options);

    return data$;
  }

  /**
   * Returns the list of roles
   */
  getRolesList(params?: paramsGetAllRoles) {
    const url = Endpoints.CONFIGURATIONS.ROLES;
    const opt = this.sessionService.getOptions();
    opt['params'] = params;
    const reply$ = this.http.get(url, opt);

    return reply$;
  }
  getOfficesList(params?: paramsGetAllRoles) {
    const url = Endpoints.CONFIGURATIONS.OFFICES;
    const opt = this.sessionService.getOptions();
    opt['params'] = params;
    const reply$ = this.http.get(url, opt);

    return reply$;
  }

  /**
   * add treatment to resource
   */

  addTreatmentToResource(idEntity: string, idTreatment: string, isStaff: boolean) {
    let url;
    if (isStaff) {
      url = Endpoints.STAFF.STAFF_TREATMENT(idEntity, idTreatment);
    } else {
      url = Endpoints.CONFIGURATIONS.RESOURCES_TREATMENT(idEntity, idTreatment);
    }
    const options = this.sessionService.getOptions();
    const data$ = this.http.post(url, {}, options);

    return data$;
  }

  /**
   * remove treatment from resource
   */

  removeTreatmentFromResource(idEntity: string, idTreatment: string, isStaff: boolean) {
    let url;
    if (isStaff) {
      url = Endpoints.STAFF.STAFF_TREATMENT(idEntity, idTreatment);
    } else {
      url = Endpoints.CONFIGURATIONS.RESOURCES_TREATMENT(idEntity, idTreatment);
    }
    const options = this.sessionService.getOptions();
    const data$ = this.http.delete(url, options);

    return data$;
  }

  deleteResource(resourceID: any, isStaff: any, body: any): any {
    let url;
    if (isStaff) {
      url = Endpoints.STAFF.DELETE_STAFF(resourceID);
    } else {
      url = Endpoints.CONFIGURATIONS.DELETE_RESOURCES(resourceID);
    }
    const options = this.sessionService.getOptions();
    const data$ = this.http.put(url, body, options);

    return data$;
  }

  /**
   * add treatment to resource
   */

  addAvailabilityToResource(idEntity: string, availability: any, isStaff: boolean) {
    let url;
    if (isStaff) {
      url = Endpoints.STAFF.STAFF_AVAILABILITY(idEntity);
    } else {
      url = Endpoints.CONFIGURATIONS.RESOURCES_AVAILABILITY(idEntity);
    }
    const options = this.sessionService.getOptions();
    const data$ = this.http.post(url, availability, options);

    return data$;
  }

  addUnavailabilityToResource(idEntity: string, unavailability: any, isStaff: boolean) {
    let url;
    if (isStaff) {
      url = Endpoints.STAFF.STAFF_UNAVAILABILITY(idEntity);
    } else {
      url = Endpoints.CONFIGURATIONS.RESOURCES_UNAVAILABILITY(idEntity);
    }
    const options = this.sessionService.getOptions();
    const data$ = this.http.post(url, unavailability, options);

    return data$;
  }

  addUnavailabilityToClinic(idEntity: string, unavailability: any) {
    const url = Endpoints.CONFIGURATIONS.CLINICS_UNAVAILABILITIES(idEntity);

    const options = this.sessionService.getOptions();
    const data$ = this.http.post(url, unavailability, options);

    return data$;
  }

  /**
   * remove treatment from resource
   */

  removeUnavailabilityFromClinic(idClinic: string, idAggregation: string) {
    const url = Endpoints.CONFIGURATIONS.CLINICS_UNAVAILABILITY(idClinic, idAggregation);
    const options = this.sessionService.getOptions();
    const data$ = this.http.delete(url, options);

    return data$;
  }

  removeUnavailabilityFromResource(idEntity: string, idAvailability: string, isStaff: boolean) {
    let url;
    if (isStaff) {
      url = Endpoints.STAFF.STAFF_UNAVAILABILITY_DELETE(idEntity, idAvailability);
    } else {
      url = Endpoints.CONFIGURATIONS.RESOURCES_UNAVAILABILITY_DELETE(idEntity, idAvailability);
    }
    const options = this.sessionService.getOptions();
    const data$ = this.http.delete(url, options);

    return data$;
  }

  removeAvailabilityFromResource(idEntity: string, idAvailability: string, isStaff: boolean) {
    let url;
    if (isStaff) {
      url = Endpoints.STAFF.STAFF_AVAILABILITY_DELETE(idEntity, idAvailability);
    } else {
      url = Endpoints.CONFIGURATIONS.RESOURCES_AVAILABILITY_DELETE(idEntity, idAvailability);
    }
    const options = this.sessionService.getOptions();
    const data$ = this.http.delete(url, options);

    return data$;
  }

  removeAllAvailabilityFromResource(idEntity: string, isResource: boolean) {
    let url;
    if (!isResource) {
      url = Endpoints.STAFF.STAFF_AVAILABILITY(idEntity);
    } else {
      url = Endpoints.CONFIGURATIONS.RESOURCES_AVAILABILITY(idEntity);
    }
    const options = this.sessionService.getOptions();
    const data$ = this.http.delete(url, options);

    return data$;
  }

  /**
   * Update values for resource / staff
   */
  updateResourceValues(idEntity: string, body: any, isStaff: boolean) {
    let url = '';
    if (isStaff) {
      url = Endpoints.STAFF.UPDATE_STAFF(idEntity);
    } else {
      url = Endpoints.CONFIGURATIONS.UPDATE_RESOURCES(idEntity);
    }

    const options = this.sessionService.getOptions();
    const data$ = this.http.put(url, body, options);

    return data$;
  }

  updateStaffRole(idStaff, fields) {
    if (fields.idRole) {
      let body = { idRole: fields.idRole };
      let url = Endpoints.STAFF.STAFF_ROLE(idStaff);
      const options = this.sessionService.getOptions();
      const data$ = this.http.put(url, body, options);
      return data$;
    }
  }

  /**
   * Returns the list of problems
   */
  getProblemsList() {
    const url = Endpoints.CONFIGURATIONS.PROBLEMS;
    const opt = this.sessionService.getOptions();
    const reply$ = this.http.get(url, opt);

    return reply$;
  }

  /**
   * Returns the list of problems
   */
  getContraindicationList() {
    const url = Endpoints.CONFIGURATIONS.GENERIC_TYPES.CONTRAINDICATION;
    const opt = this.sessionService.getOptions();
    const reply$ = this.http.get(url, opt);

    return reply$;
  }

  /**
   * Returns the list of vouchers
   */
  getVoucherList(queryString: string) {
    const url = Endpoints.CONFIGURATIONS.CAMPAIGNS + '?' + queryString;
    const opt = this.sessionService.getOptions();
    const reply$ = this.http.get(url, opt);

    return reply$;
  }

  /**
   * Returns the list of all status types {name, id, title, subtitle, ...}
   */
  getLeadStatusList(params?) {
    const url = Endpoints.CONFIGURATIONS.STATES;
    const opt = this.sessionService.getOptions();
    if (params) {
      opt['params'] = params;
    }
    const reply$ = this.http.get(url, opt);

    return reply$;
  }

  getCalendarViewTypes() {
    const url = Endpoints.CONFIGURATIONS.GENERIC_TYPES.CALENDAR_VIEW_TYPES;
    return this.http.get(url, this.sessionService.getOptions()).toPromise();
  }

  getCalendarState() {
    const url = Endpoints.CONFIGURATIONS.GENERIC_TYPES.CALENDAR_STATE;
    return this.http.get(url, this.sessionService.getOptions());
  }

  /**
   * Given a Collection of ids returns the corresponding data from database - Common method
   */
  getRealTimeData(firestoreReply$: AngularFirestoreCollection<{}>, url: string, params = null) {
    const body = { ids: [] };
    const backendReply$ = firestoreReply$.stateChanges().pipe(
      switchMap(firestoreChanges => {
        const options = this.sessionService.getOptions();
        options['params'] = params;

        // Apply changes to ID list
        firestoreChanges.forEach((element: any) => {
          // console.log('Firestore element', element); // TODO: remove the comment on PR !!!
          switch (element.type) {
            case 'added':
              body.ids.push(element.payload.doc.id);
              break;
            case 'removed':
              const index = body.ids.indexOf(element.payload.doc.id);

              if (index > -1) {
                body.ids.splice(index, 1);
              }
              break;
            case 'modified':
              break;

            default:
              break;
          }
        });

        // console.log('Firestore Reply: ', body); // TODO: remove the comment on PR !!!

        // Fetch data with selected IDs <= It's POST due to GETs header size restrictions
        const data$ = this.http.post(url, body, options);

        // console.log('data$', data$); // TODO:        Remover antes do PR
        return data$;
      })
    );

    return backendReply$;
  }

  /**
   * Returns the opportunities ordered by Date
   */
  getOpportunities(quantity: number, offset: Date): Observable<any> {
    // .where('isDirectSale','==',false)
    const firestoreReply$ = this.firestore.collection('/opportunities', rep =>
      rep.where('isSale', '==', false).orderBy('createdDate', 'desc').startAt(offset).limit(quantity)
    );

    const url = Endpoints.OPPORTUNITIES.OPPORTUNITIES_BATCH;
    const backendReply$ = this.getRealTimeData(firestoreReply$, url).pipe(
      map((l: any[]) => {
        l.map(e => {
          e.modifiedDate = getDate(e.modifiedDate);
          return e;
        });
        return l;
      }), // modifiedDate: string => Date
      map((l: any[]) => l.sort((a, b) => b.modifiedDate - a.modifiedDate))
    ); // sorts by modifiedDate

    return backendReply$;
  }

  /**
   * Returns the sales ordered by Date
   */
  getSales(quantity: number, offset: Date): Observable<any> {
    const firestoreReply$ = this.firestore.collection('/opportunities', rep =>
      rep.where('isSale', '==', true).orderBy('createdDate', 'desc').startAt(offset).limit(quantity)
    );

    const url = Endpoints.OPPORTUNITIES.OPPORTUNITIES_BATCH;
    const backendReply$ = this.getRealTimeData(firestoreReply$, url).pipe(
      map((l: any[]) => {
        l.map(e => {
          e.modifiedDate = getDate(e.modifiedDate);
          return e;
        });
        return l;
      }), // modifiedDate: string => Date
      map((l: any[]) => l.sort((a, b) => b.modifiedDate - a.modifiedDate))
    ); // sorts by modifiedDate

    return backendReply$;
  }

  /**
   * Returns a lead
   */
  getOpportunity(id: string) {
    const options = this.sessionService.getOptions();
    let url = Endpoints.OPPORTUNITIES.OPPORTUNITY(id);

    const clients$ = this.http.get(url, options);

    return clients$;
  }

  updateOppHistory(change): Observable<any> {
    const change$ = change;
    return change$;
  }

  /**
   * Fetches user Notes from firestore. (Requires User ID & isFavorite)
   */
  getUserNotes(quantity: number, offset: Date, filters: any) {
    const firestoreReply$ = this.firestore.collection('/notes', rep =>
      rep
        .where('idUser', '==', filters.idUser)
        .where('isFavorite', '==', filters.isFavorite)
        .orderBy('modifiedDate', 'desc')
        .startAt(offset)
        .limit(quantity)
    );

    const url = Endpoints.CLIENTS.USERS.USER_NOTES_BATCH(filters.idUser);
    const backendReply$ = this.getRealTimeData(firestoreReply$, url).pipe(
      map((l: any[]) => {
        l.map(e => {
          e.modifiedDate = getDate(e.modifiedDate);
          return e;
        });
        return l;
      }), // modifiedDate: string => Date
      map((l: any[]) => l.sort((a, b) => b.modifiedDate - a.modifiedDate))
    ); // sorts by modifiedDate

    return backendReply$;
  }

  getOpportunityFromFS(id: string) {
    return this.firestore.collection('/opportunities').doc(id).valueChanges();
  }

  /**
   * Returns a opportnities filtered
   */
  getOpportunityFiltered(filters: string, addParams = true) {
    const options = this.sessionService.getOptions();
    let url = '';
    const body = { idOrigin: '' };

    if (filters['idOrigin'] && filters['idOrigin'].length > 0) {
      body.idOrigin = filters['idOrigin'];
      delete filters['idOrigin'];
    }

    if (addParams) {
      url = Endpoints.OPPORTUNITIES.OPPORTUNITIES_GET_ALL;
      options['params'] = filters;
    } else {
      url = Endpoints.OPPORTUNITIES.OPPORTUNITIES_FILTER(filters);
    }

    const reply$ = this.http.post(url, body, options);

    return reply$;
  }

  /**
   * Returns an Object containing a month worth of data
   */
  getOpportunitiesByMonth(quantity: number, offset: number, filters: any) {
    // FIXME: verificar se está a filtrar
    // console.log('QUANTIDADE   ', quantity); // TODO: remove the comment on PR !!!
    // console.log('OFFSET   ', offset); // TODO: remove the comment on PR !!!
    const start = getDate(`${filters.date.getFullYear()}-${filters.date.getMonth()}-1`);
    const end = getDate(`${filters.date.getFullYear()}-${filters.date.getMonth() + 1}-1`);
    // console.log('Start', start); // TODO: remove the comment on PR !!!
    // console.log('End', end); // TODO: remove the comment on PR !!!

    const firestoreReply$ = this.firestore.collection('/opportunities', rep =>
      rep
        // .where('createdDate', '>=', start)
        // .where('createdDate', '<', end)
        .orderBy('modifiedDate', 'desc')
        .startAt(offset)
        .limit(quantity)
    );

    const url = Endpoints.OPPORTUNITIES.OPPORTUNITIES_BATCH;
    const infos$ = this.getRealTimeData(firestoreReply$, url).pipe(
      map((l: any[]) => {
        l.map(e => {
          e.modifiedDate = getDate(e.modifiedDate);
          return e;
        });
        return l;
      }), // modifiedDate: string => Date
      map((l: any[]) => l.sort((a, b) => b.modifiedDate - a.modifiedDate))
    ); // sorts by modifiedDate

    return infos$;
  }

  /**
   * Returns a set of opportunities for a given status ordered by Date
   */
  getOpportunitiesByStatus(quantity: number, offset: Date, filters: any) {
    const firestoreReply$ = this.firestore.collection('/opportunities', rep =>
      rep
        .where('idEtapa', '==', filters.status)
        .where('isWon', '==', filters.value)
        .orderBy('modifiedDate', 'desc')
        .startAfter(offset) // FIXME: Adicionar StartAfter
        .limit(quantity)
    );

    const url = Endpoints.OPPORTUNITIES.OPPORTUNITIES_BATCH;
    const backendReply$ = this.getRealTimeData(firestoreReply$, url).pipe(
      map((l: any[]) => {
        l.map(e => {
          e.modifiedDate = getDate(e.modifiedDate);
          return e;
        });
        return l;
      }), // modifiedDate: string => Date
      map((l: any[]) => l.sort((a, b) => b.modifiedDate - a.modifiedDate))
    ); // sorts by modifiedDate

    return backendReply$;
  }

  getExportedLeads(filters: string) {
    const url = Endpoints.CLIENTS.LEADS.LEADS_EXPORT(filters);
    const opt = this.sessionService.getOptions('text/csv');
    opt['responseType'] = 'blob' as 'json';
    const reply$ = this.http.get(url, opt);
    return reply$;
  }

  /**
   * Returns a set of leads for a given status ordered by Date
   */
  getLeadsByStatus(quantity: number, offset: Date, filters: any) {
    const firestoreReply$ = this.firestore.collection('/leads', rep =>
      rep
        .where('state', '==', filters.status)
        .where('isLost', '==', filters.isLost)
        .orderBy('modifiedDate', 'desc')
        .startAfter(offset)
        .limit(quantity)
    );

    const url = Endpoints.CLIENTS.LEADS.LEADS_BATCH;

    const backendReply$ = this.getRealTimeData(firestoreReply$, url).pipe(
      map((l: any) => {
        return l;
      }), // modifiedDate: string => Date
      map((l: any[]) => {
        l.map(e => {
          e.modifiedDate = getDate(e.modifiedDate);
          return e;
        });
        return l;
      }), // modifiedDate: string => Date
      map((l: any[]) => l.sort((a, b) => b.modifiedDate - a.modifiedDate))
    ); // sorts by modifiedDate

    return backendReply$;
  }

  /**
   * Returs the leads associated with the provided client ID and Status ordered by Date Desc
   */
  getLeadsByClientIdClientDetails(clientId: string, quantity: number, offset: Date, filters: any) {
    const firestoreReply$ = this.firestore.collection('/leads', rep =>
      rep.where('idUser', '==', clientId).orderBy('modifiedDate', 'desc').startAt(offset).limit(quantity)
    );

    const url = Endpoints.CLIENTS.LEADS.LEADS_BATCH;
    const backendReply$ = this.getRealTimeData(firestoreReply$, url).pipe(
      map((l: any[]) => {
        l.map(e => {
          e.modifiedDate = getDate(e.modifiedDate);
          return e;
        });
        return l;
      }), // modifiedDate: string => Date
      map((l: any[]) => l.sort((a, b) => b.modifiedDate - a.modifiedDate))
    ); // sorts by modifiedDate

    return backendReply$;
  }

  /**
   * Returns a set of leads for a given status ordered by Date
   */
  getLeadsByClientId(clientId: string, isClient, quantity: number, offset: Date, filters: any) {
    const firestoreReply$ = this.firestore.collection('/leads', rep =>
      rep
        .where('idUser', '==', clientId)
        .where('isClient', '==', isClient) // FIXME: foi removido para mostrar as leads todas em contact-detail/leads
        .orderBy('modifiedDate', 'desc')
        .startAt(offset)
        .limit(quantity)
    );

    const url = Endpoints.CLIENTS.LEADS.LEADS_BATCH;
    const backendReply$ = this.getRealTimeData(firestoreReply$, url).pipe(
      map((l: any[]) => {
        l.map(e => {
          e.modifiedDate = getDate(e.modifiedDate);
          return e;
        });
        return l;
      }), // modifiedDate: string => Date
      map((l: any[]) => l.sort((a, b) => b.modifiedDate - a.modifiedDate))
    ); // sorts by modifiedDate

    return backendReply$;
  }

  getRealtimeEvents(idClinic, startDate, endDate, params = null) {
    const firestoreReply$ = this.firestore.collection(
      '/calendar',
      rep => rep.where('idClinic', '==', idClinic).where('startDate', '>', startDate).where('startDate', '<', endDate)
      // .orderBy('startDate', 'desc')
    );

    const url = Endpoints.CALENDAR.CLINIC_EVENTS_BATCH; // FIXME: Criar o endpoint
    const backendReply$ = this.getRealTimeData(firestoreReply$, url, params).pipe(
      map((l: any[]) => {
        l.map(e => {
          e.modifiedDate = getDate(e.modifiedDate, false);
          return e;
        });
        return l;
      }), // modifiedDate: string => Date
      map((l: any[]) => l.sort((a, b) => b.modifiedDate - a.modifiedDate))
    ); // sorts by modifiedDate

    return backendReply$;
  }

  getUserRealtimeEvents(idClient: any, quantity: number, offset: Date) {
    const firestoreReply$ = this.firestore.collection('/calendar', rep =>
      rep.where('idUser', '==', idClient).orderBy('startDate', 'desc').startAt(offset)
    );

    const url = Endpoints.CALENDAR.CLINIC_EVENTS_BATCH;
    const backendReply$ = this.getRealTimeData(firestoreReply$, url).pipe(
      map((l: any[]) => {
        l.map(e => {
          e.startDate = getDate(e.startDate, false);
          return e;
        });
        return l;
      }), // modifiedDate: string => Date
      map((l: any[]) => l.sort((a, b) => b.startDate.unix() - a.startDate.unix()))
    ); // sorts by modifiedDate

    return backendReply$;
  }

  exportUserEvents(idUser: string, idsStateEvent: string[]) {
    const options = this.sessionService.getOptions();
    options['responseType'] = 'blob' as 'json';
    const body = { ids: idsStateEvent };
    let url = Endpoints.CALENDAR.EXPORT_EVENTS(idUser);
    const events$ = this.http.post(url, body, options);

    return events$;
  }

  getUserLastEvent(idClient: any) {
    const offset = getDate();
    const firestoreReply$ = this.firestore.collection('/calendar', rep =>
      rep
        .where('idUser', '==', idClient)
        .where('startDate', '<', offset)
        .orderBy('startDate', 'desc')
        .startAt(offset)
        .limit(1)
    );

    const url = Endpoints.CALENDAR.CLINIC_EVENTS_BATCH; // FIXME: Criar o endpoint
    const backendReply$ = this.getRealTimeData(firestoreReply$, url).pipe(
      map((l: any[]) => {
        l.map(e => {
          e.modifiedDate = getDate(e.modifiedDate, false);
          return e;
        });
        return l;
      }), // modifiedDate: string => Date
      map((l: any[]) => l.sort((a, b) => b.modifiedDate - a.modifiedDate))
    ); // sorts by modifiedDate

    return backendReply$;
  }

  getUserNextEvent(idClient: any) {
    const offset = getDate();
    const firestoreReply$ = this.firestore.collection('/calendar', rep =>
      rep
        .where('idUser', '==', idClient)
        .where('startDate', '>', offset)
        .orderBy('startDate', 'asc')
        .startAt(offset)
        .limit(1)
    );

    const url = Endpoints.CALENDAR.CLINIC_EVENTS_BATCH; // FIXME: Criar o endpoint
    const backendReply$ = this.getRealTimeData(firestoreReply$, url).pipe(
      map((l: any[]) => {
        l.map(e => {
          e.modifiedDate = getDate(e.modifiedDate, false);
          return e;
        });
        return l;
      }), // modifiedDate: string => Date
      map((l: any[]) => l.sort((a, b) => b.modifiedDate - a.modifiedDate))
    ); // sorts by modifiedDate

    return backendReply$;
  }

  /**
   * Returns the leads ordered by Date
   */
  getLeads(quantity: number, offset: Date): Observable<any> {
    const firestoreReply$ = this.firestore.collection('/leads', rep =>
      rep.orderBy('modifiedDate', 'desc').startAt(offset).limit(quantity)
    );

    const url = Endpoints.CLIENTS.LEADS.LEADS_BATCH;
    const backendReply$ = this.getRealTimeData(firestoreReply$, url).pipe(
      map((l: any[]) => {
        l.map(e => {
          e.modifiedDate = getDate(e.modifiedDate);
          return e;
        });
        return l;
      }), // modifiedDate: string => Date
      map((l: any[]) => l.sort((a, b) => b.modifiedDate - a.modifiedDate))
    ); // sorts by modifiedDate

    return backendReply$;
  }

  /**
   * Returns a list of Clients that can be filtered by People or Contacts
   */
  getClients(quantity: number, offset: Date, filters: any): Observable<any> {
    const firestoreReply$ = this.firestore.collection('/users', rep =>
      rep.where('isClient', '==', filters.isClient).orderBy('modifiedDate', 'desc').startAt(offset).limit(quantity)
    );

    const url = Endpoints.CLIENTS.USERS.USERS_BATCH;
    const clients$ = this.getRealTimeData(firestoreReply$, url).pipe(
      map((l: any[]) => {
        l.map(e => {
          e.modifiedDate = getDate(e.modifiedDate);
          return e;
        });
        return l;
      }), // modifiedDate: string => Date
      map((l: any[]) => l.sort((a, b) => b.modifiedDate - a.modifiedDate))
    ); // sorts by modifiedDate

    return clients$;
  }

  /**
   * Returns Lead/User associated infos
   */
  getUserInfos(quantity: number, offset: Date, id: string, isLead: boolean = false) {
    const firestoreReply$ = this.firestore.collection('/notes', rep =>
      rep.where('idEntity', '==', id).orderBy('modifiedDate', 'desc').startAt(offset).limit(quantity)
    );

    const url = isLead ? Endpoints.CLIENTS.LEADS.LEAD_NOTES_BATCH(id) : Endpoints.CLIENTS.USERS.USER_NOTES_BATCH(id);
    const infos$ = this.getRealTimeData(firestoreReply$, url).pipe(
      map((l: any[]) => {
        l.map(e => {
          e.modifiedDate = getDate(e.modifiedDate);
          return e;
        });
        return l;
      }), // modifiedDate: string => Date
      map((l: any[]) => l.sort((a, b) => b.modifiedDate - a.modifiedDate))
    ); // sorts by modifiedDate

    return infos$;
  }

  /**
   * Returns notes/User associated infos
   */
  getNotesUser(offset: Date, id: string) {
    const firestoreReply$ = this.firestore.collection('/notes', rep =>
      rep.where('idUser', '==', id).orderBy('modifiedDate', 'desc').startAt(offset)
    );

    const url = Endpoints.CLIENTS.USERS.USER_NOTES_BATCH(id);
    const infos$ = this.getRealTimeData(firestoreReply$, url).pipe(
      map((l: any[]) => {
        l.map(e => {
          e.modifiedDate = getDate(e.modifiedDate);
          return e;
        });
        return l;
      }), // modifiedDate: string => Date
      map((l: any[]) => l.sort((a, b) => b.modifiedDate - a.modifiedDate))
    ); // sorts by modifiedDate

    return infos$;
  }

  /**
   * Returns the docs associated with the user
   */
  getUserDocs(quantity: number, offset: Date, id: string, isLead: boolean = false) {
    const firestoreReply$ = this.firestore.collection('/documents', rep =>
      rep.where('idEntity', '==', id).orderBy('modifiedDate', 'desc').startAt(offset).limit(quantity)
    );

    const url = isLead ? Endpoints.CLIENTS.LEADS.FILES_BATCH(id) : Endpoints.CLIENTS.USERS.FILES_BATCH(id);
    const infos$ = this.getRealTimeData(firestoreReply$, url).pipe(
      map((l: any[]) => {
        l.map(e => {
          e.modifiedDate = getDate(e.modifiedDate);
          return e;
        });
        return l;
      }), // modifiedDate: string => Date
      map((l: any[]) => l.sort((a, b) => b.modifiedDate - a.modifiedDate))
    ); // sorts by modifiedDate

    return infos$;
  }

  /**
   * Returns All docs associated with the user, its leads and its payments
   */
  getAllUserDocs(quantity: number, offset: Date, id: string) {
    const firestoreReply$ = this.firestore.collection('/documents', rep =>
      rep.where('idUser', '==', id).orderBy('modifiedDate', 'desc').startAt(offset).limit(quantity)
    );

    const url = Endpoints.CLIENTS.USERS.FILES_BATCH(id);
    const infos$ = this.getRealTimeData(firestoreReply$, url).pipe(
      map((l: any[]) => {
        l.map(e => {
          e.modifiedDate = getDate(e.modifiedDate);
          return e;
        });
        return l;
      }), // modifiedDate: string => Date
      map((l: any[]) => l.sort((a, b) => b.modifiedDate - a.modifiedDate))
    ); // sorts by modifiedDate

    return infos$;
  }

  /**
   * Returns user Deductions
   */
  getDeductions(quantity: any, offset: any, id: string): any {
    const firestoreReply$ = this.firestore.collection('/deductions', rep =>
      rep.where('idUser', '==', id).orderBy('modifiedDate', 'desc').startAt(offset).limit(quantity)
    );

    const url = Endpoints.CLIENTS.USERS.USER_DEDUCTIONS_BATCH(id);

    const deductions$ = this.getRealTimeData(firestoreReply$, url).pipe(
      map((l: any[]) => {
        l.map(e => {
          e.modifiedDate = getDate(e.modifiedDate);
          return e;
        });
        return l;
      }), // modifiedDate: string => Date
      map((l: any[]) => l.sort((a, b) => b.modifiedDate - a.modifiedDate))
    ); // sorts by modifiedDate

    return deductions$;
  }

  /**
   * Returns the list of user Points
   */
  getUserPoints(quantity: number, offset: Date, id: string) {
    const firestoreReply$ = this.firestore.collection('/points', rep =>
      rep.where('idUser', '==', id).orderBy('modifiedDate', 'desc').startAt(offset).limit(quantity)
    );

    const url = Endpoints.CLIENTS.USERS.POINTS_BATCH(id);
    const points$ = this.getRealTimeData(firestoreReply$, url).pipe(
      map((l: any[]) => {
        l.map(e => {
          e.modifiedDate = getDate(e.modifiedDate);
          return e;
        });
        return l;
      }), // modifiedDate: string => Date
      map((l: any[]) => l.sort((a, b) => b.modifiedDate - a.modifiedDate))
    ); // sorts by modifiedDate

    return points$;
  }
  getPoints() {
    const url = Endpoints.CONFIGURATIONS.PONTOS;
    const opt = this.sessionService.getOptions();

    const reply$ = this.http.get(url, opt);

    return reply$;
  }
  updatePointConvertionRate(id, body) {
    const url = Endpoints.CONFIGURATIONS.PONTOS_SPECIFIC(id);
    const opt = this.sessionService.getOptions();

    const reply$ = this.http.put(url, body, opt);

    return reply$;
  }
  /**
   * Returns Lead/User associated tasks
   */
  getUserTasks(quantity: number, offset: Date, id: string, isLead: boolean = false) {
    const firestoreReply$ = this.firestore.collection('/tasks', rep =>
      rep.where('idEntity', '==', id).orderBy('modifiedDate', 'desc').startAt(offset).limit(quantity)
    );

    const url = isLead ? Endpoints.CLIENTS.LEADS.LEAD_TASKS_BATCH(id) : Endpoints.CLIENTS.USERS.USER_TASKS_BATCH(id);
    const tasks$ = this.getRealTimeData(firestoreReply$, url).pipe(
      map((l: any[]) => {
        l.map(e => {
          e.modifiedDate = getDate(e.modifiedDate);
          return e;
        });
        return l;
      }), // modifiedDate: string => Date
      map((l: any[]) => l.sort((a, b) => b.modifiedDate - a.modifiedDate))
    ); // sorts by modifiedDate

    return tasks$;
  }

  /**
   * TODO: refactor all configuration methods to single method -> move to DataService
   * Returns a list of Opportunity types.
   */
  getOpportunityTypeList() {
    const url = Endpoints.CONFIGURATIONS.GENERIC_TYPES.OPPORTUNITIES_TYPES;
    const opt = this.sessionService.getOptions();

    const reply$ = this.http.get(url, opt);

    return reply$;
  }

  /**
   * TODO: refactor all configuration methods to single method -> move to DataService
   * Returns a list of Opportunity status.
   */
  getOpportunityStatusList() {
    const url = Endpoints.CONFIGURATIONS.GENERIC_TYPES.OPPORTUNITIES_STATUS;
    const opt = this.sessionService.getOptions();

    const reply$ = this.http.get(url, opt);

    return reply$;
  }

  getOpportunityEtapasList(search?: any) {
    const url = Endpoints.CONFIGURATIONS.ETAPAS;
    const opt = this.sessionService.getOptions();
    opt['params'] = {
      orderColumn: 'order',
      orderType: 'ASC',
    };
    if (search) {
      opt['params'] = {
        ...opt['params'],
        name: search,
      };
    }
    const reply$ = this.http.get(url, opt);

    return reply$;
  }

  getEstadoLeadsList(params?: any) {
    const url = Endpoints.CONFIGURATIONS.ESTADO_LEADS;
    const opt = this.sessionService.getOptions();
    if (params) {
      opt['params'] = params;
    }
    const reply$ = this.http.get(url, opt);

    return reply$;
  }

  getCategoriaTratamentoList(params?: any) {
    const url = Endpoints.CONFIGURATIONS.CATEGORIAS_TRATAMENTOS;
    const opt = this.sessionService.getOptions();
    if (params) {
      opt['params'] = params;
    }
    const reply$ = this.http.get(url, opt);

    return reply$;
  }

  getGoals(search?: any) {
    const url = Endpoints.CONFIGURATIONS.GOALS;
    const opt = this.sessionService.getOptions();
    if (search) {
      opt['params'] = search;
    }
    const reply$ = this.http.get(url, opt);

    return reply$;
  }

  deleteGoals(body) {
    const url = Endpoints.CONFIGURATIONS.DELETE_GOALS;
    const opt = this.sessionService.getOptions();
    const reply$ = this.http.post(url, body, opt);

    return reply$;
  }

  deleteGoal(id) {
    const url = Endpoints.CONFIGURATIONS.GOAL(id);
    const opt = this.sessionService.getOptions();
    const reply$ = this.http.delete(url, opt);

    return reply$;
  }

  updateGoalsList(body) {
    const url = Endpoints.CONFIGURATIONS.GOALS;
    const opt = this.sessionService.getOptions();
    const reply$ = this.http.put(url, body, opt);

    return reply$;
  }

  updateGoals(id, body) {
    const url = Endpoints.CONFIGURATIONS.GOAL(id);
    const opt = this.sessionService.getOptions();
    const reply$ = this.http.put(url, body, opt);

    return reply$;
  }

  createGoals(body: any) {
    const options = this.sessionService.getOptions();
    const url = Endpoints.CONFIGURATIONS.GOALS;

    const data$ = this.http.post(url, body, options);

    return data$;
  }

  deleteOpportunityEtapas(body) {
    const url = Endpoints.CONFIGURATIONS.DELETE_ETAPAS;
    const opt = this.sessionService.getOptions();
    const reply$ = this.http.post(url, body, opt);

    return reply$;
  }

  deleteOpportunityEtapa(id) {
    const url = Endpoints.CONFIGURATIONS.ETAPA(id);
    const opt = this.sessionService.getOptions();
    const reply$ = this.http.delete(url, opt);

    return reply$;
  }

  deleteEstadoLeads(id) {
    const url = Endpoints.CONFIGURATIONS.ESTADO_LEADS_ID(id);
    const opt = this.sessionService.getOptions();
    const reply$ = this.http.delete(url, opt);

    return reply$;
  }

  deleteListEstadoLeads(body) {
    const url = Endpoints.CONFIGURATIONS.DELETE_ESTADO_LEADS;
    const opt = this.sessionService.getOptions();
    const reply$ = this.http.post(url, body, opt);

    return reply$;
  }

  deleteClinic(id) {
    const url = Endpoints.CONFIGURATIONS.GET_CLINIC(id);
    const opt = this.sessionService.getOptions();
    return this.http.delete(url, opt).toPromise();
  }
  deleteListClinic(body) {
    const url = Endpoints.CONFIGURATIONS.DELETE_CLINICS;
    const opt = this.sessionService.getOptions();
    return this.http.post(url, body, opt);
  }
  updateSingleClinic(id, body) {
    const url = Endpoints.CONFIGURATIONS.GET_CLINIC(id);
    const opt = this.sessionService.getOptions();
    return this.http.put(url, body, opt).toPromise();
  }
  updateClinic(body) {
    const url = Endpoints.CONFIGURATIONS.CLINICS_GENERIC;
    const opt = this.sessionService.getOptions();
    return this.http.put(url, body, opt).toPromise();
  }
  createClinic(body) {
    const url = Endpoints.CONFIGURATIONS.CLINICS_GENERIC;
    const opt = this.sessionService.getOptions();
    return this.http.post(url, body, opt).toPromise();
  }
  deleteCategoriaTratamento(id) {
    const url = Endpoints.CONFIGURATIONS.CATEGORIA_TRATAMENTO(id);
    const opt = this.sessionService.getOptions();
    const reply$ = this.http.delete(url, opt);

    return reply$;
  }
  deleteListCategoriaTratamento(body) {
    const url = Endpoints.CONFIGURATIONS.DELETE_CATEGORIA_TRATAMENTO;
    const opt = this.sessionService.getOptions();
    const reply$ = this.http.post(url, body, opt);

    return reply$;
  }
  updateSingleCategoriaTratamento(id, body) {
    const url = Endpoints.CONFIGURATIONS.CATEGORIA_TRATAMENTO(id);
    const opt = this.sessionService.getOptions();
    const reply$ = this.http.put(url, body, opt);

    return reply$;
  }
  updateCategoriaTratamento(body) {
    const url = Endpoints.CONFIGURATIONS.CATEGORIAS_TRATAMENTOS;
    const opt = this.sessionService.getOptions();
    const reply$ = this.http.put(url, body, opt);

    return reply$;
  }
  createCategoriaTratamento(body) {
    const url = Endpoints.CONFIGURATIONS.CATEGORIAS_TRATAMENTOS;
    const opt = this.sessionService.getOptions();
    const reply$ = this.http.post(url, body, opt);

    return reply$;
  }

  updateOpportunityEtapasList(body) {
    const url = Endpoints.CONFIGURATIONS.ETAPAS;
    const opt = this.sessionService.getOptions();
    const reply$ = this.http.put(url, body, opt);

    return reply$;
  }

  updateEstadoLeadsList(body) {
    const url = Endpoints.CONFIGURATIONS.ESTADO_LEADS;
    const opt = this.sessionService.getOptions();
    const reply$ = this.http.put(url, body, opt);

    return reply$;
  }

  updateEtapa(id, body) {
    const url = Endpoints.CONFIGURATIONS.ETAPA(id);
    const opt = this.sessionService.getOptions();
    const reply$ = this.http.put(url, body, opt);

    return reply$;
  }

  updateEstadoLeads(id, body) {
    const url = Endpoints.CONFIGURATIONS.ESTADO_LEADS_ID(id);
    const opt = this.sessionService.getOptions();
    const reply$ = this.http.put(url, body, opt);

    return reply$;
  }

  /**
   * TODO: refactor all configuration methods to single method -> move to DataService
   * Returns a list of all Task Types
   */
  getTaskTypes() {
    const url = Endpoints.CONFIGURATIONS.GENERIC_TYPES.TASK_TYPES;
    const opt = this.sessionService.getOptions();

    const taskTypes$ = this.http.get(url, opt);

    return taskTypes$;
  }

  /**
   * Returns a list of all Status Types
   */
  getTaskStatus() {
    const url = Endpoints.CONFIGURATIONS.GENERIC_TYPES.TASK_STATUS;
    const opt = this.sessionService.getOptions();

    const taskStatus$ = this.http.get(url, opt);

    return taskStatus$;
  }

  /**
   * Returns a contact
   */
  getContact(id: string) {
    const firestoreReply$ = this.firestore.collection('/users').doc(id);

    const options = this.sessionService.getOptions();
    const url = Endpoints.CLIENTS.USERS.USER(id);

    // FIXME: >>>>> Continuar aqui <<<<<<        -       Por em tempo real
    // const clients$ = this.coisas(firestoreReply$, url);
    const clients$ = this.http.get(url, options);

    return clients$;
  }

  /**
   * Returns a lead
   */
  getLead(id: string) {
    const firestoreReply$ = this.firestore
      .collection('/leads')
      .doc(id)
      .valueChanges()
      .subscribe(h => {
        // FIXME: >>>>> Continuar aqui <<<<<<        -       Por em tempo real
      });

    const options = this.sessionService.getOptions();
    const url = Endpoints.CLIENTS.LEADS.LEAD(id);

    const clients$ = this.http.get(url, options);

    return clients$;
  }

  // TODO: implement
  getClientHistory(id: number, quantity: number, offset: number, filter: any): Observable<any> {
    return of([
      //   {
      //     date: '10/05/2018',
      //     type: 'Pagamento',
      //     title: ' BodyScience Admin adicionou um pagamento, efectuado pelo método Visa 1, no valor de 249,99 €',
      //     description: 'Pagamento do valor 249,99 € com método de pagamento Visa 1'
      //   },
      //   {
      //     date: '1/04/2018',
      //     type: 'Pagamento',
      //     title: ' BodyScience Admin adicionou um pagamento, efectuado pelo método Visa 1, no valor de 249,99 €',
      //     description: 'Pagamento do valor 249,99 € com método de pagamento Visa 1'
      //   }
    ]);
  }

  //  ===========================================================================
  //  ============================  Configuration  ==============================
  //  ===========================================================================

  getAllConfigurations() {
    const configs$ = observableOf(this.getConfigurations());

    return configs$;
  }

  getConfigurationsByType() {
    const configs$ = observableOf(this.getConfiguration());

    return configs$;
  }

  // FIXME: remove once getConfigurationByType() fetches from endpoint
  getConfiguration(): {} {
    return {
      name: 'general',
      title: 'nabia.configurations.general.title',
      subtitle: 'nabia.configurations.general.sub-title',
      items: [
        {
          name: 'problems',
          title: 'nabia.configurations.general.items.problems',
          uri: '',
        },
        {
          name: 'occupations',
          title: 'nabia.configurations.general.items.occupations',
          uri: '',
        },
        {
          name: 'clinics',
          title: 'nabia.configurations.general.items.clinics',
          uri: '',
        },
        {
          name: 'clinics2',
          title: 'nabia.configurations.general.items.clinics',
          uri: '',
        },
        {
          name: 'clinics3',
          title: 'nabia.configurations.general.items.clinics',
          uri: '',
        },
        {
          name: 'clinics4',
          title: 'nabia.configurations.general.items.clinics',
          uri: '',
        },
        {
          name: 'clinics5',
          title: 'nabia.configurations.general.items.clinics',
          uri: '',
        },
        {
          name: 'comissions',
          title: 'nabia.configurations.general.items.comissions',
          uri: '',
        },
      ],
    };
  }
  // FIXME: remove once getAllConfiturations() fetches from endpoint
  getConfigurations(): {}[] {
    const configurations = Array<Object>();

    configurations.push(
      {
        name: 'general',
        title: 'nabia.configurations.general.title',
        subtitle: 'nabia.configurations.general.sub-title',
        items: [
          {
            name: 'problems',
            title: 'nabia.configurations.general.items.problems',
            uri: '',
          },
          {
            name: 'occupations',
            title: 'nabia.configurations.general.items.occupations',
            uri: '',
          },
          {
            name: 'clinics',
            title: 'nabia.configurations.general.items.clinics',
            uri: '',
          },
          {
            name: 'clinics2',
            title: 'nabia.configurations.general.items.clinics',
            uri: '',
          },
          {
            name: 'clinics3',
            title: 'nabia.configurations.general.items.clinics',
            uri: '',
          },
          {
            name: 'clinics4',
            title: 'nabia.configurations.general.items.clinics',
            uri: '',
          },
          {
            name: 'clinics5',
            title: 'nabia.configurations.general.items.clinics',
            uri: '',
          },
          {
            name: 'comissions',
            title: 'nabia.configurations.general.items.comissions',
            uri: '',
          },
        ],
      },
      {
        name: 'stock',
        title: 'nabia.configurations.general.title',
        subtitle: 'nabia.configurations.general.sub-title',
        items: [
          {
            name: 'problems',
            title: 'nabia.configurations.general.items.problems',
            uri: '',
          },
          {
            name: 'occupations',
            title: 'nabia.configurations.general.items.occupations',
            uri: '',
          },
        ],
      },
      {
        name: 'opportunities',
        title: 'nabia.configurations.general.title',
        subtitle: 'nabia.configurations.general.sub-title',
        items: [
          {
            name: 'problems',
            title: 'nabia.configurations.general.items.problems',
            uri: '',
          },
          {
            name: 'occupations',
            title: 'nabia.configurations.general.items.occupations',
            uri: '',
          },
        ],
      },
      {
        name: 'payments',
        title: 'nabia.configurations.general.title',
        subtitle: 'nabia.configurations.general.sub-title',
        items: [
          {
            name: 'problems',
            title: 'nabia.configurations.general.items.problems',
            uri: '',
          },
          {
            name: 'occupations',
            title: 'nabia.configurations.general.items.occupations',
            uri: '',
          },
        ],
      }
    );

    return configurations;
  }

  //  ===========================================================================
  //  ============================  Clinical Records  ===========================
  //  ===========================================================================

  getClinicalRecordTypes(uId: string): Observable<any> {
    const options = this.sessionService.getOptions();
    const url = Endpoints.CLINICAL_RECORDS.AVAILABLE_TYPES(uId);
    const crTypes$ = this.http.get(url, options);

    return crTypes$;
  }

  getEvaluationTypes(uId: string): Observable<any> {
    const options = this.sessionService.getOptions();
    const url = Endpoints.EVALUATIONS.AVAILABLE_TYPES(uId);
    const crTypes$ = this.http.get(url, options);

    return crTypes$;
  }

  getReportTypes(uId: string): Observable<any> {
    const options = this.sessionService.getOptions();
    const url = Endpoints.REPORTS.AVAILABLE_TYPES(uId);
    const crTypes$ = this.http.get(url, options);

    return crTypes$;
  }

  getClinicalRecords(uId: string): Observable<any> {
    const options = this.sessionService.getOptions();
    const url = Endpoints.CLINICAL_RECORDS.RECORDS(uId);
    const crTypes$ = this.http.get(url, options);

    return crTypes$;
  }

  getEvaluations(uId: string): Observable<any> {
    const options = this.sessionService.getOptions();
    const url = Endpoints.EVALUATIONS.EVALUATIONS(uId);
    const crTypes$ = this.http.get(url, options);

    return crTypes$;
  }

  getReports(uId: string): Observable<any> {
    const options = this.sessionService.getOptions();
    const url = Endpoints.REPORTS.REPORTS(uId);
    const crTypes$ = this.http.get(url, options);

    return crTypes$;
  }

  getClinicalRecordsEntry(uId: string, type: string): Observable<any> {
    const options = this.sessionService.getOptions();
    const url = Endpoints.CLINICAL_RECORDS.ENTRY_TYPE(uId, type);
    const crTypes$ = this.http.get(url, options);

    return crTypes$;
  }

  getEvaluationsEntry(uId: string, type: string): Observable<any> {
    const options = this.sessionService.getOptions();
    const url = Endpoints.EVALUATIONS.ENTRY_TYPE(uId, type);
    const crTypes$ = this.http.get(url, options);

    return crTypes$;
  }

  getReportsEntry(uId: string, type: string): Observable<any> {
    const options = this.sessionService.getOptions();
    const url = Endpoints.REPORTS.ENTRY_TYPE(uId, type);
    const crTypes$ = this.http.get(url, options);

    return crTypes$;
  }

  createRecord(typeId: string, userId: string) {
    const options = this.sessionService.getOptions();
    const url = Endpoints.CLINICAL_RECORDS.RECORDS(userId);
    const body = {
      idRecordType: typeId,
    };
    const data$ = this.http.post(url, body, options);

    return data$;
  }

  createEvaluation(typeId: string, userId: string) {
    const options = this.sessionService.getOptions();
    const url = Endpoints.EVALUATIONS.EVALUATIONS(userId);
    const body = {
      idEvaluationType: typeId,
    };
    const data$ = this.http.post(url, body, options);

    return data$;
  }

  createReport(typeId: string, userId: string) {
    const options = this.sessionService.getOptions();
    const url = Endpoints.REPORTS.REPORTS(userId);
    const body = {
      idEvaluationType: typeId,
    };
    const data$ = this.http.post(url, body, options);

    return data$;
  }

  createRecordEntry(data: any, recordId: string, typeId: string, userId: string) {
    const options = this.sessionService.getOptions();
    const url = Endpoints.CLINICAL_RECORDS.ENTRIES(userId, typeId);
    const body = {
      idRecordType: typeId,
      idUser: userId,
      idParentRecord: recordId,
      data: [data],
    };
    const data$ = this.http.post(url, body, options);

    return data$;
  }

  createEvaluationEntry(recordId: string, typeId: string, userId: string) {
    const options = this.sessionService.getOptions();
    const url = Endpoints.EVALUATIONS.ENTRIES(userId, typeId, recordId);
    const data$ = this.http.post(url, {}, options);

    return data$;
  }

  createReportsEntry(recordId: string, typeId: string, userId: string) {
    const options = this.sessionService.getOptions();
    const url = Endpoints.REPORTS.ENTRIES(userId, typeId, recordId);
    const data$ = this.http.post(url, {}, options);

    return data$;
  }

  updateRecordEntry(data: any, recordId: string, userId: string) {
    const options = this.sessionService.getOptions();
    const url = Endpoints.CLINICAL_RECORDS.UPDATE_RECORDS(userId, recordId);

    const body = {
      idUser: userId,
      idParentRecord: recordId,
      data: data,
    };
    const data$ = this.http.put(url, body, options);

    return data$;
  }

  updateEvaluationsEntry(data: any, recordId: string, userId: string, typeId: string) {
    const options = this.sessionService.getOptions();
    const url = Endpoints.EVALUATIONS.UPDATE_ENTRIES(userId, typeId, recordId);
    const body = {
      data: data,
    };
    const data$ = this.http.put(url, body, options);

    return data$;
  }

  updateReportEntry(data: any, recordId: string, userId: string, typeId: string) {
    const options = this.sessionService.getOptions();
    const url = Endpoints.REPORTS.UPDATE_ENTRIES(userId, typeId, recordId);
    const body = {
      data: data,
    };
    const data$ = this.http.put(url, body, options);

    return data$;
  }

  uploadFileEvaluation(file) {
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + this.sessionService.getTokenFromSession(),
    });

    const options = { headers: headers };

    const url = Endpoints.FILES.UPLOAD;

    const body = new FormData();
    body.append('files', file, file.name);
    const data$ = this.http.post(url, body, options);

    return data$;
  }

  getImage(url: string) {
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + this.sessionService.getTokenFromSession(),
    });
    const options = { headers: headers };

    const data$ = this.http.get(url, options);

    return data$;
  }

  getConfigurationByType(configurationType: string) {
    const options = this.sessionService.getOptions();
    const url = Endpoints.CONFIGURATIONS.GET_BY_TYPE(configurationType);

    const data$ = this.http.get(url, options);
    return data$;
  }

  createGenericConfiguration(payload: any) {
    const options = this.sessionService.getOptions();
    const url = Endpoints.CONFIGURATIONS.CREATE_GENERIC;

    const data$ = this.http.post(url, payload, options);

    return data$;
  }
  deleteGenericConfiguration(configurationId: any) {
    const options = this.sessionService.getOptions();
    const url = Endpoints.CONFIGURATIONS.UPDATE_GENERIC(configurationId);

    const data$ = this.http.delete(url, options);

    return data$;
  }
  updateGenericConfiguration(data: any, configurationId: string) {
    const options = this.sessionService.getOptions();
    const url = Endpoints.CONFIGURATIONS.UPDATE_GENERIC(configurationId);

    const data$ = this.http.put(url, data, options);

    return data$;
  }
  getGenericConfiguration(configurationId: string) {
    const options = this.sessionService.getOptions();
    const url = Endpoints.CONFIGURATIONS.UPDATE_GENERIC(configurationId);

    const data$ = this.http.get(url, options);

    return data$;
  }

  aggregateClient(aggregatingUserId: string, aggregatedUserId: string) {
    const options = this.sessionService.getOptions();
    const url = Endpoints.CLIENTS.USERS.AGGREGATE(aggregatingUserId, aggregatedUserId);
    const body = {};
    const data$ = this.http.post(url, body, options);

    return data$;
  }

  getCredits(idUser, params?) {
    const options = this.sessionService.getOptions();
    const url = Endpoints.CLIENTS.USERS.CREDITS(idUser);
    options['params'] = params;
    return this.http.get(url, options);
  }

  getEtapa(id) {
    const options = this.sessionService.getOptions();
    const url = Endpoints.CONFIGURATIONS.ETAPA(id);
    return this.http.get(url, options);
  }

  getEtapasType() {
    const options = this.sessionService.getOptions();
    const url = Endpoints.CONFIGURATIONS.GENERIC_TYPES.TYPE('01fa1805-81de-4918-89ec-3feaaf4154dc');
    return this.http.get(url, options);
  }

  getGoalsType() {
    const options = this.sessionService.getOptions();
    const url = Endpoints.CONFIGURATIONS.GENERIC_TYPES.TYPE('9ea066ee-5b21-11eb-8002-0242ac12000d');
    return this.http.get(url, options);
  }

  getOpportunitiesType() {
    const url = Endpoints.CONFIGURATIONS.GENERIC_TYPES.OPPORTUNITIES_TYPES;
    const opt = this.sessionService.getOptions();
    const reply$ = this.http.get(url, opt);
    return reply$;
  }

  getEstadoLeadsType() {
    const options = this.sessionService.getOptions();
    const url = Endpoints.CONFIGURATIONS.GENERIC_TYPES.TYPE('5ee427e1-02f0-11eb-9994-0242ac120008');
    return this.http.get(url, options);
  }

  getVersionApi() {
    const url = Endpoints.CONFIGURATIONS.VERSION_API;
    const options = this.sessionService.getOptions();
    return this.http.get(url, options);
  }

  getVersionFront() {
    const options = this.sessionService.getOptions();
    const url = Endpoints.CONFIGURATIONS.VERSION_FRONT;
    return this.http.get(url, options);
  }

  getTypesPayment(params?) {
    const url = Endpoints.CONFIGURATIONS.TYPES_PAYMENT;
    const opt = this.sessionService.getOptions();
    if (params) {
      opt['params'] = params;
    }

    return this.http.get(url, opt).toPromise();
  }

  postTypesPayment(body) {
    const url = Endpoints.CONFIGURATIONS.TYPES_PAYMENT;
    const opt = this.sessionService.getOptions();
    return this.http.post(url, body, opt).toPromise();
  }

  putTypesPayment(problemId: string, body) {
    const url = Endpoints.CONFIGURATIONS.TYPES_PAYMENT_SPECIFIC(problemId);
    const opt = this.sessionService.getOptions();
    return this.http.put(url, body, opt).toPromise();
  }

  putListTypesPayment(body) {
    const url = Endpoints.CONFIGURATIONS.TYPES_PAYMENT;
    const opt = this.sessionService.getOptions();
    return this.http.put(url, body, opt);
  }

  deleteTypesPayment(typePaymentId: string) {
    const url = Endpoints.CONFIGURATIONS.TYPES_PAYMENT_SPECIFIC(typePaymentId);
    const opt = this.sessionService.getOptions();
    return this.http.delete(url, opt).toPromise();
  }

  deleteListTypesPayment(body) {
    const url = Endpoints.CONFIGURATIONS.TYPES_PAYMENT_DELETE;
    const opt = this.sessionService.getOptions();
    return this.http.post(url, body, opt);
  }

  getTypesPaymentsType() {
    const options = this.sessionService.getOptions();
    const url = Endpoints.CONFIGURATIONS.GENERIC_TYPES.TYPE('5ca7016e-2d52-4f12-8c5a-b8158bfafded');
    return this.http.get(url, options);
  }
}
