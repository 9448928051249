import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { CalendarService } from '../../system/services/calendar.service';
import * as configs from '../../system/configurations';
import { ProductService } from '../../system/services/product.service';
import { ContactService } from '../../system/services/contact.service';
import { DataService } from '../../system/services/data.service';
import { MatDialog } from '@angular/material';
import { OkActionComponent } from '../../ok-action/ok-action.component';
import {getDate} from "../../system/date";
import { LeadStateService } from '../../system/services/leadState.service';



@Component({
    selector: 'app-schedule',
    templateUrl: './schedule.component.html',
    styleUrls: ['./schedule.component.scss']
})
export class ScheduleComponent implements OnInit {

    date = getDate();
    day: any;
    weekDay: any;
    nWeeks: any;
    month = getDate().getMonth();
    year = getDate().getFullYear();
    vag = 0;
    pre = 0;
    appValidated = false;
    validateButton = false;
    pessoas = [];
    request = { events: [] };
    clinics: any;
    roomsList: any;
    typeRoom = configs.resourceTypes.room;
    submit = true;
    weekDays = ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'];
    // TODO: PROBLEM_HERE (Property 'currentMonth' does not exist on type 'AddPaymentComponent'.
    currentMonth: any;
    // TODO: PROBLEM_HERE
    monthList = [
        { id: 0, name: 'Janeiro' },
        { id: 1, name: 'Fevereiro' },
        { id: 2, name: 'Março' },
        { id: 3, name: 'Abril' },
        { id: 4, name: 'Maio' },
        { id: 5, name: 'Junho' },
        { id: 6, name: 'Julho' },
        { id: 7, name: 'Agosto' },
        { id: 8, name: 'Setembro' },
        { id: 9, name: 'Outubro' },
        { id: 10, name: 'Novembro' },
        { id: 11, name: 'Dezembro' }
    ];
    // Exemplo de estrutura de dados que vem do backend
    date1 = getDate('2018-09-01T09:44:42.000Z'); // ISOString
    date2 = getDate('2018-09-02T09:44:42.000Z');
    date3 = getDate('2018-09-27T09:44:42.000Z');
    disponibilidades = [];
    listRooms = [];
    availabilities: any;
    selectedDay: any;
    selectedVacancies = []; // Vacancies that are shown on the second column (middle) of the view, where the user can select a free hour on a given day
    scheduledVacancies = [];  // Final list of the scheduled treatments. It appears on the final column (right) of the view
    viewVagas = false;
    totalDias = [];
    appType = [];
    origin: string;
    id2: string;
    param1: string;
    eventInicialState = configs.calStatus[0].bdID;
    idEvent: any;
    noRoom = false;
    isOnline = 0;

    constructor(
        private _location: Location,
        private route: ActivatedRoute,
        private calendarService: CalendarService,
        private router: Router,
        private dataService: DataService,
        private calendar: CalendarService,
        public dialog: MatDialog,
        private leadState : LeadStateService,
        private contactService: ContactService,
        private product: ProductService) {

        this.getParams();
    }


    ngOnInit() {
        this.getOrigin();
        this.getDaysInMonth(this.year, this.month);
        this.getLastDaysBefore(this.year, this.month);
        this.getFistDaysAfter(this.year, this.month);
        this.getEvaluationAppointmentTreatments();
        this.getAvailabilities();
        this.getClinics();
    }


    getParams() {
        this.route.params.subscribe(param => {
            this.isOnline = param.isOnline;
            this.param1 = JSON.parse(JSON.stringify(param.origin));
            this.idEvent = JSON.parse(JSON.stringify(param.id3));
        }).unsubscribe();
        this.route.queryParams.subscribe(param => {
            this.isOnline = param.isOnline;
        }).unsubscribe();
    }


    getEvaluationAppointmentTreatments() {
        this.appType = [];
        this.product.getEvaluationAppointmentServices().subscribe((reply: any) => {
            const tempArr = [];
            reply.forEach(element => {
                tempArr.push({ value: element.id, label: element.name });
            });
            this.appType = tempArr;
        });
    }


    getOrigin() {
        if (this.param1 && this.param1 === 'lead-detail' || this.param1 && this.param1 === 'reschedule-lead') {
            this.id2 = this.route.snapshot.params['id2'];
            this.origin = '/leads/lead-detail/' + this.id2;

        } else if (this.param1 && this.param1 === 'contact-detail') {
            this.id2 = this.route.snapshot.params['id2'];
            this.origin = '/people/' + this.param1 + '/' + this.id2 + '/appointment';

        } else if (this.param1 === 'client') {
            this.id2 = this.route.snapshot.params['id2'];
            this.calendarService.getAppointmentById(this.id2).subscribe((ap: any) => {
                this.contactService.getLead(ap.idLead).subscribe((ld: any) => {
                    this.origin = '/people/' + 'contact-detail' + '/' + ld.idUser + '/appointment';
                })
            });
        } else {
            this.origin = '/' + this.param1;
        }
    }


    getAvailabilities() {

        this.calendar.getAvailability(this.route.snapshot.params['id']).subscribe(h => {
            this.availabilities = h;
            this.getPessoas();
            this.creatAvailabilities();
        }, err => {
            if (err.status === 500) {
                this.noRoom = true;
            }

        });
    }

    creatRooms(rooms: any) {
        this.roomsList = {};
        for (const key in rooms) {
            if (rooms.hasOwnProperty(key)) {
                this.dataService.getResource(key).subscribe((res: any) => {
                    this.roomsList[key] = res ? res.name : 'Sala xpto';
                });
            }
        }
    }

    creatRoomList(id) {
        for (const key in id) {
            if (id.hasOwnProperty(key)) {
                const elem = id[key];
                elem.forEach(room => {
                    this.listRooms[room] = '';
                });
            }
        }
    }

    creatAvailabilities() {
        if (this.availabilities) {
            this.availabilities.forEach(person => {
                for (const key in person.availabilities) {
                    if (person.availabilities.hasOwnProperty(key)) {
                        const dates = key;
                        let hasDate = false;
                        this.disponibilidades.forEach(ele => {
                            const aux2 = getDate(dates, false).startOf('day').toDate();
                            if (ele.data.getTime() === aux2.getTime()) {
                                hasDate = true;
                                const id = person.appointment.appointment_lead.lead_user.id;
                                const name = person.appointment.appointment_lead.lead_user.users_names[0].firstName;
                                const horas = [];
                                for (const key2 in person.availabilities[key]) {
                                    if (person.availabilities[key].hasOwnProperty(key2)) {
                                        const hora = key2;
                                        // const aux = hora.split(':');
                                        const idClinic = person.appointment.appointment_lead.idConfigurationClinic;
                                        const resources = person.availabilities[key][key2];
                                        this.creatRoomList(resources);
                                        horas.push({ start: hora, end: '', added: false, isOpen: false, idClinic: idClinic, resources });
                                    }
                                }
                                ele.pessoas.push({ id: id, nome: name, totalDisp: 20, hasSelected: false, horas: horas });
                            }
                        });
                        if (hasDate === false) {
                            const pessoa = [];
                            const id = person.appointment.appointment_lead.lead_user.id;
                            const name = person.appointment.appointment_lead.lead_user.users_names[0].firstName;
                            const horas = [];
                            for (const key2 in person.availabilities[key]) {
                                if (person.availabilities[key].hasOwnProperty(key2)) {
                                    const hora = key2;
                                    // const hourSplit = hora.split(':');
                                    const resources = person.availabilities[key][key2];
                                    const idClinic = person.appointment.appointment_lead.idConfigurationClinic;
                                    this.creatRoomList(resources);

                                    horas.push({ start: hora, end: '', added: false, isOpen: false, idClinic: idClinic, resources });

                                }
                            }

                            pessoa.push({ id: id, nome: name, totalDisp: 20, hasSelected: false, horas: horas });
                            const aux = getDate(dates, false).startOf('day').toDate();
                            // aux.setHours(0o0, 0o0, 0o0);
                            this.disponibilidades.push({ data: aux, pessoas: pessoa });

                        }
                    }
                }
            });
            this.creatRooms(this.listRooms);
        }
    }

    getClinics() {
        this.clinics = {};
        return this.dataService.getClinics().subscribe((clinics: any) => {
            clinics.forEach(elem => {
                this.clinics[elem.id] = elem.name;
            });


        });
    }


    teste(id: string) {
        for (let i = 0; i < this.pessoas.length; i++) {
            const elem = this.pessoas[i];
            if (elem.id === id) {
                return elem.sessoes;
            }
        }
    }

    getPessoas() {
        if (this.availabilities) {
            this.availabilities.forEach(elem => {
                const sessoes = [];

                for (let i = 0; i < elem.appointment.nSessions; i++) {
                    sessoes.push({ data: getDate(), selected: false, resources: [] });
                }

                this.product.getAllTreatment(elem.appointment.idTreatment).subscribe((treatment: any) => {
                    const name = elem.appointment.appointment_lead.lead_user.users_names[0].firstName;
                    const id = elem.appointment.appointment_lead.lead_user.id;
                    const leadId = elem.appointment.appointment_lead.id;
                    const clinicId = elem.appointment.appointment_lead.idConfigurationClinic;
                    const serviceId = elem.appointment.idService;
                    this.pessoas.push({ id: id, nome: name, vagas: elem.appointment.nSessions, preenchidas: 0, isOpen: false, idLead: leadId, idClinic: clinicId, idService: serviceId, idAppointment: elem.appointment.id, duration: treatment.sessionDuration, sessoes: sessoes });
                });

            });
        }
    }

    selectSessionVaccancy(hora: any, pid: number, index: number) {
        let pessoa = 0;
        
        var data = getDate(this.selectedDay.data, false);
        const [hour, minute, segundos] = hora.start.split(':');
        data = data.set({hour,minute}).toDate();

        for (let i = 0; i < this.pessoas.length; i++) {
            const elem = this.pessoas[i];
            if (elem.id === pid) {
                pessoa = i;
            }
        }

        this.pessoas[pessoa].sessoes.forEach(elem => {
            if (elem.data.getTime() === data.getTime()) {
                elem.selected = false;
            }
        });

        this.pessoas[pessoa].sessoes[index].selected = true;
        this.pessoas[pessoa].sessoes[index].data = data;
        this.pessoas[pessoa].sessoes[index].resources = hora.resources;


        this.checkVaccancyNumber();
    }

    removeVaccancy(sessao: any) {
        sessao.selected = false;
        this.checkVaccancyNumber();
    }

    checkVaccancyNumber() {
        let allChecked = true;
        this.pre = 0,
            this.vag = 0;
        this.pessoas.forEach(elem => {
            elem.preenchidas = 0;
            elem.sessoes.forEach(sessao => {
                this.vag += 1;
                if (sessao.selected === true) {
                    elem.preenchidas += 1;
                    this.pre += 1;
                } else {
                    allChecked = false;
                }
            });
        });
        this.validateButton = allChecked;
    }

    selectVaccanciesDay(vaga: any) {

        if (this.selectedDay) {
            this.selectedDay.pessoas.forEach((elem: any) => {
                elem.hasSelected = false;
            });
        }
        this.viewVagas = true;
        this.selectedDay = vaga;
        if (this.selectedDay) {
            this.selectedDay.pessoas.forEach((elem: any) => {
                elem.hasSelected = true;
            });
        }
    }

    on() {
        document.getElementById('overlay').style.display = 'block';
    }


    checkFirstVisit() {
        let resp = false;
        this.appType.forEach(treatment => {
            if (this.availabilities[0].appointment.idTreatment === treatment.value && treatment.value != configs.genTypes.TREATMENT_TYPES.FOLLOWUP_ID) {
                resp = true;
            }
        });
        return resp;
    }

    // Faz aqui o request ao servidor, para uma nova marcação
    validateAppointments() {
        this.submit = false;
        this.request = { events: [] };
        // if (this.validateButton === true) {
        this.validateButton = false;
        this.submit = false;
        this.pessoas.forEach(pessoa => {

            pessoa.sessoes.forEach(sessao => {
                if (sessao.selected === true) {
                    const resources = [];
                    for (const key in sessao.resources) {
                        if (sessao.resources.hasOwnProperty(key)) {
                            const elem = sessao.resources[key];
                            resources.push(elem[0]);
                        }
                    }
                    
                    const startDate = getDate(sessao.data, false);
                    const endDate = getDate(sessao.data,false);

                    endDate.add(pessoa.duration, 'm');

                    const firstVisit = this.checkFirstVisit();
                    this.request.events.push({
                        endDate: endDate.toDate().getTime() ,
                        startDate: startDate.toDate().getTime() ,
                        eventState: this.eventInicialState,
                        idService: pessoa.idService,
                        idLead: pessoa.idLead,
                        idClinic: pessoa.idClinic,
                        isOnline: this.isOnline,
                        idAppointment: pessoa.idAppointment,
                        resources: resources,
                        firstVisit: firstVisit
                    });

                }
            });
        });
        if (this.param1 && this.param1 === 'lead-detail') {
            this.request.events[0]['firstVisit'] = true;
        }
        const body = { eventState: 'e401d466-ca7c-11e8-9bda-0242ac120003' };
        // Faz o cancelamento de um evento antigo se se tratar de uma remarcação
        if (this.param1 === 'reschedule' || this.param1 === 'reschedule-lead' || this.param1 === 'client') {
            // this.calendar.cancelEvent(this.idEvent, body).subscribe((cancel: any) => {
            //     this.origin = (this.param1 === 'reschedule') ? '/calendar' : this.origin;

            //     this.calendar.createEvent(this.request).subscribe(reply => {

            //         if (reply === null) {
            //             this.openConfirmDialog({ value: "nabia.general.occupiedResource" });
            //         } else {
            //             this.on(); // Se a resposta for positiva
            //             this.appValidated = true; // Se a resposta for positiva
            //         }
            //     }, err => {
            //         if (err.status === 400) {
            //             this.openConfirmDialog({ value: "nabia.general.sessionNumberNotEqual" });
            //         }
            //     });
            // });

            this.request['reschedule'] = true;
            this.request['idEventReschedule'] = this.idEvent;
            this.calendar.createEvent(this.request).subscribe(reply => {
                if (reply === null) {
                    this.openConfirmDialog({ value: "nabia.general.occupiedResource" });
                } else {
                    this.calendar.cancelEvent(this.idEvent, body).subscribe((cancel: any) => {
                        this.origin = (this.param1 === 'reschedule') ? '/calendar' : this.origin;
                        this.on(); // Se a resposta for positiva
                        this.appValidated = true; // Se a resposta for positiva
                    });
                }
            }, err => {
                if (err.status === 400) {
                    this.openConfirmDialog({ value: "nabia.general.sessionNumberNotEqual" });
                }
            });

        } else {
            this.calendar.createEvent(this.request).subscribe(reply => {

                if (reply === null) {
                    this.openConfirmDialog({ value: "nabia.general.occupiedResource" });
                } else {
                    this.on(); // Se a resposta for positiva
                    this.appValidated = true; // Se a resposta for positiva
                }
            }, err => {
                if (err.status === 400) {
                    this.openConfirmDialog({ value: "nabia.general.sessionNumberNotEqual" });
                }
            });
        }
        // } else {
        // }
    }

    changeMonth(month: string) {
        if (month === 'next') {
            this.month+= 1;
            if(this.month > 11){
                this.month = 0;
                this.year += 1;
            }
            this.getDaysInMonth(this.year, this.month);
            this.getLastDaysBefore(this.year, this.month);
            this.getFistDaysAfter(this.year, this.month);
        } else if (month === 'last') {
            this.month-= 1;
            if(this.month < 0){
                this.month = 11;
                this.year -= 1;
            }
            this.getDaysInMonth(this.year, this.month);
            this.getLastDaysBefore(this.year, this.month);
            this.getFistDaysAfter(this.year, this.month);
        }
    }

    /**
     * Gets the number of days in a month and inserts them into the array totalDias
     * @param ano Year you intend to get the date from
     * @param mes Month you intend to get the date from
     */
    getDaysInMonth(ano, mes): any {
        this.totalDias = [];
        const nDays = getDate({year: ano, month:mes, day:1}, false).daysInMonth(); // Vai ao mês seguinte (mes +1), recua um dia (, 0) e temos o ultimo dia referente ao mes indicado.
        for (let i = 1; i < nDays + 1; i++) {
            this.totalDias.push({ atual: true, data: getDate({year:ano, month: mes,day: i}, false).startOf('day').toDate()});
        }
        this.monthList.forEach(elem => {
            // const varMes = getDate(`${ano}-${mes}-1`).getMonth();
            // const varMes = mes;
            if (mes === elem.id) {
                this.currentMonth = elem;
            }
        });
    }

    /**
     * Gets the days until last Sunday and inserts them in the array, but only if the month doesn's already begin in a sunday
     * @param ano Year you're refering to
     * @param mes Month you're refering to
     */
    getLastDaysBefore(ano, mes) {
        const diaSemana = getDate({year: ano, month: mes,day: 1}, false).format('d');
        // let nDays = new Date(0, mes, ano);
        if (diaSemana > 0) {
            for (let i = 0; i > -diaSemana; i--) {
                this.totalDias.unshift({ atual: false, data: getDate({year: ano, month: mes,day: i}, false).startOf('day').toDate() });
            }
        }
    }

    /**
     *  Gets the first days of the next month, until the first saturday
     * @param ano Year you're refering to
     * @param mes Month you're refering to
     */
    getFistDaysAfter(ano, mes) {
        const diaSemana = getDate({year: ano, month: mes + 1,day: 1}, false).format('d');
        if (diaSemana > 0) {
            for (let i = 1; i < (8 - diaSemana); i++) {
                this.totalDias.push({ atual: false, data: getDate({year:ano, month: mes + 1,day: i}, false).startOf('day').toDate() });
            }
        }
    }

    /**
     * Returns the string value of the percentage of vacancies
     * @param total total slots for a certain treatment
     * @param vagas number of vacancies for the same treatment
     */
    barSize(total: any, vagas: any) {
        const size = ((vagas / total) * 100).toString() + '%';
        return size;
    }

    backClicked() {
        this._location.back();
    }

    goToOrigin() {
        // this.router.navigate([this.origin]);
        if (this.origin.indexOf('lead') !== -1) {
            const body = {};
            body['idLeadState'] = this.leadState.getFirstCalendarState().id;
            this.router.navigate([this.origin]);
        } else {
            this.router.navigate([this.origin]);
        }
    }

    goToCalendar() {
        this.router.navigate(['/calendar']);
    }


    openConfirmDialog(msg: any) {
        const message = msg.value;
        const dialogRef = this.dialog.open(OkActionComponent, {
            data: message
        });
        dialogRef.afterClosed().subscribe(result => {
            this.resetVariables();
            this.ngOnInit();
        });
    }

    resetVariables() {
        this.date = getDate();
        this.day = null;
        this.weekDay = null;
        this.nWeeks = null;
        this.month = getDate().getMonth();
        this.year = getDate().getFullYear();
        this.vag = 0;
        this.pre = 0;
        this.appValidated = false;
        this.validateButton = false;
        this.pessoas = [];
        this.request = { events: [] };
        this.clinics = null;
        this.roomsList = null;
        this.typeRoom = configs.resourceTypes.room;
        this.submit = true;

        this.weekDays = ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'];
        // TODO: PROBLEM_HERE (Property 'currentMonth' does not exist on type 'AddPaymentComponent'.
        this.currentMonth = null;
        // TODO: PROBLEM_HERE
        this.monthList = [
            { id: 0, name: 'Janeiro' },
            { id: 1, name: 'Fevereiro' },
            { id: 2, name: 'Março' },
            { id: 3, name: 'Abril' },
            { id: 4, name: 'Maio' },
            { id: 5, name: 'Junho' },
            { id: 6, name: 'Julho' },
            { id: 7, name: 'Agosto' },
            { id: 8, name: 'Setembro' },
            { id: 9, name: 'Outubro' },
            { id: 10, name: 'Novembro' },
            { id: 11, name: 'Dezembro' }
        ];
        // Exemplo de estrutura de dados que vem do backend
        this.date1 = getDate('2018-09-01T09:44:42.000Z'); // ISOString
        this.date2 = getDate('2018-09-02T09:44:42.000Z');
        this.date3 = getDate('2018-09-27T09:44:42.000Z');
        this.disponibilidades = [];
        this.listRooms = [];
        this.availabilities = null;

        this.selectedDay = null;
        this.selectedVacancies = []; // Vacancies that are shown on the second column (middle) of the view, where the user can select a free hour on a given day
        this.scheduledVacancies = [];  // Final list of the scheduled treatments. It appears on the final column (right) of the view

        this.viewVagas = false;
        this.totalDias = [];

        this.appType = [];

        this.origin = null;
        this.id2 = null;


        this.eventInicialState = configs.calStatus[0].bdID;
    }
}
