import {DateAdapter, MAT_DATE_LOCALE, MatDateFormats} from "@angular/material/core";
import {Inject, Injectable, Optional} from "@angular/core";
import {MAT_MOMENT_DATE_ADAPTER_OPTIONS} from "@angular/material-moment-adapter";
import * as moment from "moment-timezone";

/** Creates an array and fills it with values. */
function range<T>(length: number, valueFunction: (index: number) => T): T[] {
    const valuesArray = Array(length);
    for (let i = 0; i < length; i++) {
        valuesArray[i] = valueFunction(i);
    }
    return valuesArray;
}
export const MAT_MOMENT_DATE_FORMATS: MatDateFormats = {
    parse: {
        dateInput: 'l',
    },
    display: {
        dateInput: 'L',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
    },
};

@Injectable()
export class MomentDateAdapter extends DateAdapter<any> {

    private _localeData: {
        firstDayOfWeek: number,
        longMonths: string[],
        shortMonths: string[],
        dates: string[],
        longDaysOfWeek: string[],
        shortDaysOfWeek: string[],
        narrowDaysOfWeek: string[]
    };

    constructor(@Optional() @Inject(MAT_DATE_LOCALE) dateLocale: string,
                @Optional() @Inject(MAT_MOMENT_DATE_ADAPTER_OPTIONS)
                private _options?: any) {
        super();
        this.setLocale(dateLocale || moment.locale());
    }

    setLocale(locale: string) {
        moment.locale('pt');
        super.setLocale('pt');

        let momentLocaleData = moment.localeData('pt');
        this._localeData = {
            firstDayOfWeek: momentLocaleData.firstDayOfWeek(),
            longMonths: momentLocaleData.months(),
            shortMonths: momentLocaleData.monthsShort(),
            dates: range(31, (i) => this.createDate(2017, 0, i + 1).format('D')),
            longDaysOfWeek: momentLocaleData.weekdays(),
            shortDaysOfWeek: momentLocaleData.weekdaysShort(),
            narrowDaysOfWeek: momentLocaleData.weekdaysMin(),
        };
    }

    getYear(date): number {
        return this.clone(date).year();
    }

    getMonth(date): number {
        return this.clone(date).month();
    }

    getDate(date): number {
        return this.clone(date).date();
    }

    getDayOfWeek(date): number {
        return this.clone(date).day();
    }

    getMonthNames(style: 'long' | 'short' | 'narrow'): string[] {
        // Moment.js doesn't support narrow month names, so we just use short if narrow is requested.
        return style == 'long' ? this._localeData.longMonths : this._localeData.shortMonths;
    }

    getDateNames(): string[] {
        return this._localeData.dates;
    }

    getDayOfWeekNames(style: 'long' | 'short' | 'narrow'): string[] {
        if (style == 'long') {
            return this._localeData.longDaysOfWeek;
        }
        if (style == 'short') {
            return this._localeData.shortDaysOfWeek;
        }
        return this._localeData.narrowDaysOfWeek;
    }

    getYearName(date): string {
        return this.clone(date).format('YYYY');
    }

    getFirstDayOfWeek(): number {
        return this._localeData.firstDayOfWeek;
    }

    getNumDaysInMonth(date): number {
        return this.clone(date).daysInMonth();
    }

    clone(date) {
        return moment(date);
    }

    createDate(year: number, month: number, date: number) {
        // Moment.js will create an invalid date if any of the components are out of bounds, but we
        // explicitly check each case so we can throw more descriptive errors.
        if (month < 0 || month > 11) {
            throw Error(`Invalid month index "${month}". Month index has to be between 0 and 11.`);
        }

        if (date < 1) {
            throw Error(`Invalid date "${date}". Date has to be greater than 0.`);
        }

        const result = this._createMoment({year, month, date});

        // If the result isn't valid, the date must have been out of bounds for this month.
        if (!result.isValid()) {
            throw Error(`Invalid date "${date}" for month with index "${month}".`);
        }

        return result;
    }

    today() {
        return this._createMoment();
    }

    parse(value: any, parseFormat: string | string[]) {
        if (value && typeof value == 'string') {
            return this._createMoment(value, parseFormat, this.locale);
        }
        return value ? this._createMoment(value): null;
    }

    format(date, displayFormat: string): string {
        date = this.clone(date);
        if (!this.isValid(date)) {
            throw Error('MomentDateAdapter: Cannot format invalid date.');
        }
        return date.format(displayFormat);
    }

    addCalendarYears(date, years: number) {
        return this.clone(date).add({years});
    }

    addCalendarMonths(date, months: number) {
        return this.clone(date).add({months});
    }

    addCalendarDays(date, days: number) {
        return this.clone(date).add({days});
    }

    toIso8601(date): string {
        return this.clone(date).format();
    }

    /**
     * Returns the given value if given a valid Moment or null. Deserializes valid ISO 8601 strings
     * (https://www.ietf.org/rfc/rfc3339.txt) and valid Date objects into valid Moments and empty
     * string into null. Returns an invalid date for all other values.
     */
    deserialize(value: any) {
        let date;
        if (value instanceof Date) {
            date = this._createMoment(value);
        } else if (this.isDateInstance(value)) {
            // Note: assumes that cloning also sets the correct locale.
            return this.clone(value);
        }
        if (typeof value === 'string') {
            if (!value) {
                return null;
            }
            date = this._createMoment(value);
        }
        if (date && this.isValid(date)) {
            return this._createMoment(date);
        }
        return super.deserialize(value);
    }

    isDateInstance(obj: any): boolean {
        return moment.isMoment(obj);
    }

    isValid(date): boolean {
        return this.clone(date).isValid();
    }

    invalid() {
        return moment.invalid();
    }

    /** Creates a Moment instance while respecting the current UTC settings. */
    private _createMoment(
        date?: any,
        format?: any,
        locale?: string,
    ): any {
        if(date == 'Todos'){
            return null;
        }

        const {strict, useUtc}: any = this._options || {};
        return  moment(date);
    }
}