import { Component, OnInit } from '@angular/core';

import { UserblockService } from './userblock.service';
import { SessionService } from '../../../nabia/system/services/session.service';

@Component({
    selector: 'app-userblock',
    templateUrl: './userblock.component.html',
    styleUrls: ['./userblock.component.scss']
})
export class UserblockComponent implements OnInit {
    user: any;
    constructor(public userblockService: UserblockService, private session: SessionService) {

        this.user = {
            picture: session.getUserPicture()
        };
    }

    ngOnInit() {
    }

    userBlockIsVisible() {
        return this.userblockService.getVisibility();
    }

}
