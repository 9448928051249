import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {ProductService} from '../system/services/product.service';
import {Endpoints} from '../system/configurations';
import {TranslatorService} from '../../core/translator/translator.service';
import {SessionService} from "../system/services/session.service";
import {ContactService} from "../system/services/contact.service";
import {OpportunitiesService} from "../system/services/opportunities.service";
import { getDate } from './../system/date/date';
import { GetCurrencyPipe } from '../../layout/pipe/get-currency.pipe';

@Component({
  selector: 'app-add-product',
  templateUrl: './add-product.component.html',
  styleUrls: ['./add-product.component.scss']
})

export class AddProductComponent implements OnInit {
  selector = '.prod-list';
  icon = 'http://icons.iconarchive.com/icons/alecive/flatwoken/256/Apps-User-Online-icon.png';
  currencySymbol = ''
  discountType = [];

  offsetProducts: Date;
  offsetServices: Date;
  quantity: number;
  filter: any;
  newProdList: any;
  backupProdList: any;
  cart: any = [];
  backupCart: any = [];
  searchResult: boolean;
  spinner: boolean =false;

  searchBoxDisable = true;

  total = 0;
  totalSemCreditos = 0;
  descontos = 0;
  credits = 0;
  creditsBefore = 0;
  tab = true;
  changes = false;
  disableButton = false;

  isLoading = true;
  searchValue: any;
  searchFlag = true;

  constructor(
    private productService: ProductService,
    private contactService: ContactService,
    private translate: TranslatorService,
    public thisDialogRef: MatDialogRef<AddProductComponent>,
    public sessionService: SessionService,
    private getCurrency: GetCurrencyPipe,
    public oppService: OpportunitiesService,
    @Inject(MAT_DIALOG_DATA) public data: any) {


    this.offsetProducts =  getDate();
    this.offsetServices =  getDate();
    this.quantity = 15;
    this.filter = { search: '' };
  }


  ngOnInit() {
    this.loadCurrencySymbol();
    if (this.data.type == 'products') {
      this.getProductList(true);
    } else {
      this.getServiceList(true);
    }
    this.getUser()

  }
  loadCurrencySymbol() {
    this.currencySymbol = this.getCurrency.symbol;
    this.discountType = ['%', this.getCurrency.symbol];
  }
  async getUser(){
    try{
        const lead: any     = await this.contactService.getUser(this.data.lead.idUser).toPromise();
        if(this.data.exchangeCredits){
          lead.credits += this.data.exchangeCredits;
        }
        this.credits        = lead.credits ;
        this.creditsBefore  = lead.credits;
    } catch (e) {
      console.error(e);
    }
  }

  onScroll() {
    if (this.data.type == 'products') {
      this.getProductList();
    } else {
      this.getServiceList();
    }
  }



  getProductList(fresh = false) {
    if (fresh) {
      this.newProdList = [];
      this.offsetProducts = (fresh) ? getDate()  : this.offsetProducts;
    }

    // Spinner showing
    this.isLoading = true;

    this.productService.getAllProducts(this.quantity, this.offsetProducts, this.filter).subscribe((pList: any) => {
      this.isLoading = false;

      if (pList && pList.length > 0) {
        this.newProdList = pList;
        this.offsetProducts = pList[pList.length - 1].modifiedDate;
        this.backupProdList = this.newProdList;

        this.newProdList.forEach(prod => {
          prod['amount'] = 0;
          prod['type'] = (this.data.origin == 'leads') ? 1 : prod['type'];
          prod['value'] = (this.data.origin == 'leads') ? 100 : prod['value'];
        });
        this.searchBoxDisable = false;
      }
    });
  }



  getServiceList(fresh = false) {
    if (fresh) {
      // Spinner showing
      this.isLoading = true;
      this.newProdList = [];
      this.offsetServices = (fresh) ? getDate() : this.offsetServices;
    }

    // this.productService.getServicesSearch().subscribe((reply: any) => {
    //   console.log('reply todos prods', reply);
    // });

    this.productService.getAllServices(this.quantity, this.offsetServices, this.filter).subscribe((sList: any) => {
      this.isLoading = false;


      if (sList && sList.length > 0) {
        sList.forEach(prod => {
          prod['amount'] = 0; // prod['type'] = (this.data.origin == 'leads') ? 1 : prod['type'];
          prod['type'] = 1; // prod['value'] = (this.data.origin == 'leads') ? 100 : prod['value'];
          prod['value'] = 0;
        });
        this.newProdList = this.newProdList.concat(sList);
        this.offsetServices = sList[sList.length - 1].modifiedDate;
        this.backupProdList = this.newProdList;
      }
      this.searchBoxDisable = false;
    });

  }



  searchProduct(value: any) {
    if (value.length > 2) {
      setTimeout(() => {
        if (this.searchFlag == true) {
          this.searchFlag = false;
          this.searchValue = value;
          const splitted = this.searchValue.split(' ');
          if (this.backupProdList !== undefined) {
            const tempArr = [];
            this.backupProdList.forEach((prod: any) => {
              let flag = true;

              for (let index = 0; index < splitted.length; index++) {
                if (flag) {
                  const valor = splitted[index];
                  const aux = '.*' + valor + '.*';
                  const regex = new RegExp(aux, 'i');
                  if (regex.test(prod.name)) {
                    flag = true;
                  } else {
                    flag = false;
                  }
                }
              }

              if (flag) {
                tempArr.push(prod);
              }
            });
            this.newProdList = tempArr;
          }
          const params = {} as any;
          params["name"] = this.searchValue;
          if (this.data.type == 'products') {
            this.productService.getProductsWithParams(params).subscribe((reply: any) => {
              reply.forEach(prod => {
                prod['amount'] = 0; // prod['type'] = (this.data.origin == 'leads') ? 1 : prod['type'];
                prod['type'] = 1; // prod['value'] = (this.data.origin == 'leads') ? 100 : prod['value'];
                prod['value'] = 0;
              });
              this.newProdList = reply;
            });
          } else {
            this.productService.getServicesSearch(params).subscribe((reply: any) => {
              reply.forEach(prod => {
                prod['amount'] = 0; // prod['type'] = (this.data.origin == 'leads') ? 1 : prod['type'];
                prod['type'] = 1; // prod['value'] = (this.data.origin == 'leads') ? 100 : prod['value'];
                prod['value'] = 0;
              });
              this.newProdList = reply;
            });
          }
          this.searchFlag = true;
        }
      }, 500);
    }
  }


  searchCart(value: any) {
    this.searchValue = value;
    const aux = '.*' + this.searchValue + '.*';
    const regex = new RegExp(aux, 'i');
    const tempArr = [];
    this.backupCart.forEach((prod: any) => {
      if (regex.test(prod.name)) {
        tempArr.push(prod);
      }
    });
    this.cart = tempArr;
  }


  incrementAmount(product: any) {
    // TODO: Verificar stock !
    if (true) {
      product.amount++;
    }
  }

  decrementAmount(product: any) {
    if (product.amount > 0) {
      product.amount--;
    }
  }


  addToCart() {
    this.newProdList.forEach(element => {
      if (element.amount > 0) {
        if (this.updateCart(element) == false) {
          this.cart.push(JSON.parse(JSON.stringify(element)));
        }
        element['amount'] = 0;
        element['type'] = 1; // TODO: get do type
        element['value'] = (this.data.origin == 'leads') ? 100 : 0;
      }
    });
    this.backupCart = this.cart;
    this.calculateTotal();
  }


  removeProduct(id: any) {
    for (let i = 0; i < this.cart.length; i++) {
      const elem = this.cart[i];
      if (elem.id == id) {
        const removed = this.cart.splice(i, 1);
        this.calculateTotal();
      }
    }
    this.backupCart = this.cart;
  }


  /**
   * Função que verifica se um produto já se encontra na lista Cart e faz update aos valores
   * @param elem Objecto da lista newProductList
   */
  updateCart(elem: any): boolean {

    for (let i = 0; i < this.cart.length; i++) {
      const element = this.cart[i];
      if ((elem.id == element.id) && (elem.type == element.type) && (elem.value == element.value)) {
        element.amount += elem.amount;
        element.type = elem.type; // Tipo de desconto
        element.value = (elem.type == 1) ? elem.value : elem.value + element.value; // mantem se for percentagem, ou soma se for euros
        this.backupCart = this.cart;
        return true;
      }
    }

    return false;
  }

  /**
   * Function that check the list Cart to calculate the 'total' and the 'discount'
   */
  calculateTotal() {
    this.total = 0;
    this.descontos = 0;
    this.credits = this.creditsBefore;

    this.backupCart.forEach(elem => {
      if (!elem['value']){
        elem['value'] = 0;
      }
      if (elem.type == 1) {
        this.total += (+elem['price'] * elem['amount']) * ((100 - elem['value']) / 100);
        this.descontos += (+elem['price'] * elem['amount']) * (elem['value'] / 100);
      } else {
        this.total += (+elem['price'] * elem['amount']) - elem['value'];
        this.descontos += elem['value'];
      }
    });
    this.totalSemCreditos = this.total;
    if (this.data.hasOwnProperty('exchange')) {
      if (this.credits > this.total) {
        this.credits  -= this.total;
        this.total    = 0;
      } else {
        this.total    -= this.credits;
        this.credits  = 0;
      }
    }

  }


  /**
   * Completes checkout adding products to entity
   */
  async checkout() {

    if(this.backupCart.length == 0){
      return false;
    }


    this.spinner = true;
    if(this.data.hasOwnProperty('exchange')){
      this.changes = true;
      this.disableButton = true;

      const bodyExchange = {
        newExchange: (this.data.type == 'services') ? this.getServices() : this.getProducts(),
        exchange: this.data.items,
      };
      const opp:any = await this.oppService.exchangeService(this.data.idOpportunity, bodyExchange).toPromise();

      this.thisDialogRef.close({
        changes: this.changes,
        amountPayable: this.total,
        opportunity: opp.id,
        opportunityName: opp.name
      });
      this.spinner = false;
      return true;
    }



    if (this.data.type == 'products') {

      const url = (this.data.origin == 'leads') ? Endpoints.CLIENTS.LEADS.LEAD_PRODUCTS(this.data.id) : Endpoints.OPPORTUNITIES.OPPORTUNITY_PRODUCTS(this.data.id);

      await this.productService.createEntityProduct(url, { products: this.getProducts() }).toPromise();

    } else if (this.data.type == 'services') {



      const url = (this.data.origin == 'leads') ? Endpoints.CLIENTS.LEADS.LEAD_SERVICES(this.data.id) : Endpoints.OPPORTUNITIES.OPPORTUNITY_SERVICES(this.data.id);

      await this.productService.createEntityService(url, { services: this.getServices() }).toPromise();
    }

    this.spinner = false;
    this.thisDialogRef.close(this.changes);
  }

  getServices(){
    const body = [];
    this.backupCart.forEach(service => {
      const offer = (this.data.origin == 'leads') ? true : false;

      body.push({
        idTreatment: service.id,
        boughtSessions: service.amount,
        isDiscountPercent: service.type == 1,
        discount: service.value,
        isOffer: offer,
        idPrescription: null
      });
    });
    return body;
  }

  getProducts(){
    const body = [];
    this.backupCart.forEach(prod => {
      body.push({
        idConfigurationProduct: prod.id,
        quantity: prod.amount,
        isDiscountPercent: prod.type == 1,
        discount: (prod.value !== undefined) ? prod.value : 0
      });
    });
    return body;
  }


  swapTab() {
    this.tab = !this.tab;
  }

  onCloseCancel() {
    this.thisDialogRef.close(this.changes);
  }

  selectionChanged($event) {
  }

  enableDicount() {
    return (
        (!this.data.hasOwnProperty('exchange') || !this.data.exchange)
        || (this.data.exchange && this.sessionService.hasPermission('troca.desconto.habilitar', 'oportunidade', this.data.lead.idConfigurationClinic))
    );
  }

  discountChange(product){
    if(
        this.data.hasOwnProperty('exchange')
        && this.data.exchange
        && this.sessionService.hasPermission('troca.desconto.max', 'oportunidade', this.data.lead.idConfigurationClinic)
        && product.value > 30
    ) {
      product.value = 30;
    }
  }
}
