export const PROTOCOL_LOCAL = 'http';
export const PROTOCOL_SAFE = 'https';
export const PORT_LOCAL = '4200';
export const IP_REMOTE = 'gr8.bodyscience.pt';
export const IP_LOCAL = 'gr8.bodyscience.pt';

export const environment = {
  production: true,

  // ---------  PROD  ---------
  AUTH_URL: `${PROTOCOL_SAFE}://${IP_LOCAL}/auth`,
  FACEBOOK_CLIENT_ID: '2358708890923607',
  GOOGLE_CLIENT_ID: '1031305714815-p1jd2r7lj3s8727vn6r85o88spif0n5r.apps.googleusercontent.com',
  firebase: {
    apiKey: 'AIzaSyCFPZaP1wdCg0ZRk3wzOnP4V0c2nTF9OzA',
    authDomain: 'gr8live-bd569.firebaseapp.com',
    databaseURL: 'https://gr8live-bd569.firebaseio.com',
    projectId: 'gr8live-bd569',
    storageBucket: 'gr8live-bd569.appspot.com',
    messagingSenderId: '195040682488'
  }
};
