import { getDate } from './../date/date';
import { Injectable } from '@angular/core';
import { Observable, forkJoin, of, from } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { DataService } from './data.service';
import { SessionService } from './session.service';
import { TranslateService } from '@ngx-translate/core';
import { Endpoints, Gender, genTypes } from '../configurations';
import { ConfigurationsCacheService } from './configurations-cache.service';

@Injectable()
export class ContactService {

  constructor(
    private databaseService: DataService,
    private http: HttpClient,
    private translate: TranslateService,
    private configurationsCacheService: ConfigurationsCacheService,
    private sessionService: SessionService) {

  }


  /**
   * Returns lead or client history
   */
  getContactHistory(lId, params?) {
    const url = Endpoints.CLIENTS.HISTORY(lId);
    const opt = this.sessionService.getOptions();
    opt['params'] = params

    const reply$ = this.http.get(url, opt);

    return reply$;
  }


  /**
   * Returns columns type object list
   */
  getColumns(params?) {
    const statusTypes$ = this.databaseService.getLeadStatusList(params).pipe(map((status: any) => {
      status = status.items;
      const list = {};
      const controlColumn = JSON.parse(JSON.stringify(status[0]));
      controlColumn.id = '-';
      controlColumn.name = 'Todas';
      controlColumn.order = -1;
      list['-'] = controlColumn;
      status.forEach(type => {
        list[type.id] = type;
      });

      return list;
    }));
    return statusTypes$;
  }



  /**
   * Returns origin type object list
   */
  getOrigins() {
    // const originTypes$ = this.databaseService.getOrigins().pipe(map((origins: any) => {
    //   const list = {};
    //   origins.forEach(origin => {
    //     list[origin.id] = origin;
    //   });

    //   return list;
    // }));

    // return originTypes$;
    return from(this.configurationsCacheService.getOrigins(false));
  }


  /**
   * Returns communication channel type object list
   */
  getCommunicationChannels(): Observable<any> {
    // const communicationList$ = this.databaseService.getCommunicationChannels().pipe(map((comChannels: any) => {
    //   const list = {};
    //   comChannels.forEach(channel => {
    //     list[channel.id] = channel;
    //   });

    //   return list;
    // }));

    // return communicationList$;

    return from(this.configurationsCacheService.getChannels(false));
  }


  /**
   * Returns occupation list
   */
  getOccupations() {
    const occupationList$ = this.databaseService.getOccupationsList().pipe(map((occupations: any) => {
      const list = {};
      occupations.forEach(occupation => {
        list[occupation.id] = occupation;
      });

      return list;
    }));

    return occupationList$;
  }


  getClinics() {
    // const clinicsList$ = this.databaseService.getClinics().pipe(map((clinics: any) => {
    //   const list = {};
    //   clinics.forEach(clinic => {
    //     list[clinic.id] = clinic;
    //   });

    //   return list;
    // }));

    // return clinicsList$;
    return from(this.configurationsCacheService.getClinics(false));
  }

  getAllClinics() {
    // const clinicsList$ = this.databaseService.getClinics().pipe(map((cList: any) => {
    //   const nList = cList;

    //   nList.forEach(cli => {
    //     cli.value = cli.id;
    //     cli.label = cli.name;
    //   });

    //   return nList;
    // }));

    // return clinicsList$;
    return from(this.configurationsCacheService.getClinics(false));
  }


  /**
   * Returns ProblemList mapped for ngSelect
   */
  getProblemsList() {
    const reply$ = this.databaseService.getProblemsList().pipe(map((pList: any) => {
      const nList = pList.items;

      nList.forEach(cli => {
        cli.value = cli.id;
        cli.label = cli.name;
      });

      return nList;
    }));

    return reply$;
  }


  /**
   * Returns ContraindicationList mapped for ngSelect
   */
  getContraindicationList() {
    const reply$ = this.databaseService.getContraindicationList().pipe(map((cList: any) => {
      const nList = cList;

      nList.forEach(cli => {
        cli.value = cli.id;
        cli.label = cli.name;
      });

      return nList;
    }));

    return reply$;
  }


  /**
   * Returns VoucherList mapped for ngSelect
   */
  getVoucherList(queryString) {
    const reply$ = this.databaseService.getVoucherList(queryString).pipe(map((vList: any) => {
      const nList = vList;

      nList.forEach(cli => {
        cli.value = cli.id;
        cli.label = cli.title;
      });

      return nList;
    }));

    return reply$;
  }

  getUserLastEvent(idClient: any) {
    return this.databaseService.getUserLastEvent(idClient);
  }

  getUserNextEvent(idClient: any) {
    return this.databaseService.getUserLastEvent(idClient);
  }


  // FIXME: A funcionar provisoriamente :: Gararntir que funciona sempre
  getGender() {
    const genderList = [];
    const self = this;
    for (const key in Gender) {
      if (Gender.hasOwnProperty(key)) {
        const element = Gender[key];
        this.translate.get('nabia.general.gender.' + element).subscribe(tr => {
          genderList.push({
            value: key,
            label: tr
          });
        });
      }
    }
    return genderList;
  }

  // ***********************************************************
  // **  LEADS                                                **
  // ***********************************************************

  /**
   * Returns leads filtered by status
   */
  getLeadsByStatus(quantity: number, offset: Date, filters: {}): Observable<any> {
    const leadList$ = this.databaseService.getLeadsByStatus(quantity, offset, filters);

    return leadList$;
  }

  /**
   * Returns leads filtered by client
   */
  getLeadsByClient(clientId: string, isClient: Boolean, quantity: number, offset: Date, filters: any): Observable<any> {

    const leadList$ = this.databaseService.getLeadsByClientId(clientId, isClient, quantity, offset, filters);

    return leadList$;
  }

  /**
   * Returns leads by clientId
   */
  getLeadsByClientIdClientDetails(clientId: string, quantity: number, offset: Date, filters: any): Observable<any> {

    const leadList$ = this.databaseService.getLeadsByClientIdClientDetails(clientId, quantity, offset, filters);

    return leadList$;
  }


  /**
   * Returns leads filtered by user but not in realtime
   */
  getUserLeadList(cId, params = null) {
    const url = Endpoints.CLIENTS.USERS.USER_LEADS(cId);
    const opt = this.sessionService.getOptions();

    opt['params'] = params;
    const leadList$ = this.http.get(url, opt);

    return leadList$;
  }

  getUserEvents(uId) {
    const url = Endpoints.CLIENTS.USERS.USER_EVENTS(uId);
    const opt = this.sessionService.getOptions();

    const leadList$ = this.http.get(url, opt);

    return leadList$;
  }

  /**
   * Returns unfiltered lists
   */
  getLeads(quantity: number, offset: Date): Observable<any> {

    // const leadList$ = this.databaseService.getLeads(quantity, offset);
    const leadList$ = this.databaseService.getLeads(quantity, offset);

    return leadList$;
  }


  /**
   * Returns a lead
   */
  getLead(id: string) {
    const lead$ = this.databaseService.getLead(id);

    return lead$;
  }

  /**
   * Deaggregate the lead from it's current user and creates a new one
   */

  deaggregateLead(id: string) {
    const opt = this.sessionService.getOptions();
    const url = Endpoints.CLIENTS.LEADS.LEAD_DEAGGREGATE(id);

    const data$ = this.http.put(url, {}, opt);

    return data$;
  }


  /**
   * Returns all leads
   */
  getFilteredLeads(filters: string, stateId?: string) {
    const options = this.sessionService.getOptions();
    let url: string;
    if (stateId) {
      url = Endpoints.CLIENTS.LEADS.LEADS_FILTER(filters) + '&leadState=' + stateId;
    } else {
      url = Endpoints.CLIENTS.LEADS.LEADS_FILTER(filters);
    }


    const clients$ = this.http.get(url, options);

    return clients$;
  }

  /**
   * Creates a new lead
   */
  createLead(form) {
    const url = Endpoints.CLIENTS.LEADS.LEADS;
    const opt = this.sessionService.getOptions();

    const reply$ = this.http.post(url, form, opt);

    return reply$;
  }

  /**
   * Updates a lead
   */
  updateLead(id: string, body: any) {
    const url = Endpoints.CLIENTS.LEADS.LEAD(id);
    const opt = this.sessionService.getOptions();

    const data$ = this.http.put(url, body, opt);

    return data$;
  }
  deleteLead(id: string) {
    const url = Endpoints.CLIENTS.LEADS.LEAD(id);
    const opt = this.sessionService.getOptions();

    const data$ = this.http.delete(url,  opt);

    return data$;
  }

  /**
   * Creates a new contact
   */
  creatContact(body: any) {
    const url = Endpoints.CLIENTS.LEADS.LEADS;
    const data$ = this.http.post(url, body, this.sessionService.getOptions());

    return data$;
  }

  /**
   * Creates a new contact
   */
  creatUser(body: any) {
    const url = Endpoints.CLIENTS.USERS.USERS;
    const data$ = this.http.post(url, body, this.sessionService.getOptions());

    return data$;
  }


  /**
   * Creates a new contact
   */
  getUserFilters(filter: string) {
    const url = Endpoints.CLIENTS.USERS.USERS_FILTER(filter);
    const data$ = this.http.get(url, this.sessionService.getOptions());

    return data$;
  }

  /**
   * Update a new contact
   */
  updateContact(id: string, body: any) {
    const url = Endpoints.CLIENTS.USERS.USER(id);
    const data$ = this.http.put(url, body, this.sessionService.getOptions());

    return data$;
  }

  /**
   * @WARNING: NOT working, probably not being used! User getContact instead
   */
  getUser(id: string) {
    const url = Endpoints.CLIENTS.USERS.USER(id);
    const data$ = this.http.get(url, this.sessionService.getOptions());

    return data$;
  }


  /**
   * Returns a contact list in Realtime
   */
  getContacts(quantity: number, offset: Date, filters: any = {}): Observable<any> {
    filters.isClient = false;
    const contactList$ = this.databaseService.getClients(quantity, offset, filters);

    return contactList$;
  }

  /**
  * Returns non realtime contact list
  */
  getAllContacts() {
    const date = getDate(),
      y = date.getFullYear(),
      m = date.getMonth() + 1;
    const firstDay = getDate(`${y}-${m}-1`);

    const url = Endpoints.CLIENTS.USERS.USERS;
    const clientsList$ = this.http.get(url + '?createdAfter=' + firstDay.getTime(), this.sessionService.getOptions()).pipe(map((iList: any) => {
      const reply = [];
      iList.forEach(elem => {
        const isClient = (elem.isClient === 1) ? true : false;
        reply.push({ label: elem.users_names[0].firstName + ' ' + elem.users_names[0].lastName + ' (' + elem.users_phones[0].number + ')', value: elem.id, isClient: isClient, modifiedDate: elem.modifiedDate });
      });
      return reply;
    }));
    return clientsList$;
  }


  /**
   * Returns the info of the contact when provided with the id
   */
  getContact(id: string) {
    const contactList$ = this.databaseService.getContact(id);

    return contactList$;
  }


  getInfoTypes() {
    const url = Endpoints.CONFIGURATIONS.GENERIC_TYPES.INFO_TYPES;

    const infoTypes$ = this.http.get(url, this.sessionService.getOptions()).pipe(map((iList: any) => {
      const reply = [];

      iList.forEach(elem => {
        reply.push({ label: elem.name, value: elem.id });
      });

      return reply;
    }));

    return infoTypes$;
  }

  /**
   * Returns the infos of a user
   */
  getUserInfos(quantity, offset, id: string) {
    const userInfos$ = this.databaseService.getUserInfos(quantity, offset, id, false);

    return userInfos$;
  }

  getUserFavoriteInfos(quantity, offset, uId: string) {
    const opt = this.sessionService.getOptions();
    const url = Endpoints.CLIENTS.USERS.USER_NOTES_FAVORITE(uId);
    const qstr = url + '?quantity=' + quantity + '&offset=' + offset;

    const userFavInfos$ = this.http.get(qstr, opt);

    return userFavInfos$;
  }


  createInfo(url, form) {

    const reply$ = this.http.post(url, form, this.sessionService.getOptions());

    return reply$;
  }


  updateInfo(cId, tId, form) {
    const url = Endpoints.CLIENTS.USERS.USER_NOTES_UPDATE(cId, tId);
    const reply$ = this.http.put(url, form, this.sessionService.getOptions());

    return reply$;
  }


  deleteInfo(uid: string, tid: string) {
    const url = Endpoints.CLIENTS.USERS.USER_NOTES_UPDATE(uid, tid);
    const rep = this.http.delete(url, this.sessionService.getOptions());

    return rep;
  }

  /**
   * Return the docs associated with a user
   */
  getUserDocuments(quantity, offset, id: string) {
    const userDocs$ = this.databaseService.getUserDocs(quantity, offset, id, false);

    return userDocs$;
  }


  /**
   * All documents associated with the user
   */
  getAllUserDocuments(quantity, offset, id: string) {
    return this.databaseService.getAllUserDocs(quantity, offset, id);
  }

  getUserSessions(quantity, offset, id: string) {
    const url = Endpoints.CLIENTS.USERS.USER_SESSIONS(id);
    const opt = this.sessionService.getOptions();

    const userSessions$ = this.http.get(url, opt);

    return userSessions$;
  }

  /**
   * Returns User Checking-Account list
   */
  getCheckingAccount(id: string) {
    const opt = this.sessionService.getOptions();
    const url = Endpoints.CLIENTS.USERS.CHECKING(id);

    const checkingAccount$ = this.http.get(url, opt).map((lds: any[]) => {
      const leads = [];

      lds.forEach(elem => {
        leads.push(elem.opp_lead[0]);
      });
      return leads;
    });

    return checkingAccount$;
  }



  /**
   * Returns User Points list
   */
  getUserPoints(quantity, offset, id: string) {
    const userDocs$ = this.databaseService.getUserPoints(quantity, offset, id);

    return userDocs$;
  }

  /**
   * Create points Adjustment
   */
  createUserPoints(data: any, uid: string) {
    const url = Endpoints.CLIENTS.USERS.POINTS(uid);
    const opt = this.sessionService.getOptions();

    const points$ = this.http.post(url, data, opt);

    return points$;
  }



  /**
   * Return the docs associated with a user
   */
  getLeadDocuments(quantity, offset, id: string) {
    const userDocs$ = this.databaseService.getUserDocs(quantity, offset, id, true);

    return userDocs$;
  }


  /**
  * Returns the infos of a lead
  */
  getLeadInfos(quantity, offset, id: string) {
    const userInfos$ = this.databaseService.getUserInfos(quantity, offset, id, true);

    return userInfos$;
  }


  /**
   * Returns deduction type list
   */
  getDeductionTypeList() {
    const url = Endpoints.CONFIGURATIONS.GENERIC_TYPES.DEDUCTIONS;
    const opt = this.sessionService.getOptions();

    const reply$ = this.http.get(url, opt);

    return reply$;
  }


  /**
   * Returns Deductions
   */
  getUserDeductions(quantity, offset, id: string) {
    const deductions$ = this.databaseService.getDeductions(quantity, offset, id);

    return deductions$;
  }


  /**
   * Creates new Deduction
   */
  createUserDeduction(oId: string, form: any) {
    const url = Endpoints.CLIENTS.USERS.USER_DEDUCTION(oId);

    const reply$ = this.http.post(url, form, this.sessionService.getOptions());

    return reply$;
  }

  /**
   * Remove user Deduction
   */
  deleteUserDeduction(uId: string, dId: string) {
    const url = Endpoints.CLIENTS.USERS.USER_DEDUCTIONS(uId, dId);
    const opt = this.sessionService.getOptions();

    const reply$ = this.http.delete(url, opt);

    return reply$;
  }

  /**
   * Updates a Deduction
   */
  updateUserDeduction(uId: string, dId: string, form) {
    const url = Endpoints.CLIENTS.USERS.USER_DEDUCTIONS(uId, dId);

    const reply$ = this.http.put(url, form, this.sessionService.getOptions());

    return reply$;
  }

  // ***********************************************************
  // **  TASKS                                                **
  // ***********************************************************

  /**
   * Returns the tasks of a user
   */
  getUserTasks(quantity, offset, id: string) {
    const userTasks$ = this.databaseService.getUserTasks(quantity, offset, id, false);

    return userTasks$;
  }


  updateTask(cId, tId, form) {
    const url = Endpoints.CLIENTS.USERS.USER_TASKS_UPDATE(cId, tId);
    const reply$ = this.http.put(url, form, this.sessionService.getOptions());

    return reply$;
  }


  createTask(cId, form) {
    const url = Endpoints.CLIENTS.USERS.USER_TASKS(cId);

    const reply$ = this.http.post(url, form, this.sessionService.getOptions());

    return reply$;
  }


  deleteTask(uid, tid) {
    const url = Endpoints.CLIENTS.USERS.USER_TASKS_UPDATE(uid, tid);
    const rep = this.http.delete(url, this.sessionService.getOptions());

    return rep;
  }

  /**
   * Returns the list of types of staff
   */
  getTaskTypes() {
    const userTasks$ = this.databaseService.getTaskTypes().pipe(map((a: [any]) => {
      const reply = [];

      a.forEach(element => {
        reply.push({ label: element.name, value: element.id });
      });

      return reply;
    }));

    return userTasks$;
  }

  /**
   * Returns the list of Task status
   */
  getTaskStatus() {
    const userTasks$ = this.databaseService.getTaskStatus().pipe(map((a: [any]) => {
      const reply = [];

      a.forEach(element => {
        reply.push({ label: element.name, value: element.id });
      });
      return reply;
    }));

    return userTasks$;
  }


  /**
   * Returns the tasks of a Lead
   */
  getLeadTasks(quantity, offset, id: string) {
    const userTasks$ = this.databaseService.getUserTasks(quantity, offset, id, true);

    return userTasks$;
  }


  // ***********************************************************
  // **  CLIENTS                                              **
  // ***********************************************************

  getClients(quantity: number, offset: Date, filters: any = {}): Observable<any> {
    filters.isClient = true;

    const clientList$ = this.databaseService.getClients(quantity, offset, filters);
    // .pipe(map(clients => {
    //   // Do any mapping here
    //   return clients;
    // }));

    return clientList$;
  }


  addCreditToUser(id: string, body){

    const url = Endpoints.CLIENTS.USERS.USER_ADD_CREDIT(id);
    const opt = this.sessionService.getOptions();

    const reply$ = this.http.post(url, body, opt);

    return reply$;
  }


  // ***********************************************************
  // **  DUMMY                                                **
  // ***********************************************************


  // getClientTaskList(quantity: number, offset: number, filters: any): Observable<any> {

  //   return of(this.dummyClientTasksList);
  // }

  // getClientInfoList(quantity: number, offset: number, filters: any): Observable<any> {

  //   return of(this.dummyClientInformationList);
  // }

  // getClientDocList(quantity: number, offset: number, filters: any): Observable<any> {

  //   return of(this.dummyClientDocumentsList);
  // }

  // getClientSessionList(quantity: number, offset: number, filters: any): Observable<any> {

  //   return of(this.dummyClientSessionList);
  // }

  // getClientAppointmentList(quantity: number, offset: number, filters: any): Observable<any> {

  //   return of(this.dummyClientAppointmentList);
  // }

  // getClientOpportunityList(quantity: number, offset: number, filters: any): Observable<any> {

  //   return of(this.dummyClientOpportunityList);
  // }

  // getClientLeadList(quantity: number, offset: number, filters: any): Observable<any> {

  //   return of(this.dummyClientLeadList);
  // }

  // getTransactionList(quantity: number, offset: number, filters: any): Observable<any> {
  //   return of(this.dummyTransactionList);
  // }

  // getClientPointsList(quantity: number, offset: number, filters: any): Observable<any> {
  //   return of(this.dummyClientPointsList);
  // }

  // getClientReturnsList(quantity: number, offset: number, filters: any): Observable<any> {
  //   return of(this.dummyClientReturnsList);
  // }

  // getEntitiesList(): Observable<any> {
  //   return of(this.entitiesList);
  // }
  // getTypeList(): Observable<any> {
  //   return of(this.typeList);
  // }
  // getStatusList(): Observable<any> {
  //   return of(this.statusList);
  // }
  // getInfoTypeList(): Observable<any> {
  //   return of(this.infoTypeList);
  // }
  // getResponsibleList(): Observable<any> {
  //   return of(this.responsibleList);
  // }
  // getInfoDescriptionList(): Observable<any> {
  //   return of(this.infoDescriptionList);
  // }
  // getTechnitians(): Observable<any> {
  //   return of(this.technicianList);
  // }
  // getMethodsList(): Observable<any> {
  //   return of(this.methodList);
  // }
  // getDocTypeList(): Observable<any> {
  //   return of(this.docTypeList);
  // }



}
