import { Injectable } from '@angular/core';
import * as configs from '../configurations';
import { DataService } from './data.service';

@Injectable({
    providedIn: 'root'
})
export class LeadStateService {
    leadStateList: Array<any> = [];

    constructor(private dataService: DataService) {
        this.load();
    }

    load() {
        this.dataService.getEstadoLeadsList().subscribe((states : any) => {
            this.leadStateList = states.items;
        });
    }
    getStateById(id) {
        const leadStates = this.leadStateList.filter(x => x.id == id);
        if (leadStates.length > 0)
            return leadStates[0]
    }

    getInitialState(): any {
        return this.leadStateList.filter(x => x.type == configs.leadStateypes.initial)[0];
    }

    getLostState(): any {
        return this.leadStateList.filter(x => x.type == configs.leadStateypes.lost)[0];
    }

    getWonState(): any {
        return this.leadStateList.filter(x => x.type == configs.leadStateypes.won)[0];
    }

    getFirstCalendarState(): any {
        return this.leadStateList.filter(x => x.type == configs.leadStateypes.calendar)[0];
    }
}
