import * as moment from "moment-timezone";

export const getDate = (date?, toDate = true): any => {
    try {
        if(date && date.toString().match(/\d+/g)[0].length == date.length){
            date = parseInt(date);
        }

        const value = (date) ? moment(date) : moment();

        if (toDate) {
            return value.toDate();
        }

        return value;
    }catch (e) {
        console.log('invalid date moment pipe ', date);
        return null;
    }
}