import { Component, OnInit } from '@angular/core';
import { SessionService } from '../../services/session.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import * as configs from '../../configurations';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss']
})
export class AuthComponent implements OnInit {

  URI_TOKEN_KEY = 'access_token';
  URI_TTL_KEY = 'expires_in';

  constructor(
    private sessionService: SessionService,
    private router: Router,
    private activatedRoute: ActivatedRoute) {

  }

  ngOnInit() {
    const uriParamArray = this.router.url.split('#')[1];
    const uriParams = this.transformer(uriParamArray);

    const token = uriParams.get(this.URI_TOKEN_KEY);
    const ttl = uriParams.get(this.URI_TTL_KEY);
    this.sessionService.setToken(token, + ttl);
    this.sessionService.getPermissions();
    this.sessionService.getConfigurations();    
  }

  transformer(str: string) {
    const params = new Map<string, string>();
    const uriParams = str.split('&');

    uriParams.forEach(param => {
      const p = param.split('=');
      params.set(p[0], decodeURIComponent(p[1]));
    });


    return params;
  }

}
