import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
    selector: 'app-erro-requisicao',
    templateUrl: './erro-requisicao.component.html',
})
export class ErroRequisicaoComponent  {

    public _exibir : any = null;

    @Output() onReload    = new EventEmitter();


}
