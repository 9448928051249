import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ConfirmActionComponent } from '@app/nabia/confirm-action/confirm-action.component';
import { ConfigurationsService } from '../../../system/services/configurations.service';
import { IDataFilter } from '../origins-dto/IDataFilter';

@Component({
  selector: 'app-origins-modal-generic',
  templateUrl: './origins-modal-generic.component.html',
  styleUrls: ['./origins-modal-generic.component.scss'],
})
export class OriginsModalGenericComponent implements OnInit {

  @ViewChild('apptree') apptree;
  @ViewChild('apptreeselected') apptreeselected;

  public TREE_DATA = {};
  public TREE_DATA_SELECTED = {};
  public treeIsSelected: boolean = false;
  public originsList: Array<any> = [];
  public selectItems: Array<any> = [];
  public previousSelectedItems: Array<any> = [];
  public loadingSpinner: boolean = true;
  public isCheckedAll: boolean = false;

  constructor(
    private configurationsService: ConfigurationsService,
    public dialogRef: MatDialogRef<OriginsModalGenericComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.selectItems = Object.assign([], data.originData);
    this.previousSelectedItems = Object.assign([], data.originData);
  }

  ngOnInit() {
    this.loadSource();

  }

  handleClose() {
    this.dialogRef.close(this.previousSelectedItems);

  }

  handleSubmit() {
    const selectedItems = this.getSourceList();
    const data = [];
    selectedItems.map((origins) => {
      data.push({ label: origins.title, value: origins.id });
    });

    this.dialogRef.close(data);

  }

  getSourceList() {
    return this.selectItems;

  }

  setSourceList(items: any) {
    items = this.getIntersectionBetweenSelectedAndItems(items);
    this.selectItems = Object.assign([], items);

    const ids = items.map(item => item.id);
    const originsFilteredByIds = this.handleFilterByIds(ids);

    this.treeIsSelected = true;
    this.TREE_DATA_SELECTED = originsFilteredByIds;
    this.apptreeselected._database.initialize(originsFilteredByIds);

  }

  private getIntersectionBetweenSelectedAndItems(items: any) {
    let newListItems = this.selectItems.filter(item => {
      let nodes = this.apptree.treeControl.dataNodes
        .filter(nodeTree => nodeTree.data.id != null);

      let origins = nodes.find(origin => {
        let nodeOrigins = origin.data.id == ((item?.id ?? item?.value) ?? item);
        return nodeOrigins;
      });

      return origins == null;

    });

    items.forEach(element => { newListItems.push(element); });

    return newListItems;

  }

  loadSource() {
    this.originsList = [];

    try {
      this.configurationsService.getOriginsList().subscribe((origins: any) => {

        this.loadingSpinner = false;

        delete origins.undefined;
        this.originsList = Object.assign([], origins);

        this.TREE_DATA = this.originsList;
        this.apptree._database.initialize(this.originsList);
        this.markFiltered();
      });
    } catch (err) {
      console.error(err);
    }
  }

  handleFilterByIds(ids): any {
    let newOrigins = {};
    Object.entries(this.originsList).map(origin => {
      const [nameSource, valuesSource] = origin;
      let newMedium = {};

      Object.entries<[any]>(valuesSource).map(valueSource => {
        const [nameMedium, valuesMedium] = valueSource;

        const filteredMedium = valuesMedium.filter(valueMedium => {
          return ids.includes(valueMedium.id);
        });

        if (filteredMedium.length > 0) {
          newMedium[nameMedium] = filteredMedium;
        }
      });

      if (Object.values(newMedium).length > 0) {
        newOrigins[nameSource] = newMedium;
      }
    });

    return newOrigins;

  }

  handleFilterByText(filters: IDataFilter): any {
    let newOrigins = {};
    Object.entries(this.originsList).map(origin => {
      const [nameSource, valuesSource] = origin;
      let newMedium = {};
      Object.entries<[any]>(valuesSource).map(valueSource => {
        const [nameMedium, valuesMedium] = valueSource;
        const filteredMedium = valuesMedium.filter(valueMedium => {
          const { utm_source, utm_medium, utm_campaign, title } = filters;
          let match = true;

          if (utm_source) {
            match = valueMedium.utm_source.toLowerCase().includes(utm_source.toLowerCase());
          }

          if (match && utm_medium) {
            match = valueMedium.utm_medium.toLowerCase().includes(utm_medium.toLowerCase());
          }

          if (match && utm_campaign) {
            match = valueMedium.utm_campaign.toLowerCase().includes(utm_campaign.toLowerCase());
          }

          if (match && title) {
            match = valueMedium.title.toLowerCase().includes(title.toLowerCase());
          }

          return match;

        });

        if (filteredMedium.length > 0) {
          newMedium[nameMedium] = filteredMedium;
        }

      });

      if (Object.values(newMedium).length > 0) {
        newOrigins[nameSource] = newMedium;
      }
    });

    return newOrigins;

  }

  setDataFilter(filters: IDataFilter) {
    const hasSearchTerm = (
      filters.utm_source != "" ||
      filters.utm_medium != "" ||
      filters.utm_campaign != "" ||
      filters.title != ""
    );

    try {
      if (hasSearchTerm) {
        const dataSourceLocal = this.handleFilterByText(filters);
        this.TREE_DATA = dataSourceLocal;
        this.apptree._database.initialize(dataSourceLocal);
        this.markFiltered(false);
      } else {
        this.isCheckedAll = false;
        this.clearFilter();
      }

    } catch (err) {
      console.error(err);
    }
  }

  clearFilter() {
    this.loadSource();
  }

  markFiltered(exec = true) {
    if (this.selectItems && this.selectItems.length > 0) {

      let idsSelectedItems = [];
      this.selectItems.map(origins => {
        const idOrigin = (origins?.id ?? origins?.value) ?? origins;
        idsSelectedItems.push(idOrigin);
      });

      this.apptree.markFilteredTree(idsSelectedItems, exec);
    }
  }

  onChangeSelectAll(isChecked: boolean) {
    if (isChecked) {
      this.isCheckedAll = true;
      this.selectAllTree();
    } else {
      this.isCheckedAll = false;
      this.clearSelection();
    }

  }

  selectAllTree() {
    this.apptree.checkAll();

  }

  clearSelection() {
    this.setSourceList([]);
    this.treeIsSelected = false;
    this.apptree.resetSelected();
    this.apptree.treeControl.collapseAll();
  }

  openConfirmDialog() {
    const message = 'Tem a certeza que pretende limpar a seleção?';
    const dialogRef = this.dialog.open(ConfirmActionComponent, {
      data: message,
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.setSourceList([]);
        this.selectItems = [];
        this.clearSelection();
        this.isCheckedAll = false;
      }
    });
  }

}
