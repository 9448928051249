import * as moment from "moment-timezone";
export interface DateParamObject {
    year: any,
    month: any,
    day: any,
}
export const getDate = (date?: DateParamObject | any, toDate = true): any => {
    try {
        if (date && typeof date != 'object' && date.toString().match(/\d+/g)[0].length == date.length) {
            date = parseInt(date);
        }
        let value = moment();
        if (date && date != '0000-00-00 00:00:00') {
            const macthDate = date.toString().match('(0[0-9]|1[0-9]|2[0-3]):([0-5][0-9]):(0[0-9]|1[0-9]|2[0-3])');
            if (typeof date == 'object' && !(date instanceof moment().constructor) && !(date instanceof Date)) {
                value.year(date.year).month(date.month).date(date.day)
            } else if (macthDate && macthDate[0].length == date.toString().length) {
                value = moment(date, "HH:mm");
            } else {
                value = moment(date);
            }
        }

        if (toDate) {
            return value.toDate();
        }

        return value;
    } catch (e) {
        console.log('invalid date moment pipe ', date, e);
        return null;
    }
}
