import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ErroRequisicaoComponent} from "./erro-requisicao.component";

@NgModule({
  imports: [
    CommonModule,
  ],
  exports: [
    ErroRequisicaoComponent
  ],
  declarations: [
    ErroRequisicaoComponent
  ],
  providers: [
  ]
})
export class ErroRequisicaoModule { }
