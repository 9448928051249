import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ProductService } from '../system/services/product.service';
import { ContactService } from '../system/services/contact.service';
import { SessionService } from '../system/services/session.service';
import { Endpoints } from '../system/configurations';
import { DocumentService } from '../system/services/document.service';
import { GetCurrencyPipe } from '../../layout/pipe/get-currency.pipe';
import { debounceTime } from 'rxjs/operators';
@Component({
  selector: 'app-add-prescription',
  templateUrl: './add-prescription.component.html',
  styleUrls: ['./add-prescription.component.scss']
})
export class AddPrescriptionComponent implements OnInit {

  offset: number;
  quantity: number;
  filter: any;
  filterOption: any;
  pagePrescription = 1;

  tab = true;

  newPrescriptionList: any; // Lista gerada na inicilização do componente, a partir da lista original de produtos. O objectivo é a manipulação
  auxNewPrescriptionList: any;
  clinicList: any;
  problemList: any;
  contraindicationList: any;

  voucherList: any;
  selectedVoucher: any;
  selectedTreatment: any;
  selectedCartTreatment: any;
  cart: any;

  disableButton = false;

  isLoading = true;
  seletor = '.prod-list';
  public currencySymbol = '';

  constructor(
    private sessionService: SessionService,
    private contactService: ContactService,
    private productService: ProductService,
    private documentService: DocumentService,
    private getCurrency: GetCurrencyPipe,
    public thisDialogRef: MatDialogRef<AddPrescriptionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.loadCurrencySymbol();
    this.offset = 0;
    this.quantity = 30;
    // this.filter = { prescription_clinic: '', problem: '', duration: '', minWeight: '', contraindication: '', voucher: '', page: this.pagePrescription };
    this.filter = {};
    this.filterOption = {};
  }


  ngOnInit() {
    this.generateNewList(true);
    this.getClinicList();
    this.getProblemsList();
    this.getContraindicationList();
    this.loadPrescriptionFilter();

  }

  loadPrescriptionFilter() {
    this.filterOption =  {
      'clinic': (prescriptionList) => {
        const clinic = prescriptionList
        .map(prescription => {
          const idsClininc = prescription.prescription_clinic
            .some(item => this.filter['clinic'].includes(item.id));

          if (idsClininc) {
            return prescription;
          }
          return;
        })
        .filter(item => item !== undefined);
        return clinic;
      },
      'problem': (prescriptionList) => {
        const problem = prescriptionList
        .map(prescription => {
          const idsProblem = prescription.problems
            .some(item => this.filter['problem'].includes(item.id));

          if (idsProblem) {
            return prescription;
          }
          return;
        })
        .filter(item => item !== undefined);
        return problem;
      },
      'contraindication': (prescriptionList) => {
        const contraindication = prescriptionList
          .map(prescription => {
            let idsPres;
            if (prescription.services.length > 0) {
              prescription.services.map(service => {
                if (service.treatment.treatmentCategory.contraindications.length > 0) {
                  if (service.treatment.treatmentCategory.contraindications.some(({ id }) => this.filter['contraindication'].includes(id)))
                    return;
                }
              });
            }

            if (prescription.contraindications.length > 0) {
              if (prescription.contraindications.some(({ id }) => this.filter['contraindication'].includes(id)))
                return;
            }
            
            if (!idsPres) {
              return prescription;
            }

            return;

          })
          .filter(item => item !== undefined);
        return contraindication;
      },
      'duration': (prescriptionList) => {
        const minWeight = prescriptionList
        // tslint:disable-next-line: radix
        .filter(prescription => parseInt(prescription.duration) === parseInt(this.filter.duration));
        return minWeight;
      },
      'weight': (prescriptionList) => {
        const minWeight = prescriptionList
        .filter(prescription => prescription.minWeight >= parseInt(this.filter['minWeight']) && prescription.maxWeight <= parseInt(this.filter['maxWeight']));
        return minWeight;
      }
    };
  }
  downloadImages(path, element) {
    const url = encodeURI(path);
    const docu$ = this.documentService.downloadFile(url).subscribe((blob) => {
      const url2 = window.URL.createObjectURL(blob);
      const a = document.createElement('img');
      a.setAttribute('src', url2);
      const img = document.getElementById(element);
      img.setAttribute('src', url2);
    });
  }
  loadCurrencySymbol() {
    this.currencySymbol = this.getCurrency.symbol;
  }
  onScroll() {
    this.filter['page'] = this.pagePrescription++;
    this.generateNewList(false);
  }
  /**
   * Changes to tab t
   */
  changeTab(t) {
    this.tab = (t === 1) ? true : false;
    this.selectedTreatment = undefined;
  }

  /**
   * Updates the filters with selected 'problem', 'clinic' or 'contraindication'
   */
  selectionChanged($event, origin) {
    const ids = $event.map(item => item.value);
    this.filter[origin] = ($event) ? ids.join(',') : '';
    if (ids.length === 0 ) {
      delete this.filter[origin];
    }
  }


  /**
   * Updates the filters with input 'weight' or 'duration'
   */
  updateFilter($event, origin) {
    debounceTime(500);

    if (origin === 'weight') {
      this.filter[$event.target.id] = $event.target.value;
    }

    let prop = $event.target.value;
    prop.length === 0 ? prop = '0' : prop;
    this.filter[origin] = prop;

    if ($event.target.value.length === 0 ) {
      delete this.filter[origin];
    }
  }

  applyFilter() {
    const key = Object.keys(this.filter);
    let resultFilter = this.auxNewPrescriptionList;
    let result;
    for (const filter of key) {
     if (filter === 'page') { continue; }
     if (filter === 'minWeight' || filter === 'maxWeight') {
         result = this.filterOption['weight'](resultFilter); 
      } else {
        result = this.filterOption[filter](resultFilter); 
      }

      resultFilter = result;
    }

    this.newPrescriptionList = resultFilter;

  }

  /**
   * Fetches the clinics list
   */
  getClinicList() {
    this.contactService.getAllClinics().subscribe(cList => {
      this.clinicList = cList.map(clinica => ({ label: clinica.name, value: clinica.id }));
    });
  }

  /**
   * Fetches the problem list
   */
  getProblemsList() {
    this.contactService.getProblemsList().subscribe(pList => {
      this.problemList = pList;
    });
  }

  /**
   * Fetches the contraindication list
   */
  getContraindicationList() {
    this.contactService.getContraindicationList().subscribe(cList => {
      this.contraindicationList = cList;
    });
  }



  /**
   * Returns the total number of session
   */
  getTotalSessions(services) {
    let value = 0;
    services.forEach(srv => {
      value = (srv.sessions) ? srv.sessions + value : value;
    });

    return value;
  }

  /**
   * Returns the discounted price
   */
  getDiscountedPrice(services) {
    let value = 0;
    services.forEach(srv => {
      if (srv.treatment && srv.treatment.price) {
        value += (srv.isDiscountPercent === 1) ? (this.calcPercentageDiscount(srv.discount, srv.treatment.price) * srv.sessions) : (this.calcCurrencyDiscount(srv.discount, srv.treatment.price) * srv.sessions);
      }
    });

    // Applies discount if Voucher is selected
    if (this.selectedVoucher !== undefined) {
      value = value - this.selectedVoucher.amount;
    }

    return value;
  }

  /**
   * Returns the biggest discount
   */
  getMaxDiscount(services) {
    let value = 0;
    services.forEach(srv => {
      value = (srv.discount && srv.discount > value) ? srv.discount : value;
    });

    return value;
  }

  /**
   * Returns the price without discount
   */
  getFullPrice(services) {
    let value = 0;
    services.forEach(srv => {
      value = (srv.treatment && srv.treatment.price) ? (srv.treatment.price * srv.sessions) + value : value;
    });

    return value;
  }

  /**
   * Returns the Discount in Euros
   */
  getDiscountEuros(services) {
    const value = this.getFullPrice(services) - this.getDiscountedPrice(services);

    return value;
  }

  /**
   * Calculates percentage discount and returns the discounted price in euros
   */
  calcPercentageDiscount(disc, price) {
    const value = price / (1 + (disc / 100));
    return value;
  }

  /**
   * Calculates discount in euros and returns the discounted price in euros
   */
  calcCurrencyDiscount(disc, price) {
    const value = price - disc;
    return value;
  }

  /**
   * Toggles expand/collapse in selected treatment
   */
  treatmentClicked(index: number) {
    this.selectedTreatment = (this.selectedTreatment !== index) ? index : undefined;
    this.getVoucherList(index);
  }

  /**
   * Toggles expand/collapse in selected treatment
   */
  cartTreatmentClicked(product: any) {
    const index = 0;
    this.selectedCartTreatment = (this.selectedCartTreatment !== index) ? index : undefined;
  }

  /**
   * Changes the selected voucher
   */
  voucherSelectionChanged($event) {
    this.selectedVoucher = ($event) ? $event : undefined;
  }

  /**
   * Fetches the voucher list with correct filters
   */
  getVoucherList(id) {
    const ids = [];
    this.newPrescriptionList[id].services.forEach(service => {
      ids.push(service.treatment.id);
    });
    const staff = 'staff=' + this.sessionService.getUserId();
    const clinics = 'clinics=' + this.filter.clinic;
    const treatment = 'treatment=' + ids.join(',');

    this.contactService.getVoucherList(staff + '&' + clinics + '&' + treatment).subscribe(vList => {
      this.voucherList = vList;
    });
  }


  /**
   * Executed when a prescription e bought
   */
  checkout() {
    const body: any = {
      services: []
    };

    if (this.selectedVoucher && this.selectedVoucher.id) {
      body.campaignId = this.selectedVoucher.id;
    }

    this.disableButton = true;

    this.cart[0].services.forEach(service => {
      body.services.push({
        idTreatment: service.treatment.id,
        boughtSessions: service.sessions,
        isDiscountPercent: service.isDiscountPercent === 1,
        discount: service.discount,
        isOffer: false,
        idPrescription: this.cart[0].id
      });
    });

    const url = (this.data.origin === 'leads') ? Endpoints.CLIENTS.LEADS.LEAD_SERVICES(this.data.id) : Endpoints.OPPORTUNITIES.OPPORTUNITY_SERVICES(this.data.id);
    this.productService.createEntityService(url, body).subscribe(reply => {
      this.thisDialogRef.close('');
    });
  }


  /**
   * Creates a new prescription list, to be manipulated
   */
  generateNewList(fresh = false) {
    this.offset = (fresh) ? 0 : this.offset;
    if (fresh) {
      this.pagePrescription = 1;
      this.newPrescriptionList = [];
     }
    // Spinner showing
    this.isLoading = true;
    // this.filter.page = this.pagePrescription;
    this.productService.getAllPrescriptions(this.filter).subscribe((pList: any) => {
      this.isLoading = false;

      this.newPrescriptionList = pList;
      this.auxNewPrescriptionList = pList;
      this.setImagesOnFront();
    });
  }

  setImagesOnFront() {
    for (let i = 0; i < this.newPrescriptionList.length; i++) {
      const prod = this.newPrescriptionList[i];
      if (prod.photo !== null) {
        this.downloadImages(prod.photo, 'photo' + i);
      }
      for (let a = 0; a < prod.services.length; a++) {
        const sub = prod.services[a];
        if (sub.photo) {
          this.downloadImages(sub.photo, 'photo-sub' + a);
        }
      }
    }
  }


  calculateTotalPrice(id: any) {
    let total = 0;

    for (let i = 0; i < this.newPrescriptionList.length; i++) {
      const elem = this.newPrescriptionList[i];
      if (elem.id === id) {
        for (let a = 0; a < elem.sub.length; a++) {
          const elemSub = elem.sub[a];
          total += elemSub.price;
        }
      }
    }

    this.newPrescriptionList[id].price = total;
  }

  calculateDiscountPrice(id: any) {
    let total = 0;
    for (let i = 0; i < this.newPrescriptionList.length; i++) {
      const elem = this.newPrescriptionList[i];

      if (elem.id === id) {
        for (let a = 0; a < elem.sub.length; a++) {
          const elemSub = elem.sub[a];
          const discount = 1 - elemSub.discount.selected.split(' ')[1] / 100;
          total += elemSub.price * discount;

        }
      }
      this.newPrescriptionList[i].discount = elem.price - total;
    }

  }

  openSubProducts(id: number) {
    for (let i = 0; i < this.newPrescriptionList.length; i++) {
      const element = this.newPrescriptionList[i];
      if (element.id === id) {
        this.newPrescriptionList[i].showSub = !element.showSub;
      }
    }
  }

  openAddedSubProducts(id: number) {
    for (let i = 0; i < this.cart.length; i++) {
      const element = this.cart[i];
      if (this.cart[i].id === id) {
        this.cart[i].showSub = !this.cart[i].showSub;
      }
    }
  }

  onSessionChange(id: number, subId: number, values: string) {
    const object = this.splitIdValue(values);
    for (let i = 0; i < this.newPrescriptionList.length; i++) {
      const elem = this.newPrescriptionList[i];
      if (elem.id === id) {
        for (let e = 0; e < elem['sub'].length; e++) {
          const elemSub = elem['sub'][e];
          if (elemSub.id === subId) {
            this.newPrescriptionList[i].sub[e].duration.id = +object[0];
            this.newPrescriptionList[i].sub[e].duration.value = object[1] + ' ' + object[2];

          }
        }

      }
    }
  }

  onTypeChange(id: number, subId: number, values: string) {
    const object = this.splitIdValue(values);
    for (let i = 0; i < this.newPrescriptionList.length; i++) {
      const elem = this.newPrescriptionList[i];
      if (elem.id === id) {
        for (let e = 0; e < elem['sub'].length; e++) {
          const elemSub = elem['sub'][e];
          if (elemSub.id === subId) {
            this.newPrescriptionList[i].sub[e].type.id = +object[0];
            this.newPrescriptionList[i].sub[e].type.value = object[1];
          }
        }

      }
    }
  }

  onDiscountChange(id: number, subId: number, values: string) {
    const object = this.splitIdValue(values);
    for (let i = 0; i < this.newPrescriptionList.length; i++) {
      const elem = this.newPrescriptionList[i];
      if (elem.id === id) {
        for (let e = 0; e < elem['sub'].length; e++) {
          const elemSub = elem['sub'][e];
          if (elemSub.id === subId) {
            this.newPrescriptionList[i].sub[e].discount.id = +object[0];
            this.newPrescriptionList[i].sub[e].discount.value = object[1];
          }
        }

      }
    }
  }

  splitIdValue(values: string) {
    return values.split(' ');
  }


  /**
   * Checks and adds a product to cart if it's not already there.
   */
  addToCart(product: any, index: number) {
    this.cart = [];
    this.cart.push(JSON.parse(JSON.stringify(product)));
    this.changeTab(2);
  }


  removeFromCart(id: any) {
    this.cart = [];
    this.changeTab(1);
  }



  onCloseCancel() {
    this.thisDialogRef.close('Cancel');
  }



}
