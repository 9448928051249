import { getDate } from './../date/date';
import { Injectable } from '@angular/core';
import { of, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { DataService } from './data.service';
import * as configs from '../configurations';
import { map } from 'rxjs-compat/operator/map';
import { SessionService } from './session.service';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root',
})
export class CalendarService {
  columns = [
    { title: 'Consultório Av. 1 ' },
    { title: 'Consultório Av. 2 ' },
    { title: 'Consultório Av. 3 ' },
    { title: 'Consultório Av. 4 ' },
    { title: 'Consultório Av. 5 ' },
    { title: 'Consultório Av. 6 ' },
    { title: 'Consultório Av. 7 ' },
    { title: 'Consultório Av. 8 ' },
    { title: 'Consultório Av. 9 ' },
    { title: 'Consultório Av. 10 ' },
  ];

  data = [
    {
      id: '1ec9-8r62',
      rooms: [
        {
          room_id: 'GUID Room 1',
          title: 'Consultório Av. 1 ',
          appointments: [
            {
              startDate: '1530782100000',
              endDate: '1530784800000',
              status: 'scheduled',
            },
            {
              startDate: '1531310400000',
              endDate: '1531321200000',
              status: 'confirmed',
            },
          ],
        },
        {
          room_id: 'GUID Room 2',
          title: 'Consultório Av. 2 ',
          appointments: [
            {
              startDate: '1530777600000',
              endDate: '1530777640000',
              status: 'status_id',
            },
            {
              startDate: '1530877600000',
              endDate: '1530877640000',
              status: 'status_id',
            },
          ],
        },
        {
          room_id: 'GUID Room 3',
          title: 'Consultório Av. 3 ',
          appointments: [],
        },
        {
          room_id: 'GUID Room 4',
          title: 'Consultório Av. 4 ',
          appointments: [],
        },
        {
          room_id: 'GUID Room 5',
          title: 'Consultório Av. 5 ',
          appointments: [
            {
              startDate: '1530798300000',
              endDate: '1530801900000',
              status: 'status_id',
            },
            {
              startDate: '1530804600000',
              endDate: '1530806400000',
              status: 'status_id',
            },
          ],
        },
        {
          room_id: 'GUID Room 6',
          title: 'Consultório Av. 6 ',
          appointments: [
            {
              startDate: '1530802800000',
              endDate: '1530806400000',
              status: 'status_id',
            },
            {
              startDate: '1530807300000',
              endDate: '1530813600000',
              status: 'status_id',
            },
          ],
        },
      ],
    },
  ];

  constructor(private dataService: DataService, private http: HttpClient, private sessionService: SessionService) {}

  createAppointment(form) {
    const url = configs.Endpoints.CALENDAR.APPOINTMENTS;

    const reply$ = this.http.post(url, form, this.sessionService.getOptions());

    return reply$;
  }

  getAppointmentById(id) {
    const url = configs.Endpoints.CALENDAR.GET_APPOINTMENT(id);

    const reply$ = this.http.get(url, this.sessionService.getOptions());

    return reply$;
  }

  getAvailability(id) {
    const url = configs.Endpoints.CALENDAR.AVAILABILITY(id);

    const reply$ = this.http.get(url, this.sessionService.getOptions());

    return reply$;
  }

  getClinic(id) {
    const url = configs.Endpoints.CONFIGURATIONS.GET_CLINIC(id);

    const reply$ = this.http.get(url, this.sessionService.getOptions());

    return reply$;
  }

  getClinicEvents(id) {
    const url = configs.Endpoints.CALENDAR.CLINIC_EVENTS(id);

    const reply$ = this.http.get(url, this.sessionService.getOptions());

    return reply$;
  }
  getEvent(id) {
    const url = configs.Endpoints.CALENDAR.GET_EVENTS(id);

    const reply$ = this.http.get(url, this.sessionService.getOptions());

    return reply$;
  }
  getEvents(params) {
    const url = configs.Endpoints.CALENDAR.EVENTS_PAGINATION;
    const opt = this.sessionService.getOptions();
    opt['params'] = params;
    const reply$ = this.http.get(url, opt);

    return reply$;
  }
  getClinicEventsByDates(id, start, end) {
    const url = configs.Endpoints.CALENDAR.CLINIC_EVENTS_BY_DATES(id, start, end);

    const reply$ = this.http.get(url, this.sessionService.getOptions());

    return reply$;
  }

  getClinicResources(cid, rid) {
    const url = configs.Endpoints.CALENDAR.CLINIC_RESOURCES(cid, rid);

    const reply$ = this.http.get(url, this.sessionService.getOptions());

    return reply$;
  }

  createEvent(form) {
    const url = configs.Endpoints.CALENDAR.EVENTS;

    const reply$ = this.http.post(url, form, this.sessionService.getOptions());

    return reply$;
  }

  getEventsMissingResources(idClinic) {
    const url = configs.Endpoints.CALENDAR.EVENT_MISSING(idClinic);

    const reply$ = this.http.get(url, this.sessionService.getOptions());

    return reply$;
  }

  getRealtimeEvents(clinic: any, startDate: any, endDate: any, params = null) {
    // const clinic = '0126496c-c972-11e8-9bda-0242ac120003';
    // const startDate = getDate('2018/11/18');
    return this.dataService.getRealtimeEvents(clinic, startDate, endDate, params);
  }

  getUserRealtimeEvents(idClient: any, quantity: number, offset: Date) {
    return this.dataService.getUserRealtimeEvents(idClient, quantity, offset);
  }

  getClinicsList() {
    //   return of([
    //     {
    //       id: '1ec9-8r62',
    //       name: 'Porto',
    //       label: 'Porto',
    //       value: '1ec9-8r62'
    //     },
    //     {
    //       id: '1ex4-39k2',
    //       name: 'Lisboa',
    //       label: 'Lisboa',
    //       value: '1ex4-39k2'
    //     }
    //   ]);
  }

  getRooms(clinic_id: string) {
    return of(this.columns);
  }

  /**
   * Returns the quarter-hour graded schedule Column
   */
  getScheduleColumn() {
    return configs.schedule.long.quarterly.map(hour => {
      const col = {
        type: configs.schedule.types[0],
        time: {
          start: {
            hour: +hour.split(':')[0],
            min: +hour.split(':')[1],
          },
          end: {
            hour: +hour.split(':')[0],
            min: +hour.split(':')[1] + 15,
          },
        },
      };

      // Correction applied to increment hour
      if (col.time.end.min === 60) {
        col.time.end.hour = col.time.end.hour + 1;
        col.time.end.min = 0;
      }

      return col;
    });
  }

  getLead(lId) {
    const url = configs.Endpoints.CLIENTS.LEADS.LEAD(lId);

    const reply$ = this.http.get(url, this.sessionService.getOptions());

    return reply$;
  }
  cancelEvent(eId, body) {
    const url = configs.Endpoints.CALENDAR.CANCEL_EVENT(eId);

    const reply$ = this.http.put(url, body, this.sessionService.getOptions());

    return reply$;
  }

  changeEvent(eId, body) {
    const url = configs.Endpoints.CALENDAR.EVENT_DURATION(eId);

    const reply$ = this.http.put(url, body, this.sessionService.getOptions());

    return reply$;
  }

  rescheduleEvent(eId, body) {
    const url = configs.Endpoints.CALENDAR.RESCHEDULE_EVENT(eId);
    const reply$ = this.http.put(url, body, this.sessionService.getOptions());
    return reply$;
  }
  updateEvent(eId, body) {
    const url = configs.Endpoints.CALENDAR.UPDATE_EVENT(eId);
    const reply$ = this.http.put(url, body, this.sessionService.getOptions());
    return reply$;
  }

  changeEventState(id, body) {
    const url = configs.Endpoints.CALENDAR.EVENT_STATE(id);

    const reply$ = this.http.put(url, body, this.sessionService.getOptions());

    return reply$;
  }
  changeIsOnline(id, body) {
    const url = configs.Endpoints.CALENDAR.EVENT_ISONLINE(id);

    const reply$ = this.http.put(url, body, this.sessionService.getOptions());

    return reply$;
  }
  changeEventResponsible(id, body) {
    const url = configs.Endpoints.CALENDAR.EVENT_RESPONSIBLE(id);

    const reply$ = this.http.put(url, body, this.sessionService.getOptions());

    return reply$;
  }

  /**
   * FIXME: Fetch the data from dataService
   * Returns the Matrix ready to be drown
   */
  getAppointmentsMatrix(dataStructure: any) {
    const matrix = [];

    const data: any = dataStructure;
    // const max_rooms = data[0].rooms.length;

    // Build and initialize matrix
    data[0].rooms.forEach(room => {
      const roomArray = {
        id: room.room_id,
        title: room.title,
        status: configs.schedule.types[0],
        data: this.getScheduleColumn(),
      };

      matrix.push(roomArray);
    });

    // ForEach room in the matrix
    let r = 0;
    matrix.forEach(room => {
      // ForEach cell in the matrix => add event if exists
      let c = 0;
      room.data.forEach(cell => {
        // Set event in cell
        const event = JSON.parse(JSON.stringify(this.getAppointment(cell.time, data[0].rooms[r])));

        if (event) {
          matrix[r].data[c] = event;
        }
        c++;
      });
      r++;
    });

    return matrix;
  }

  /**
   * Returns an appointment if it's in range
   */
  getAppointment(cell: any, room: any) {
    // used to save the type of event
    let type = configs.schedule.types[0];

    // Find event in range of the cell
    const appointmentInRange = room.appointments.find(ev => {
      // Build Event
      const sDate = getDate(+ev.startDate, false);
      const eDate = getDate(+ev.endDate, false);
      const event = {
        start: {
          hour: +sDate.get('hour'),
          min: +sDate.get('minute'),
        },
        end: {
          hour: +eDate.get('hour'),
          min: +eDate.get('minute'),
        },
      };

      // Get event in range type
      const eIR = this.eventInRange(cell, event);
      if (eIR !== undefined) {
        type = eIR;
      }
      return eIR !== configs.schedule.types[0];
    });

    let reply: any = {};
    if (appointmentInRange) {
      reply = appointmentInRange;
      reply.type = type;
    }

    return reply;
  }

  /**
   * Returns true if an event is in range
   */
  eventInRange(cell: any, event: any) {
    let reply = configs.schedule.types[0];

    const cs_le_es = this.compDate(cell.start, '<=', event.start);
    const cs_g_es = this.compDate(cell.start, '>', event.start);
    const cs_l_ee = this.compDate(cell.start, '<', event.end);
    const ce_ge_ee = this.compDate(cell.end, '>=', event.end);
    const ce_g_es = this.compDate(cell.end, '>', event.start);
    const ce_l_ee = this.compDate(cell.end, '<', event.end);

    // Case single
    if (cs_le_es && ce_ge_ee) {
      reply = configs.schedule.types[1];
    }
    // Case multiple-start
    else if (cs_le_es && ce_g_es && ce_l_ee) {
      reply = configs.schedule.types[2];
    }
    // Case multiple-mid
    else if (cs_g_es && ce_l_ee) {
      reply = configs.schedule.types[3];
    }
    // Case multiple-end
    else if (cs_g_es && ce_ge_ee && cs_l_ee) {
      reply = configs.schedule.types[4];
    }

    return reply;
  }

  /**
   * Returns true if date
   */
  compDate(date1: any, comp: string, date2: any) {
    let rep = false;

    if (comp === '<' && (date1.hour < date2.hour || (date1.hour === date2.hour && date1.min < date2.min))) {
      rep = true;
    }
    if (comp === '<=' && (date1.hour < date2.hour || (date1.hour === date2.hour && date1.min <= date2.min))) {
      rep = true;
    }
    if (comp === '=' && date1.hour === date2.hour && date1.min === date2.min) {
      rep = true;
    }
    if (comp === '>=' && (date1.hour > date2.hour || (date1.hour === date2.hour && date1.min >= date2.min))) {
      rep = true;
    }
    if (comp === '>' && (date1.hour > date2.hour || (date1.hour === date2.hour && date1.min > date2.min))) {
      rep = true;
    }

    return rep;
  }

  uniformHours(date, eventCreatedDate) {
    // shenanigans to deal with DST from events imported from old CRM
    const dst = moment.duration('01:00:00');
    const formattedDate = getDate(date, false);

    if (getDate(eventCreatedDate) < getDate('2019-02-04')) {
      return formattedDate.isDST() ? formattedDate.subtract(dst) : formattedDate;
    } else {
      return formattedDate;
    }
  }

  /**
   * Returns events history
   */
  getCalendarHistory(lId, params?) {
    const url = configs.Endpoints.CALENDAR.HISTORY(lId);
    const opt = this.sessionService.getOptions();
    opt['params'] = params;
    const reply$ = this.http.get(url, opt);

    return reply$;
  }
}
