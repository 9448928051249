import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe, DecimalPipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { AlertModule } from 'ngx-bootstrap/alert';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
// import {CarouselModule} from 'ngx-bootstrap/carousel';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ModalModule } from 'ngx-bootstrap/modal';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { RatingModule } from 'ngx-bootstrap/rating';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { DatepickerModule } from 'ngx-bootstrap/datepicker';
import { SparklineDirective } from './directives/sparkline/sparkline.directive';
import { EasypiechartDirective } from './directives/easypiechart/easypiechart.directive';
import { ColorsService } from './colors/colors.service';
import { CheckallDirective } from './directives/checkall/checkall.directive';
import { VectormapDirective } from './directives/vectormap/vectormap.directive';
import { NowDirective } from './directives/now/now.directive';
import { ScrollableDirective } from './directives/scrollable/scrollable.directive';
import { JqcloudDirective } from './directives/jqcloud/jqcloud.directive';
import { TranslatableService } from '../nabia/system/services/translatable.service';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { ContactService } from '../nabia/system/services/contact.service';
import { OpportunitiesService } from '../nabia/system/services/opportunities.service';
import { TimeSincePipe } from '../nabia/system/pipes/time-since.pipe';
import { StaffService } from '../nabia/system/services/staff.service';
import { ConfigurationsService } from '../nabia/system/services/configurations.service';
import { CalendarService } from '../nabia/system/services/calendar.service';
import { NgSelectModule } from '@ng-select/ng-select';
import {
  MAT_DATE_LOCALE,
  MatButtonModule,
  MatCheckboxModule,
  MatDatepickerModule,
  MatDialogModule,
  MatFormFieldModule,
  MatInputModule,
  MatRippleModule,
  MatSidenavModule,
  MatProgressSpinnerModule,
  MatIconModule,
} from '@angular/material';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { HistoryComponent } from '../nabia/history/history.component';
import { AddOpportunityComponent } from '../nabia/opportunities/add-opportunity/add-opportunity.component';
import { NgxUploaderModule } from 'ngx-uploader';
import { ProductsComponent } from '../nabia/products/products.component';
import { ChooseComponent } from '../nabia/products/choose/choose.component';
import { AddProductComponent } from '../nabia/add-product/add-product.component';
import { AddPrescriptionComponent } from '../nabia/add-prescription/add-prescription.component';
import { ScriptService } from '../nabia/system/services/script.service';
// import { OpportunityDetailComponent } from '../nabia/opportunities/opportunity-detail/opportunity-detail.component';
import { ScheduleComponent } from '../nabia/calendar/schedule/schedule.component';
import { AddInfoComponent } from '../nabia/contact-detail/tables/informations/add-info/add-info.component';
import { AddPaymentComponent } from '../nabia/opportunities/add-payment/add-payment.component';
import { ConfirmActionComponent } from '../nabia/confirm-action/confirm-action.component';
import { AddStaffComponent } from '../nabia/opportunities/opportunity-detail/add-staff/add-staff.component';
import { AddFileComponent } from '../nabia/opportunities/opportunity-detail/add-file/add-file.component';
import { AddReturnComponent } from '../nabia/opportunities/add-return/add-return.component';
import { OkActionComponent } from '../nabia/ok-action/ok-action.component';
import { EditFieldComponent } from '../nabia/edit-field/edit-field.component';
import { ReScheduleComponent } from '../nabia/calendar/re-schedule/re-schedule.component';
import { HistoryPopupComponent } from '../nabia/history/history-popup/history-popup.component';
import { PerdidoComponent } from '../nabia/opportunities/opportunity-detail/perdido/perdido.component';
import { GanhoComponent } from '../nabia/opportunities/opportunity-detail/ganho/ganho.component';
import { SelectAccountComponent } from '../nabia/opportunities/select-account/select-account.component';
import { EditServiceComponent } from '../nabia/products/edit-service/edit-service.component';
// import { NgxSelectModule } from 'ngx-select-ex';
import { CommunicationsChannelsModalComponent } from '../nabia/configurations/general/communications-channels/communications-channels-modal/communications-channels-modal.component';
import { AggregationModalComponent } from '../nabia/contact-detail/aggregation-modal/aggregation-modal.component';
import { ExchangeServiceComponent } from '../nabia/products/exchange/exchange.component';
import { ExchangeSuccessComponent } from '../nabia/products/exchange/success/exchange-success.component';
import { SelectUsersModule } from '../layout/select/clients/users/select-users.module';
import { SpinnerModule } from '../layout/spinner/spinner.module';
import { ErroRequisicaoModule } from '../layout/erro-requisicao/erro-requisicao.module';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS, MatSnackBarModule } from '@angular/material/snack-bar';
import { SnackRevertComponent } from '../layout/snack/revert/revert/snack-revert.component';
import { MomentPipe } from '../layout/pipe/moment.pipe';
import { GetCurrencyPipe } from '../layout/pipe/get-currency.pipe';
import { SecurePipe } from '../layout/pipe/secure.pipe';
import { MatTreeModule } from '@angular/material/tree';
import { OriginsGenericComponent } from '@app/nabia/components/origins/origins.generic.component';
import { OriginsModalGenericComponent } from '@app/nabia/components/origins/origins-modal/origins-modal-generic.component';
import { OriginsFilterGenericComponent } from '@app/nabia/components/origins/origins-filter/origins-filter-generic.component';
import { OriginsTreeViewGenericComponent } from '@app/nabia/components/origins/origins-tree-view/origins-tree-view-generic.component';
import { AppointmentModalComponent } from '@app/nabia/contact-detail/tables/appointment/appointment-modal/appointment-modal.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
    TranslateModule,
    AccordionModule.forRoot(),
    AlertModule.forRoot(),
    ButtonsModule.forRoot(),
    // CarouselModule.forRoot(),
    CollapseModule.forRoot(),
    DatepickerModule.forRoot(),
    BsDropdownModule.forRoot(),
    ModalModule.forRoot(),
    PaginationModule.forRoot(),
    ProgressbarModule.forRoot(),
    RatingModule.forRoot(),
    TabsModule.forRoot(),
    TimepickerModule.forRoot(),
    TooltipModule.forRoot(),
    PopoverModule.forRoot(),
    InfiniteScrollModule,
    NgSelectModule,
    MatDialogModule,
    MatSidenavModule,
    MatButtonModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatRippleModule,
    // MatNativeDateModule,
    NgxUploaderModule,
    MatSlideToggleModule,
    SelectUsersModule,
    SpinnerModule,
    ErroRequisicaoModule,
    ErroRequisicaoModule,
    MatSnackBarModule,
    MatTreeModule,
    MatProgressSpinnerModule,
    MatIconModule,
  ],
  entryComponents: [
    SnackRevertComponent,
    HistoryComponent,
    ConfirmActionComponent,
    EditFieldComponent,
    OkActionComponent,
    ProductsComponent,
    PerdidoComponent,
    AddOpportunityComponent,
    ChooseComponent,
    AddInfoComponent,
    AddProductComponent,
    AddPaymentComponent,
    AddPrescriptionComponent,
    AddStaffComponent,
    AddFileComponent,
    AddReturnComponent,
    ScheduleComponent,
    ReScheduleComponent,
    GanhoComponent,
    EditServiceComponent,
    SelectAccountComponent,
    HistoryPopupComponent,
    CommunicationsChannelsModalComponent,
    AggregationModalComponent,
    ExchangeServiceComponent,
    ExchangeSuccessComponent,
    OriginsGenericComponent,
    OriginsModalGenericComponent,
    OriginsTreeViewGenericComponent,
    OriginsFilterGenericComponent,
    AppointmentModalComponent,
  ],
  providers: [
    CurrencyPipe,
    DecimalPipe,
    ColorsService,
    TranslatableService,
    OpportunitiesService,
    ContactService,
    ConfigurationsService,
    StaffService,
    CalendarService,
    HistoryComponent,
    ConfirmActionComponent,
    EditFieldComponent,
    OkActionComponent,
    ProductsComponent,
    ScriptService,
    MomentPipe,
    GetCurrencyPipe,
    SecurePipe,
    { provide: MAT_DATE_LOCALE, useValue: 'pt-PT' },
    { provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: { duration: 2500 } },
  ],
  declarations: [
    SnackRevertComponent,
    TimeSincePipe,
    SparklineDirective,
    EasypiechartDirective,
    CheckallDirective,
    VectormapDirective,
    NowDirective,
    ScrollableDirective,
    JqcloudDirective,
    AddOpportunityComponent,
    AddInfoComponent,
    HistoryComponent,
    ProductsComponent,
    PerdidoComponent,
    ChooseComponent,
    AddProductComponent,
    AddPrescriptionComponent,
    AddPaymentComponent,
    ConfirmActionComponent,
    EditFieldComponent,
    OkActionComponent,
    AddStaffComponent,
    AddFileComponent,
    AddReturnComponent,
    ScheduleComponent,
    ReScheduleComponent,
    GanhoComponent,
    EditServiceComponent,
    SelectAccountComponent,
    HistoryPopupComponent,
    CommunicationsChannelsModalComponent,
    AggregationModalComponent,
    ExchangeServiceComponent,
    ExchangeSuccessComponent,
    MomentPipe,
    GetCurrencyPipe,
    SecurePipe,
    OriginsGenericComponent,
    OriginsModalGenericComponent,
    OriginsTreeViewGenericComponent,
    OriginsFilterGenericComponent,
    AppointmentModalComponent,
  ],
  exports: [
    SnackRevertComponent,
    TimeSincePipe,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    RouterModule,
    AccordionModule,
    AlertModule,
    ButtonsModule,
    // CarouselModule,
    CollapseModule,
    DatepickerModule,
    BsDropdownModule,
    ModalModule,
    PaginationModule,
    ProgressbarModule,
    RatingModule,
    TabsModule,
    TimepickerModule,
    TooltipModule,
    PopoverModule,
    ConfirmActionComponent,
    EditFieldComponent,
    OkActionComponent,
    SparklineDirective,
    EasypiechartDirective,
    CheckallDirective,
    VectormapDirective,
    NowDirective,
    ScrollableDirective,
    JqcloudDirective,
    InfiniteScrollModule,
    NgSelectModule,
    MatDialogModule,
    MatSidenavModule,
    MatButtonModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatRippleModule,
    // MatNativeDateModule,
    NgxUploaderModule,
    HistoryComponent,
    ProductsComponent,
    MatSlideToggleModule,
    MatSnackBarModule,
    MomentPipe,
    GetCurrencyPipe,
    SecurePipe,
    OriginsGenericComponent,
    OriginsTreeViewGenericComponent,
  ],
})
export class SharedModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: SharedModule,
    };
  }
}
