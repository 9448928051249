import { LayoutComponent } from '../layout/layout.component';
import { LoginComponent } from './pages/login/login.component';
import { RegisterComponent } from './pages/register/register.component';
import { RecoverComponent } from './pages/recover/recover.component';
import { LockComponent } from './pages/lock/lock.component';
import { MaintenanceComponent } from './pages/maintenance/maintenance.component';
import { Error404Component } from './pages/error404/error404.component';
import { Error500Component } from './pages/error500/error500.component';
import { AuthenticationService } from '../nabia/system/interceptors/authentication.service';
import { ScheduleComponent } from '../nabia/calendar/schedule/schedule.component';
import { defaultUrl } from '../nabia/system/configurations';

export const routes = [

    {
        path: '',
        component: LayoutComponent,
        children: [
            { path: '', redirectTo: 'home', pathMatch: 'full' }, // canActivate: [TenantSettingsInterceptorService]
            { path: 'home', loadChildren: '../nabia/home/home.module#HomeModule', canActivate: [AuthenticationService] },
            { path: 'calendar', loadChildren: '../nabia/calendar/calendar.module#CalendarModule', canActivate: [AuthenticationService], data: { 'path': 'calendario', operation: 'visualizar' } },
            { path: 'clients', loadChildren: '../nabia/clients/clients.module#ClientsModule', canActivate: [AuthenticationService] },
            { path: 'sales', loadChildren: '../nabia/sales/sales.module#SalesModule', canActivate: [AuthenticationService],  },
            { path: 'leads', loadChildren: '../nabia/leads/leads.module#LeadsModule', canActivate: [AuthenticationService], data: { 'path': 'leads', operation: 'visualizar' } },
            { path: 'opportunities', loadChildren: '../nabia/opportunities/opportunities.module#OpportunitiesModule', canActivate: [AuthenticationService], data: { 'path': 'oportunidade', operation: 'visualizar' } },
            { path: 'people', loadChildren: '../nabia/people/people.module#PeopleModule', canActivate: [AuthenticationService] },
            { path: 'marketing', loadChildren: '../nabia/marketing/marketing.module#MarketingModule', canActivate: [AuthenticationService]},
            { path: 'metrics', loadChildren: '../nabia/metrics/metrics.module#MetricsModule', canActivate: [AuthenticationService] },
            { path: 'hr', loadChildren: '../nabia/human-resources/human-resources.module#HumanResourcesModule', canActivate: [AuthenticationService] },
            { path: 'stock', loadChildren: '../nabia/stock/stock.module#StockModule', canActivate: [AuthenticationService] },
            { path: 'news', loadChildren: '../nabia/news/news.module#NewsModule', canActivate: [AuthenticationService]},
            { path: 'configurations', loadChildren: '../nabia/configurations/configurations.module#ConfigurationsModule', canActivate: [AuthenticationService], data: { 'path': 'configuracoes', operation: 'visualizar' } },
            { path: 'profile', loadChildren: '../nabia/profile/profile.module#ProfileModule', canActivate: [AuthenticationService]},
            { path: 'reports', loadChildren: '../nabia/reports/reports.module#ReportsModule', canActivate: [AuthenticationService], data: { 'path': 'configuracoes', operation: 'visualizar' } },
        ]
    },

    { path: 'login', component: LoginComponent },
    { path: 'register', component: RegisterComponent },
    { path: 'recover', component: RecoverComponent },
    { path: 'lock', component: LockComponent },
    { path: 'maintenance', component: MaintenanceComponent },
    { path: 'schedule/:origin/:id/:id2/:id3', component: ScheduleComponent },
    { path: '404', component: Error404Component },
    { path: '500', component: Error500Component },

    { path: '**', redirectTo: defaultUrl },

   

];
