import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { DataService } from '../../../../system/services/data.service';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import * as configs from '../../../../system/configurations';
import {SessionService} from "../../../../system/services/session.service";

@Component({
  selector: 'app-communications-channels-modal',
  templateUrl: './communications-channels-modal.component.html',
  styleUrls: ['./communications-channels-modal.component.scss']
})
export class CommunicationsChannelsModalComponent implements OnInit {

  isEditChannelModal = true;
  submitForm;
  channelToEdit = null;
  constructor(
    public dialog: MatDialog,
    private dataService: DataService,
    private formBuilder: FormBuilder,
    public sessionService: SessionService,
    public thisDialogRef: MatDialogRef<CommunicationsChannelsModalComponent>, @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.channelToEdit = data;
    this.isEditChannelModal = data ? true : false;
  }

  ngOnInit() {
    this.submitForm = this.formBuilder.group({
      title: new FormControl((this.channelToEdit ? this.channelToEdit.name : null), [
        Validators.required,
        Validators.maxLength(150)
      ]),
      manualOnly: (this.channelToEdit ? this.channelToEdit.manual : false),
    });
  }

  get title() { return this.submitForm.get('title'); }

  onSubmit(channel) {
    const manualOnly = channel.manualOnly ? channel.manualOnly : false;
    if (this.channelToEdit) {
      const editChannelPayload = this.purgeNotUpdatedProperties(channel.title, manualOnly);
      this.dataService.updateGenericConfiguration(editChannelPayload, this.channelToEdit.id).subscribe(
        (response: any) => {
          this.onCloseCancel(true);
        },
        (error: any) => {
          alert("Ocorreu um erro na edição do canal de comunicação, por favor tente mais tarde.");
        });
    } else {
      const newChannelPayload = {
        name: channel.title,
        description: manualOnly ? 1 : 0,
        idConfigurationType: configs.genTypes.GEN_CONFIG_TYPES.COM_CHANNEL_ID
      };
      this.dataService.createGenericConfiguration(newChannelPayload).subscribe(
        (response: any) => {
          this.onCloseCancel(true);
        },
        (error: any) => {
          console.log(error)
          alert("Ocorreu um erro na criação do canal de comunicação, por favor tente mais tarde.");
        });
    }
  }

  purgeNotUpdatedProperties(title: string, manualOnly: boolean) {
    const channel: any = {};

    if (title !== this.channelToEdit.name) {
      channel['name'] = title;
    }

    if (manualOnly !== this.channelToEdit.manual) {
      channel['description'] = manualOnly ? 1 : 0;
    }

    return channel;
  }

  onCloseCancel(refreshData?: boolean) {
    this.thisDialogRef.close(refreshData);
  }
}
