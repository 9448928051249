import { Pipe, PipeTransform } from "@angular/core";
import * as moment from 'moment-timezone';


@Pipe({ name: 'moment' })
export class MomentPipe implements PipeTransform {
    transform(date, format) {
        if (!date) {
            return null;
        }

        if (format == 'HH:mm')
            return moment(date, format).format('HH:mm');

        if (format) {
            format = format.replace('dd/MM/yyyy, HH:mm', 'L, LT');
            format = format.replace('dd', 'DD');
            format = format.replace('mm', 'MM');
            format = format.replace('yyyy', 'Y');
        }

        return moment(date).format(format);
    }
}
