import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DataService } from './data.service';
import { SessionService } from './session.service';
import { TranslateService } from '@ngx-translate/core';
import { Endpoints, Gender, genTypes } from '../configurations';
import { AngularFirestore, AngularFirestoreCollection } from 'angularfire2/firestore';
import { getDate } from '@app/nabia/system/date';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ProductService {

  constructor(
    private firestore: AngularFirestore,
    private databaseService: DataService,
    private http: HttpClient,
    private translate: TranslateService,
    private sessionService: SessionService) {

  }


  ///////////////////////////////// PRODUCTS /////////////////////////////////

  getProductsWithParams(params?) {
    const url = Endpoints.CONFIGURATIONS.PRODUCTS;
	const options = this.sessionService.getOptions();
    if(params) {
      options['params'] = params;
    }
    const response = this.http.get(url, options);

    return response;
  }

  getAllProducts(quantity: number = 0, offset: Date= getDate(), filters: any = {}) {
    const url = Endpoints.CONFIGURATIONS.PRODUCTS;
    const opt = this.sessionService.getOptions();
    const prodList$ = this.http.get(url, opt);
    return prodList$;
    // const firestoreReply$ = this.firestore.collection('/configurations_products', rep => rep
    //   // .where('idOwnerEntity', '==', filters.idOwnerEntity)
    //   .orderBy('modifiedDate', 'desc')
    //   .startAt(offset)
    //   .limit(quantity)
    // );

    // const url = Endpoints.CONFIGURATIONS.PRODUCTS_BATCH;
    // const prodList$ = this.databaseService.getRealTimeData(firestoreReply$, url).pipe(map((l: any[]) => { l.map(e => { e.modifiedDate = getDate(e.modifiedDate); return e; }); return l; }), // modifiedDate: string => Date
    //   map((l: any[]) => l.sort((a, b) => b.modifiedDate - a.modifiedDate)));

    // return prodList$;
  }

  deleteProduct(id) {
	const url = Endpoints.CONFIGURATIONS.PRODUCTS_ID(id);
    const opt = this.sessionService.getOptions();
    return this.http.delete(url, opt);
  }

  updateProduct(id, body) {
	const url = Endpoints.CONFIGURATIONS.PRODUCTS_ID(id);
    const opt = this.sessionService.getOptions();
    return this.http.put(url, body, opt);
  }
  getById(id) {
    const url = Endpoints.CONFIGURATIONS.PRODUCTS_ID(id);
    const opt = this.sessionService.getOptions();
    const prodList$ = this.http.get(url, opt);
    return prodList$;
  }


  getEntityProducts(quantity: number, offset: Date, filters: any) {
    const firestoreReply$ = this.firestore.collection('/products', rep => rep
      .where('idOwnerEntity', '==', filters.idOwnerEntity)
      .orderBy('modifiedDate', 'desc')
      .startAt(offset)
      .limit(quantity)
    );

    const url = Endpoints.CLIENTS.LEADS.LEAD_PRODUCTS_BATCH(filters.idOwnerEntity);
    const prodList$ = this.databaseService.getRealTimeData(firestoreReply$, url);

    return prodList$;
  }


  createEntityProduct(url: string, body: any) {
    const opt = this.sessionService.getOptions();

    const product$ = this.http.post(url, body, opt);

    return product$;
  }

  deleteEntityProduct(lId: string, pId: string) {
    const url = Endpoints.OPPORTUNITIES.OPPORTUNITY_PRODUCTS_DELETE(lId, pId);
    const opt = this.sessionService.getOptions();

    const prodList$ = this.http.delete(url, opt);

    return prodList$;
  }

  ///////////////////////////////// SERVICES /////////////////////////////////

  getTreatmentCategory() {
	const url = Endpoints.CONFIGURATIONS.SERVICES_CATEGOTY;
    const opt = this.sessionService.getOptions();
	const treatmentCategoryList = this.http.get(url, opt);
    return treatmentCategoryList;
  }

  getServicesSearch(param: string) {
    // const url = Endpoints.CONFIGURATIONS.SERVICES_SEARCH(param);
    const url = Endpoints.CONFIGURATIONS.SERVICES;
    const opt = this.sessionService.getOptions();
    opt['params'] = param;
    const serviceList$ = this.http.get(url, opt);
    return serviceList$;

  }
  getSearchServices(param: string) {
	const url = Endpoints.CONFIGURATIONS.SERVICES;
	const options = this.sessionService.getOptions();
    options['params'] = param;
    const serviceList$ = this.http.get(url, options);
    return serviceList$;

  }
  getServices() {
	const url = Endpoints.CONFIGURATIONS.SERVICES;
    const opt = this.sessionService.getOptions();
    const serviceList = this.http.get(url, opt);
    return serviceList;
  }

  deleteService(id) {
	const url = Endpoints.CONFIGURATIONS.GET_SERVICES(id);
	const opt = this.sessionService.getOptions();
    return this.http.delete(url, opt);

  }
  getAllServices(quantity: number = 0, offset: Date = getDate(), filters: any = {}) {
    const url = Endpoints.CONFIGURATIONS.SERVICES;
    const opt = this.sessionService.getOptions();
    const serviceList$ = this.http.get(url, opt);
    return serviceList$;

    // const firestoreReply$ = this.firestore.collection('/configurations_services', rep => rep
    //   // .where('idOwnerEntity', '==', filters.idOwnerEntity)
    //   .orderBy('modifiedDate', 'desc')
    //   .startAt(offset)
    //   .limit(quantity)
    // );

    // const url = Endpoints.CONFIGURATIONS.SERVICES_BATCH;
    // const servicesList$ = this.databaseService.getRealTimeData(firestoreReply$, url)
    //   .pipe(map((l: any[]) => { l.map(e => { e.modifiedDate = getDate(e.modifiedDate); return e; }); return l; }), // modifiedDate: string => Date
    //     map((l: any[]) => l.sort((a, b) => b.modifiedDate - a.modifiedDate))); // sorts by modifiedDate

    // return servicesList$;
  }

  updateService(id: string, body: any) {
    const url = Endpoints.CONFIGURATIONS.GET_SERVICES(id)
    const opt = this.sessionService.getOptions();

    const product$ = this.http.put(url, body, opt);

    return product$;
  }
  /**
   * Get Filtered Services
   */
  getEvaluationAppointmentServices(quantity: number = 0, offset: number = 0, filters: any = {}) {
    const url = Endpoints.CONFIGURATIONS.SERVICES + '/?category=' + genTypes.EVALUATION_APPOINTMENT_TREATMENT_CATEGORY + ',' + genTypes.CONSULTATION_APPOINTMENT_TREATMENT_CATEGORY;
    const opt = this.sessionService.getOptions();

    const serviceList$ = this.http.get(url, opt);

    return serviceList$;
  }
  getAllTreatment(tId: string) {
    const url = Endpoints.CONFIGURATIONS.GET_SERVICES(tId);
    const opt = this.sessionService.getOptions();

    const serviceList$ = this.http.get(url, opt);

    return serviceList$;
  }


  getEntityServices(quantity: number, offset: Date, filters: any) {
    const firestoreReply$ = this.firestore.collection('/services', rep => rep
      .where('idOwnerEntity', '==', filters.idOwnerEntity)
      .orderBy('modifiedDate', 'desc')
      .startAt(offset)
      .limit(quantity)
    );

    const url = Endpoints.CLIENTS.LEADS.LEAD_SERVICES_BATCH(filters.idOwnerEntity);
    const serviceList$ = this.databaseService.getRealTimeData(firestoreReply$, url);

    return serviceList$;
  }


  createEntityService(url: string, body: any) {
    const opt = this.sessionService.getOptions();

    const reply$ = this.http.post(url, body, opt);

    return reply$;
  }

  updateEntityService(lId: string, pId: string, body: any) {
    const url = Endpoints.OPPORTUNITIES.OPPORTUNITY_SERVICES_UPDATE(lId, pId);
    const opt = this.sessionService.getOptions();

    const product$ = this.http.put(url, body, opt);

    return product$;
  }

  deleteEntityService(lId: string, sId: string) {
    const url = Endpoints.OPPORTUNITIES.OPPORTUNITY_SERVICES_DELETE(lId, sId);
    const opt = this.sessionService.getOptions();

    const reply$ = this.http.delete(url, opt);

    return reply$;
  }


  ///////////////////////////////// PRESCRIPTIONS /////////////////////////////////

  getAllPrescriptions(filters: any = {}) {
    const page = (filters.hasOwnProperty('page') && filters.page !== '' || filters.page !== undefined) ? 'page=' + filters.page : 1;
    const weight = (filters.hasOwnProperty('weight') && filters.weight !== '') ? 'minWeight=' + filters.weight : '';
    const duration = (filters.hasOwnProperty('duration') && filters.duration !== '') ? 'duration=' + filters.duration : '';
    const clinics = (filters.hasOwnProperty('clinic') && filters.clinic !== '') ? 'prescription_clinic=' + filters.clinic : '';
    const contraindications = (filters.hasOwnProperty('contraindication') && filters.contraindication !== '') ? 'contraindications=' + filters.contraindication : '';
    const problems = (filters.hasOwnProperty('problem') && filters.problem !== '') ? 'problems=' + filters.problem : '';
    const filter = page + '&' + weight + '&' + duration + '&' + clinics + '&' + contraindications + '&' + problems;

    const url = Endpoints.CONFIGURATIONS.PRESCRIPTIONS_ALL + '?' + filter;
    const opt = this.sessionService.getOptions();

    const serviceList$ = this.http.get(url, opt);

    return serviceList$;
  }


  deleteEntityPrescription(lId: string, sId: string) {
    // const url = Endpoints.CLIENTS.LEADS.LEAD_SERVICE(lId, sId);
    // const opt = this.sessionService.getOptions();

    // const reply$ = this.http.delete(url, opt);

    // return reply$;
  }




}
