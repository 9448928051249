import { Injectable } from '@angular/core';
import { Socket } from 'ngx-socket-io';
import { SessionService } from '@app/nabia/system/services/session.service';
import { MatSnackBar, MatSnackBarRef, SimpleSnackBar } from '@angular/material';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  private errorSnackRef: MatSnackBarRef<SimpleSnackBar>;

  constructor(
    private socket: Socket,
    private sessionService: SessionService,
    private _snackBar: MatSnackBar
  ) {
    this.socket.on('connect', () => {
      this.socket.emit('joinRoom', this.sessionService.getSessionWebSocket());
      if (!!this.errorSnackRef) {
        this.errorSnackRef.dismiss();
      }
    });

    this.socket.on('error', () => {
      this.openErrorMessage();
    });

    this.socket.on('disconnect', () => {
      this.openErrorMessage();
    });

  }

  openErrorMessage() {
    this.errorSnackRef = this._snackBar.open(
      'Conexão com servidor perdido, favor recarregue a página.',
      '',
      { duration: undefined }
    );
  }

  getReports() {
    return this.socket.fromEvent<any>('finish-reports');
  }

  getReportsData() {
    return this.socket.fromEvent<any>('reports');
  }

  getLoadReports() {
    return this.socket.fromEvent<any>('loadReports');
  }
}
