import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { Router } from '@angular/router';
import { NotificationService } from './notification/provider/notification.service';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
  providers: [NotificationService],
})
export class LayoutComponent implements OnInit {
  public notificationQueue = [];

  public notificationVisible = false;

  public links = {
    disponibilidade: '/reports/availability/',
    oportunidade: '/reports/sales-and-opportunities/',
    financeiro: '/reports/finances/',
    eventos: '/reports/events/',
  };

  constructor(
    private router: Router,
    private notificationService: NotificationService,
    private _snackBar: MatSnackBar
  ) {}

  ngOnInit() {
    this.notificationService.getReports().subscribe(message => {
      const { _id, tipo } = message;
      if (!this.router.url.includes('/reports/')) {
        this.notificationQueue.push({ _id, tipo });
        this.openNotification();
      }
    });
  }

  openNotification() {
    if (this.notificationQueue.length === 0) {
      return;
    }

    if (this.notificationVisible) {
      return;
    }
    this.notificationVisible = true;
    const message = this.notificationQueue.shift();

    const notification = this._snackBar.open(
      'Seu relatório está pronto',
      'Abrir',
      {
        duration: undefined,
      }
    );

    notification.onAction().subscribe(() => {
      this.router.navigate([`${this.links[message.tipo]}${message._id}`]);
    });
    notification.afterDismissed().subscribe(() => {
      this.notificationVisible = false;
      this.openNotification();
    });
  }
}
