
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { DataService } from './data.service';
import { Observable, from } from 'rxjs';
import { Endpoints } from '../configurations';
import { SessionService } from './session.service';
import { HttpClient } from '@angular/common/http';
import { ConfigurationsCacheService } from './configurations-cache.service';

@Injectable()
export class StaffService {


  constructor(
    private http: HttpClient,
    private sessionService: SessionService,
    private databaseService: DataService,
    private configurationsCacheService: ConfigurationsCacheService) {

  }


  // Do any mapping necessary here
  getStaff(filters: {}, quantity: number, offset: number): Observable<any> {
    const staff$ = this.databaseService.getStaff(quantity, offset, filters);
    return staff$;
  }

  getAllStaff(all = true): Observable<any> {
    const staff$ = from(this.configurationsCacheService.getStaff(false).then((st: any) => {
      // const staff$ = this.databaseService.getAllStaff().pipe(map((st: [any]) => {
      const reply: any = st;
      const newArray = [];

      reply.forEach(elem => {
        if(all || (!all && elem.hidden == 0)){
          elem.label = elem.staff_names[0].firstName + ' ' + elem.staff_names[0].lastName;
          elem.value = elem.id;

          newArray.push(elem)
        }
      });

      return newArray;
    }));
    return staff$;
  }

  getStaffById(opId: Array<string>) {
    const staff$ = this.getStaff({ id: opId }, 1, 0);
    return staff$;
  }


  getById(id) {
    const url = Endpoints.STAFF.STAFF_ID(id);

    const backendReply$ = this.http.get(url, this.sessionService.getOptions());

    return backendReply$;
  }

  /**
   * Adds a new follower to a given opportunity
   */
  addFollower(opId: string, sId: string) {
    const url = Endpoints.OPPORTUNITIES.OPPORTUNITY_FOLLOWER(opId, sId);
    const opt = this.sessionService.getOptions();

    const reply$ = this.http.post(url, {}, opt);

    return reply$;
  }

  /**
   * Adds a new commercial to a given opportunity
   */
  addCommercial(opId: string, sId: string) {
    const url = Endpoints.OPPORTUNITIES.OPPORTUNITY_COMMERCIAL(opId, sId);
    const opt = this.sessionService.getOptions();

    const reply$ = this.http.post(url, {}, opt);

    return reply$;
  }

  /**
   * Adds a new follower to a given opportunity
   */
  removeFollower(opId: string, sId: string) {
    const url = Endpoints.OPPORTUNITIES.OPPORTUNITY_FOLLOWER(opId, sId);
    const opt = this.sessionService.getOptions();

    const reply$ = this.http.delete(url, opt);

    return reply$;
  }

  /**
   * Adds a new commercial to a given opportunity
   */
  removeCommercial(opId: string, sId: string) {
    const url = Endpoints.OPPORTUNITIES.OPPORTUNITY_COMMERCIAL(opId, sId);
    const opt = this.sessionService.getOptions();

    const reply$ = this.http.delete(url, opt);

    return reply$;
  }

}
