import { NgModule } from '@angular/core';

import { NgSelectModule } from '@ng-select/ng-select';
import {SelectUsersComponent} from './select-users.component';
import {TranslateModule} from "@ngx-translate/core";
import {CommonModule} from "@angular/common";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";

@NgModule({
    imports: [
        CommonModule,
        NgSelectModule,
        TranslateModule,
        ReactiveFormsModule,
        FormsModule
    ],
    providers: [
    ],
    declarations: [
        SelectUsersComponent
    ],
    exports: [
        SelectUsersComponent
    ]
})
export class SelectUsersModule { }
