import { MAT_DATE_FORMATS, DateAdapter, MatNativeDateModule } from '@angular/material/core';
import { MAT_DATE_LOCALE, MAT_DIALOG_DATA } from '@angular/material';
import { NgModule, LOCALE_ID } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'; // this is needed!
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpModule } from '@angular/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { LayoutModule } from './layout/layout.module';
import { SharedModule } from './shared/shared.module';
import { RoutesModule } from './routes/routes.module';
import { environment } from '../environments/environment';
import { AngularFireModule } from 'angularfire2';
import { AngularFirestoreModule } from 'angularfire2/firestore';
import { AuthComponent } from './nabia/system/auth/auth/auth.component';
import { OAuthModule } from 'angular-oauth2-oidc';
import { registerLocaleData } from '@angular/common';
import localePt from '@angular/common/locales/pt-PT';
import { DataService } from './nabia/system/services/data.service';
import { HelperService } from './nabia/system/services/helper.service';

import { GoogleChartsModule } from 'angular-google-charts';
import { ConsentComponent } from "./nabia/system/auth/consent/consent.component";
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import * as moment from 'moment-timezone';
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from "./nabia/system/services/timepicker.service";
registerLocaleData(localePt);
moment.locale('pt');

export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

import { DemoMaterialModule } from './material-module';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { AuthInterceptorService } from './nabia/system/services/authInterceptorService.service';


@NgModule({
    declarations: [
        AppComponent,
        AuthComponent,
        ConsentComponent,
    ],
    imports: [
        AngularFireModule.initializeApp(environment.firebase),
        AngularFirestoreModule,
        HttpClientModule,
        BrowserAnimationsModule,
        CoreModule,
        LayoutModule,
        GoogleChartsModule.forRoot(),
        SharedModule.forRoot(),
        RoutesModule,
        InfiniteScrollModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (createTranslateLoader),
                deps: [HttpClient]
            }
        }),
        HttpModule,
        OAuthModule.forRoot(),
        DemoMaterialModule,
        MatNativeDateModule,
        Ng2SearchPipeModule,
        DragDropModule
    ],
    providers: [
        HelperService,
        DataService,
        { provide: LOCALE_ID, useValue: 'pt-PT' },
        { provide: MAT_DATE_LOCALE, useValue: 'pt-PT' },
        { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
        {
            provide: DateAdapter,
            useClass: MomentDateAdapter,
            deps: [MAT_DATE_LOCALE]
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptorService,
            multi: true,
        },
        { provide: MAT_DIALOG_DATA, useValue: [] },
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
