import { Injectable } from '@angular/core';
import {SnackRevertComponent} from "../../../layout/snack/revert/revert/snack-revert.component";
import {MatSnackBar} from "@angular/material/snack-bar";
import {RevertParams} from "./models/snackbar";

@Injectable({
  providedIn: 'root'
})
export class SnackbarService {

  timeoutRevert = 2500;
  constructor(
      public matSnackBar:MatSnackBar
  ) {
  }

  showRevert(params: RevertParams) {
    return this.matSnackBar.openFromComponent(SnackRevertComponent, {
      panelClass: 'snackbar-container',
      data: params,
      horizontalPosition: 'start',
      verticalPosition: 'bottom',
    });
  }
}