import { Injectable } from '@angular/core';
import { DataService } from './data.service';
import { from, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { resourceTypes, TENANT } from '../configurations';
import { ConfigurationsCacheService } from './configurations-cache.service';
import { SessionService } from './session.service';

@Injectable()
export class ConfigurationsService {
  configurations: {}[] = [];

  constructor(
    private databaseService: DataService,
    private configurationsCacheService: ConfigurationsCacheService,
    public sessionService: SessionService
  ) {
    const items = [];
    if (this.sessionService.hasPermission('clinicas.visualizar', 'configuracoes')) {
      items.push({
        name: 'clinics',
        title: 'nabia.configurations.general.items.clinics',
        uri: '',
        id: 0,
      });
    }
    if (this.sessionService.hasPermission('salas.visualizar', 'configuracoes')) {
      items.push({
        name: 'rooms',
        title: 'nabia.configurations.general.items.rooms',
        uri: '',
        id: 1,
      });
    }
    if (this.sessionService.hasPermission('maquinas.visualizar', 'configuracoes')) {
      items.push({
        name: 'machines',
        title: 'nabia.configurations.general.items.machines',
        uri: '',
        id: 2,
      });
    }
    if (this.sessionService.hasPermission('recursos.visualizar', 'configuracoes')) {
      items.push({
        name: 'rh',
        title: 'nabia.configurations.general.items.rh',
        uri: '',
        id: 3,
      });
    }
    if (this.sessionService.hasPermission('recursos.visualizar', 'configuracoes')) {
      items.push({
        name: 'Problemas',
        title: 'nabia.configurations.general.items.problem',
        uri: '',
        id: 4,
      });
    }

    if (items.length) {
      this.configurations.push({
        name: 'general',
        title: 'nabia.configurations.general.title',
        subtitle: 'nabia.configurations.general.sub-title',
        items: items,
      });
    }
  }

  deleteResource(resourceID: any, isStaff, body: any): any {
    const data$ = this.databaseService.deleteResource(resourceID, isStaff, body);

    return data$;
  }

  // Do any mapping necessary here
  getAllConfigurations(): Observable<any> {
    // const configs$ = this.databaseService.getAllConfigurations();
    // return configs$;

    return of(this.configurations);
  }

  // Do any mapping necessary here
  getConfigurationByType(uri: string): Observable<any> {
    const configs$ = this.databaseService.getConfigurationsByType();

    return configs$;
  }

  getOrigins() {
    const origins$ = this.databaseService.getOrigins();
    return origins$;
  }

  getAllClinics() {
    // const clinicsList$ = this.databaseService.getClinics();

    // return clinicsList$;
    return from(this.configurationsCacheService.getClinics(false));
  }

  getAllTenants() {
    return this.databaseService.getTenants();
  }

  getTenant() {
    return this.databaseService.getTenant(TENANT);
  }

  getAllProblems(params?) {
    //returns a list of problems, their info and associated treatments
    return this.databaseService.getProblems(params);
  }
  getAllTaxes(params?) {
    //returns a list of problems, their info and associated treatments
    return this.databaseService.getTaxes(params);
  }
  getAllProducts(params?) {
    //returns a list of problems, their info and associated treatments
    return this.databaseService.getProducts(params);
  }
  getAllServices(params?) {
    //returns a list of problems, their info and associated treatments
    return this.databaseService.getServices(params);
  }

  getAllOccupations(params?) {
    //returns a list of problems, their info and associated treatments
    return this.databaseService.getOccupations(params);
  }

  getAllPrescriptions(params?) {
    //returns a list of problems, their info and associated treatments
    return this.databaseService.getPrescriptions('', params);
  }

  getAllLossReasons(searchParams?: any) {
    if (searchParams) {
      return this.databaseService.getLossReasons(searchParams);
    }

    return this.databaseService.getLossReasons();
  }

  getAllContraindications(params?: Object) {
    //returns a list of problems, their info and associated treatments
    return this.databaseService.getContraindications(params);
  }

  getAllOpportunityTypes(params?: Object) {
    //returns a list of problems, their info and associated treatments
    return this.databaseService.getOpportunityTypes(params);
  }

  getAllCalendarViewTypes(params?: Object) {
    //returns a list of problems, their info and associated treatments
    return this.databaseService.getCalendarViewTypes();
  }

  getAllCalendarState(params?: Object) {
    return this.databaseService.getCalendarState();
  }

  getAllRooms(name?: string, params = null) {
    //returns a list of rooms, their info and associated treatments
    const roomsList$ = this.databaseService.getRooms(name, params);

    return roomsList$;
  }

  getAllMachines(name?: string, params = null) {
    //returns a list of machines, their info and associated treatments
    const machineList$ = this.databaseService.getMachines(name, params);

    return machineList$;
  }

  getAllStaff(name?: string, params = null) {
    //returns a list of staff, their info and associated treatments
    const staffList$ = this.databaseService.getRHStaff(name, params);

    return staffList$;
  }

  getAllEstadoLeads(params?: any) {
    let estadoLeadsList$ = this.databaseService.getEstadoLeadsList(params);
    return estadoLeadsList$;
  }

  getAllEtapas(searchParams?: any) {
    //returns a list of staff, their info and associated treatments
    let staffList$;
    if (searchParams) {
      staffList$ = this.databaseService.getOpportunityEtapasList(searchParams);
      return staffList$;
    }
    staffList$ = this.databaseService.getOpportunityEtapasList();

    return staffList$;
  }

  deleteEtapas(idsEtapas: any) {
    const etapas = this.databaseService.deleteOpportunityEtapas(idsEtapas);

    return etapas;
  }
  deleteEtapa(id: string) {
    const etapas = this.databaseService.deleteOpportunityEtapa(id);

    return etapas;
  }

  getAllGoals(params?) {
    return this.databaseService.getGoals(params);
  }

  deleteGoals(ids: any) {
    return this.databaseService.deleteGoals(ids);
  }

  deleteGoal(id: string) {
    return this.databaseService.deleteGoal(id);
  }

  updateListGoals(bodyUpdate) {
    const update = this.databaseService.updateGoalsList(bodyUpdate);

    return update;
  }

  updateGoals(id, bodyUpdate) {
    const update = this.databaseService.updateGoals(id, bodyUpdate);

    return update;
  }

  deleteEstadoLeads(id: string) {
    const estadoLeads = this.databaseService.deleteEstadoLeads(id);
    return estadoLeads;
  }

  deleteListEstadoLeads(body: any) {
    const estadoLeads = this.databaseService.deleteListEstadoLeads(body);
    return estadoLeads;
  }

  deleteCategoriaTratamento(id: string) {
    const categoria = this.databaseService.deleteCategoriaTratamento(id);

    return categoria;
  }
  updateSingleCategoriaTratamento(id: string, body: any) {
    const categoria = this.databaseService.updateSingleCategoriaTratamento(id, body);

    return categoria;
  }
  deleteClinic(id: string) {
    return this.databaseService.deleteClinic(id);
  }
  updateSingleClinic(id: string, body: any) {
    return this.databaseService.updateSingleClinic(id, body);
  }
  getSingleStaff(id: string) {
    //returns a staff, their info and associated treatments
    const staff$ = this.databaseService.getSingleRHStaff(id);

    return staff$;
  }

  getSingleResource(id: string) {
    //returns a  resource, their info and associated treatments
    const resource$ = this.databaseService.getSingleResource(id);

    return resource$;
  }

  getResourceAvailabilities(id: string) {
    // returns list of availabilities belonging to a specific typififed resource
    const availsList$ = this.databaseService.getResourceAvailabilities(id).pipe(
      map((availabilities: any) => {
        const list = {};

        availabilities.forEach(availability => {
          if (list[availability.idAggregation]) {
            list[availability.idAggregation].push(availability);
          } else {
            list[availability.idAggregation] = [];
            list[availability.idAggregation].push(availability);
          }
        });
        return list;
      })
    );

    return availsList$;
  }

  getStaffAvailabilities(id: string) {
    // returns list of availabilities belonging to a specific staff
    const availsList$ = this.databaseService.getRHStaffSAvailabilities(id).pipe(
      map((availabilities: any) => {
        const list = {};

        availabilities.forEach(availability => {
          if (list[availability.idAggregation]) {
            list[availability.idAggregation].push(availability);
          } else {
            list[availability.idAggregation] = [];
            list[availability.idAggregation].push(availability);
          }
        });
        return list;
      })
    );

    return availsList$;
  }

  getClinicUnavailabilities(id: string) {
    // returns list of availabilities belonging to a specific typififed resource
    const unavailsList$ = this.databaseService.getClinicUnavailabilities(id).pipe(
      map((unavailabilities: any) => {
        const list = {};

        unavailabilities.forEach(unavailability => {
          if (list[unavailability.idAggregation]) {
            list[unavailability.idAggregation].push(unavailability);
          } else {
            list[unavailability.idAggregation] = [];
            list[unavailability.idAggregation].push(unavailability);
          }
        });
        return list;
      })
    );

    return unavailsList$;
  }

  getResourceUnavailabilities(id: string) {
    // returns list of availabilities belonging to a specific typififed resource
    const unavailsList$ = this.databaseService.getResourceUnavailabilities(id).pipe(
      map((unavailabilities: any) => {
        const list = {};

        unavailabilities.forEach(unavailability => {
          if (list[unavailability.idAggregation]) {
            list[unavailability.idAggregation].push(unavailability);
          } else {
            list[unavailability.idAggregation] = [];
            list[unavailability.idAggregation].push(unavailability);
          }
        });
        return list;
      })
    );

    return unavailsList$;
  }

  getStaffUnavailabilities(id: string) {
    // returns list of availabilities belonging to a specific staff
    const unavailsList$ = this.databaseService.getRHStaffUnavailabilities(id).pipe(
      map((unavailabilities: any) => {
        const list = {};

        unavailabilities.forEach(unavailability => {
          if (list[unavailability.idAggregation]) {
            list[unavailability.idAggregation].push(unavailability);
          } else {
            list[unavailability.idAggregation] = [];
            list[unavailability.idAggregation].push(unavailability);
          }
        });
        return list;
      })
    );

    return unavailsList$;
  }

  createProblem(problem: any) {
    let payload = {
      name: problem.name,
      description: '',
      idResourceType: resourceTypes.room,
      idClinic: problem.idClinic,
      treatments: problem.treatments,
    };

    const newResource$ = this.databaseService.createResource(payload);

    return newResource$;
  }

  createRoom(room: any) {
    let payload = {
      name: room.name,
      description: '',
      idResourceType: resourceTypes.room,
      idClinic: room.idClinic,
      treatments: room.treatments,
    };

    const newResource$ = this.databaseService.createResource(payload);

    return newResource$;
  }

  createEtapa(etapa: any) {
    let payload = {
      name: etapa.name,
      type: etapa.type,
      order: etapa.order,
      //idTenant: room.idClinic
    };

    const newResource$ = this.databaseService.createEtapa(payload);

    return newResource$;
  }

  createMachine(room: any) {
    let payload = {
      name: room.name,
      description: '',
      idResourceType: resourceTypes.machine,
      idClinic: room.idClinic,
      treatments: room.treatments,
    };

    const newResource$ = this.databaseService.createResource(payload);

    return newResource$;
  }

  createStaff(staff: any) {
    let nameParts = staff.name.split(' ');
    const firstName = nameParts.shift();
    const lastName = nameParts.join(' ');
    let payload = {
      gender: staff.gender,
      firstName: firstName,
      lastName: lastName,
      email: staff.email,
      idRole: staff.idRole,
      idOffice: staff.idOffice,
      idConfigurationClinic: staff.idClinic,
      idFirstVisitType: staff.idFirstVisitType,
      treatments: staff.treatments,
    };

    const newResource$ = this.databaseService.createStaff(payload);

    return newResource$;
  }

  getRolesList() {
    const roles$ = this.databaseService.getRolesList();

    return roles$;
  }
  getCategoriasTratamento(params) {
    let tratamentoList;
    if (params) {
      tratamentoList = this.databaseService.getCategoriaTratamentoList(params);
      return tratamentoList;
    }
    tratamentoList = this.databaseService.getCategoriaTratamentoList();

    return tratamentoList;
  }
  getClinics(searchParams) {
    let result;
    if (searchParams) {
      result = this.databaseService.getClinicsPagination(searchParams);
      return result;
    }
    result = this.databaseService.getClinicsPagination(null);

    return result;
  }
  addServiceToResource(idResource: string, idService: string) {
    const data$ = this.databaseService.addTreatmentToResource(idResource, idService, false);

    return data$;
  }

  addServiceToStaff(idStaff: string, idService: string) {
    const data$ = this.databaseService.addTreatmentToResource(idStaff, idService, true);

    return data$;
  }

  removeServiceFromResource(idResource: string, idService: string) {
    const data$ = this.databaseService.removeTreatmentFromResource(idResource, idService, false);

    return data$;
  }

  removeServiceFromStaff(idStaff: string, idService: string) {
    const data$ = this.databaseService.removeTreatmentFromResource(idStaff, idService, true);

    return data$;
  }

  addAvailabilityToResource(idResource: string, availability: any) {
    const data$ = this.databaseService.addAvailabilityToResource(idResource, availability, false);

    return data$;
  }

  addAvailabilityToStaff(idStaff: string, availability: any) {
    const data$ = this.databaseService.addAvailabilityToResource(idStaff, availability, true);

    return data$;
  }

  addUnavailabilityToClinic(clinicId: string, unavailability: any) {
    const data$ = this.databaseService.addUnavailabilityToClinic(clinicId, unavailability);

    return data$;
  }

  addUnavailabilityToResource(idResource: string, unavailability: any) {
    const data$ = this.databaseService.addUnavailabilityToResource(idResource, unavailability, false);

    return data$;
  }

  addUnavailabilityToStaff(idStaff: string, unavailability: any) {
    const data$ = this.databaseService.addUnavailabilityToResource(idStaff, unavailability, true);

    return data$;
  }

  removeAvailabilityFromResource(idResource: string, idAvailability: string) {
    const data$ = this.databaseService.removeAvailabilityFromResource(idResource, idAvailability, false);

    return data$;
  }

  removeAvailabilityFromStaff(idStaff: string, idAvailability: string) {
    const data$ = this.databaseService.removeAvailabilityFromResource(idStaff, idAvailability, true);

    return data$;
  }

  removeUnavailabilityFromClinic(clinicId: string, idAggregation: string) {
    const data$ = this.databaseService.removeUnavailabilityFromClinic(clinicId, idAggregation);

    return data$;
  }

  removeUnavailabilityFromResource(idResource: string, idAvailability: string) {
    const data$ = this.databaseService.removeUnavailabilityFromResource(idResource, idAvailability, false);

    return data$;
  }

  removeUnavailabilityFromStaff(idStaff: string, idAvailability: string) {
    const data$ = this.databaseService.removeUnavailabilityFromResource(idStaff, idAvailability, true);

    return data$;
  }

  removeAllAvailabilityFromResource(idResource: string, isResource: boolean) {
    const data$ = this.databaseService.removeAllAvailabilityFromResource(idResource, isResource);

    return data$;
  }

  updateValuesFromResource(idEntity, body, isStaff) {
    const data$ = this.databaseService.updateResourceValues(idEntity, body, isStaff);

    return data$;
  }

  updateEtapa(id, bodyUpdate) {
    const update = this.databaseService.updateEtapa(id, bodyUpdate);

    return update;
  }

  updateListEtapa(bodyUpdate) {
    const update = this.databaseService.updateOpportunityEtapasList(bodyUpdate);

    return update;
  }
  updateListEstadoLeads(bodyUpdate) {
    const update = this.databaseService.updateEstadoLeadsList(bodyUpdate);

    return update;
  }

  updateListOpportunityType(bodyUpdate): Observable<Object> {
    return this.databaseService.putListOpportunityType(bodyUpdate);
  }

  updateListContraindications(bodyUpdate): Observable<Object> {
    return this.databaseService.putListContraindications(bodyUpdate);
  }
  updateListTaxes(bodyUpdate): Observable<Object> {
    return this.databaseService.putListTaxes(bodyUpdate);
  }

  updateListClinics(bodyUpdate): Promise<Object> {
    return this.databaseService.updateClinic(bodyUpdate);
  }
  updateListProblems(bodyUpdate): Observable<Object> {
    return this.databaseService.putListProblems(bodyUpdate);
  }

  updateListPrescriptions(bodyUpdate): Observable<Object> {
    return this.databaseService.putListPrescriptions(bodyUpdate);
  }

  updatePrescription(id, bodyUpdate): Observable<Object> {
    return this.databaseService.putPrescriptions(id, bodyUpdate);
  }

  updateListOccupations(bodyUpdate): Observable<Object> {
    return this.databaseService.putListOccupations(bodyUpdate);
  }

  updateListLossReasons(bodyUpdate): Observable<Object> {
    return this.databaseService.putListLossReasons(bodyUpdate);
  }

  removePrescriptionService(id): Promise<Object> {
    return this.databaseService.deletePrescriptionsService(id);
  }

  getOriginsList(): Observable<Object> {
    return this.databaseService.getOriginsList();
  }
}
