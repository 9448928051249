import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class HistoryService {

  secondsLink = 4000;

  constructor() {
  }


  scrollToDiv(id){
    try {
      const div: any = document.getElementById(id);
      if (div) {
        div.scrollIntoView();
        const styleBefore = div.style;
        this.setStyleLink(div);
        window.scrollBy(0, -50);

        setTimeout(() => {
          div.style = styleBefore;
        }, this.secondsLink);
      }
    }catch (e) {
      console.error(e)
    }
  }

  setStyleLink(div){
    div.style.border= "2px solid #e36b45";
  }
}
