import {Component, Inject} from '@angular/core';
import {MAT_SNACK_BAR_DATA, MatSnackBarRef} from "@angular/material/snack-bar";

@Component({
    selector: 'app-snack-revert',
    template: `
        <div class="snack-content">
            {{ data.text | translate }}
            <button (click)="data.onRevert(data);_snackRef.dismiss()" mat-flat-button class="snack-button"><i class="fa fa-undo"></i> Reverter</button>
        </div>
    `,
    styleUrls: ['./snack-revert.component.css']
})
export class SnackRevertComponent  {

    constructor(
        @Inject(MAT_SNACK_BAR_DATA) public data: any,
         public _snackRef: MatSnackBarRef<SnackRevertComponent>
    ) {}

}
