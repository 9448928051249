import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-history-popup',
  templateUrl: './history-popup.component.html',
  styleUrls: ['./history-popup.component.scss']
})
export class HistoryPopupComponent implements OnInit {

  origin = '';
  idEntity = '';

  constructor(public thisDialogRef: MatDialogRef<HistoryPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.origin = data.origin;
    this.idEntity = data.idEntity;
  }

  ngOnInit() {

  }

}
