
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CanActivate, CanActivateChild, RouterStateSnapshot, Router, ActivatedRouteSnapshot } from '@angular/router';
import { SessionService } from '../services/session.service';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService implements CanActivate, CanActivateChild {

  constructor(
    private router: Router,
    private sessionService: SessionService) {
    this.sessionService.getPermissions2();
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
    if (environment.production) {
      if (this.sessionService.getTokenFromSession() != '')
        return true;
    }

    const token = localStorage.getItem(this.sessionService.SESSION_TOKEN)
    if (token) {
      if (route.data['path']) {
        return this.sessionService.hasPermission(route.data['operation'], route.data['path']);
      } else {
        return true;
      }
    }
    else {
      this.router.navigateByUrl('/login')
    }
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
    throw new Error('Method not implemented.');
  }
}
