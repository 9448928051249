import { getDate } from './../date/date';
import { Injectable } from '@angular/core';
import { observable, of } from 'rxjs';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { OAuthService } from 'angular-oauth2-oidc';
import { Endpoints, genTypes } from '../configurations';
import * as configs from '../configurations';
import { isNullOrUndefined } from 'util';
import * as moment from 'moment-timezone';
import * as uuid from 'uuid';

@Injectable({
  providedIn: 'root'
})
export class SessionService {
  SESSION_TOKEN = 'SessionToken';
  TENANT_ID = '';
  options = {};

  userId = 'default';
  staffFirstVisitType = [];
  userName = 'Nome Indisponível';
  userEmail = 'Email Indisponível';
  userRole = { title: 'Role Indisponível', id: '' };
  userPic = 'assets/img/user/08.jpg';
  staffClinics = [];
  MaxFreeTreatamentScheduled;
  reloading = false;
  metaDataStorage: any;

  permissions = {
    'home.general': [
      'create',
      'read',
      'update',
      'delete'
    ]
  };


  constructor(
    private oauthService: OAuthService,
    private http: HttpClient,
    private router: Router, ) {
    this.getPermissions2();
    this.getUserData();
    // console.log('Permissoes constructor', this.permissions);
  }

  getSessionWebSocket(){
    if (!sessionStorage.getItem('websocket') ?? true) {
      sessionStorage.setItem('websocket', this.getUniqueId(5));
    }
    return sessionStorage.getItem('websocket');
  }

  /**
 * generate groups of 4 random characters
 * @example getUniqueId(1) : 607f
 * @example getUniqueId(2) : 95ca-361a-f8a1-1e73
 */
  getUniqueId(parts: number): string {
    const stringArr = [];
    for(let i = 0; i< parts; i++){
      // tslint:disable-next-line:no-bitwise
      const S4 = (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
      stringArr.push(S4);
    }
    return stringArr.join('-');
  }

  getUserData() {
    const url = Endpoints.STAFF.STAFF_INFO;
    const opt = this.getOptions();

    const request = this.http.get<any>(url, opt)

    request.subscribe((resp: any) => {
      // console.log('user Info', resp); // TODO:        Remover antes do PR
      this.userName = resp.staff_names[0].firstName + ' ' + resp.staff_names[0].lastName;
      this.userEmail = resp.staff_emails[0].email;
      this.userRole = resp.role;
      this.staffFirstVisitType = resp.staff_first_visit_type;
      this.userId = resp.id;
      this.staffClinics = [resp.clinics_staff];
    });

    return request;
  }

  toBase64(str: string) {
    return btoa(encodeURIComponent(str));
  }

  fromBase64(str: string) {
    return decodeURIComponent(atob(str));
  }
  setTenant(idTenant) {
    this.TENANT_ID = idTenant;
  }

  getMaxFreeTreatamentScheduled() {
    return this.MaxFreeTreatamentScheduled;
  }

  async getConfigurations() {
    if (!isNullOrUndefined(localStorage.getItem('system'))) {
      const system = JSON.parse(decodeURIComponent(atob(localStorage.getItem('system'))));
      const parseSymbol = this.fromBase64(system.symbol);
      system.symbol = parseSymbol;
      this.setTenant(system.id);
      this.MaxFreeTreatamentScheduled = parseInt(system.maxFreeTreatamentScheduled);

      moment.tz.setDefault(system.timezone);
      return system;
    }
    const tenant = await this.getCurrentTenant();
    let symbol = '';
    if (tenant) {
      symbol = this.toBase64(tenant.symbol);
      tenant.symbol = symbol;
      this.setTenant(tenant.id);
      this.MaxFreeTreatamentScheduled = parseInt(tenant.maxFreeTreatamentScheduled);
      localStorage.setItem('system', btoa(unescape(encodeURIComponent(JSON.stringify(tenant)))));

      moment.tz.setDefault(tenant.timezone);
    }

    return tenant;
  }

  async getCurrentTenant() {
    const url = Endpoints.TENANT.GET_TENANT_BY_ID;
    const opt = this.getOptions();
    const result: any = await this.http.get(url, opt).toPromise();
    return result;
  }

  getUserName() {
    return this.userName;
  }

  getUserEmail() {
    return this.userEmail;
  }

  getUserRole() {
    return this.userRole.title;
  }

  getUserRoleID() {
    return this.userRole.id;
  }

  getUserPicture() {
    return this.userPic;
  }

  getUserId() {
    return this.userId;
  }


  isUserAuthenticated() {
    const token = this.getTokenFromSession();
    const reply = (token && token !== '') ? true : false;
    if (!reply) {
      console.log('User not authenticated!'); // TODO: remove the comment on PR !!!
    }

    return of(reply);
  }


  checkClinicsByStaff(clinic){
    return Boolean(this.staffClinics.filter(aux => aux.id == clinic).length);
  }

  checkClinicsByPermission(filter, clinic){
    if (filter[0].clinics.length == 0) {
      return true;
    }
    return Boolean(filter[0].clinics.filter(aux => aux.id == clinic).length);
  }

  private getMetaDataStorage() {
    if (!this.metaDataStorage)
      this.metaDataStorage = JSON.parse(atob(localStorage.getItem('metadata')));

    return this.metaDataStorage;
  }

  hasPermission(operation: string, path: any, clinic = null) {
    try {
      if (!isNullOrUndefined(localStorage.getItem('metadata'))) {
        const permission = this.getMetaDataStorage()[path];
        if (permission) {
          const filter = permission.filter(aux => aux.action == operation);
          if (clinic && filter.length) {
            return this.checkClinicsByPermission(filter, clinic);
          } else {
            return Boolean(filter.length);
          }
        }

        return false;
      } else {
        if (!this.reloading) {
          this.reloading = true;
          this.logout();
          location.reload();
        }

      }
    } catch (e) {
      console.log(e);
    }
  }


  getPermissions() {
    const url = Endpoints.CONFIGURATIONS.PERMISSIONS;
    const opt = this.getOptions();
    this.clearLocalStorage();
    const leadList$ = this.http.get(url, opt).subscribe((resp: any) => {
      this.permissions = resp;
      this.router.navigate([configs.defaultUrl]);
      localStorage.setItem('metadata', btoa(JSON.stringify(this.permissions)));


    });

    return leadList$;
  }

  getPermissions2() {
    const url = Endpoints.CONFIGURATIONS.PERMISSIONS;
    const opt = this.getOptions();

    const leadList$ = this.http.get(url, opt).subscribe((resp: any) => {
      this.permissions = resp;
      // console.log('Permissões 2', this.permissions); // TODO:        Remover antes do PR
    });
    return leadList$;
  }




  /**
   * Fetches token from Session Storage
   */
  public getTokenFromSession() {
    let token = '';
    const sessionAuth = localStorage.getItem(this.SESSION_TOKEN);
    if (sessionAuth) {
      const auth = JSON.parse(sessionAuth);
      const dateTTL = auth.ttl;
      const dateNow = getDate();

      token = (dateTTL >= dateNow.getTime()) ? auth.authToken : '';

      if (token === '') {
        this.removeTokenFromSession();
        // console.log('The session has expired !'); // TODO: remove the comment on PR !!!
      }
    }

    return token;
  }


  removeTokenFromSession() {
    localStorage.removeItem(this.SESSION_TOKEN);
  }


  /**
   * Sets token in Session Storage
   */
  setToken(token: string, ttl: number) {
    const date = getDate();

    date.setSeconds(date.getSeconds() + ttl);

    localStorage.setItem(this.SESSION_TOKEN, JSON.stringify({ authToken: token, ttl: date.getTime() }));
  }

  getTenant() {
    return of(this.TENANT_ID);
  }


  getOptions(contentType = 'application/json') {
    const headers = new HttpHeaders({
      'Content-Type': contentType,
      'Authorization': 'Bearer ' + this.getTokenFromSession()
    });

    const options = { headers: headers };

    return options;
  }



  logout() {
    localStorage.removeItem('metadata');
    localStorage.removeItem('calendar');
    localStorage.clear();
    this.oauthService.logOut();
    this.removeTokenFromSession();
    this.clearLocalStorage();
  }

  clearLocalStorage() {
    localStorage.removeItem('origins');
    localStorage.removeItem('channels');
    localStorage.removeItem('clinics');
    localStorage.removeItem('staff');
    localStorage.removeItem('system');
    this.metaDataStorage = null;
  }
}
