import {Injectable} from '@angular/core';
import {Endpoints} from "../configurations";
import {SessionService} from "./session.service";
import {HttpClient} from "@angular/common/http";
import {paramsGetAll} from "./models/clientsLeads";

@Injectable({
    providedIn: 'root'
})
export class ClientsLeadsService {

    constructor(
        private sessionService: SessionService,
        private http: HttpClient
    ) {
    }

    getById(id): any {
        const url = Endpoints.CLIENTS.LEADS.LEADS_FILTER(id);
        const opt = this.sessionService.getOptions();
        return this.http.get(url, opt);
    }
    getAll(params: paramsGetAll, body?: any): any {
        const url = Endpoints.CLIENTS.LEADS.LEADS_PAGINATION;
        const opt = this.sessionService.getOptions();
        opt['params'] = params;

        return this.http.post(url, body, opt);
    }
    getAllUsers(params: paramsGetAll): any {
        const url = Endpoints.CLIENTS.USERS.USERS_PAGINATION;
        const opt = this.sessionService.getOptions();
        opt['params'] = params;

        return this.http.get(url, opt);
    }
}
