import { getDate } from './../system/date/date';
import { Component, OnInit, Input, SimpleChanges, OnChanges, OnDestroy } from '@angular/core';
import { DataService } from '../system/services/data.service';
import { StaffService } from '../system/services/staff.service';
import { OpportunitiesService } from '../system/services/opportunities.service';
import { ContactService } from '../system/services/contact.service';
import { TranslateService } from '@ngx-translate/core';
import { CalendarService } from '../system/services/calendar.service';
import {
  exchangesTypes,
  firstVisitType,
  SYSTEM_USER,
  MA_ID,
  NAME_MA_ID,
  NAME_SYSTEM_USER,
} from '../system/configurations';
import { ProductService } from '../system/services/product.service';
import { ClientsLeadsService } from '../system/services/clientsLeads.service';
import { DecimalPipe } from '@angular/common';
import { SessionService } from '../system/services/session.service';
import { pagination } from '../system/models/pagination';
import { ActivatedRoute, Router } from '@angular/router';
import { HistoryService } from '../system/services/history.service';
import { DomSanitizer } from '@angular/platform-browser';
import { GetCurrencyPipe } from '../../layout/pipe/get-currency.pipe';
import { LeadStateService } from '../system/services/leadState.service';

@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.scss'],
})
export class HistoryComponent implements OnInit, OnDestroy {
  _idEntity;
  _origin;
  @Input() set idEntity(value) {
    if (value) {
      this._idEntity = value;
      this.getItems(true);
    }
  }
  @Input() set origin(value) {
    if (value) {
      this._origin = value;
      this.getItems(true);
    }
  }

  height = 240;
  subscribe;
  spinner = true;
  lastPage = false;
  items = [];
  page = 1;
  error = false;
  staffList: any;
  fieldsGetName = {
    calendar_events: {
      rescheduled: async value => {
        switch (value) {
          case -1:
            return 'Sim';
          default:
            return 'Não';
        }
      },
      duration: async value => {
        if (value) {
          return value / 60;
        }
      },
      startDate: async value => this.getValueDateHour(value),
      missingResource: async value => this.getValueEnum(value),
      endDate: async value => this.getValueDateHour(value),
      isOnline: async value => this.getValueEnum(value),
      firstVisit: async value => this.getValueEnum(value),
      idResponsible: async value => this.getStaffName(value),
      idCreator: async value => this.getStaffName(value),
      eventState: async value => this.getNameGenericConfiguration(value),
      idLead: async value => this.getNameValueColoum(value, 'clientsLeadsService', 'getById', 'title'),
      idClinic: async value => this.getNameValueColoum(value, 'data', 'getClinic'),
    },
    comments: {
      idLead: async value => this.getNameValueColoum(value, 'clientsLeadsService', 'getById', 'title'),
      idResource: async value => this.getStaffName(value),
    },
    clients_leads: {
      idConfigurationClinic: async id => this.getNameValueColoum(id, 'data', 'getClinic'),
      idOrigin: async id => this.getNameGenericConfiguration(id),
      idClientsLeadsInfluencer: async value =>
        this.getNameValueColoum(value, 'clientsLeadsService', 'getById', 'title'),
      idCommunicationChannel: async id => this.getNameGenericConfiguration(id),
      idLeadState: async id => this.getNameStateLead(id),
      idLostReason: async id => this.getNameGenericConfiguration(id),
      isClient: async value => this.getValueEnum(value),
      idTreatment: async value => this.getNameValueColoum(value, 'productService', 'getAllTreatment'),
      acceptedTerms: async value => this.getValueEnum(value),
      isLost: async value => this.getValueEnum(value),
      birthdate: async value => this.getValueDate(value),
      profession: async value => this.getNameGenericConfiguration(value),
      firstVisitType: async value => {
        const result = firstVisitType.find(aux => aux.value == value);
        if (result) {
          return result.label;
        }
        return null;
      },
      gender: async value => {
        const genders = { 0: 'Masculino', 1: 'Feminino' };
        if (genders.hasOwnProperty(value)) {
          return genders[value];
        }
        return 'Desconhecido';
      },
    },
    opportunities_services: {
      isDiscountPercent: async value => this.getValueEnum(value),
      treatmentPricePerSession: async value => this.getValueMoney(value),
      idTreatment: async value => this.getNameValueColoum(value, 'productService', 'getAllTreatment'),
    },
    opportunities_opportunity: {
      idLead: async value => this.getNameValueColoum(value, 'clientsLeadsService', 'getById', 'title'),
      idOpportunityState: async value => this.getNameGenericConfiguration(value),
      idEtapa: async value => this.getNameEtapa(value),
      idOpportunityLostReason: async value => this.getNameGenericConfiguration(value),
      idOpportunityType: async value => this.getNameGenericConfiguration(value),
      commercial: async value => this.getStaffName(value),
      idStaffOwnedBy: async value => this.getStaffName(value),
      netPrice: async value => this.getValueMoney(value),
      isOnline: async value => this.getValueEnum(value),
      isWon: async value => {
        if (value === -1) {
          return 'Desbloqueado';
        } else if (value === 1) {
          return 'Sim';
        } else if (value === 0) {
          return 'Não';
        }

        return null;
      },
      createdDate: async value => this.getValueDateHour(value),
      closingForecast: async value => this.getValueDate(value),
      firstVisitType: async value => {
        const result = firstVisitType.find(aux => aux.value == value);
        if (result) {
          return result.label;
        }
        return null;
      },
    },
    clients_users_credits: {
      idUserAssignor: async value => this.getClientName(value),
      idResource: async value => this.getStaffName(value),
      amount: async value => this.getValueMoney(value),
      isDiscountPercent: async value => this.getValueEnum(value),
      idType: async value => this.getNameGenericConfiguration(value),
      idConfigurationProduct: async value => this.getNameValueColoum(value, 'productService', 'getById'),
      idTreatment: async value => this.getNameValueColoum(value, 'productService', 'getAllTreatment'),
      idUserYield: async value => this.getClientName(value),
      idOpportunity: async id => this.getNameValueColoum(id, 'opportunityService', 'getOpportunity'),
    },
    opportunities_product: {
      idConfigurationProduct: async value => this.getNameValueColoum(value, 'productService', 'getById'),
      idStaff: async value => this.getStaffName(value),
      isDiscountPercent: async value => this.getValueEnum(value),
      productPricePerUnit: async value => this.getValueMoney(value),
    },
    opportunities_deductions: {
      idDeductionType: async value => this.getNameValueColoum(value, 'opportunityService', 'getPaymentType'),
      amount: async value => this.getValueMoney(value),
      idStaff: async value => this.getStaffName(value),
    },
    opportunities_followers: {
      follower: async value => this.getStaffName(value),
    },
    opportunities_commercial: {
      commercial: async value => this.getStaffName(value),
    },
    opportunities_products: {
      idConfigurationProduct: async value => this.getNameValueColoum(value, 'productService', 'getById'),
      idStaff: async value => this.getStaffName(value),
      isDiscountPercent: async value => this.getValueEnum(value),
      productPricePerUnit: async value => this.getValueMoney(value),
    },
    clients_notes: {
      isFavorite: async value => this.getValueEnum(value),
      idStaff: async value => this.getStaffName(value),
      idType: async value => this.getNameGenericConfiguration(value),
      idOpportunity: async value => this.getNameValueColoum(value, 'opportunityService', 'getOpportunity'),
    },
    clients_documents: {
      idType: async value => this.getNameGenericConfiguration(value),
      idStaff: async value => this.getStaffName(value),
    },
    clients_points: {
      idOpportunity: async id => this.getNameValueColoum(id, 'opportunityService', 'getOpportunity'),
    },
    clients_tasks: {
      toBePerformedBy: async value => this.getStaffName(value),
      scheduledBy: async value => this.getStaffName(value),
      isFavorite: async value => this.getValueEnum(value),
      idType: async value => this.getNameGenericConfiguration(value),
      idState: async value => this.getNameGenericConfiguration(value),
    },
    clients_evaluations: {
      idEvaluationType: async value => this.getNameGenericConfiguration(value),
      idStaff: async value => this.getStaffName(value),
    },
    clients_clinical_records: {
      idRecordType: async value => this.getNameGenericConfiguration(value),
      idStaff: async value => this.getStaffName(value),
    },
    clients_users: {
      idLead: async value => this.getNameValueColoum(value, 'clientsLeadsService', 'getById', 'title'),
      startDate: async value => this.getValueDateHour(value),
      birthdate: async value => this.getValueDate(value),
      isClient: async value => this.getValueEnum(value),
      profession: async value => this.getNameGenericConfiguration(value),
      isLost: async value => this.getValueEnum(value),
      acceptedTerms: async value => this.getValueEnum(value),
      idTreatment: async value => this.getNameValueColoum(value, 'productService', 'getAllTreatment'),
      idConfigurationClinic: async id => this.getNameValueColoum(id, 'data', 'getClinic'),
      idOrigin: async id => this.getNameGenericConfiguration(id),
      idCommunicationChannel: async id => this.getNameGenericConfiguration(id),
      idLeadState: async id => this.getNameStateLead(id),
      idLostReason: async id => this.getNameGenericConfiguration(id),
      gender: async value => {
        const genders = { 0: 'Masculino', 1: 'Feminino' };
        if (genders.hasOwnProperty(value)) {
          return genders[value];
        }
        return 'Desconhecido';
      },
    },
    opportunities_payments: {
      idConfigurationPayment: async id => this.getNameValueColoum(id, 'opportunityService', 'getPaymentType'),
      accountId: async value => this.getNameGenericConfiguration(value),
      received: async value => this.getValueEnum(value),
      inbox: async value => this.getValueEnum(value),
      idPaymentState: async value => this.getNameGenericConfiguration(value),
      createdDate: async value => this.getValueDateHour(value),
      idStaff: async value => this.getStaffName(value),
      idStaffOwnedBy: async value => this.getStaffName(value),
      amount: async value => this.getValueMoney(value),
    },
  };
  fieldsBlock = {
    calendar_events: [
      'idTenant',
      'createdDate',
      'modifiedDate',
      'id',
      'idService',
      'idEventReschedule',
      'idAppointment',
      'legacyId',
      'event_lead',
      'events',
    ],
    comments: ['idTenant', 'createdDate', 'modifiedDate', 'id'],
    clients: ['reminderId', 'idTenant'],
    opportunities_deductions: ['opp', 'id', 'createdDate', 'modifiedDate', 'idOpportunity', 'idTenant'],
    opportunities_services: [
      'modifiedDate',
      'isDeleted',
      'id',
      'createdDate',
      'idStaff',
      'idPrescription',
      'deductedSessions',
      'isBudget',
      'idTenant',
      'isOffer',
      'idOwnerEntity',
    ],
    clients_tasks: [
      'id',
      'createdDate',
      'modifiedDate',
      'idEntity',
      'idTenant',
      'date',
      'createReminder',
      'reminderDate',
    ],
    clients_notes: ['id', 'createdDate', 'modifiedDate', 'idEntity', 'idTenant'],
    clients_leads: ['idTenant', 'idSubject', 'modifiedDate', 'firstVisit'],
    clients_users: [
      'idTenant',
      'createdDate',
      'modifiedDate',
      'noAnswer0',
      'noAnswer1',
      'noAnswer2',
      'noAnswer3',
      'idSubject',
      'integrationId',
      'id',
      'idAppointment',
      'event_lead',
      'legacyId',
    ],
    clients_documents: ['fileName', 'path', 'id', 'idTenant', 'createdDate', 'modifiedDate', 'url', 'idEntity'],
    clients_users_credits: [
      'id',
      'createdDate',
      'idUser',
      'modifiedDate',
      'idTenant',
      'idOpportunityProduct',
      'idOpportunityService',
    ],
    opportunities_opportunity: ['modifiedDate', 'isSale', 'isLost', 'isDirectSale'],
    clients_evaluations: ['modifiedDate', 'id', 'createdDate', 'idTenant', 'idUser'],
    clients_clinical_records: ['modifiedDate', 'id', 'createdDate', 'idTenant', 'idUser'],
    opportunities_product: ['id', 'idOwnerEntity', 'idTenant', 'modifiedDate', 'createdDate', 'isBudget'],
    opportunities_products: ['id', 'idOwnerEntity', 'idTenant', 'modifiedDate', 'createdDate', 'isBudget'],
    opportunities_payments: [
      'modifiedDate',
      'idTenant',
      'id',
      'idOpportunity',
      'isDeleted',
      'deletedDate',
      'confirmedHeadquarters',
      'netPrice',
      'paidPrice',
    ],
  };
  tablesLink = {
    opportunities_services: (item, execute = true) => this.scrollToDiv(item, execute),
    opportunities_products: (item, execute = true) => this.scrollToDiv(item, execute),
    opportunities_product: (item, execute = true) => this.scrollToDiv(item, execute),
    opportunities_payments: (item, execute = true) => this.scrollToDiv(item, execute),
    opportunities_deductions: {
      clients: (item, execute = true) => {
        if (execute && (item.action == 'create' || item.action == 'update')) {
          this.toRouter(item, `/people/contact-detail/${this.params['id']}/returns`);
          return this.params['id'];
        }
      },
      opportunities: (item, execute = true) => this.scrollToDiv(item, execute),
    },
    clients_tasks: (item, execute = true) => {
      if (item.action == 'create' || item.action == 'update') {
        const idEntity = item.valuesParse.idEntity ? item.valuesParse.idEntity : item.valuesParseBefore.idEntity;
        if (idEntity && execute) {
          this.toRouter(item, `/people/contact-detail/${idEntity}/tasks`);
        }
        return idEntity;
      }
    },
    clients_users_credits: (item, execute = true) => {
      const idType = item.valuesParse.idType ? item.valuesParse.idType : item.valuesParseBefore.idType;
      const idUserAssignor = item.valuesParse.idUserAssignor
        ? item.valuesParse.idUserAssignor
        : item.valuesParseBefore.idUserAssignor;
      const idUserYield = item.valuesParse.idUserYield
        ? item.valuesParse.idUserYield
        : item.valuesParseBefore.idUserYield;

      if (item.action == 'create') {
        switch (idType) {
          case exchangesTypes.payment:
          case exchangesTypes.serviceProduct:
          case exchangesTypes.credit:
            const idOpp = item.valuesParse.idOpportunity
              ? item.valuesParse.idOpportunity
              : item.valuesParseBefore.idOpportunity;
            if (idOpp && execute) {
              this.toRouter(item, `/opportunities/opportunity-detail/${idOpp}`);
            }
            return idOpp;
          case exchangesTypes.yieldAssignor:
            if (idUserYield && execute) {
              this.toRouter(item, `/people/contact-detail/${idUserYield}`);
            }
            return idUserYield;
          case exchangesTypes.yield:
            if (idUserAssignor && execute) {
              this.toRouter(item, `/people/contact-detail/${idUserAssignor}`);
            }
            return idUserAssignor;
        }
      }
    },
    clients_documents: (item, execute = true) => {
      if (item.action == 'create' || item.action == 'update') {
        const idEntity = item.valuesParse.idEntity ? item.valuesParse.idEntity : item.valuesParseBefore.idEntity;
        if (idEntity && execute) {
          this.toRouter(item, `/people/contact-detail/${idEntity}/documents`);
        }
        return idEntity;
      }
    },
    clients_points: (item, execute = true) => {
      if (item.action == 'create' || item.action == 'update') {
        const idEntity = item.valuesParse.idUser ? item.valuesParse.idUser : item.valuesParseBefore.idUser;
        if (idEntity && execute) {
          this.toRouter(item, `/people/contact-detail/${idEntity}/points`);
        }
        return idEntity;
      }
    },
    clients_evaluations: (item, execute = true) => {
      if (item.action == 'create' || item.action == 'update') {
        const idEntity = item.valuesParse.idUser ? item.valuesParse.idUser : item.valuesParseBefore.idUser;
        if (idEntity && execute) {
          this.toRouter(item, `/people/contact-detail/${idEntity}/evaluations`);
        }
        return idEntity;
      }
    },
    clients_clinical_records: (item, execute = true) => {
      if (item.action == 'create' || item.action == 'update') {
        const idEntity = item.valuesParse.idUser ? item.valuesParse.idUser : item.valuesParseBefore.idUser;
        if (idEntity && execute) {
          this.toRouter(item, `/people/contact-detail/${idEntity}/clinical-records`);
        }
        return idEntity;
      }
    },
    opportunities_opportunity: {
      clients: (item, execute = true) => {
        if (item.action == 'create' || item.action == 'update') {
          const idEntity = item.valuesParse.id ? item.valuesParse.id : item.valuesParseBefore.id;
          if (idEntity && execute) {
            this.toRouter(item, `/opportunities/opportunity-detail/${idEntity}`);
          }
          return idEntity;
        }
      },
      opportunities: () => {},
    },
  };
  tablesTranslate = {
    clients_tasks: {
      create: async item => {
        const str = await this.translate.get(`${item.prefix}${item.tableName}.create`).toPromise();
        return `${str} ${item.valuesParse.title}`;
      },
      update: async item => {
        item['showNgContainer'] = false;
        const countFields = Object.keys(item.valuesParse);

        if (countFields.length == 1) {
          const str = await Promise.all([
            this.translate.get(`${item.prefix}${item.tableName}.updateOne`).toPromise(),
            this.translate.get(`${item.prefixField}${countFields[0]}`).toPromise(),
          ]);
          const strField = str[1].toLocaleLowerCase();

          return `${str[0].replace('%N%', strField)} ${item.valuesParseBefore.title}`;
        } else if (countFields.length > 1) {
          const str = await this.translate.get(`${item.prefix}${item.tableName}.update`).toPromise();

          return `${str.replace('%N%', countFields.length)} ${item.valuesParseBefore.title}`;
        }
      },
    },
    clients_users_credits: {
      create: async item => {
        const types = {
          [exchangesTypes.payment]: { msg: 'createExchangeService', positive: true },
          [exchangesTypes.serviceProduct]: { msg: 'createExchangeService', positive: true },
          [exchangesTypes.credit]: { msg: 'createExchangeCredit', positive: null },
          [exchangesTypes.yieldAssignor]: { msg: 'createExchangeYielMe', positive: true },
          [exchangesTypes.yield]: { msg: 'createExchangeYield', positive: null },
        };

        const type = types[item.valuesParse.idType];

        if (type) {
          const promises = await Promise.all([
            this.translate.get(`${item.prefix}${item.tableName}.${type.msg}`).toPromise(),
            this.getValueMoney(item.valuesParse.creditsBefore),
            this.getValueMoney(item.valuesParse.creditsAfter),
            this.getValueMoney(item.valuesParse.amount, type.positive),
          ]);
          return promises[0]
            .replace('%CREDITS_BEFORE%', promises[1])
            .replace('%CREDITS_AFTER%', promises[2])
            .replace('%CREDITS%', promises[3]);
        }
      },
    },
    clients_evaluations: {
      create: async item => {
        let type = '';
        if (item.valuesParse.idEvaluationType == '3b3b0c57-d389-11e8-8f3e-0242ac120002') {
          type = 'report';
        }
        const promises = await Promise.all([
          await this.translate.get(`${item.prefix}${item.tableName}${type}.create`).toPromise(),
          this.getNameGenericConfiguration(item.valuesParse.idEvaluationType),
        ]);
        return `${promises[0]} ${promises[1]}`;
      },
    },
    clients_clinical_records: {
      create: async item => {
        const promises = await Promise.all([
          await this.translate.get(`${item.prefix}${item.tableName}.create`).toPromise(),
          this.getNameGenericConfiguration(item.valuesParse.idRecordType),
        ]);
        return `${promises[0]} ${promises[1]}`;
      },
    },
  };
  tablesNotShowFields = {
    clients_leads: ['create', 'update'],
    opportunities_opportunity: ['create'],
    opportunities_deductions: ['create', 'update'],
    opportunities_payments: ['create'],
    opportunities_services: ['create', 'update'],
    clients_users_credits: ['create', 'update'],
    clients_users: ['create', 'update'],
    clients_documents: ['create', 'update'],
    clients_points: ['create', 'update'],
    clients_tasks: ['create', 'update'],
    clients_evaluations: ['create', 'update'],
    clients_clinical_records: ['create', 'update'],
    clients_notes: ['delete', 'create', 'update'],
  };

  params;
  constructor(
    private opportunityService: OpportunitiesService,
    private contactService: ContactService,
    private calendarService: CalendarService,
    private route: ActivatedRoute,
    public translate: TranslateService,
    public clientsLeadsService: ClientsLeadsService,
    private staffService: StaffService,
    private sessionService: SessionService,
    private stateLead: LeadStateService,
    public productService: ProductService,
    private decimalPipe: DecimalPipe,
    private data: DataService,
    public router: Router,
    public sanitization: DomSanitizer,
    public historyService: HistoryService,
    private getCurrency: GetCurrencyPipe
  ) {}

  ngOnInit() {
    this.getStaff();
    this.route.params.subscribe(param => {
      this.params = param;
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe();
  }
  unsubscribe() {
    if (this.subscribe) {
      this.subscribe.unsubscribe();
    }
  }

  getItems(reset = false) {
    try {
      if (!this._idEntity || !this._origin) {
        return false;
      }

      if (reset) {
        this.page = 1;
        this.items = [];
        this.lastPage = false;
      }

      this.spinner = true;
      this.error = false;
      const params = {
        page: this.page,
      };

      this.unsubscribe();
      this.subscribe = this.getEntityData(params).subscribe((result: pagination) => {
        if (result.items.length) {
          this.getHistory(result.items);
        }

        if (this.page == result.totalPages) {
          this.lastPage = true;
        }
        this.spinner = false;
      });
    } catch (e) {
      this.spinner = false;
      this.error = true;
      console.error(e);
    }
  }

  getStaff() {
    this.staffService.getAllStaff(true).subscribe(staffList => {
      this.staffList = staffList;
      this.getItems(true);
    });
  }

  getName(elem: any) {
    return elem.firstName + ' ' + elem.lastName;
  }

  async getNameGenericConfiguration(id) {
    if (!id) {
      return null;
    }
    const value: any = await this.data.getGenericConfiguration(id).toPromise();
    if (value) {
      return value.name;
    }
    return null;
  }

  async getNameStateLead(id) {
    if (!id) {
      return null;
    }
    const value: any = this.stateLead.getStateById(id);
    if (value) {
      return value.name;
    }
    const genericValue: any = await this.getNameGenericConfiguration(id);
    return genericValue;
  }

  async getNameEtapa(id) {
    if (!id) {
      return null;
    }
    const value: any = await this.data.getEtapa(id).toPromise();
    if (value) {
      return value.name;
    }
    return null;
  }

  async getNameValueColoum(id, service, method, column = 'name') {
    if (!id) {
      return null;
    }
    const value: any = await this[service][method](id).toPromise();
    if (value) {
      return value[column];
    }
    return null;
  }
  async getValueEnum(value) {
    return value ? 'Sim' : 'Não';
  }

  getEntityData(params) {
    const origins = {
      leads: val => this.contactService.getContactHistory(this._idEntity, val),
      opportunities: val => this.opportunityService.getOpportunityHistory(this._idEntity, val),
      clients: val => this.contactService.getContactHistory(this._idEntity, val),
      events: val => this.calendarService.getCalendarHistory(this._idEntity, val),
    };

    return origins[this._origin](params);
  }

  parseFields(entry) {
    Object.keys(entry.valuesParse).map(key => {
      if (this.isMapped(entry.tableName, key)) {
        if (entry.values[key] !== null) {
          entry.values[key] = 'Carregando...';
        }
        this.getNamebyId(key, entry.valuesParse[key], entry.tableName).then(value => {
          if (value !== null) {
            entry.values[key] = value;
          }
        });
      }
    });
    if (entry.valuesParseBefore) {
      Object.keys(entry.valuesParseBefore).map(key => {
        if (this.isMapped(entry.tableName, key)) {
          if (entry.valuesBefore[key] !== null) {
            entry.valuesBefore[key] = 'Sem Informação';
          }
          this.getNamebyId(key, entry.valuesParseBefore[key], entry.tableName).then(value => {
            if (value !== null) {
              entry.valuesBefore[key] = value;
            }
          });
        }
      });
    }
  }

  onScrollDown() {
    try {
      if (this.spinner) {
        return false;
      }
      this.page++;
      this.getItems();
    } catch (e) {
      console.error(e);
    }
  }

  async getHistory(logs) {
    const prefix = `nabia.history.${this._origin}.`;

    for (let log of logs) {
      const entry: any = {};

      entry.action = log.action;
      entry.tableName = log.tableName;
      entry.prefixField = `nabia.history.fields.${log.tableName}.`;
      entry.prefix = prefix;
      const staff = this.staffList.find(staff => staff.id === log.createdBy);
      entry.staff = staff ? this.getName(staff.staff_names[0]) : '';
      entry.staffId = log.createdBy;
      entry.date = log.createdDate;
      entry.changedValues = log.changedValues;
      entry.beforeValues = log.beforeValues;
      entry.fieldsDescript = JSON.parse(log.changedValues);

      if (!entry.hasOwnProperty('valuesParse')) {
        entry.valuesParse = entry.changedValues ? JSON.parse(entry.changedValues) : {};
      }
      if (!entry.hasOwnProperty('valuesParseBefore')) {
        entry.valuesParseBefore = entry.beforeValues ? JSON.parse(entry.beforeValues) : {};
      }
      entry.values = {};
      entry.valuesBefore = {};
      entry.fields = Object.keys(entry.valuesParse).filter(key => {
        // Table Column exceptions should be added here to be filtered.
        const add = !(
          this.fieldsBlock.hasOwnProperty(entry.tableName) && this.fieldsBlock[entry.tableName].includes(key)
        );
        if (add) {
          entry.values[key] = entry.valuesParse[key];
          if (entry.valuesParseBefore && entry.valuesParseBefore.hasOwnProperty(key)) {
            entry.valuesBefore[key] = entry.valuesParseBefore[key];
          }
          if (entry.values[key] != entry.valuesBefore[key]) {
            return add;
          }
        }
      });

      if (entry.fields.length == 1) {
        this.parseFields(entry);
        // entry.str  = await this.translate.get('nabia.history.fields.update').toPromise();
        entry.str = await this.translate.get(`${prefix}${log.tableName}.updateOne`).toPromise();
      } else {
        entry.str = `${prefix}${log.tableName}.${log.action}`;
        entry.str = await this.translate.get(entry.str).toPromise();
        entry.str = entry.str.replace('%N%', entry.fields.length);
      }

      if (
        this.tablesTranslate.hasOwnProperty(log.tableName) &&
        this.tablesTranslate[log.tableName].hasOwnProperty(log.action)
      ) {
        entry.str = 'Carregando...';
        entry.str = await this.tablesTranslate[log.tableName][log.action](entry);
      }

      entry.isShowFields = this.isShowFields(entry);
      entry.hasLinkTable = this.hasLinkTable(entry);

      this.items.push(entry);
    }
  }

  async getNamebyId(field, id, table) {
    return this.fieldsGetName[table][field](id);
  }

  isMapped(table, field) {
    return this.fieldsGetName.hasOwnProperty(table) && this.fieldsGetName[table].hasOwnProperty(field);
  }

  getValueDateHour(value) {
    return getDate(value, false).format('DD/MM/YYYY LT');
  }
  getValueDate(value) {
    if (!value) {
      return null;
    }
    return getDate(value, false).format('DD/MM/YYYY');
  }

  async getStaffName(value) {
    if (!value) {
      return null;
    }
    const result: any = await this.staffService.getById(value).toPromise();
    if (result) {
      return result.staff_names[0].firstName + ' ' + result.staff_names[0].lastName;
    }
    return null;
  }
  async getClientName(value) {
    if (!value) {
      return null;
    }
    const result: any = await this.contactService.getUser(value).toPromise();
    if (result) {
      return result.users_names[0].firstName + ' ' + result.users_names[0].lastName;
    }
    return null;
  }

  displayFields(item) {
    if (!item.display && this.tablesLink.hasOwnProperty(item.tableName)) {
      if (this.tablesLink[item.tableName].hasOwnProperty(this._origin)) {
        this.tablesLink[item.tableName][this._origin](item);
      } else {
        this.tablesLink[item.tableName](item);
      }
    }

    if (item.isShowFields) {
      item.display = item.display ? false : true;
      if (item.display) {
        this.parseFields(item);
      }
    }
  }

  hasLinkTable(item) {
    if (this.tablesLink.hasOwnProperty(item.tableName)) {
      if (this.tablesLink[item.tableName].hasOwnProperty(this._origin)) {
        return this.tablesLink[item.tableName][this._origin](item, false);
      } else if (typeof this.tablesLink[item.tableName] == 'function') {
        return this.tablesLink[item.tableName](item, false);
      }
    }
    return false;
  }

  isShowFields(item) {
    return (
      item.fields.length > 1 &&
      item.action != 'create' &&
      (!this.tablesNotShowFields.hasOwnProperty(item.tableName) ||
        !this.tablesNotShowFields[item.tableName].includes(item.action))
    );
  }

  showFieldsBefore(item) {
    return Object.keys(item.valuesBefore).length;
  }

  getStyleName(item) {
    if (item.mouseEnter && item.hasLinkTable) {
      return { color: '#0000FF' };
    }
    if (item.staffId == this.sessionService.userId) {
      return {
        color: '#819ced',
      };
    }
  }
  getNameStaff(item) {
    if (item.staffId == this.sessionService.userId) {
      return 'Você';
    } else if (item.staffId == SYSTEM_USER) {
      return NAME_SYSTEM_USER;
    } else if (item.staffId == MA_ID) {
      return NAME_MA_ID;
    }
    return item.staff;
  }

  async getValueMoney(value, positive = false) {
    if (typeof value != 'number') {
      return value;
    }
    if (positive) {
      value = Math.abs(value);
    }
    return this.decimalPipe.transform(value, '.2', 'pt-PT') + this.getCurrency.symbol;
  }

  scrollToDiv(item, execute = true, actions = ['create', 'update'], id?) {
    try {
      if (actions.includes(item.action)) {
        if (!id) {
          id = item.valuesParseBefore && item.valuesParseBefore.id ? item.valuesParseBefore.id : item.valuesParse.id;
        }
        if (execute && id) {
          this.historyService.scrollToDiv(id);
        }

        return id;
      }

      return false;
    } catch (e) {
      console.error(e);
    }
  }
  toRouter(item, router, id?) {
    try {
      if (!id) {
        id = item.valuesParseBefore && item.valuesParseBefore.id ? item.valuesParseBefore.id : item.valuesParse.id;
      }
      this.router.navigate([router], { queryParams: { hyperLink: id } });
    } catch (e) {
      console.error(e);
    }
  }

  getMaxHeight() {
    return this.sanitization.bypassSecurityTrustStyle(this.height + 'px');
  }

  getColorItem(item) {
    if (item.mouseEnter && item.hasLinkTable) {
      return '#0000FF';
    } else {
      return '#7f90a4';
    }
  }
}
