import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { typeSourceSpan } from '@angular/compiler';

@Component({
  selector: 'app-edit-field',
  templateUrl: './edit-field.component.html',
  styleUrls: ['./edit-field.component.scss']
})
export class EditFieldComponent implements OnInit {

  constructor(public thisDialogRef: MatDialogRef<EditFieldComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {

  }

  // Types of data: date, list, string, ...
  types = ['date', 'list', 'string'];
  selectedType: any;
  returnValue: any;
  dados = this.data.data;
  disableButton = true;
  displayMessage = null;
  ngOnInit() {
    this.checkType();

  }

  /**
   * This function checks what type of field is being updated( list, dates, textboxes(strings))
   */
  checkType() {
    this.selectedType = 'na';
    this.displayMessage = this.data.displayMessage;
    this.types.forEach(type => {
      if (type === this.data.type) {

        this.selectedType = type;
      }
    });

    if (this.selectedType === 'na') {
    }
  }

  applyUpdate() {
    this.returnValue = (this.selectedType === 'list') ? this.returnValue.value : this.returnValue;
    this.thisDialogRef.close(this.returnValue);
  }

  onCloseCancel(answer: boolean) {
    this.thisDialogRef.close(answer);
  }

}
