import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material';
import { ConfirmActionComponent } from '@app/nabia/confirm-action/confirm-action.component';
import { IDataFilter } from '../origins-dto/IDataFilter';
import { Subject } from 'rxjs/Subject'
import { debounceTime } from 'rxjs/operators';

@Component({
    selector: 'app-origins-filter-generic',
    templateUrl: './origins-filter-generic.component.html',
    styleUrls: ['./origins-filter-generic.component.scss']
})

export class OriginsFilterGenericComponent implements OnInit {

    @Output() searchTextChange = new EventEmitter();

    public searchTextSource: string = "";
    public searchTextMedium: string = "";
    public searchTextCampaign: string = "";
    public searchTextTitle: string = "";

    public debouncer: Subject<string> = new Subject<string>();

    constructor(public dialog: MatDialog) {
    }

    ngOnInit() {
        try {
            this.debouncer.pipe(debounceTime(500)).subscribe((value) => this.onSearch());
        } catch (err) {
            console.error(err);
        }
    }

    onChangeText() {
        this.debouncer.next();
    }

    onSearch() {
        const dataFilter: IDataFilter = {
            utm_source: this.searchTextSource,
            utm_medium: this.searchTextMedium,
            utm_campaign: this.searchTextCampaign,
            title: this.searchTextTitle
        };

        this.searchTextChange.emit(dataFilter);
    }

    clearSearchText() {
        this.searchTextSource = "";
        this.searchTextMedium = "";
        this.searchTextCampaign = "";
        this.searchTextTitle = "";
        this.onSearch();
    }

    openConfirmDialog() {
        const message = 'Tem a certeza que pretende limpar a pesquisa?';
        const dialogRef = this.dialog.open(ConfirmActionComponent, {
          data: message,
        });
        dialogRef.afterClosed().subscribe(result => {
          if (result) {
              this.clearSearchText();
          }
        });
      }

}
