import { Component, OnInit, Injector, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
declare var $: any;
import { MenuService } from '../../core/menu/menu.service';
import { SettingsService } from '../../core/settings/settings.service';
import { AlertService } from '../../nabia/system/services/alert.service';
import { TranslateService } from '@ngx-translate/core';
import { SessionService } from '../../nabia/system/services/session.service';
import { Endpoints } from '../../nabia/system/configurations';

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit, OnDestroy {

    menuItems: Array<any> = [];
    router: Router;
    sbclickEvent = 'click.sidebar-toggle';
    $doc: any = null;
    link: any = '';

    alerts: string;

    constructor(
        public menu: MenuService,
        public settings: SettingsService,
        public injector: Injector,
        public session: SessionService,
        public translate: TranslateService,
        public alertService: AlertService) {

        this.alerts = '';
    }

    ngOnInit() {

        this.router = this.injector.get(Router);
        this.menu.getMenu().map(item => {
            if (item.permission) {
                if (this.session.hasPermission(item.permission.action, item.permission.path)) {
                    this.menuItems.push(item);
                }
            } else {
                this.menuItems.push(item);
            }
        });


        this.session.getUserData().subscribe((x) => {
          this.link = Endpoints.AUTH.RESET(this.session.getUserId());
        })


        this.router.events.subscribe(() => {
            this.removeFloatingNav();
            window.scrollTo(0, 0);
            this.settings.layout.asideToggled = false;
        });
        this.anyClickClose();
    }

    getAlertMessage() {
        const nAlerts = + this.alertService.getNumberOfAlerts();

        if (nAlerts === 0) {
            this.translate.get('nabia.general.alerts.no-alerts').subscribe((res: string) => {
                this.alerts = res;
            });
        } else {
            this.translate.get('nabia.general.alerts.you-have').subscribe((yh: string) => {
                this.translate.get('nabia.general.alerts.alerts').subscribe((al: string) => {
                    const reply = yh + nAlerts + ' ' + al;
                    this.alerts = reply;
                });
            });
        }
    }

    anyClickClose() {
        this.$doc = $(document).on(this.sbclickEvent, (e) => {
            if (!$(e.target).parents('.aside-container').length) {
                this.settings.layout.asideToggled = false;
            }
        });
    }

    ngOnDestroy() {
        if (this.$doc) {
            this.$doc.off(this.sbclickEvent);
        }
    }

    toggleSubmenuClick(event) {
        if (!this.isSidebarCollapsed() && !this.isSidebarCollapsedText() && !this.isEnabledHover()) {
            event.preventDefault();

            const target = $(event.target || event.srcElement || event.currentTarget);
            let ul, anchor = target;

            if (!target.is('a')) {
                anchor = target.parent('a').first();
            }
            ul = anchor.next();

            const parentNav = ul.parents('.sidebar-subnav');
            $('.sidebar-subnav').each((idx, el) => {
                const $el = $(el);
                if (!$el.is(parentNav) && !$el.is(ul)) {
                    this.closeMenu($el);
                }
            });

            if (!ul.length) {
                return;
            }
            ul.find('.sidebar-subnav').each((idx, el) => {
                this.closeMenu($(el));
            });

            if (parseInt(ul.height(), 0)) {
                this.closeMenu(ul);
            }
            else {
                ul.on('transitionend', () => {
                    ul.height('auto').off('transitionend');
                }).height(ul[0].scrollHeight);
                ul.addClass('opening');
            }
        }
    }

    closeMenu(elem) {
        elem.height(elem[0].scrollHeight);
        elem.height(0);
        elem.removeClass('opening');
    }

    toggleSubmenuHover(event) {
        const self = this;
        if (this.isSidebarCollapsed() || this.isSidebarCollapsedText() || this.isEnabledHover()) {
            event.preventDefault();

            this.removeFloatingNav();

            const target = $(event.target || event.srcElement || event.currentTarget);
            let ul, anchor = target;

            if (!target.is('a')) {
                anchor = target.parent('a');
            }
            ul = anchor.next();

            if (!ul.length) {
                return;
            }

            const $aside = $('.aside-container');
            const $asideInner = $aside.children('.aside-inner'); // for top offset calculation
            const $sidebar = $asideInner.children('.sidebar');
            const mar = parseInt($asideInner.css('padding-top'), 0) + parseInt($aside.css('padding-top'), 0);
            const itemTop = ((anchor.parent().position().top) + mar) - $sidebar.scrollTop();

            const floatingNav = ul.clone().appendTo($aside);
            const vwHeight = $(window).height();

            floatingNav
                .removeClass('opening')
                .addClass('nav-floating')
                .css({
                    position: this.settings.layout.isFixed ? 'fixed' : 'absolute',
                    top: itemTop,
                    bottom: (floatingNav.outerHeight(true) + itemTop > vwHeight) ? 0 : 'auto'
                });

            floatingNav
                .on('mouseleave', () => { floatingNav.remove(); })
                .find('a').on('click', function (e) {
                    e.preventDefault();
                    const routeTo = $(this).attr('route');
                    if (routeTo) { self.router.navigate([routeTo]); }
                });

            this.listenForExternalClicks();
        }
    }

    listenForExternalClicks() {
        const $doc = $(document).on('click.sidebar', (e) => {
            if (!$(e.target).parents('.aside-container').length) {
                this.removeFloatingNav();
                $doc.off('click.sidebar');
            }
        });
    }

    removeFloatingNav() {
        $('.nav-floating').remove();
    }

    isSidebarCollapsed() {
        return this.settings.layout.isCollapsed;
    }
    isSidebarCollapsedText() {
        return this.settings.layout.isCollapsedText;
    }
    isEnabledHover() {
        return this.settings.layout.asideHover;
    }

    logout() {
        this.session.logout();
        this.router.navigateByUrl('/login');
    }
}
