import { Pipe, PipeTransform } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { DataService } from '../../nabia/system/services/data.service';

@Pipe({
  name: 'secure'
})
export class SecurePipe implements PipeTransform {

  constructor(private dataService: DataService) { }

  transform(url: string) {

    return new Observable<string>((observer) => {
      // This is a tiny blank image
      observer.next('https://upload.wikimedia.org/wikipedia/commons/c/ce/Image_of_none.svg');

      // The next and error callbacks from the observer
      const { next, error } = observer;
      
      if (url) {
        this.dataService.getPhoto(url).subscribe((response: any) => {
          const reader = new FileReader();
          reader.readAsDataURL(response);
          reader.onloadend = function () {
            observer.next(reader.result as string);
          };
        });
      }

      return { unsubscribe() { } };
    });
  }
}