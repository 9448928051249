import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ContactService } from '../../../system/services/contact.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { StaffService } from '../../../system/services/staff.service';

@Component({
  selector: 'app-add-staff',
  templateUrl: './add-staff.component.html',
  styleUrls: ['./add-staff.component.scss']
})
export class AddStaffComponent implements OnInit {

  staffList: any;
  addStaffForm: FormGroup;
  type: boolean;

  disableButton = false;

  constructor(
    private staffService: StaffService,
    private formbuilder: FormBuilder,
    public thisDialogRef: MatDialogRef<AddStaffComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {

    this.type = (data && data.type === true) ? true : false;
  }


  ngOnInit() {

    this.addStaffForm = this.formbuilder.group({
      id: [null, Validators.required]
    });

    this.staffService.getAllStaff().subscribe(reply => {
      this.staffList = reply;
    });
  }


  onSubmit(form) {

    if (this.addStaffForm.valid) { // Valid form
      const staffId = form.id.value;
      this.disableButton = true;

      if (this.type) { // is Follower
        this.staffService.addFollower(this.data.id, staffId).subscribe(reply => {
          this.thisDialogRef.close('Success');
        });
      } else { // is Commercial
        this.staffService.addCommercial(this.data.id, staffId).subscribe(reply => {
          this.thisDialogRef.close('Success');
        });
      }
    }
  }


  onCloseCancel() {
    this.thisDialogRef.close('Cancel');
  }


}
