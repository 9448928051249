import { Component, OnInit, Inject, EventEmitter } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UploaderOptions, UploadInput, UploadFile, humanizeBytes, UploadOutput } from 'ngx-uploader';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { SessionService } from '../../../system/services/session.service';
import { DocumentService } from '../../../system/services/document.service';
import { Endpoints } from '../../../system/configurations';
import { ContactService } from '../../../system/services/contact.service';

@Component({
  selector: 'app-add-file',
  templateUrl: './add-file.component.html',
  styleUrls: ['./add-file.component.scss']
})
export class AddFileComponent implements OnInit {

  options: UploaderOptions;
  formData: FormData;
  uploadInput: EventEmitter<UploadInput>;
  humanizeBytes: Function;
  dragOver: boolean;
  file: UploadFile;
  files = [];

  newDocumentForm: FormGroup;
  docTypeList: any;
  selectedFile = null;
  isValid = true;
  submit = false;
  uploading = false;

  constructor(
    private formbuilder: FormBuilder,
    private documentService: DocumentService,
    private session: SessionService,
    public thisDialogRef: MatDialogRef<AddFileComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {

    this.options = { concurrency: 1, maxUploads: 3 };
    this.uploadInput = new EventEmitter<UploadInput>(); // input events, we use this to emit data to ngx-uploader
    this.humanizeBytes = humanizeBytes;
  }

  ngOnInit() {
    this.getDocumentTypes();

    this.newDocumentForm = this.formbuilder.group({
      'name': [null, Validators.required],
      'type': [null, Validators.required],
      'file': [null, this.file]
    });
  }

  getDocumentTypes() {
    this.documentService.getDocumentTypes().subscribe(rep => {
      this.docTypeList = rep;
    });
  }

  clearFile(): void {
    this.uploadInput.emit({ type: 'remove', id: this.file.id });
    this.file = undefined;
    this.submit = false;
  }


  onUploadOutput(output: UploadOutput): void {

    if (output.type === 'addedToQueue' && typeof output.file !== 'undefined') { // add file to array when added
      this.file = output.file;
      this.files.push(this.file);

    } else if (output.type === 'dragOver') {
      this.dragOver = true;

    } else if (output.type === 'dragOut') {
      this.dragOver = false;

    } else if (output.type === 'uploading' && typeof output.file !== 'undefined') {
      // update current data in files array for uploading file
      this.file = output.file;
      this.uploading = true;

    } else if (output.type === 'removed') {
      // remove current data in files array for uploading file
      this.file = undefined;
      this.uploading = false;

    } else if (output.type === 'drop') {
      this.dragOver = false;


    } else if (output.type === 'allAddedToQueue') { // when all files added in queue
      const event: UploadInput = {
        type: 'uploadAll',
        url: Endpoints.FILES.UPLOAD,
        method: 'POST',
        headers: { Authorization: 'Bearer ' + this.session.getTokenFromSession() },
        file: this.file
      };

      this.uploadInput.emit(event);

    } else if (output.type === 'done') {
      this.uploading = false;

      if (this.file.response.result === 'success') {
        this.submit = true;
      }

    }

  }

  // onUploadOutput(output: UploadOutput): void {
  //   if (output.type === 'addedToQueue' && typeof output.file !== 'undefined') { // add file to array when added
  //     this.file = output.file;
  //   } else if (output.type === 'dragOver') {
  //     this.dragOver = true;
  //     // console.log('in');
  //   } else if (output.type === 'dragOut') {
  //     // console.log('out');
  //     this.dragOver = false;
  //   } else if (output.type === 'drop') {
  //     // console.log('out');
  //     this.dragOver = false;
  //   }
  // }

  // clearFile(): void {
  //   this.uploadInput.emit({ type: 'remove', id: this.file.id });
  //   this.file = null;
  // }



  onSubmit($event, form) {
    if (this.newDocumentForm.valid) {
      this.isValid = true;
      const names = this.file.name.split('.');
      const ext = names[names.length - 1];

      if (this.file.response.result === 'success') {
        const data = {
          'fileName': this.file.response.temporaryFileName,
          'path': this.file.response.temporaryFile,
          'name': form.name + '.' + ext,
          'idType': form.type.value
        };

        this.documentService.addFileToPayment(data, this.data.id, this.data.payment.id).subscribe(reply => {
        });

      }

      this.thisDialogRef.close('submitted');
    } else {
      this.isValid = false;
    }
  }



  onCloseCancel() {
    this.thisDialogRef.close('Cancel');
  }


  selectionChanged($event) {
  }

}
