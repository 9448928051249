import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { OpportunitiesService } from '../../system/services/opportunities.service';
import {Endpoints, paymentsTypes} from '../../system/configurations';
import { config } from 'rxjs';
import {ContactService} from "../../system/services/contact.service";
import {CurrencyPipe} from "@angular/common";
import { GetCurrencyPipe } from '../../../layout/pipe/get-currency.pipe';

@Component({
  selector: 'app-add-payment',
  templateUrl: './add-payment.component.html',
  styleUrls: ['./add-payment.component.scss']
})
export class AddPaymentComponent implements OnInit {
  // newPaymentForm: FormGroup;
  isValid = true;

  paymentTypeList: any;
  defaultPaymentStatus: any;
  unpaid: any = 0;
  idConfigurationPayment: any;
  idPaymentState: any;
  oldAmount = 0;
  amount = 0;
  valid: any;
  paidPrice = 0;

  disableButton = false;

  constructor(
    private opportunityService: OpportunitiesService,
    private contact: ContactService,
    private formBuilder: FormBuilder,
    private currencyPipe: CurrencyPipe,
    private getCurrency: GetCurrencyPipe,
    public thisDialogRef: MatDialogRef<AddPaymentComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {

    this.valid = { msg: '' };
    this.unpaid = Math.round((data.opp.totalPrice - data.opp.paidPrice - data.opp.credits) * 100) / 100;
    this.getPaymentStatusList();
    this.getPaymentTypeList();
  }

  ngOnInit() {
    this.paidPrice = this.data.opp.paidPrice;
    this.oldAmount = this.data.value;
    this.amount = this.oldAmount;
  }

  calculateNetPrice(){
    let netPrice = this.data.opp.netPrice;
    if (this.data.edit === true) {
      const oldTax = this.oldAmount * this.data.type.tax;
      const newTax = this.amount * this.idPaymentState.tax;
      netPrice = this.data.opp.netPrice + oldTax - newTax;
    }
    return netPrice;

  }

  setDefaultValues(){
    this.paymentTypeList.forEach(elem => {
      if (this.data.type.id === elem.id){
        this.idPaymentState = elem;
      }
    });
  }

  // constructFormBuilder(){
  //   if(this.data.edit === false){
  //     this.newPaymentForm = this.formBuilder.group({
  //       'idConfigurationPayment': [null, null], // TODO: testar :: (id) => id !== undefined],
  //       'idPaymentState': [null, Validators.required],
  //       'amount': [0, Validators.required]
  //     });
  //   }else{
  //     this.newPaymentForm = this.formBuilder.group({
  //       'idConfigurationPayment': [this.data.type.idPaymentType, null], // TODO: testar :: (id) => id !== undefined],
  //       'idPaymentState': [this.data.type, Validators.required],
  //       'amount': [this.data.value, Validators.required]
  //     });
  //   }
  // }

  /**
   * Returns the default Payment status
   */
  getPaymentStatusList() {
    this.opportunityService.getPaymentStatusList().subscribe((pStatusList: any) => {
      this.defaultPaymentStatus = pStatusList.find(elem => elem.order === 0);
      console.log('this.defaultPaymentStatus', this.defaultPaymentStatus);
    });
  }

  /**
   * Returns the list of Payment types
   */
  getPaymentTypeList() {
    this.opportunityService.getPaymentTypesList().subscribe((reply: any) => {

      this.paymentTypeList = reply.sort((a,b) => a.name.localeCompare(b.name));
      this.setDefaultValues();
    });
  }


  /**
   * Validates every scenario
   */
  validateAmount() {
    const usrTotPts = this.data.opp.opp_lead.lead_user.campaignPoints;
    const usrCapPts = this.data.opp.opp_lead.lead_user.campaignPoints;
    const selPType = this.idPaymentState;
    const amount = this.amount;


    // Tem que ser superior a 0.
    this.valid.msg = (amount <= 0) ? 'O valor tem que ser superior a zero.' : '';

    if (selPType && selPType.isPoints === 0) { // With Currency

      // Tem que ser igual ou inferior ao permitido pelo método de pagamento
      this.valid.msg = (this.valid.msg === '' && selPType.maxValue && amount > selPType.maxValue) ? `O valor é demasiado alto para este tipo de pagamento. máx: ${this.getCurrency.symbol}` + selPType.maxValue : this.valid.msg;
      // Tem que ser no max o valor em falta
      this.valid.msg = (this.valid.msg === '' && amount > this.unpaid && this.data.edit === false) ? 'O valor inserido é maior do que o valor em falta.' : this.valid.msg;

    } else { // With Points

      // With Campaign Points - free to use
      if (selPType && selPType.referralPointsOnly === 1) {

        // Tem que ser no max o numero de pontos do utilizador e no máx o valor max permitido pelo metodo de pagamento
        this.valid.msg = (this.valid.msg === '' && amount > selPType.maxValue) ? 'O valor é demasiado alto para este tipo de pagamento. máx: ' + selPType.maxValue + ' Pontos' : this.valid.msg;
        this.valid.msg = (this.valid.msg === '' && amount > usrCapPts) ? 'Não existem pontos suficientes. (' + usrCapPts + ' pontos disponíveis).' : this.valid.msg;

      } else { // With Total Points - (selPType && selPType.referralPointsOnly === 0)

        // Tem que ser multiplos de 500.
        this.valid.msg = (amount % 500 !== 0) ? 'Os pontos têm que ser usados em múltiplos de 500. (Tem ' + usrTotPts + ' pontos disponíveis).' : this.valid.msg;
        // Tem que ser no max o numero de pontos do utilizador e no máx o valor max permitido pelo metodo de pagamento
        this.valid.msg = (this.valid.msg === '' && amount > selPType.maxValue) ? 'O valor é demasiado alto para este tipo de pagamento. máx: ' + selPType.maxValue + ' Pontos' : this.valid.msg;
        this.valid.msg = (this.valid.msg === '' && amount > usrTotPts) ? 'Não existem pontos suficientes. (' + usrTotPts + ' pontos disponíveis).' : this.valid.msg;
      }
    }
  }


  async onSubmit($event) {
    $event.preventDefault();
    if(this.idPaymentState && this.idPaymentState.value == paymentsTypes.credito){
      const user: any = await this.contact.getContact(this.data.opp.opp_lead.idUser).toPromise();
      if (this.amount > user.credits){
        this.valid.msg = `Créditos insuficientes (${this.currencyPipe.transform(user.credits, 'EUR', 'symbol', '.2')})`
        return false;
      }
    }

    this.validateAmount();
    const newPaidPrice = this.paidPrice - this.oldAmount + this.amount;
    this.paidPrice = (this.data.inbox === 1) ? newPaidPrice : this.paidPrice;
    if ((this.idPaymentState !== '' && this.amount > 0) && this.valid.msg === '') {
      this.isValid = true;
      this.disableButton = true;
      const netprice = this.calculateNetPrice();
      let body = {
        idConfigurationPayment: this.idPaymentState.value,
        amount: this.amount,
        idPaymentState: this.defaultPaymentStatus.value,
        netPrice: netprice,
        idOpportunity: this.data.opp.id,
        paidPrice: this.paidPrice
      };



      if(this.data.edit === false) {
        const pType = this.paymentTypeList.find(p => p.id === body.idConfigurationPayment);
        const url = (pType.isPoints === 0) ? Endpoints.OPPORTUNITIES.OPPORTUNITY_PAYMENTS(this.data.opp.id) : Endpoints.OPPORTUNITIES.OPPORTUNITY_PAYMENTS_POINTS(this.data.id);
        this.opportunityService.createPayment(url, body).subscribe(reply => {
          this.thisDialogRef.close('success'); // TODO: descomentar no Fim
        });
      }else{
        const url = Endpoints.OPPORTUNITIES.OPPORTUNITY_PAYMENT(this.data.opp.id ,this.data.idPayment)
        this.opportunityService.editPayment(url, body).subscribe(reply => {
          this.thisDialogRef.close('success'); // TODO: descomentar no Fim
        });
      }

    } else {
      this.isValid = false;
    }
  }


  onCloseCancel() {
    this.thisDialogRef.close('Cancel');
  }

}
