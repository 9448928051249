import { Component, OnInit } from '@angular/core';
import { SettingsService } from '../../../core/settings/settings.service';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { CustomValidators } from 'ng2-validation';
import { HttpClient } from '@angular/common/http';
import * as configs from '../../../nabia/system/configurations';
import { ActivatedRoute, Params, Routes, Router } from '@angular/router';
import { TranslatableService } from '../../../nabia/system/services/translatable.service';
import { catchError } from 'rxjs/operators';
import { SessionService } from '../../../nabia/system/services/session.service';

@Component({
    selector: 'app-register',
    templateUrl: './register.component.html',
    styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

    URI_TOKEN_KEY = 'user_id';
    userID: string;

    valForm: FormGroup;
    passwordForm: FormGroup;

    constructor(public settings: SettingsService,
                fb: FormBuilder,
                public http: HttpClient,
                public routes: Router,
                private sessionService: SessionService,
                public translationService: TranslatableService,
                private activatedRoute: ActivatedRoute) {

        const password = new FormControl('', Validators.compose([Validators.required, Validators.pattern('^[a-zA-Z0-9]{6,10}$')]));
        const certainPassword = new FormControl('', [Validators.required, CustomValidators.equalTo(password)]);

        this.passwordForm = fb.group({
            'password': password,
            'confirmPassword': certainPassword
        });

        this.valForm = fb.group({
            'fstName': null,
            'lstName': null,
            'email': null,
            // 'phone': null,
            'passwordGroup': this.passwordForm
        });

        // Read the userID from the URL
        this.activatedRoute.queryParams.subscribe((params: Params) => {
            this.userID = params[this.URI_TOKEN_KEY];

            // Fetch user to display information
            const url = configs.Endpoints.AUTH.USER2(this.userID);
            this.http.get(url).subscribe((user: any) => {
                if (user) {
                    // set the user fields in the form
                    const email = user.staff_emails[0].email;
                    const fstName = user.staff_names[0].firstName;
                    const lstName = user.staff_names[0].lastName;
                    // const phone = (user.staff_phones.length > 0 && user.staff_phones[0].hasOwnProperty('phone')) ?
                    // user.staff_phones[0].phone : '';

                    this.valForm.controls.email.setValue(email);
                    this.valForm.controls.fstName.setValue(fstName);
                    this.valForm.controls.lstName.setValue(lstName);
                    // this.valForm.controls.phone.setValue(phone);
                }
            });
        });
    }


    ngOnInit() {

    }


    submitForm($ev, value: any) {

        $ev.preventDefault();
        for (const c in this.valForm.controls) {
            if (this.valForm.controls.hasOwnProperty(c)) {
                this.valForm.controls[c].markAsTouched();
            }
        }

        for (const c in this.passwordForm.controls) {
            if (this.valForm.controls.hasOwnProperty(c)) {
                this.passwordForm.controls[c].markAsTouched();
            }
        }

        if (this.valForm.valid) {

            const url = configs.Endpoints.AUTH.REGISTER(this.userID);
            const options = this.sessionService.getOptions();
            const staff = {
                password: this.valForm.value.passwordGroup.password,
                passwordConfirmation: this.valForm.value.passwordGroup.confirmPassword,
                // phone: value.phone, // TODO: adicionar o email tb
            };

            this.http.put(url, staff, options).subscribe((result: any) => {
                // TODO: AUTH SETTINGS >>>>> Termina aqui <<<<<<
                if(result.status === 'Ok'){
                    this.routes.navigate(['/login']);
                }
            });

            // TODO: redirect to login maybe
        }
    }


}
