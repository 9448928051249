import {Component, EventEmitter, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material';
import {ProductService} from '../../system/services/product.service';
import {SnackbarService} from "../../system/services/snackbar.service";
import { GetCurrencyPipe } from '../../../layout/pipe/get-currency.pipe';

@Component({
  selector: 'app-edit-service',
  templateUrl: './edit-service.component.html',
  styleUrls: ['./edit-service.component.scss']
})
export class EditServiceComponent implements OnInit {

  discountType = [{ label: '%', value: 0 }, { label: '', value: 1 }];
  product: any;
  discount = 0;
  discountWaring = false;
  isDiscountPercent = { label: '', value: 1 };
  amount = 0;
  amountWarning = false;
  amountWarning2 = false;
  oppId: any;
  prodId: any;
  formBefore

  constructor(
    private productService: ProductService,
    public dialog: MatDialog,
    public thisDialogRef: MatDialogRef<EditServiceComponent>,
    public snackbarService: SnackbarService,
    private getCurrency: GetCurrencyPipe,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      this.loadCurrencySymbol();
    this.product = data.product;
    this.initializeValues();
  }

  ngOnInit() {
  }
  loadCurrencySymbol() {
    this.discountType = [{ label: '%', value: 0 }, { label: this.getCurrency.symbol, value: 1 }];
    this.isDiscountPercent = { label: this.getCurrency.symbol, value: 1 };
  }
  initializeValues() {
    this.isDiscountPercent = (this.product.isDiscountPercent === 1) ? this.discountType[0] : this.discountType[1];
    this.discount = this.product.discount;
    this.amount = this.product.boughtSessions;
    this.prodId = this.product.id;
    this.oppId = this.product.idOwnerEntity;
    this.formBefore = this.getValuesForm();
  }

  validateValues() {
    let flag = true;
    this.discountWaring = false;
    this.amountWarning = false;
    this.amountWarning2 = false;
    if (typeof (this.discount) !== 'number') {
      this.discountWaring = true;
      flag = false;
    }
    if (this.discount < 0) {
      this.discountWaring = true;
      flag = false;
    }
    if (typeof (this.amount) !== 'number') {
      this.amountWarning = true;
      flag = false;
    }
    if (this.amount < 1) {
      this.amountWarning = true;
      flag = false;
    }
    const events = this.product.finishedSessions + this.product.scheduledSessions + this.product.deductedSessions;

    if (this.amount < events) {
      this.amountWarning2 = true;
      flag = false;
    }

    if (flag) {
      this.updateValues();
    }
  }

  getValuesForm() {
    return {
      discount: this.discount,
      boughtSessions: this.amount,
      isDiscountPercent: (this.isDiscountPercent.value === 0) ? true : false
    };
  }
  updateValues() {
    const values  = this.getValuesForm();
    const timeout = setTimeout(() => this._update(values), this.snackbarService.timeoutRevert);

    this.thisDialogRef.close(values);
    this.snackbarService.showRevert({
      text: 'nabia.snackbar.opportunity.service',
      onRevert: async () => {
        clearTimeout(timeout);
      }
    });

  }
  _update(values){
    this.productService.updateEntityService(this.oppId, this.prodId, values).subscribe(reply => {
    }, err => {
      if (err.status === 400) {
        this.amountWarning2 = true;
      }
    });
  }
  onCloseCancel() {
    this.thisDialogRef.close(false);
  }

}
