import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { OpportunitiesService } from '../../../system/services/opportunities.service';
import * as configs from '../../../system/configurations'; 
import { EtapaService } from '../../../system/services/etapa.service';

@Component({
  selector: 'app-perdido',
  templateUrl: './perdido.component.html',
  styleUrls: ['./perdido.component.scss']
})
export class PerdidoComponent implements OnInit {

  lostForm: FormGroup;
  lostReasonList: any;
  // idOpportunityType: string;

  constructor(
    private opportunityService: OpportunitiesService,
    private etapaService: EtapaService,
    private formBuilder: FormBuilder,
    public thisDialogRef: MatDialogRef<PerdidoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {


  }

  ngOnInit() {

    this.lostForm = this.formBuilder.group({
      'motive': [null, Validators.required],
      'comment': [],
    });

    this.getLostReasonList();
  }



  onSubmit($event, form) {
    $event.preventDefault();

    if (form && this.lostForm.valid) { // && this.idOpportunityType) {

      const body = {
        // idOpportunityType: this.idOpportunityType,
        idOpportunityLostReason: form.motive.value,
        comment: form.comment,
        idEtapa: this.etapaService.getLostEtapa().id,
        isWon: 0,
      };

      this.opportunityService.updateOpportunity(this.data.id, body).subscribe(reply => {
        // console.log('Update reply :: ', reply);    // FIXME: Remove this !!!  <<<<<<<<<<<<<<<<<<<<<<<<<<<<
        this.thisDialogRef.close('Success');
      });

    }
  }



  getLostReasonList() {
    this.opportunityService.getLostReasonList().subscribe(reply => {
      this.lostReasonList = reply;
    });
  }

  onCloseCancel() {
    this.thisDialogRef.close('Cancel');
  }

}
