import { Injectable } from '@angular/core';
import { ScriptStore } from './script.store';

declare var document: any;

// based on https://stackoverflow.com/questions/34489916/how-to-load-external-scripts-dynamically-in-angular
@Injectable()
export class ScriptService {

    private scripts: any = {};

    constructor() {
        ScriptStore.forEach((script: any) => {
            this.scripts[script.name] = {
                loaded: false,
                src: script.src
            };
        });
    }

    load(...scripts: string[]) {
        const promises: any[] = [];
        scripts.forEach((script) => promises.push(this.loadScript(script)));
        return Promise.all(promises);
    }

    loadScript(name: string) {
        return new Promise<any>((resolve, reject) => {
            // resolve if already loaded
            if (this.scripts[name].loaded) {
                resolve({ script: name, loaded: true, status: 'Already Loaded' });
            } else {
                // load script
                const script = document.createElement('script');
                script.type = 'text/javascript';
                script.src = this.scripts[name].src;
                if (script.readyState) {  // IE
                    script.onreadystatechange = () => {
                        if (script.readyState === 'loaded' || script.readyState === 'complete') {
                            script.onreadystatechange = null;
                            this.scripts[name].loaded = true;
                            resolve({ script: name, loaded: true, status: 'Loaded' });
                        }
                    };
                } else {  // Others
                    script.onload = () => {
                        this.scripts[name].loaded = true;
                        resolve({ script: name, loaded: true, status: 'Loaded' });
                    };
                }
                script.onerror = (error: any) => resolve({ script: name, loaded: false, status: 'Loaded' });
                document.getElementsByTagName('head')[0].appendChild(script);
            }
        });
    }

    unLoadScript(name: string) {
        const script = this.getScriptByName(name);
        if (script) {
            const head = document.getElementsByTagName('head')[0];
            const scripts = Array.prototype.slice.call(head.getElementsByTagName('script'));
            scripts.forEach(element => {
                const src = element.getAttribute('src');
                if (script.src === src) {
                    head.removeChild(element);
                }
            });
        } else {
            console.log('script does not exist');
        }

    }

    getScriptByName(name: string) {
        return ScriptStore.find((script) => script.name === name);
    }

}
