import { getDate } from './../date/date';
import { Injectable } from '@angular/core';
import { DataService } from './data.service';
import { Endpoints, Gender, genTypes } from '../configurations';
import { SessionService } from './session.service';
import { HttpClient } from '@angular/common/http';
import { Observable, forkJoin, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { AngularFirestore } from 'angularfire2/firestore';


@Injectable()
export class OpportunitiesService {

    id: number;

    constructor(
        private firestore: AngularFirestore,
        private http: HttpClient,
        private dataService: DataService,
        private sessionService: SessionService) {
        this.id = 0;
    }


    // ***********************************************************
    // **  OPPORTUNITIES                                        **
    // ***********************************************************

    getOpportunityHistory(oId, params?) {
        const url = Endpoints.OPPORTUNITIES.HISTORY(oId);
        const opt = this.sessionService.getOptions();
        opt['params'] = params
        const reply$ = this.http.get(url, opt);

        return reply$;
    }

    getOpportunitiesType() {
        const url = Endpoints.CONFIGURATIONS.GENERIC_TYPES.OPPORTUNITIES_TYPES;
        const opt = this.sessionService.getOptions();

        const reply$ = this.http.get(url, opt);

        return reply$;
    }


    convertPaymentToCredit(id, idUser) {
        const url = Endpoints.OPPORTUNITIES.OPPORTUNITY_CONVERT_PAYMENT(id);
        const opt = this.sessionService.getOptions();
        const body = { idUser }
        const reply$ = this.http.post(url, body, opt);

        return reply$;
    }

    getInformationAboutServices(id) {
        const url = Endpoints.OPPORTUNITIES.OPPORTUNITY_INFORMATIONS_SERVICES(id);
        const opt = this.sessionService.getOptions();

        const reply$ = this.http.get(url, opt);

        return reply$;
    }

/**
 * Returns the list of opportunities
 */
    getOpportunities(quantity: number, offset: Date) {
        const oppList$ = this.dataService.getOpportunities(quantity, offset);

        return oppList$;
    }
    exchangeCredit(idOpportunity, body){
        const url = Endpoints.OPPORTUNITIES.EXCHANGE_CREDIT(idOpportunity);
        const opt = this.sessionService.getOptions();

        const reply$ = this.http.post(url, body, opt);
        return reply$;
    }
    exchangeYield(idOpportunity, body){
        const url = Endpoints.OPPORTUNITIES.EXCHANGE_YIELD(idOpportunity);
        const opt = this.sessionService.getOptions();

        const reply$ = this.http.post(url, body, opt);
        return reply$;
    }
    exchangeService(idOpportunity, body){
        const url = Endpoints.OPPORTUNITIES.EXCHANGE_SERVICE(idOpportunity);
        const opt = this.sessionService.getOptions();

        const reply$ = this.http.post(url, body, opt);
        return reply$;
    }

    getSales(quantity: number, offset: Date) {
        const salesList$ = this.dataService.getSales(quantity, offset);

        return salesList$;
    }

    getOportunityServices(cId: string) {
        const url = Endpoints.OPPORTUNITIES.OPPORTUNITY_SERVICES(cId);
        const opt = this.sessionService.getOptions();

        const reply$ = this.http.get(url, opt);
        return reply$;
    }

    /**
     * Returns a list of opportunities of the provided user ID
     */
    getUserOpportunities(quantity, offset, id: string) {
        const reply$ = this.dataService.getUserOpportunities(quantity, offset, id);

        return reply$;
    }

    getAllUserOpportunities(cId: string) {
        const url = Endpoints.CLIENTS.USERS.USER_OPPORTUNITIES(cId);
        const opt = this.sessionService.getOptions();

        const reply$ = this.http.get(url, opt);
        return reply$;
    }

    getExportedOpps(filters: string) {
        const url = Endpoints.OPPORTUNITIES.OPPORTUNITIES_EXPORT(filters);
        const opt = this.sessionService.getOptions('text/csv');
        opt["responseType"] = 'blob' as 'json';
        const reply$ = this.http.get(url, opt);
        return reply$;
    }

    /**
     * Creates new Opportunity
     */
    createOpportunity(body) {
        const url = Endpoints.OPPORTUNITIES.OPPORTUNITIES;
        const opt = this.sessionService.getOptions();

        const reply$ = this.http.post(url, body, opt);

        return reply$;
    }


    updateOpportunity(id: string, body: any) {
        const url = Endpoints.OPPORTUNITIES.OPPORTUNITY(id);
        const opt = this.sessionService.getOptions();

        const data$ = this.http.put(url, body, opt);

        return data$;
    }

    getAll(params?) {
        const url = Endpoints.OPPORTUNITIES.OPPORTUNITIES_GET_ALL;
        const opt = this.sessionService.getOptions();
        const body = { idOrigin: "" };

        if (params["idOrigin"] && params["idOrigin"].length > 0) {
          body.idOrigin = params["idOrigin"];
          delete params["idOrigin"];
        }

        opt['params'] = params;
        const reply$ = this.http.post(url, body, opt);

        return reply$;
    }
    getSumPagination(params?) {
        const url = Endpoints.OPPORTUNITIES.SUM_PAGINATION;
        const opt = this.sessionService.getOptions();
        const body = { idOrigin: "" };

        if (params["idOrigin"] && params["idOrigin"].length > 0) {
          body.idOrigin = params["idOrigin"];
          delete params["idOrigin"];
        }

        opt['params'] = params;
        const reply$ = this.http.post(url, body, opt);

        return reply$;
    }

    deleteOpportunity(id: string) {
        const url = Endpoints.OPPORTUNITIES.OPPORTUNITY_DELETE(id);
        const opt = this.sessionService.getOptions();

        const data$ = this.http.delete(url, opt);

        return data$;
    }


    getLostReasonList() {
        const url = Endpoints.CONFIGURATIONS.GENERIC_TYPES.OPPORTUNITIES_LOST_REASON_TYPE;
        const opt = this.sessionService.getOptions();

        const lostReason$ = this.http.get(url, opt).pipe(map((list: any) => {
            const newList = [];

            list.forEach(elem => {
                const nElem = elem;
                nElem.label = elem.name;
                nElem.value = elem.id;

                newList.push(nElem);
            });

            return newList;
        }));

        return lostReason$;
    }


    /**
     * TODO: refactor all configuration methods to single method -> move to DataService
     * Returns a list of Opportunity status.
     */
    getOpportunityStatusList() {
        const oppTypes$ = this.dataService.getOpportunityStatusList().pipe(map((types: any) => {
            const reply = [];

            types.forEach(element => {
                const newElem = JSON.parse(JSON.stringify(element));
                newElem.label = element.name;
                newElem.value = element.id;

                reply.push(newElem);
            });

            return reply;
        }));

        return oppTypes$;
    }

    getOpportunityEtapasList() {
        const oppTypes$ = this.dataService.getOpportunityEtapasList().pipe(map((types: any) => {
            return types.items;
        }));

        return oppTypes$;
    }

    getOpportunityEtapasComboBox() {
        const oppTypes$ = this.dataService.getOpportunityEtapasList().pipe(map((types: any) => {
            const reply = [];

            types.items.forEach(element => {
                const newElem = JSON.parse(JSON.stringify(element));
                newElem.label = element.name;
                newElem.value = element.id;

                reply.push(newElem);
            });

            return reply;
        }));

        return oppTypes$;
    }

    /**
     * TODO: refactor all configuration methods to single method -> move to DataService
     * Returns a list of Opportunity types.
     */
    getOpportunityTypeList() {
        const oppTypes$ = this.dataService.getOpportunityTypeList().pipe(map((types: any) => {
            const reply = types;

            reply.forEach(element => {
                element.label = element.name;
                element.value = element.id;
            });

            return reply;
        }));

        return oppTypes$;
    }


    /**
     *  Get returns an opportunity
     */
    getOpportunity(id: string) {
        const configs$ = this.dataService.getOpportunity(id);

        return configs$;
    }

    /**
     * Deductions in real time
     */
    getRealTimeDeductions(id: string) {
        const firestoreReply$ = this.firestore.collection('/deductions', rep => rep
            .where('idOpportunity', '==', id)
            .orderBy('modifiedDate', 'desc')
            .startAt(getDate())
        );

        const url = Endpoints.OPPORTUNITIES.OPPORTUNITY_DEDUCTIONS_BATCH(id);
        const opt = this.sessionService.getOptions();
        const deductions$ = this.dataService.getRealTimeData(firestoreReply$, url);

        return deductions$;
    }

    /**
     * Deductions (not in realtime)
     */
    getDeductions(id: string) {
        const url = Endpoints.OPPORTUNITIES.OPPORTUNITY_DEDUCTIONS(id);
        const opt = this.sessionService.getOptions();
        const deductions$ = this.http.get(url, opt);

        return deductions$;
    }

    /**
     * Creates a new Deduction
     */
    createDeduction(oppId: string, body: any) {
        const url = Endpoints.OPPORTUNITIES.OPPORTUNITY_DEDUCTIONS(oppId);
        const opt = this.sessionService.getOptions();

        const reply$ = this.http.post(url, body, opt);

        return reply$;
    }


    /**
     * Returns a list of deductions
     */
    getDeductionTypeList() {
        const url = Endpoints.CONFIGURATIONS.GENERIC_TYPES.DEDUCTIONS;
        const opt = this.sessionService.getOptions();

        const deductionList$ = this.http.get(url, opt).pipe(map((list: any) => {
            const newList = [];

            list.forEach(elem => {
                const nElem = elem;
                nElem.label = elem.name;
                nElem.value = elem.id;

                newList.push(nElem);
            });

            return newList;
        }));

        return deductionList$;
    }


    /**
     * Returns a list of daily payments
     */
    getDailyPaymentsList(oId: string) {
        const url = Endpoints.OPPORTUNITIES.OPPORTUNITY_PAYMENTS_DIARY(oId);
        const opt = this.sessionService.getOptions();

        const deductionList$ = this.http.get(url, opt);

        return deductionList$;
    }


    /**
     * Returns a list of daily payments
     */
    getPaymentsList(oId: string) {
        const url = Endpoints.OPPORTUNITIES.OPPORTUNITY_PAYMENTS(oId);
        const opt = this.sessionService.getOptions();

        const deductionList$ = this.http.get(url, opt);

        return deductionList$;
    }

    /**
     * Returns the list of payment types
     */
    getPaymentTypesList() {
        const url = Endpoints.CONFIGURATIONS.PAYMENTS;
        const opt = this.sessionService.getOptions();

        const reply$ = this.http.get(url, opt).pipe(map((pStatusList: any) => {
            const tempList = pStatusList;
            tempList.forEach(elem => {
                elem.value = elem.id;
                elem.label = elem.name;
            });
            return tempList;
        }));

        return reply$;
    }
    getPaymentType(id) {
        const url = Endpoints.CONFIGURATIONS.PAYMENT(id);
        const opt = this.sessionService.getOptions();
        return this.http.get(url, opt);
    }

    /**
     * Returns the list of payment status
     */
    getPaymentStatusList() {
        const url = Endpoints.CONFIGURATIONS.GENERIC_TYPES.PAYMENT_STATUS;
        const opt = this.sessionService.getOptions();

        const reply$ = this.http.get(url, opt).pipe(map((pStatusList: any) => {
            const tempList = pStatusList;
            tempList.forEach(elem => {
                elem.value = elem.id;
                elem.label = elem.name;
            });
            return tempList;
        }));

        return reply$;
    }

    getPaymentAccountList() {
        const url = Endpoints.CONFIGURATIONS.GENERIC_TYPES.PAYMENT_ACCOUNTS;
        const opt = this.sessionService.getOptions();

        const reply$ = this.http.get(url, opt).pipe(map((reply: any) => {
            return reply;
        }));

        return reply$;
    }

    /**
     * Set payments inbox
     */
    setPaymentInbox(oId: string, pId: string) {
        const url = Endpoints.OPPORTUNITIES.OPPORTUNITY_PAYMENTS_INBOX(oId, pId);
        const opt = this.sessionService.getOptions();
        const reply$ = this.http.put(url, {}, opt);

        return reply$;
    }

    /**
     * Set payments received
     */
    setPaymentReceived(oId: string, pId: string, aid: string) {
        const url = Endpoints.OPPORTUNITIES.OPPORTUNITY_PAYMENTS_RECEIVED(oId, pId, aid);
        const opt = this.sessionService.getOptions();
        const reply$ = this.http.put(url, {}, opt);

        return reply$;
    }


    /**
     * Updates a payment
     */
    updatePayment(oId: string, pId: string, body: any) {
        const url = Endpoints.OPPORTUNITIES.OPPORTUNITY_PAYMENT(oId, pId);
        const opt = this.sessionService.getOptions();

        const reply$ = this.http.put(url, body, opt);

        return reply$;
    }


    /**
     * Updates a payment
     */
    deletePayment(oId: string, pId: string) {
        const url = Endpoints.OPPORTUNITIES.OPPORTUNITY_PAYMENT(oId, pId);
        const opt = this.sessionService.getOptions();

        const reply$ = this.http.delete(url, opt);

        return reply$;
    }


    /**
     * Creates a payment
     */
    createPayment(url: string, body: any) {
        const opt = this.sessionService.getOptions();
        const reply$ = this.http.post(url, body, opt);

        return reply$;
    }

    editPayment(url, body) {
        const opt = this.sessionService.getOptions();
        const reply$ = this.http.put(url, body, opt);

        return reply$;
    }


    /**
     * Returns the offer if available
     */
    getOffer(oId: string) {
        const url = Endpoints.OPPORTUNITIES.OPPORTUNITY_OFFERS(oId);
        const opt = this.sessionService.getOptions();

        const reply$ = this.http.get(url, opt);

        return reply$;
    }


    /**
     * Returns an Object containing a month worth of data
     */
    getOpportunitiesByMonth(quantity: number, offset: Date, filters: any) {

        const firestoreReply$ = this.firestore.collection('/opportunities', rep => rep
            .where('createdDate', '>=', filters.start)
            .where('createdDate', '<', filters.end)
            .orderBy('createdDate', 'desc')
            .startAt(offset)
            .limit(quantity)
        );

        const url = Endpoints.OPPORTUNITIES.OPPORTUNITIES_BATCH;
        const infos$ = this.dataService.getRealTimeData(firestoreReply$, url);

        return infos$;
    }



    /**
     * Returns the data structure as an object array.
     */
    getOpportunityFunil(offset: any, quantity: any): {} {
        const data = {};
        const columns = this.getColumns();

        columns.forEach(column => {
            const status = column['data'];
            data[status] = this.getOpportunitiesByStatus(status, offset, quantity);
        });

        return data;
    }

    getColumns() {
        const statusTypes$ = this.dataService.getOpportunityEtapasList().pipe(map((status: any) => {
            const list = {};
            status.items.forEach(type => {
                list[type.id] = type;
            });
            return list;
        }));
        return statusTypes$;
    }

    /**
     * Returns opportunities filtered by status
     */
    getOpportunitiesByStatus(quantity: number, offset: Date, filters: {}): Observable<any> {
        const oppList$ = this.dataService.getOpportunitiesByStatus(quantity, offset, filters);

        return oppList$;
    }



}
