import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { ContactService } from '../../system/services/contact.service';
import { DataService } from '../../system/services/data.service';

@Component({
  selector: 'app-aggregation-modal',
  templateUrl: './aggregation-modal.component.html',
  styleUrls: ['./aggregation-modal.component.scss']
})
export class AggregationModalComponent implements OnInit {
  aggregatingId = null;
  userToAggregate = null;
  userToAggregateUrl = null;
  errorText = null;
  loading = false;
  firstClick = false;
  aggregating = false;
  constructor(
    public contactService: ContactService,
    public dialog: MatDialog,
    public dataService: DataService,
    public thisDialogRef: MatDialogRef<AggregationModalComponent>, @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.aggregatingId = data;
  }

  ngOnInit() {
  }

  getContactInfo() {
    this.errorText = null;
    this.userToAggregate = null;
    const urlToAggregateParts = this.userToAggregateUrl.split('/');
    const idToAggregate = urlToAggregateParts[urlToAggregateParts.length - 1];

    if (idToAggregate.length !== 36) {
      this.errorText = 'Não foi possivel determinar um ID válido, por favor verifique o URL.';
    } else {
      if (idToAggregate === this.aggregatingId) {
        this.errorText = 'Não pode agregar o contacto com ele próprio.';
      } else {
        this.loading = true;
        this.contactService.getUser(idToAggregate).subscribe((user: any) => {
          this.loading = false;
          if (!user) {
            this.errorText = 'Não foi encontrado nenhum contacto.';
          } else {
            this.userToAggregate = {
              id: user.id,
              name: user.users_names ? (user.users_names[0] ? user.users_names[0].firstName + ' ' + user.users_names[0].lastName : '') : '',
              phone: user.users_phones ? (user.users_phones[0] ? user.users_phones[0].number : '') : '',
              email: user.users_emails ? (user.users_emails[0] ? user.users_emails[0].email : '') : '',
            };
          }
        });
      }

    }
  }

  aggregateUser() {
    this.aggregating = true;
    this.dataService.aggregateClient(this.aggregatingId, this.userToAggregate.id).subscribe((reply) => {
      this.thisDialogRef.close(true);
    });
  }

  onCloseCancel(refreshData?: boolean) {
    this.thisDialogRef.close(refreshData);
  }

}
