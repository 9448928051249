import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { window } from 'rxjs/operators';
import { ScriptService } from '../../../system/services/script.service';
import { OpportunitiesService } from '../../../system/services/opportunities.service';
import * as configs from '../../../system/configurations';
import { EtapaService } from '../../../system/services/etapa.service';

@Component({
  selector: 'app-ganho',
  templateUrl: './ganho.component.html',
  styleUrls: ['./ganho.component.scss']
})
export class GanhoComponent implements OnInit {

  showConfetti = true;

  constructor(
    private opportunityService: OpportunitiesService,
    private etapaService: EtapaService,
    private script: ScriptService,
    public thisDialogRef: MatDialogRef<GanhoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {

  }

  ngOnInit() {

    // Calls a service that injects JS files
    this.script.load('confetti').then(data => {
      this.onSubmit();
    }).catch(error => console.error(error));
  }


  onSubmit() {

    const body = {
      isWon: 1,
    };

    this.opportunityService.updateOpportunity(this.data.id, body).subscribe(reply => {

      // Para os casos em que a oportunidade foi desbloqueada, precisa de colocar novamente no estado "confirmado sede"
      if (this.data.paymentsSede) {
        this.opportunityService.updateOpportunity(this.data.id, { idEtapa: this.etapaService.getSedeEtapa().id }).subscribe(reply => {
        });
      }
      // console.log('Update reply :: ', reply);    // FIXME: Remove this !!!  <<<<<<<<<<<<<<<<<<<<<<<<<<<<
      // this.thisDialogRef.close('Success');
    });
  }

  onCloseCancel() {
    this.showConfetti = false;
    this.script.unLoadScript('confetti');
    this.thisDialogRef.close('Cancel');
  }
}
