import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  alerts: {}[];

  constructor() {
    this.alerts = [];
  }



  getAlerts() {
    return JSON.parse(JSON.stringify(this.alerts));
  }

  getNumberOfAlerts() {
    return this.alerts.length;
  }


}
