
const Home = {
    text: 'Home',
    link: '/home',
    icon: 'icon-home'
};

const Calendar = {
    text: 'Calendar',
    link: '/calendar',
    icon: 'icon-calendar',
    permission:{
        action: 'visualizar',
        path: 'calendario'
    }
};

const Leads = {
    text: 'Leads',
    link: '/leads',
    icon: 'icon-arrow-right-circle',
    permission:{
        action: 'visualizar',
        path: 'leads'
    }
};

const Opportunities = {
    text: 'Opportunities',
    link: '/opportunities',
    icon: 'icon-basket',
    permission:{
        action: 'visualizar',
        path: 'oportunidade'
    }
};

const People = {
    text: 'People',
    link: '/people',
    icon: 'icon-people'
};

const Clients = {
    text: 'Clients',
    link: '/clients',
    icon: 'icon-user'
};

const Sales = {
    text: 'Sales',
    link: '/sales',
    icon: 'icon-bag'
};

const Marketing = {
    text: 'Marketing',
    link: '/marketing',
    icon: 'icon-target',

};

const Metrics = {
    text: 'Metrics',
    link: '/reports',
    icon: 'icon-pie-chart',
    permission:{
        action: 'relatorio.visualizar',
        path: 'relatorio'
    }
};

const HumanResources = {
    text: 'Human Resources',
    link: '/metrics',
    icon: 'icon-notebook'
};

const Stock = {
    text: 'Stock',
    link: '/stock',
    icon: 'icon-social-dropbox'
};

const News = {
    text: 'News',
    link: '/news',
    icon: 'icon-globe'
};

const Configurations = {
    text: 'Configurations',
    link: '/configurations',
    icon: 'icon-settings',
    permission:{
        action: 'visualizar',
        path: 'configuracoes'
    }
};


const headingMain = {
    text: 'Main Navigation',
    heading: true
};

const headingComponents = {
    text: 'Components',
    heading: true
};

const headingMore = {
    text: 'More',
    heading: true
};

export const menu = [
    // headingMain,
    // headingMore,
    // headingComponents,
    Home,
    Calendar,
    Leads,
    People,
    Opportunities,
    Clients,
    // Sales,
    Marketing,
    Metrics,
    HumanResources,
    // Stock,
    // News,
    Configurations
];
