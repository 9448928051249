import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ContactService } from '../../../../system/services/contact.service';
import { Endpoints } from '../../../../system/configurations';
import { Router } from '@angular/router';
import * as configs from '../../../../system/configurations';
import { isNullOrUndefined } from 'util';


@Component({
  selector: 'app-add-info',
  templateUrl: './add-info.component.html',
  styleUrls: ['./add-info.component.scss']
})
export class AddInfoComponent implements OnInit {

  newInfoForm: FormGroup;
  typeList: any;
  isValid = true;
  origem = '';
  disableButton = false;
  public router: Router;

  constructor(
    private formbuilder: FormBuilder,
    private contact: ContactService,
    router: Router,
    public thisDialogRef: MatDialogRef<AddInfoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {

    this.router = router;
    if (data.hasOwnProperty('origin')) {
      this.origem = data.origin;
    }
  }


  ngOnInit() {
    // if (this.data.origin && this.data.origin === 'consultor') {
    //   this.typeList = configs.genTypes.GEN_CONFIG_TYPES.INFO_CONSULTANT_TYPE ;
    // }else{
      this.getTypeList();
    // }
    
    this.newInfoForm = this.formbuilder.group({
      'name': [null, Validators.required],
      'idType': [null, Validators.required],
      'description': [null, Validators.required],
      'isFavorite': [false]
    });

  }


  getTypeList() {
    let noteType=[];
    this.contact.getInfoTypes().subscribe(h => {
      this.typeList = h;

      if (this.data.origin && this.data.origin === 'consultor') {
        h.forEach(elem => {
          if (elem.label === 'Consultores') {
            noteType.push(elem);
          }
        });
        this.typeList = noteType;
      }
    });
  }



  /**
   * Submit function
   */
  onSubmit($event, form) {

    if (this.newInfoForm.valid) {
      this.disableButton = true;
      this.isValid = true;

      $event.preventDefault();
      const body = {};

      const formCtrl = this.newInfoForm.controls;

      for (const key in formCtrl) {
        if (formCtrl.hasOwnProperty(key)) {
          const elem = formCtrl[key];

          const value = (typeof (form[key]) === 'object' && form[key].hasOwnProperty('value')) ? form[key].value : form[key];
          body[key] = value;

        }
      }
      if(!isNullOrUndefined(this.data.idOpportunity)){
        body["idOpportunity"] = this.data.idOpportunity;
      }
      const url = (this.data.hasOwnProperty('origin') && this.data.origin === 'opportunities') ? Endpoints.CLIENTS.LEADS.LEAD_NOTES(this.data.idUser) : Endpoints.CLIENTS.USERS.USER_NOTES(this.data.idUser);

      if (this.isValid) {

        this.contact.createInfo(url, body).subscribe(rep => {

          if (rep) {
            this.thisDialogRef.close();
          }
          else {
          }
        });

      }

    } else { // The form is Not Valid
      this.isValid = false;
    }
  }


  onCloseCancel() {
    this.thisDialogRef.close('Cancel');
  }

  selectionChanged($event) {
  }

}
