import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { OpportunitiesService } from '../../system/services/opportunities.service';

@Component({
  selector: 'app-select-account',
  templateUrl: './select-account.component.html',
  styleUrls: ['./select-account.component.scss']
})
export class SelectAccountComponent implements OnInit {

  payment: any;
  oppID: any;
  accountID: any;
  accountList: any;

  constructor(private opportunityService: OpportunitiesService,
    public thisDialogRef: MatDialogRef<SelectAccountComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
    this.getAccounts();
  }

  getAccounts(){
    this.opportunityService.getPaymentAccountList().subscribe((contas: any) => {
      this.transformAccountList(contas);
    });
    //  this.opportunityService.getAccounts().subscribe((reply: any) => {
      //    console.log('reply accountList', reply); // TODO:        Remover antes do PR
      //    this.accountList = reply;
      //  });
  }

  transformAccountList(accounts: any){
    const tempArr=[];
    accounts.forEach(account => {
      const nome = account.name;
      tempArr.push({label: nome, value: account.id});
    });
    this.accountList = tempArr;
  }

  submitPayment(){
    this.payment = this.data.info;
    // this.payment.accountID = this.accountID;
    this.oppID = this.data.opp;
    this.opportunityService.setPaymentReceived(this.oppID, this.payment.id, this.accountID.value).subscribe(reply => {
      if (reply[0] === 1) {
        this.payment.received = 1;
        this.onCloseCancel('Sucesso!');
      }
    });
  }

  onCloseCancel(msg: any) {
    msg = (msg === undefined) ? 'cancel' : msg;
    this.thisDialogRef.close(msg);
  }
}
