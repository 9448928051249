import { getDate } from './../../system/date';
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators, NgModel } from '@angular/forms';
import { ContactService } from '../../system/services/contact.service';
import { OpportunitiesService } from '../../system/services/opportunities.service';
import {Endpoints, firstVisitType} from '../../system/configurations';
import * as configs from '../../system/configurations';
import { StaffService } from '../../system/services/staff.service';
import { SessionService } from '../../system/services/session.service';
import { map } from 'rxjs/operators';
import { log } from 'util';
import { now } from 'moment';
import { Router } from '@angular/router';
import { EtapaService } from '../../system/services/etapa.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-add-opportunity',
  templateUrl: './add-opportunity.component.html',
  styleUrls: ['./add-opportunity.component.scss']
})

export class AddOpportunityComponent implements OnInit {

  newOpportunityForm: FormGroup;
  isValid = true;
  quantity: number;
  clinicList: any;
  offset: Date;
  offset2: Date;
  filters: any;
  keepValue; string;
  timer;
  contactList = [];
  backupContactList = [];
  leadList = [];
  lead: any;
  opportunityTypeList: any;
  staffList: any;
  searchValue: string;
  searchApplied = false;
  selectedOrigin = { label: 'Selecione o tipo de proveniência', value: '' };
  originList: any;
  disableButton = false;
  loading: Boolean= false;

  public input$ = new Subject<string>();
  consultationsList = firstVisitType

  constructor(
    private router: Router,
    private staff: StaffService,
    private sessionService: SessionService,
    private etapaService: EtapaService,
    private contact: ContactService,
    private opportunitiesService: OpportunitiesService,
    private formbuilder: FormBuilder,
    public thisDialogRef: MatDialogRef<AddOpportunityComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {

    this.quantity = 30;
    this.filters = {};
    this.resetDate();
  }


  ngOnInit() {
	const idStaffOwnedBy = {value: this.sessionService.userId, label: this.sessionService.userName};
    this.newOpportunityForm = this.formbuilder.group({
      'idClient': [null, Validators.required],
      'idLead': [null, Validators.required],
      'idOrigin': [null, Validators.required],
      'firstVisitType': [null, Validators.required],
      'createdDate': [getDate(), Validators.required],
      'closingForecast': [getDate(), Validators.required],
      'idOpportunityType': [null, Validators.required],
      'idStaffOwnedBy': [idStaffOwnedBy, Validators.required]
    });
    this.getOrigins();
    this.getContactList();
    this.getOpportunityTypeList();
    this.getStaffList();
    this.getClinics();
    this.getPeopleContacts(true);
    this.input$.subscribe(search => this.applyNameFilter(search))
  }

  getOrigins() {
    this.contact.getOrigins().subscribe((elem: any) => {
      this.originList = [];
      for (const key in elem) {
        if (elem.hasOwnProperty(key)) {
          const orig = elem[key];
          // if (orig.order !== null && orig.order > 0) {
          const isManualOnly = (orig.description ? (orig.description.split('&')[1] ? (orig.description.split('&')[1] === '1' ? true : false) : false) : false);
          if (isManualOnly) {
            this.originList.push({ label: orig.name, value: orig.id });
          }
          // }
        }
      }

    });
  }
  get f() { return this.newOpportunityForm.controls; }

  getPeopleContacts(fresh = false) {
    this.contact.getContacts(this.quantity, this.offset, this.filters).subscribe(newList => {
      const tempArr = [];
      if (newList.length !== 0) {
        newList.forEach(contact => {
          const name = contact.users_names[0].firstName + ' ' + contact.users_names[0].lastName;
          const cliente = (contact.isClient === 1) ? true : false;
          tempArr.push({ value: contact.id, label: name, isClient: cliente });
        });
        this.contactList = tempArr;
        this.backupContactList = newList;
        this.offset = getDate(newList[newList.length - 1].modifiedDate);
        this.loading = false;
      }
    });
  }

  applyNameFilter(value) {
    if (value.length > 2) {
      this.loading = true;
      if (this.keepValue !== value) {
        (this.delay(() => {
          let type = 'name';
          this.keepValue = value;
          this.searchValue = '';
          this.searchValue = '?name=' + value;
          const aux = configs.regex.number;
          const regex = new RegExp(aux);
          if (regex.test(value)) {
            type = 'number';
            this.searchValue = '?phone=' + value;
          }
          if (value.indexOf('@') !== -1) {
            this.searchValue = '?email=' + value;
          }
          this.contact.getUserFilters(this.searchValue).subscribe((reply: any) => {
            this.transformList(reply);
            this.loading = false;
          });
        }))();
      }
    } else {
      if (value.length === 0) {
        this.getPeopleContacts(true);
      }
    }
  }

  delay(fn) {
    return (...args) => {
      this.timer = clearTimeout(this.timer);
      this.timer = setTimeout(fn.bind(this, ...args), 500);
    };
  }

  applyLocalSearch(value, type) {
    const tempArr = [];
    if (value !== '') {
      if (type === 'name') {
        const splitted = value.split(' ');
        this.backupContactList.forEach((contact: any) => {
          let flag = true;
          for (let index = 0; index < splitted.length; index++) {
            if (flag) {
              const valor = splitted[index];
              const aux = '.*' + valor + '.*';
              const regex = new RegExp(aux, 'i');
              const name = contact.users_names[0].firstName + ' ' + contact.users_names[0].lastName;
              if (regex.test(name)) {
                flag = true;
              } else {
                flag = false;
              }
            }
            if (flag) {
              tempArr.push(contact);
            }
          }
        });
      } else {
        this.backupContactList.forEach((contact: any) => {
          let flag = true;
          if (flag) {
            const aux = '.*' + value + '.*';
            const regex = new RegExp(aux, 'i');
            const vat = (contact.users_vat.length > 0) ? contact.users_vat[0].vat : '';
            const phone = (contact.users_phones.length > 0) ? contact.users_phones[0].number : '';
            const vatPhone = vat + ' ' + phone;
            if (regex.test(vatPhone)) {
              flag = true;
            } else {
              flag = false;
            }
          }
          if (flag) {
            tempArr.push(contact);
          }
        });
      }
      this.transformList(tempArr);
    }
  }

  transformList(reply: any) {
    const tempArr = [];
    reply.forEach(contact => {
      const isClient = (contact.isClient === 1) ? true : false;
      const name = contact.users_names[0].firstName + ' ' + contact.users_names[0].lastName;
      tempArr.push({ label: name, value: contact.id, isClient: isClient, modifiedDate: contact.modifiedDate });
    });
    this.contactList = tempArr;
  }


  resetDate() {
    this.offset = getDate();
    this.offset2 = getDate();
    this.offset.setDate(this.offset.getDate() + 1);
    this.offset2.setDate(this.offset2.getDate() + 1);
  }

  canDoAction(operation: string, path: any, idClinic = null) {
    return this.sessionService.hasPermission(operation, path, idClinic);
  }

  loadLeads($event) {
    const id = $event.value;
    const params = {
      permissionPath: 'oportunidade',
      permissionAction: 'adicionar'
    };
    this.contact.getUserLeadList(id, params).subscribe((reply: any) => {
      const tempArr = [];
      reply.forEach(elem => {
        tempArr.push({
          ...{value: elem.id, label: elem.title, origin: elem.idOrigin },
          ...elem
        });
      });
      this.leadList = tempArr;
    });
  }

  selectOrigin($event) {
    this.newOpportunityForm.controls.idOrigin.setValue($event.origin);
    const id = $event.origin;
    this.lead = $event;
    // this.originList.forEach(elem => {
    //   if (elem.value === id) {
    //     console.log('Encontrou', elem);
    //     console.log('event', $event);
    //     // this.newOpportunityForm.value.idOrigin = id;
    //     this.newOpportunityForm.controls.idOrigin.setValue({ value: id, label: elem.label });
    //     console.log('formControl', this.newOpportunityForm);
    //   }
    // });
  }

  getContactList() {
    // Filter by client ID
    const lista = [];
    if (this.data.hasOwnProperty('id')) {
      const cliente = (this.data.isClient === 1) ? true : false;
      lista.push({ value: this.data.id, label: this.data.users_names[0].firstName, isClient: cliente });
      this.contactList = lista;
    } else {
      this.contact.getAllContacts().subscribe(h => {
        this.contactList = h;
      });
    }
  }



  getName(elem: any) {
    return elem.leads_names[0].firstName + ' ' + elem.leads_names[0].lastName;
  }


  getOpportunityTypeList() {
    this.opportunitiesService.getOpportunityTypeList().subscribe(oppList => {
      this.opportunityTypeList = oppList;
    });
  }

  getStaffList() {
    this.staff.getAllStaff().subscribe(sList => {
      this.staffList = sList;
    });
  }


  formatDate(datePicked){
    const novaHora = getDate();

    novaHora.setUTCDate(datePicked.getDate());
    novaHora.setUTCMonth(datePicked.getMonth());
    novaHora.setUTCFullYear(datePicked.getFullYear());

    return novaHora;
  }

  /**
   * Creates an opportunity
   * @param reply BackEnd reply from the lead
   */
  creatOpportunityRequest(lead: any) {
    const datePicked = getDate(this.newOpportunityForm.value.createdDate);
    const closingForecastPicked = getDate(this.newOpportunityForm.value.closingForecast);

    const body = {
      idLead: this.newOpportunityForm.value.idLead.value,
      idStaffOwnedBy: this.newOpportunityForm.value.idStaffOwnedBy.id,
      idEtapa: this.etapaService.getInitialEtapa().id,
      idOpportunityType: this.newOpportunityForm.value.idOpportunityType.value,
      firstVisitType: this.newOpportunityForm.value.firstVisitType.value,
      createdDate: this.formatDate(datePicked),
      closingForecast: this.formatDate(closingForecastPicked),
      isSale: 0
    };

    this.opportunitiesService.createOpportunity(body).subscribe((response: any) => {
      this.thisDialogRef.close('Success !');
      this.router.navigate(['opportunities/opportunity-detail/', response.id]);
    });

  }

  // checkUnselected($event) {
  //   console.log('Check Unchecked');

  //   if ($event === undefined) {
  //     this.newOpportunityForm.value.idOrigin = this.selectedOrigin.value;
  //   }

  // }

  // setFirstVisitType($event) {
  //   console.log('event', $event);
  //   // this.newOpportunityForm.value.firstVisitType.setValue($event.value);
  //   // this.newOpportunityForm.controls.firstVisitType.setValue($event);
  // }

  onSubmit($event, form) {
    $event.preventDefault();

    if (this.newOpportunityForm.valid) {
      this.disableButton = true;
      this.creatOpportunityRequest(form.idLead);
    }
  }


  onCloseCancel() {
    this.thisDialogRef.close('Cancel');
  }


  selectionChanged($event) {
  }


  getClinics() {
    this.contact.getClinics().subscribe((elem: any) => {
      this.clinicList = [];
      for (const key in elem) {
        if (elem.hasOwnProperty(key)) {
          const clin = elem[key];
          this.clinicList.push({ label: clin.name, value: clin.id });
        }
      }
    });
  }

}
