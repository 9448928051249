import { SelectionModel } from '@angular/cdk/collections';
import { FlatTreeControl } from '@angular/cdk/tree';
import { Component, EventEmitter, Injectable, Input, OnInit, Output } from '@angular/core';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { BehaviorSubject } from 'rxjs';
import { ConfigurationsService } from '../../../system/services/configurations.service';
import { SessionService } from '../../../system/services/session.service';

/**
 * Node for to-do item
 */
export class TodoItemNode {
	children: TodoItemNode[];
	item: string;
	data: any
}

/** Flat to-do item node with expandable and level information */
export class TodoItemFlatNode {
	item: string;
	level: number;
	expandable: boolean;
	data: any;
}

@Injectable()
export class ChecklistDatabase {
	dataChange = new BehaviorSubject<TodoItemNode[]>([]);

	get data(): TodoItemNode[] { return this.dataChange.value; }


	initialize(dataItems) {

		// Build the tree nodes from Json object. The result is a list of `TodoItemNode` with nested
		//     file node as children.
		const data = this.buildFileTree(dataItems, 0);

		// Notify the change.
		this.dataChange.next(data);
	}

	/**
	 * Build the file structure tree. The `value` is the Json object, or a sub-tree of a Json object.
	 * The return value is the list of `TodoItemNode`.
	 */
	buildFileTree(obj: { [key: string]: any }, level: number): TodoItemNode[] {
		const result = Object.keys(obj).reduce<TodoItemNode[]>((accumulator, key) => {
			const value = obj[key];
			const node = new TodoItemNode();
			node.item = key;

			if (value != null) {
				if (typeof value === 'object' && !value.id) {
					node.children = this.buildFileTree(value, level + 1);
					node.data = obj[key]
				} else {
					node.item = value;
					node.data = obj[key]
				}
			}
			return accumulator.concat(node);
		}, []);
		return result
	}

	/** Add an item to to-do list */
	insertItem(parent: TodoItemNode, name: string) {
		if (parent.children) {
			parent.children.push({ item: name } as TodoItemNode);
			this.dataChange.next(this.data);
		}
	}

	updateItem(node: TodoItemNode, name: string) {
		node.item = name;
		this.dataChange.next(this.data);
	}
}

/**
 * @title Tree with checkboxes
 */
@Component({
	selector: 'app-tree-view-origem-generic',
	templateUrl: 'origins-tree-view-generic.component.html',
	styleUrls: ['origins-tree-view-generic.component.scss'],
	providers: [ChecklistDatabase]
})
export class OriginsTreeViewGenericComponent implements OnInit {
	@Input('dataItems') dataItems: any;
	@Input('showCheckBox') showCheckBox: boolean;

	@Output() selectItem = new EventEmitter();
	@Output() updateActive = new EventEmitter();

	/** Map from flat node to nested node. This helps us finding the nested node to be modified */
	flatNodeMap = new Map<TodoItemFlatNode, TodoItemNode>();

	/** Map from nested node to flattened node. This helps us to keep the same object for selection */
	nestedNodeMap = new Map<TodoItemNode, TodoItemFlatNode>();

	/** A selected parent node to be inserted */
	selectedParent: TodoItemFlatNode | null = null;

	/** The new item's name */
	newItemName = '';

	treeControl: FlatTreeControl<TodoItemFlatNode>;

	treeFlattener: MatTreeFlattener<TodoItemNode, TodoItemFlatNode>;

	dataSource: MatTreeFlatDataSource<TodoItemNode, TodoItemFlatNode>;

	/** The selection for checklist */
	checklistSelection = new SelectionModel<TodoItemFlatNode>(true /* multiple */);

	constructor(public _database: ChecklistDatabase,
		public sesssionService: SessionService,
		public configurationService: ConfigurationsService) {

	}
	ngOnInit(): void {

		this.showCheckBox = false;
		this._database.initialize(this.dataItems)
		this.treeFlattener = new MatTreeFlattener(this.transformer, this.getLevel,
			this.isExpandable, this.getChildren);
		this.treeControl = new FlatTreeControl<TodoItemFlatNode>(this.getLevel, this.isExpandable);
		this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

		try {
			this._database.dataChange.subscribe(data => {
				this.dataSource.data = data;
			});
		} catch (err) {
			console.error(err);
		}
	}

	getLevel = (node: TodoItemFlatNode) => node.level;

	isExpandable = (node: TodoItemFlatNode) => node.expandable;

	getChildren = (node: TodoItemNode): TodoItemNode[] => node.children;

	hasChild = (_: number, _nodeData: TodoItemFlatNode) => _nodeData.expandable;

	hasNoContent = (_: number, _nodeData: TodoItemFlatNode) => _nodeData.item === '';

	/**
	 * Transformer to convert nested node to flat node. Record the nodes in maps for later use.
	 */
	transformer = (node: TodoItemNode, level: number) => {
		const existingNode = this.nestedNodeMap.get(node);
		const flatNode = existingNode && existingNode.item === node.item
			? existingNode
			: new TodoItemFlatNode();
		flatNode.item = node.item;
		flatNode.data = node.data;
		flatNode.level = level;
		flatNode.expandable = !!node.children && !!node.children.length;
		this.flatNodeMap.set(flatNode, node);
		this.nestedNodeMap.set(node, flatNode);
		return flatNode;
	}

	/** Whether all the descendants of the node are selected. */
	descendantsAllSelected(node: TodoItemFlatNode): boolean {
		const descendants = this.treeControl.getDescendants(node);
		const descAllSelected = descendants.length > 0 && descendants.every(child => {
			return this.checklistSelection.isSelected(child);
		});
		return descAllSelected;
	}

	/** Whether part of the descendants are selected */
	descendantsPartiallySelected(node: TodoItemFlatNode): boolean {
		const descendants = this.treeControl.getDescendants(node);
		const result = descendants.some(child => this.checklistSelection.isSelected(child));
		return result && !this.descendantsAllSelected(node);
	}

	/** Toggle the to-do item selection. Select/deselect all the descendants node */
	todoItemSelectionToggle(node: TodoItemFlatNode, exec = true): void {
		this.checklistSelection.toggle(node);
		const descendants = this.treeControl.getDescendants(node);
		this.checklistSelection.isSelected(node)
			? this.checklistSelection.select(...descendants)
			: this.checklistSelection.deselect(...descendants);

		// Force update for the parent
		descendants.forEach(child => this.checklistSelection.isSelected(child));
		this.checkAllParentsSelection(node);
		if (exec)
			this.handleSelected()
	}

	/** Toggle a leaf to-do item selection. Check all the parents to see if they changed */
	todoLeafItemSelectionToggle(node: TodoItemFlatNode): void {
		this.checklistSelection.toggle(node);
		this.checkAllParentsSelection(node);
		this.handleSelected()
	}

	/* Checks all the parents when a leaf node is selected/unselected */
	checkAllParentsSelection(node: TodoItemFlatNode): void {
		let parent: TodoItemFlatNode | null = this.getParentNode(node);
		while (parent) {
			this.checkRootNodeSelection(parent);
			parent = this.getParentNode(parent);
		}
	}

	/** Check root node checked state and change it accordingly */
	checkRootNodeSelection(node: TodoItemFlatNode): void {
		const nodeSelected = this.checklistSelection.isSelected(node);
		const descendants = this.treeControl.getDescendants(node);
		const descAllSelected = descendants.length > 0 && descendants.every(child => {
			return this.checklistSelection.isSelected(child);
		});
		if (nodeSelected && !descAllSelected) {
			this.checklistSelection.deselect(node);
		} else if (!nodeSelected && descAllSelected) {
			this.checklistSelection.select(node);
		}
	}

	/* Get the parent node of a node */
	getParentNode(node: TodoItemFlatNode): TodoItemFlatNode | null {
		const currentLevel = this.getLevel(node);

		if (currentLevel < 1) {
			return null;
		}

		const startIndex = this.treeControl.dataNodes.indexOf(node) - 1;

		for (let i = startIndex; i >= 0; i--) {
			const currentNode = this.treeControl.dataNodes[i];

			if (this.getLevel(currentNode) < currentLevel) {
				return currentNode;
			}
		}
		return null;
	}

	/** Select the category so we can insert the new item. */
	addNewItem(node: TodoItemFlatNode) {
		const parentNode = this.flatNodeMap.get(node);
		this._database.insertItem(parentNode!, '');
		this.treeControl.expand(node);
	}

	/** Save the node to database */
	saveNode(node: TodoItemFlatNode, itemValue: string) {
		const nestedNode = this.flatNodeMap.get(node);
		this._database.updateItem(nestedNode!, itemValue);
	}

	isObject(item) {
		return typeof item === 'object';
	}

	getLevelDescription({ level }) {
		const options = {
			0: 'UTM Source: ',
			1: 'UTM Medium: ',
			2: 'UTM Campaign: '
		}
		return options[level]
	}

	getManualDescription(item) {
		return item.manual ? 'Sim' : 'Não';
	}

	handleSelected() {
		const seletedItems = this.checklistSelection
			.selected
			.filter(selected => selected.level === 2)
			.map(finalLevelMap => finalLevelMap.item);
		this.selectItem.emit(seletedItems)
	}

	resetSelected() {
		this.checklistSelection = new SelectionModel<TodoItemFlatNode>(true);
		this.handleSelected();
	}

	callForm(type, node) {
		switch (type) {
			case 'active':
				this.updateActive.emit(node.item)
				break;

			default:
				break;
		}
	}

	getName(item) {
		let name: string = item.utm_campaign.split('&')[0];

		if (name.includes('||')) {
			return name.split('||')[1] + item.title;
		}
		if (name.length == 0)
			return item.title;

		return name + ` Título: ${item.title}`;
	}

	markFilteredTree(idsSelectedItems, exec = true): void {
		this.checklistSelection = new SelectionModel<TodoItemFlatNode>(true);
		if (exec)
			this.resetSelected();

		const dataNodesLocal = this.treeControl.dataNodes;

		const visibleItems = dataNodesLocal.filter((node: any) => {
			return idsSelectedItems.indexOf(node.data.id) > -1;
		});

		visibleItems.forEach(node => {
			this.todoItemSelectionToggle(node, false);
		});

		if (exec)
			this.handleSelected()

	}

	checkAll() {
		for (let i = 0; i < this.treeControl.dataNodes.length; i++) {
			if (!this.checklistSelection.isSelected(this.treeControl.dataNodes[i])) {
				this.checklistSelection.toggle(this.treeControl.dataNodes[i]);
			}
		}
		this.handleSelected();
	}
}
