import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'getCurrency'
})
export class GetCurrencyPipe implements PipeTransform {
  currency = '';
  symbol = '';
  constructor() {
    this.getCurrency();
  }
  toBase64(str: string) {
    return btoa(encodeURIComponent(str));
  }

  fromBase64(str: string) {
    return decodeURIComponent(atob(str));
  }
  getCurrency() {
    const system = JSON.parse(decodeURIComponent(atob(localStorage.getItem('system'))));
    const parseSymbol = this.fromBase64(system.symbol);

    this.currency = system.currency;
    this.symbol = parseSymbol;
  }

  transform(value: number, currencyCode: string = this.currency, symbolDisplay: boolean = true, digits?: string): string {
    let locale = 'pt-PT';
    if (this.currency === 'BRL') {
      locale = 'pt-BR';
    }
    const formated = new Intl.NumberFormat(locale, { style: 'currency', currency: this.currency }).format(value);
    return formated;
  }

}
