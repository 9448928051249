import { Injectable } from '@angular/core';
import { Endpoints } from '../configurations';
import { HttpClient } from '@angular/common/http';
import { SessionService } from './session.service';
import { map } from 'rxjs/operators';
import { DataService } from './data.service';
import { ResponseContentType } from '@angular/http';

@Injectable({
  providedIn: 'root'
})
export class DocumentService {

  constructor(
    private dataService: DataService,
    private session: SessionService,
    private http: HttpClient) {

  }

  uploadFileToServer(file) {
    const url = Endpoints.FILES.UPLOAD;
    const preUpload$ = this.http.post(url, file, this.session.getOptions());

    return preUpload$;
  }


  /**
   * Adds new file to payment
   */
  addFileToPayment(file, oId: string, pId: string) {
    const url = Endpoints.OPPORTUNITIES.OPPORTUNITY_DOCUMENTS(oId, pId);
    const opt = this.session.getOptions();

    const file$ = this.http.post(url, file, opt);

    return file$;
  }


  /**
   * Adds new file to document
   */
  addFileToUser(file, uId: string) {
    const url = Endpoints.CLIENTS.USERS.FILES(uId);
    const opt = this.session.getOptions();

    const file$ = this.http.post(url, file, opt);

    return file$;
  }


  removeDocument(cId: string, dId: string) {
    const url = Endpoints.CLIENTS.USERS.FILE(cId, dId);
    const opt = this.session.getOptions();

    const reply$ = this.http.delete(url, opt);

    return reply$;
  }


  getDocumentTypes() {
    const url = Endpoints.CONFIGURATIONS.GENERIC_TYPES.DOCUMENT_TYPES;
    const opt = this.session.getOptions();

    const asd$ = this.http.get(url, opt).pipe(map((typeList: any) => {
      const newList = [];

      typeList.forEach(elem => {
        newList.push({ label: elem.name, value: elem.id });
      });

      return newList;
    }));

    return asd$;
  }

  /**
   * Returns a file for Download
   */
  downloadFile(path) {
    const url = Endpoints.FILES.DOWNLOAD(path);
    // const url = path;
    const opt = this.session.getOptions('binary');
    opt.headers['responseType'] = ResponseContentType.Blob;

    const reply$ = this.http.get(url, { responseType: 'blob', headers: { 'Authorization': 'Bearer ' + this.session.getTokenFromSession() } });

    return reply$;
  }



  // getUserDocs(qnt, offset, cId: string) {
  //   const docs$ = this.dataService.getUserDocs(qnt, offset, cId, false);

  //   return docs$;
  // }




}
