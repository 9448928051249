import { NgModule } from '@angular/core';

import { LayoutComponent } from './layout.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { HeaderComponent } from './header/header.component';
import { NavsearchComponent } from './header/navsearch/navsearch.component';
import { OffsidebarComponent } from './offsidebar/offsidebar.component';
import { UserblockComponent } from './sidebar/userblock/userblock.component';
import { UserblockService } from './sidebar/userblock/userblock.service';
import { FooterComponent } from './footer/footer.component';

import { SharedModule } from '../shared/shared.module';
import {
  MatMenuModule,
  MatDialogModule,
  MatButtonModule,
  MatSidenavModule,
  MatCheckboxModule,
  MatSnackBarModule,
} from '@angular/material';
import { IP_REMOTE, PROTOCOL_SAFE } from '@environments/environment';
import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';
import { defaultUrlWebSocket } from '@app/nabia/system/configurations';


const config: SocketIoConfig = {
  url: `${PROTOCOL_SAFE}://${IP_REMOTE}`,
  options: {
    secure: true,
    rejectUnauthorized: false,
    path: defaultUrlWebSocket,
    reconnectionAttempts: Infinity,
    reconnectionDelay: 500,
    reconnectionDelayMax: 1000,
    timeout: 1000,
  },
};
@NgModule({
  imports: [
    SharedModule,
    // MatSnackBar,
    MatDialogModule,
    MatSidenavModule,
    MatButtonModule,
    MatCheckboxModule,
    MatMenuModule,
    MatSnackBarModule,
    SocketIoModule.forRoot(config),
  ],
  providers: [UserblockService],
  declarations: [
    LayoutComponent,
    SidebarComponent,
    UserblockComponent,
    HeaderComponent,
    NavsearchComponent,
    OffsidebarComponent,
    FooterComponent,
  ],
  exports: [
    LayoutComponent,
    SidebarComponent,
    UserblockComponent,
    HeaderComponent,
    NavsearchComponent,
    OffsidebarComponent,
    FooterComponent,
  ],
})
export class LayoutModule {}
