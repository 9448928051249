import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { TranslatorService } from '../../core/translator/translator.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-ok-action',
  templateUrl: './ok-action.component.html',
  styleUrls: ['./ok-action.component.scss']
})
export class OkActionComponent implements OnInit {

  messageCustom = 'nabia.general.costumeConfirmMessage';
  titleCustom   = 'nabia.general.confirmMessage';

  constructor(
    public thisDialogRef: MatDialogRef<OkActionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    if(data !== null){
      if(typeof data == 'object'){
        this.messageCustom = data.message;
        this.titleCustom = data.title;
      }else{
        this.messageCustom = data;
      }
    }
  }

  ngOnInit() {
  }

  returnAnswer(answer: boolean) {
    this.onCloseCancel(answer);
  }

  onCloseCancel(answer: boolean) {
    this.thisDialogRef.close(answer);
  }
}
