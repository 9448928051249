import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { AddProductComponent } from '../../add-product/add-product.component';
import { AddPrescriptionComponent } from '../../add-prescription/add-prescription.component';

@Component({
  selector: 'app-choose',
  templateUrl: './choose.component.html',
  styleUrls: ['./choose.component.scss']
})
export class ChooseComponent implements OnInit {

  buttonSelect = false;
  selectedOption: string;
  productIcon = '../../assets/img/gr8-icons/produtos-icon-off.png';
  serviceIcon = '../../assets/img/gr8-icons/servicos-icon-off.png';
  prescriptionIcon = '../../assets/img/gr8-icons/prescricoes-icon-off.png';
  origin: string;

  constructor(public dialog: MatDialog,
    public thisDialogRef: MatDialogRef<ChooseComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {

    this.origin = data.origin;
  }

  ngOnInit() {

  }

  selectButton1() {
    this.buttonSelect = true;
    this.selectedOption = 'products';
    this.productIcon = '../../assets/img/gr8-icons/produtos-icon-on.png';
    this.serviceIcon = '../../assets/img/gr8-icons/servicos-icon-off.png';
    this.prescriptionIcon = '../../assets/img/gr8-icons/prescricoes-icon-off.png';
  }
  selectButton2() {
    this.buttonSelect = true;
    this.selectedOption = 'services';
    this.productIcon = '../../assets/img/gr8-icons/produtos-icon-off.png';
    this.serviceIcon = '../../assets/img/gr8-icons/servicos-icon-on.png';
    this.prescriptionIcon = '../../assets/img/gr8-icons/prescricoes-icon-off.png';
  }
  selectButton3() {
    this.buttonSelect = true;
    this.selectedOption = 'prescriptions';
    this.productIcon = '../../assets/img/gr8-icons/produtos-icon-off.png';
    this.serviceIcon = '../../assets/img/gr8-icons/servicos-icon-off.png';
    this.prescriptionIcon = '../../assets/img/gr8-icons/prescricoes-icon-on.png';
  }

  onCloseCancel() {
    this.thisDialogRef.close(false);
  }

  selectionChanged($event) {
  }

  openDialog() {
    let dialogRef: any;

    if (this.selectedOption === 'products') {
      this.data['type'] = this.selectedOption;
      dialogRef = this.dialog.open(AddProductComponent, {
        disableClose: true,
        data: this.data

      });
      // this.thisDialogRef.close('Open AddProductsComponent');
    }

    if (this.selectedOption === 'services') {
      this.data['type'] = this.selectedOption;
      dialogRef = this.dialog.open(AddProductComponent, {
        disableClose: true,
        data: this.data
      });
      // this.thisDialogRef.close('Open AddProductComponent');
    }

    if (this.selectedOption === 'prescriptions') {
      dialogRef = this.dialog.open(AddPrescriptionComponent, {
        disableClose: true,
        data: this.data
      });
      // this.thisDialogRef.close('Open x<Component');
    }
    
    dialogRef.afterClosed().subscribe(result => {
      this.thisDialogRef.close(result);
    });
  }

}
