import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ExchangeSuccessComponent} from './success/exchange-success.component';
import {TypesExchange} from './interfaces';
import {ChooseComponent} from "../choose/choose.component";
import {AddProductComponent} from "../../add-product/add-product.component";
import {animate, state, style, transition, trigger} from "@angular/animations";
import {OpportunitiesService} from "../../system/services/opportunities.service";

@Component({
  selector: 'app-exchange',
  templateUrl: './exchange.component.html',
  styleUrls: ['./exchange.component.scss'],
  animations: [
    trigger('showHidden', [
      // ...
      state('show', style({
        visibility: 'visible',
        transition: 'all .5s'
      })),
      state('hidden', style({
        visibility: 'hidden',
        transition: 'all .2s'
      })),
    ])
  ]
})
export class ExchangeServiceComponent implements OnInit {

  sessionsList  = [];
  form: FormGroup;
  sessions;
  spinner = false;
  types = TypesExchange;
  submits;

  constructor(
      public dialog: MatDialog,
      public opportunitiesService: OpportunitiesService,
      public thisDialogRef: MatDialogRef<ExchangeServiceComponent>,
      @Inject(MAT_DIALOG_DATA) public data: any,
      private formbuilder: FormBuilder,
  ) {
    this.submits = {
      [TypesExchange.credit]  : () => this.onSubmitCredit(),
      [TypesExchange.service] : () => this.onSubmitService(),
      [TypesExchange.yield]   : () => this.onSubmitYield(),
    };
  }

  changeType() {
    this.form.controls.yield.updateValueAndValidity();
  }

  ngOnInit() {
    this.form         = this.formbuilder.group({
      'items': this.formbuilder.array(this.data.items.map(item => {
        const exchange = (item.exchange) ? item.exchange : 0;
        let sessions = 0;

        if (item.product_type) {
          sessions = item.quantity - exchange;
        } else {
          sessions = item.todoSessions;
        }
        if(sessions < 0){
          sessions = 0;
        }
        item.sessionsAvailable = sessions

        return this.formbuilder.group({
          'idOpportunityService'   : [(item.hasOwnProperty('service_type')) ? item.id : null],
          'idOpportunityProduct'   : [(item.hasOwnProperty('product_type')) ? item.id : null],
          'treatment'   : [(item.hasOwnProperty('service_type')) ? item.service_type.name : item.product_type.name],
          'sessions'    : [sessions, Validators.required],
        });
      })),
      'exchangeType': [TypesExchange.service, Validators.required],
      'yield': [null,   (form) => {
        return (
            form.root.value
            && form.root.value.exchangeType == TypesExchange.yield
            && !form.value
        ) ? {required: true} : null;
      }],
    });
  }

  getName(product) {
    return (product.hasOwnProperty('service_type')) ? product.service_type.name : product.product_type.name;
  }

  getAmount() {
    let amount = 0;

    this.data.items.map((product, i) => {
        amount += this.getAmountExchange(product, i);
    })


    return amount;
  }

  getAmountExchange(product, index){
    const items: any    = this.form.controls['items'];
    const value         = (product.hasOwnProperty('service_type')) ? product.treatmentPricePerSession : product.productPricePerUnit;
    const quantityPaid  = (product.hasOwnProperty('service_type')) ? product.boughtSessions : product.quantity
    const quantity      = items.controls[index].get('sessions').value;
    const discount      = product.discount ;
    const valueProduct  = value * quantity;

    if(discount == 0){
      return valueProduct;
    }

    if (product.isDiscountPercent) {
      return valueProduct - (valueProduct * ((discount ) / 100));
    } else {
      return valueProduct - ((discount  / quantityPaid) * quantity );
    }
  }


  onCloseCancel() {
    this.thisDialogRef.close(false);
  }

  submit() {
    if (this.getAmount() >= 0) {
      const typeSelected = this.form.get('exchangeType').value;
      this.submits[typeSelected]();
    }
  }

  async onSubmitCredit() {
    try {
      this.spinner = true;
      const body ={
        idOpportunity: this.data.clientDetails.id,
        exchange: this.getItems()
      };
      const result = await this.opportunitiesService.exchangeCredit(this.data.clientDetails.id, body).toPromise();
      this.thisDialogRef.close(result);
      if (result) {
        const dialogRef = this.dialog.open(ExchangeSuccessComponent, {
          data: {type: TypesExchange.credit, amount: this.getAmount()}
        });
      }
      this.spinner = false;
    } catch (e) {
      console.error(e);
      this.spinner = false;
      this.thisDialogRef.close(false);
    }
  }
  getItems(){
    const itens: any = this.form.get('items');
    return itens.getRawValue().filter(item => item.sessions > 0 );
  }
  async onSubmitService() {
    this.spinner = true;
    const dialogRef = this.dialog.open(ChooseComponent, {
      disableClose: true,
      data:  {
        origin: this.data.origin,
        id: this.data.clientDetails.opp_lead.id,
        lead: this.data.clientDetails.opp_lead,
        exchange: true,
        idOpportunity: this.data.clientDetails.id,
        items:this.getItems(),
        exchangeCredits: this.getAmount()
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      dialogRef.close(result);
      this.thisDialogRef.close(result);

      if (result) {
        const dialogRefSuccess = this.dialog.open(ExchangeSuccessComponent, {
          data: {
            type: TypesExchange.service,
            amount: result.amountPayable,
            opportunity: result.opportunity,
            opportunityName: result.opportunityName,
          }
        });
      }
    });
    this.spinner = false;
  }
  async onSubmitYield() {
    try {
      this.spinner = true;
      const body ={
        idOpportunity: this.data.clientDetails.id,
        exchange: this.getItems(),
        idUser: this.form.get('yield').value.value
      };
      const result = await this.opportunitiesService.exchangeYield(this.data.clientDetails.id, body).toPromise();
      this.thisDialogRef.close(result);
      if(result) {
        const dialogRef = this.dialog.open(ExchangeSuccessComponent, {
          data: {
            type: TypesExchange.yield,
            amount: this.getAmount(),
            userName: this.form.get('yield').value.label,
            userId: this.form.get('yield').value.value
          }
        });
      }
      this.spinner = false;
    }catch (e) {
      this.spinner = false;
      console.error(e);
    }
  }

  changeSessions(value, index){
    const items: any = this.form.controls['items'];
    if(!value.target.value || value.target.value == 0){
      items.controls[index].controls['sessions'].setValue(1);
    }
    if(value.target.value > this.data.items[index].sessionsAvailable){
      items.controls[index].controls['sessions'].setValue(this.data.items[index].sessionsAvailable);
    }
  }

}
