import { Injectable } from '@angular/core';
import { isNullOrUndefined } from 'util';

@Injectable({
  providedIn: 'root'
})
export class HelperService {

  constructor() {

  }


  /**
   * Returns the first and last names joined with space
   */
  getName(name: any) {
    // console.log('getName()', name); // TODO: remove the comment on PR !!!
    return (name) ? name.firstName + ' ' + name.lastName : '';
  }

	getStaffName(item) {
		if(item.staff_names){
			let first = item.staff_names.find(x => x !== undefined);
			return first ? `${first.firstName} ${first.lastName}` : '';
		}
		return item.name;
	}

  /**
   * Used to build Name Initials.
   */
  getInitials(staffName: any) {
    let initials = '';

    if (staffName && staffName.hasOwnProperty('firstName') && staffName.hasOwnProperty('lastName')) {
      const a = (staffName.firstName !== '') ? staffName.firstName[0].toUpperCase() : '';
      const b = (staffName.lastName !== '') ? staffName.lastName[0].toUpperCase() : '';

      initials = a + b;
    }
    return initials;
  }


  getInitialsFromNames(firstName, lastName) {
    const a = (firstName !== undefined && firstName !== '') ? firstName: '';
    const b = (lastName !== undefined && lastName !== '') ? lastName: '';
    return a + b;
  }


  arrayNotNullOrUndefined(elem) {
    return (!isNullOrUndefined(elem) && elem instanceof Array && elem.length >= 0)
  }




}
