import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { TranslatorService } from '../../core/translator/translator.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-confirm-action',
  templateUrl: './confirm-action.component.html',
  styleUrls: ['./confirm-action.component.scss']
})
export class ConfirmActionComponent implements OnInit {

  msg1 = 'nabia.general.costumeConfirmMessage';
  // msg2 = 'Tem a certeza que pretende eliminar este recurso?';
  // msg3 = 'Tem a certeza que ... ?';
  messageCustom = '';

  constructor(private translate: TranslateService,
    public thisDialogRef: MatDialogRef<ConfirmActionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { 
      // translate.get('nabia.general.yes').subscribe(a => console.log('translate', a)); // TODO:        Remover antes do PR);
      this.messageCustom = (this.data !== null) ? this.data : this.msg1;
    }

  ngOnInit() {
  }

  returnAnswer(answer: boolean){
    this.onCloseCancel(answer);
  }

  onCloseCancel(answer: boolean) {
    this.thisDialogRef.close(answer);
  }
}
