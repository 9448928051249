import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { of } from 'rxjs';
import { ContactService } from '../../system/services/contact.service';
import { OpportunitiesService } from '../../system/services/opportunities.service';

@Component({
  selector: 'app-add-return',
  templateUrl: './add-return.component.html',
  styleUrls: ['./add-return.component.scss']
})
export class AddReturnComponent implements OnInit {

  offset: number;
  quantity: number;
  filter: any;
  id: any;

  constructor(
    private opportunitiesService: OpportunitiesService,
    private contact: ContactService,
    private formbuilder: FormBuilder,
    public thisDialogRef: MatDialogRef<AddReturnComponent>,
    @Inject(MAT_DIALOG_DATA) public data: string) {

    this.offset = 0;
    this.quantity = 30;
    this.filter = { search: '' };
    this.id = data;
  }

  newReturnForm: FormGroup;
  // oppList = [];
  methodList: any;
  isValid = true;

  ngOnInit() {

    this.getDeductionTypeList();

    this.newReturnForm = this.formbuilder.group({
      'opp': [this.id, Validators.required],
      'amount': [null, Validators.required],
      'idDeductionType': [null, Validators.required],
      'comment': [null, Validators.required]
    });
    // console.log('form', this.newReturnForm); // TODO:        Remover antes do PR
  }


  getDeductionTypeList() {
    this.opportunitiesService.getPaymentTypesList().subscribe(deductList => {
        this.methodList = deductList;
        // console.log('Deducoes :: ', deductList); // TODO: remove the comment on PR !!!
      });
  }

  onSubmit(form) {
    // console.log('The form is valid? , ', this.newReturnForm.valid);

    if (this.newReturnForm.valid) {
      this.isValid = true;
      const body = {
        amount: form.amount,
        comment: form.comment,
        idDeductionType: form.idDeductionType.id
      };

      this.opportunitiesService.createDeduction(this.data, body).subscribe(reply => {
      });
      this.thisDialogRef.close('Success');

    } else {
      this.isValid = false;
    }
  }

  onCloseCancel() {
    this.thisDialogRef.close('Cancel');
  }

  selectionChanged($event) {
  }

}
