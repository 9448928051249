import { getDate } from './../system/date/date';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ChooseComponent } from './choose/choose.component';
import { MatDialog } from '../../../../node_modules/@angular/material';
import { OpportunitiesService } from '../system/services/opportunities.service';
import { ProductService } from '../system/services/product.service';
import { ConfirmActionComponent } from '../confirm-action/confirm-action.component';
import * as configs from '../system/configurations';
import { EditServiceComponent } from './edit-service/edit-service.component';
import { OkActionComponent } from '../ok-action/ok-action.component';
import { ExchangeServiceComponent } from './exchange/exchange.component';
import { SnackbarService } from '../system/services/snackbar.service';
import { GetCurrencyPipe } from '../../layout/pipe/get-currency.pipe';
import { SessionService } from '../system/services/session.service';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss'],
})
export class ProductsComponent implements OnInit {
  @Input() clientDetails: any;
  @Input() origin: string; // Indicates the context in which the component is inserted
  @Input() allowEditAndDelete: boolean = true;
  @Input() allowCreate: boolean = true;
  @Input() allowExchange: boolean = true;
  @Input() allowExchangeZero: boolean = true;
  @Input() messageOnEditOrDelete: string;
  @Input() messageOnCreate: string;
  @Output() onChange = new EventEmitter<any>();

  offsetProducts: Date;
  offsetServices: Date;
  quantity: number;
  filter: any;
  message1 = 'nabia.general.confirmProdDelete';
  message2 = 'nabia.general.confirmServDelete';
  isWon = -1;
  total = 0;
  emitterFlag = 0;
  // TODO: PROBLEM_HERE
  selector: any;
  // TODO: PROBLEM_HERE
  partialId = configs.genTypes.OPPORTUNITIES_PARTIAL;
  productList: any;
  servicesList: any;

  viewList: any;
  public currencySymbol = '';
  constructor(
    private opportunityService: OpportunitiesService,
    private product: ProductService,
    public snackbarService: SnackbarService,
    private getCurrency: GetCurrencyPipe,
    private sessionService: SessionService,
    public dialog: MatDialog
  ) {
    // tranlate.translate('nabia.general.yes').subscribe(trad => console.log('tradução' trad));
  }

  ngOnInit() {
    this.loadCurrencySymbol();
    this.offsetProducts = this.resetDate();
    this.offsetServices = this.resetDate();
    this.quantity = 30;
    this.filter = { search: '' };
    this.viewList = [];
    this.total = 0;
    this.isWon = this.clientDetails.isWon;
    this.filter['idOwnerEntity'] = this.clientDetails.id;
    this.initializeData();
  }
  loadCurrencySymbol() {
    this.currencySymbol = this.getCurrency.symbol;
  }
  initializeData() {
    Promise.all([this.getServices(true), this.getProducts(true)]).then(res => {
      this.viewList = this.servicesList.concat(this.productList);
    });
  }

  resetDate() {
    const newDate = getDate();
    newDate.setDate(newDate.getDate() + 1);
    return newDate;
  }

  // concatToView() {
  //   this.viewList = [];

  //   if (this.productList) { this.viewList = this.productList.concat(this.viewList); }
  //   if (this.servicesList) { this.viewList = this.servicesList.concat(this.viewList); }

  //   return this.viewList;
  // }

  getProducts(fresh = false) {
    return new Promise<any[]>((resolve, reject) => {
      this.offsetProducts = fresh ? this.resetDate() : this.offsetProducts;

      this.product.getEntityProducts(this.quantity, this.offsetProducts, this.filter).subscribe(pList => {
        this.getCostPrice(pList, 'prods');

        this.productList = pList;
        resolve();
      });
    });
  }

  getServices(fresh = false) {
    return new Promise<any[]>((resolve, reject) => {
      this.offsetServices = fresh ? this.resetDate() : this.offsetServices;

      this.product.getEntityServices(this.quantity, this.offsetServices, this.filter).subscribe(sList => {
        this.servicesList = sList;
        this.getCostPrice(sList, 'service');
        resolve();
      });
    });
  }

  round(num) {
    return Math.round(num * 100) / 100;
  }

  getCostPrice(List, type) {
    let temp = 0;
    // const discounted = 0;
    // const vatTax = 0;
    let aux = 0;
    if (type === 'service') {
      List.forEach(prod => {
        const num = prod.boughtSessions;
        aux =
          prod.isDiscountPercent === 1
            ? prod.service_type.price * num * ((100 - prod.discount) / 100)
            : prod.service_type.price * num - prod.discount;
        aux =
          prod.service_type.taxIncluded === 1
            ? aux / ((+prod.service_type.treatment_tax.description + 100) / 100)
            : aux;
        aux -= prod.service_type.priceCost * num;
        temp += aux;
        // preço sem o desconto
        // discounted = (prod.isDiscountPercent === 1) ? prod.boughtSessions * (((100 - prod.discount) / 100) * prod.treatmentPricePerSession) : prod.treatmentPricePerSession - prod.discount;
        // console.log('discounted price', discounted); // TODO:        Remover antes do PR
        // // preço sem o imposto (de notar que primeiro faz-se o desconto e apenas depois se calcula o iva, ou seja, o vatTax é o valor final)
        // vatTax = (prod.service_type.taxIncluded === 1) ? (discounted / ((100 + +prod.service_type.treatment_tax.description)/100)) : discounted;
        // console.log('vatTax', vatTax); // TODO:        Remover antes do PR
        // temp += vatTax;
      });
      this.emitterFlag += 1;
    } else if (type === 'prods') {
      List.forEach(prod => {
        const num = prod.quantity;
        aux =
          prod.isDiscountPercent === 1
            ? prod.product_type.price * num * ((100 - prod.discount) / 100)
            : prod.product_type.price * num - prod.discount;
        aux =
          prod.product_type.taxIncluded === 1 ? aux / ((+prod.product_type.product_tax.description + 100) / 100) : aux;
        temp += aux;
        // // preço sem o desconto
        // discounted = (prod.isDiscountPercent === 1) ? prod.boughtSessions * (((100 - prod.discount) / 100) * prod.treatmentPricePerSession) : prod.treatmentPricePerSession - prod.discount;
        // // preço sem o imposto (de notar que primeiro faz-se o desconto e apenas depois se calcula o iva, ou seja, o vatTax é o valor final)
        // vatTax = (prod.service_type.taxIncluded === 1) ? (discounted * ((100 - +prod.service_type.treatment_tax.description)/100)) : discounted;
        // temp += vatTax;
      });
      this.emitterFlag += 1;
    }
    this.total += temp;

    if (this.emitterFlag === 2) {
      this.onChange.emit({ removeProd: false, data: this.total });
    }
  }

  async removeProduct(item: any, type: any) {
    if (item.service_prescription !== null && item.service_prescription && item.service_prescription.isPackage) {
      const packageProducts = this.viewList
        .filter(product => product.idPrescription === item.idPrescription)
        .map(item => item.id);

      for await (const servico of packageProducts) {
        this.product.deleteEntityService(this.clientDetails.id, servico).subscribe(reply => {});
      }
      this.ngOnInit();
      this.onChange.emit({ removeProd: true, data: '' });

      return;
    }
    if (type) {
      this.product.deleteEntityProduct(this.clientDetails.id, item.id).subscribe(reply => {
        this.ngOnInit();
        this.onChange.emit({ removeProd: true, data: '' });
      });
    } else {
      this.product.deleteEntityService(this.clientDetails.id, item.id).subscribe(reply => {
        this.ngOnInit();
        this.onChange.emit({ removeProd: true, data: '' });
      });
    }
  }

  // TODO: FAZER O POPUP PARA ADICIONAR PRODUTOS
  onScroll2() {
    // console.log('Infinite Scroll products'); // TODO:        Remover antes do PR
  }

  getOpportunity(oppId) {
    this.opportunityService.getOpportunity(oppId).subscribe((reply: any) => {
      this.isWon = reply.isWon;
    });
  }

  openModalCustomMessage() {
    this.dialog.open(OkActionComponent, {
      disableClose: true,
      data: {
        title: 'nabia.general.warning',
        message: this.messageOnEditOrDelete,
      },
    });
  }
  // Call message component

  openConfirmDialog(msg: string, value: string, isProd: boolean) {
    if (!this.allowEditAndDelete && this.messageOnEditOrDelete) {
      this.openModalCustomMessage();
      return true;
    }

    const message = msg;
    const dialogRef = this.dialog.open(ConfirmActionComponent, {
      data: message,
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.removeProduct(value, isProd);
      } else {
      }
    });
  }

  openEditDialog(product: any) {
    if (!this.allowEditAndDelete && this.messageOnEditOrDelete) {
      this.openModalCustomMessage();
      return true;
    }
    const dialogRef = this.dialog.open(EditServiceComponent, {
      data: { product },
    });
    dialogRef.afterClosed().subscribe(result => {
      product.discount = result.discount;
      product.boughtSessions = result.boughtSessions;
      product.isDiscountPercent = result.isDiscountPercent ? 1 : 0;

      setTimeout(() => {
        this.initializeData();
        this.getOpportunity(product.idOwnerEntity);
        this.onChange.emit({ data: 'empty' });
      }, this.snackbarService.timeoutRevert);

      // this.showSnakService(product, result)
    });
  }
  // async showSnakService(product, result){
  //   this.snackbarService.showRevert({
  //     text: 'nabia.snackbar.opportunity.service',
  //     onRevert: async () => {
  //       await this.product.updateEntityService(product.idOwnerEntity, product.id, result.formBefore).toPromise();
  //       this.initializeData();
  //       this.getOpportunity(product.idOwnerEntity);
  //       this.onChange.emit({ data: 'empty' });
  //     }
  //   });
  // }
  openExchangeDialog() {
    if (!this.allowEditAndDelete && this.messageOnEditOrDelete) {
      this.openModalCustomMessage();
      return true;
    }

    const dialogRef = this.dialog.open(ExchangeServiceComponent, {
      data: {
        items: this.getProductsChecked(),
        clientDetails: this.clientDetails,
        origin: this.origin,
      },
    });
    dialogRef.afterClosed().subscribe(result => {
      this.initializeData();
      this.getOpportunity(this.clientDetails.id);
      this.onChange.emit({ data: 'empty' });
      result = result === undefined ? 'canceled' : result;
    });
  }

  openDialog() {
    if (!this.allowEditAndDelete && this.messageOnEditOrDelete) {
      this.openModalCustomMessage();
      return true;
    }
    const data = {
      origin: this.origin,
      id: this.clientDetails.id,
    };

    const dialogRef = this.dialog.open(ChooseComponent, {
      disableClose: true,
      data: data,
    });
    dialogRef.afterClosed().subscribe(result => {
      this.ngOnInit();
      this.onChange.emit({ data: 'empty' });
    });
  }

  getGrandTotal(product) {
    const price = this.getPriceByProduct(product);
    const quantity = this.getQuantityByProduct(product);

    if (product.isDiscountPercent) {
      return +price * quantity * ((100 - product.discount) / 100);
    } else {
      return +price * quantity - product.discount;
    }
  }

  getProductsChecked() {
    return this.viewList.filter(product => product.checked);
  }

  checkProduct(product, event) {
    event.srcElement.checked = false;
    product.checked = product.checked ? false : true;

    if (!this.allowExchangeZero && this.getGrandTotal(product) == 0) {
      const dialogRef = this.dialog.open(OkActionComponent, {
        data: 'nabia.general.zeroExchange',
      });
      product.checked = false;
      event.srcElement.checked = false;
      return false;
    }
  }
  getTaxs(product) {
    const tax = (+product.service_type.treatment_tax.description + 100) / 100;

    var productValue = this.getProductValueWithDiscount(product);
    return productValue != 0 && product.service_type.taxIncluded === 1 ? (productValue / tax - productValue) * -1 : 0;
  }
  getUnitsTotal(viewList) {
    return viewList.reduce((accumulator, currentValue) => accumulator + this.getQuantityByProduct(currentValue), 0);
  }
  getPriceCostTotal(viewList) {
    return this.canDoAction('product-service.priceCost', 'oportunidade') ? viewList.reduce(
      (accumulator, currentValue) =>
        accumulator + this.getQuantityByProduct(currentValue) * currentValue.service_type.priceCost,
      0
    ) : 0;
  }
  getPriceCost(product) {
    return this.canDoAction('product-service.priceCost', 'oportunidade') ? product.boughtSessions * product.service_type.priceCost : 0;
  }
  getTaxTotal(viewList) {
    return viewList.reduce((accumulator, currentValue) => accumulator + this.getTaxs(currentValue), 0);
  }
  getPriceGrandTotal(viewList) {
    return viewList.reduce((accumulator, currentValue) => accumulator + this.getGrandTotal(currentValue), 0);
  }

  getProductValueWithDiscount(product) {
    return product.isDiscountPercent === 1
      ? this.getPriceByProduct(product) * this.getQuantityByProduct(product) * ((100 - product.discount) / 100)
      : this.getPriceByProduct(product) * this.getQuantityByProduct(product) - product.discount;
  }
  getPriceByProduct(product) {
    return product.hasOwnProperty('product_type') ? product.productPricePerUnit : product.treatmentPricePerSession;
  }
  getQuantityByProduct(product) {
    return product.hasOwnProperty('product_type') ? product.quantity : product.boughtSessions;
  }
  /**
   * Checks authorization to display/hide view items
   */
  canDoAction(operation: string, path: any, idClinic = null) {
    return this.sessionService.hasPermission(operation, path, idClinic);
  }
}
