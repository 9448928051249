import {NgModule} from '@angular/core';
import {SpinnerComponent} from './spinner.component';


@NgModule({
    imports: [
    ],
    declarations: [
        SpinnerComponent
    ],
    exports: [
        SpinnerComponent
    ]
})
export class SpinnerModule { }
