import { getDate } from './../date/date';
import { Pipe, PipeTransform, OnDestroy, ChangeDetectorRef, NgZone } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';


@Pipe({
  name: 'timeSince',
  pure: false
})
export class TimeSincePipe implements PipeTransform, OnDestroy {

  private timer: number;

  constructor(private changeDetectorRef: ChangeDetectorRef,
    private translateService: TranslateService,
    private ngZone: NgZone) {

  }


  transform(value: string): any {
    this.removeTimer();
    const d = getDate(value);
    const now = getDate();
    const seconds = Math.round(Math.abs((now.getTime() - d.getTime()) / 1000));
    const timeToUpdate = (Number.isNaN(seconds)) ? 1000 : this.getSecondsUntilUpdate(seconds) * 1000;
    this.timer = this.ngZone.runOutsideAngular(() => {
      if (typeof window !== 'undefined') {
        return window.setTimeout(() => {
          this.ngZone.run(() => this.changeDetectorRef.markForCheck());
        }, timeToUpdate);
      }
      return null;
    });
    const minutes = Math.round(Math.abs(seconds / 60));
    const hours = Math.round(Math.abs(minutes / 60));
    const days = Math.round(Math.abs(hours / 24));
    const months = Math.round(Math.abs(days / 30.416));
    const years = Math.round(Math.abs(days / 365));

    // TODO: Apply translations !!

    if (Number.isNaN(seconds)) {
      return '';
    } else if (seconds <= 45) {
      return 'há alguns segundos';
    } else if (seconds <= 90) {
      return 'há um minuto';
    } else if (minutes <= 45) {
      return 'há ' + minutes + ' minutos';
    } else if (minutes <= 90) {
      return 'há uma hora';
    } else if (hours <= 22) {
      return 'há ' + hours + ' horas';
    } else if (hours <= 36) {
      return 'há um dia';
    } else if (days <= 25) {
      return 'há ' + days + ' dias';
    } else if (days <= 45) {
      return 'há um mês';
    } else if (days <= 345) {
      return 'há ' + months + ' meses';
    } else if (days <= 545) {
      return 'há um ano';
    } else { // (days > 545)
      return 'há ' + years + ' anos';
    }
  }


  ngOnDestroy(): void {
    this.removeTimer();
  }

  private removeTimer() {
    if (this.timer) {
      window.clearTimeout(this.timer);
      this.timer = null;
    }
  }

  private getSecondsUntilUpdate(seconds: number) {
    const min = 60;
    const hr = min * 60;
    const day = hr * 24;

    if (seconds < min) { // less than 1 min, update every 2 secs
      return 2;
    } else if (seconds < hr) { // less than an hour, update every 30 secs
      return 30;
    } else if (seconds < day) { // less then a day, update every 5 mins
      return 300;
    } else { // update every hour
      return 3600;
    }
  }

}
