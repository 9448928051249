import { getDate } from './../../system/date';
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { CalendarService } from '../../system/services/calendar.service';
import { Router } from '@angular/router';
import { ConfirmActionComponent } from '../../confirm-action/confirm-action.component';
import * as moment from 'moment';

@Component({
  selector: 'app-re-schedule',
  templateUrl: './re-schedule.component.html',
  styleUrls: ['./re-schedule.component.scss']
})
export class ReScheduleComponent implements OnInit {

  eId: string;
  delete = false;
  deleted = false;
  rescheduled: any;
  durationList = [];
  duration: any;
  warning = false;
  message = 'nabia.general.confirmEventDelete';

  constructor(
    private calendarService: CalendarService,
    private route: Router,
    public dialog: MatDialog,
    public thisDialogRef: MatDialogRef<ReScheduleComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {

    this.rescheduled = data.rescheduled;
  }

  ngOnInit() {
    this.defaultDuration();
    this.createDurantions();
  }

  defaultDuration() {
    const durationEpoch = (this.data.endDate - this.data.startDate) / 1000 / 60;
    this.duration = { value: durationEpoch, label: durationEpoch + ' min' }
  }

  createDurantions() {
    let count = 15;
    for (let i = 0; i < 16; i++) {
      this.durationList.push({ value: count, label: count + ' min' });
      count += 15;
    }


  }

  cancelEvent(eId) {
    this.eId = eId;
    const body = { eventState: 'e3f714cc-ca7c-11e8-9bda-0242ac120003' };
    this.calendarService.cancelEvent(eId, body).subscribe(reply => {
      this.deleted = true;
      this.thisDialogRef.close(this.deleted);
    });
  }

  rescheduleEvent(id: string) {
    // const body = { eventState: 'e401d466-ca7c-11e8-9bda-0242ac120003' };
    // this.calendarService.rescheduleEvent(id, body).subscribe(reply => {
    // });
    const origin = (this.data.origin) ? this.data.origin : 'reschedule';
    this.route.navigate(['calendar/appointment', origin, this.data.idAppointment, this.data.idEvent]);
    this.onCloseCancel();
  }

  changeDuration() {
    if (this.warning === false) {
      let durationEpoch = this.duration.value * 60 * 1000;
      const formattedDate = getDate(this.data.startDate, false);
      const sessionDuration = durationEpoch;
      if (getDate(this.data.createDate) < getDate("2019-02-04") && formattedDate.isDST()) {
        durationEpoch += 3600000;
      }

      const endDate = this.data.startDate + durationEpoch;
      const body = {
        duration: sessionDuration,
        endDate: endDate
      };

      this.calendarService.changeEvent(this.data.idEvent, body).subscribe(reply => {
        this.onCloseCancel();
      });
    }
  }

  checkEventOverlay() {
    const durationEpoch = this.duration.value * 60 * 1000;
    const endDate = this.data.startDate + durationEpoch;
    this.warning = false;
    this.data.room.data.forEach(elem => {
      if (endDate > elem.startDate && endDate < elem.endDate && elem.idEvent !== this.data.idEvent) {
        this.warning = true;
      }
    });
  }


  // Call message component
  openConfirmDialog(msg: string) {
    const message = msg;
    const dialogRef = this.dialog.open(ConfirmActionComponent, {
      data: message,
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.cancelEvent(this.data.idEvent);
      } else {
      }

    });
  }

  onCloseCancel() {
    this.thisDialogRef.close();
  }

}
