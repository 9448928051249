import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Http} from "@angular/http";
import {SessionService} from "../../services/session.service";
import {HttpClient} from "@angular/common/http";
import {Endpoints} from "../../configurations";

@Component({
  selector: 'app-consent',
  template: '',
})
export class ConsentComponent implements OnInit {

  constructor(
      public route: ActivatedRoute,
      public http: HttpClient,
      public router: Router,
      public sessionService: SessionService
  ) {}

  ngOnInit() {
    const options = {
      ...this.sessionService.getOptions(),
      params:  {
        consent_challenge: this.route.snapshot.queryParams['consent_challenge'],
      }
    };
    const url     = Endpoints.AUTH.CONSENT;

    this.http.get(url, options).subscribe((result: any) => {
      window['location'].href = result.redirect_to;
    });
  }
}
