import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material';
import { OriginsModalGenericComponent } from './origins-modal/origins-modal-generic.component';

@Component({
	selector: 'app-origins-generic',
	templateUrl: './origins.generic.component.html',
	styleUrls: ['./origins.generic.component.scss']
})
export class OriginsGenericComponent implements OnInit {
	@Input() originData = [];
	@Output() originDataChange = new EventEmitter();
	selected: string = 'Selecionar';

	constructor(public dialog: MatDialog) { }

	ngOnInit() {
	   this.initializeOriginData();
	}

	ngOnChanges(changes: SimpleChanges) {
		this.initializeOriginData();
		this.changeLabel(this.originData.length);
    }

	openDialog(): void {
		this.initializeOriginData();
		this.changeLabel(this.originData.length);
		const dialogRef = this.dialog.open(OriginsModalGenericComponent, {
			data: {
				originData: this.originData
			},
      		disableClose: true
		});
		dialogRef.afterClosed().subscribe(result => {
			if (result) {
				this.changeLabel(result.length);
			}

			this.originDataChange.emit(result);
		});
	}

	changeLabel(dataSize) {
		dataSize = dataSize || 0;
		this.selected = (dataSize > 0) ? ("Selecionadas " + dataSize) : 'Selecionar';
	}

	initializeOriginData() {
		if (!this.originData) {
			this.originData = [];
		}
	}

}
