import { Injectable } from '@angular/core';
import {SessionService} from "../../nabia/system/services/session.service";

@Injectable()
export class MenuService {

    menuItems: Array<any>;

    constructor(
        public sessionService: SessionService
    ) {
        this.menuItems = [];
    }

    addMenu(items: Array<{
        text        : string,
        heading?    : boolean,
        link?       : string,     // internal route links
        elink?      : string,    // used only for external links
        target?     : string,   // anchor target="_blank|_self|_parent|_top|framename"
        icon?       : string,
        alert?      : string,
        permission? : {
            action  : string,
            path    : string
        },
        submenu?: Array<any>
    }>) {
        items.forEach((item) => {
                this.menuItems.push(item);
        });
    }

    getMenu() {
        return this.menuItems;
    }

}
