import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ConfigurationsService } from '@app/nabia/system/services/configurations.service';

@Component({
  selector: 'app-appointment-modal',
  templateUrl: './appointment-modal.component.html',
  styleUrls: ['./appointment-modal.component.scss'],
})
export class AppointmentModalComponent implements OnInit {
  dados = [];
  returnValue: any= [];
  disableButton = true;

  constructor(
    public thisDialogRef: MatDialogRef<AppointmentModalComponent>,
    public configurationService: ConfigurationsService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    this.getCalendarState();
  }

  getCalendarState() {
    this.configurationService.getAllCalendarState().subscribe((oppEtapas: any) => {
      oppEtapas.sort((a, b) => {
        if (a.name > b.name) {
          return 1;
        }
        if (a.name < b.name) {
          return -1;
        }
        return 0;
      });
      this.dados = oppEtapas.map(elem => {
        return { label: elem.name, value: elem.id };
      });
    });
  }

  applyUpdate() {
    if (this.returnValue.length == 0)
      this.thisDialogRef.close(this.dados.map(elem => elem.value));
    else
      this.thisDialogRef.close(this.returnValue.map(elem => elem.value));
  }

  onCloseCancel(answer: boolean) {
    this.thisDialogRef.close(answer);
  }
}
