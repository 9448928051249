import { Injectable } from '@angular/core';
import { DataService } from '../../system/services/data.service';
import { genTypes } from '../../system/configurations';

@Injectable({
  providedIn: 'root'
})
export class ConfigurationsCacheService {

  constructor(
    private dataBaseService: DataService
  ) { }

  getLeadStates(forceLoad?: boolean): Promise<Array<any>> {
    return new Promise<any[]>((resolve, reject) => {
      const leadStates = this.checkLocalStorage('leadStates');
      if (leadStates && !forceLoad) {
        resolve(leadStates);
      } else {
        this.dataBaseService.getConfigurationByType(genTypes.GEN_CONFIG_TYPES.LEADS_STATE_CONFIG_ID).subscribe((leadStatesReply: Array<any>) => {
          this.storeToLocalStorage('leadStates', leadStatesReply.sort(this.sortGenericConfigByOrder));
          resolve(leadStatesReply);
        });
      }
    });
  }

  async getOrigins(forceLoad?: boolean, active = null): Promise<Array<any>> {
    try {
      forceLoad = true;
      const origins = this.checkLocalStorage('origins');
      if (origins && !forceLoad) {
        if(active === true){
          return origins.filter(aux => aux.active == 1);
        }else if(active === false){
          return origins.filter(aux => aux.active == 0);
        }
        return origins;
      } else {
        const newOrigins: any[] = await Promise.all([
          this.dataBaseService.getConfigurationByType(genTypes.GEN_CONFIG_TYPES.ORIGIN_ID).toPromise(),
          this.dataBaseService.getConfigurationByType(genTypes.GEN_CONFIG_TYPES.ORIGIN_INFLUENCER_ID).toPromise()
        ]);
        const originsReply = [
          ...newOrigins[0],
          ...newOrigins[1],
        ];
        this.storeToLocalStorage('origins', originsReply.sort(this.sortGenericConfig));
        if(active === true){
          return originsReply.filter(aux => aux.active == 1);
        }else if(active === false){
          return originsReply.filter(aux => aux.active == 0);
        }
        return originsReply;
      }
    }catch (e) {
      throw e
    }
  }

  getChannels(forceLoad?: boolean): Promise<Array<any>> {
    return new Promise<any[]>((resolve, reject) => {
      const channels = this.checkLocalStorage('channels');
      if (channels && !forceLoad) {
        resolve(channels);
      } else {
        this.dataBaseService.getConfigurationByType(genTypes.GEN_CONFIG_TYPES.COM_CHANNEL_ID).subscribe((channelsReply: Array<any>) => {
          this.storeToLocalStorage('channels', channelsReply.sort(this.sortGenericConfig));
          resolve(channelsReply);
        });
      }
    });
  }

  getClinics(forceLoad?: boolean): Promise<Array<any>> {
    return new Promise<any[]>((resolve, reject) => {
      const clinics = this.checkLocalStorage('clinics');
      if (clinics && !forceLoad) {
        resolve(clinics);
      } else {
        this.dataBaseService.getClinics().subscribe((clinicsReply: Array<any>) => {
          this.storeToLocalStorage('clinics', clinicsReply.sort(this.sortGenericConfig));
          resolve(clinicsReply);
        });
      }
    });
  }

  getStaff(forceLoad?: boolean): Promise<Array<any>> {
    return new Promise<any[]>((resolve, reject) => {
      const staff = this.checkLocalStorage('staff');
      if (staff && !forceLoad) {
        resolve(staff);
      } else {
        this.dataBaseService.getAllStaff().subscribe((staffReply: Array<any>) => {
          this.storeToLocalStorage('staff', staffReply.sort(this.sortGenericConfig));
          resolve(staffReply);
        });
      }
    });
  }

  checkLocalStorage(key: string): Array<any> {
    if (typeof Storage !== void (0)) {
      try {
        const localStorageData = localStorage.getItem(key);
        if (localStorageData) {
          return JSON.parse(unescape(atob(localStorageData)));
        } else {
          return null;
        }
      } catch (e) {
        console.log(e);
        return null;
      }
    }
    else {
      return null;
    }
  }

  storeToLocalStorage(key: string, value: any): void {
    if (typeof Storage !== void (0)) {
      try {
        const localStorageData = btoa(escape(JSON.stringify(value)));
        localStorage.setItem(key, localStorageData);
      } catch (e) {
        console.error(e);
      }
    }
  }

  sortGenericConfig(a: any, b: any) {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  }

  sortGenericConfigByOrder(a: any, b: any) {
    if (a.order < b.order) {
      return -1;
    }
    if (a.order > b.order) {
      return 1;
    }
    return 0;
  }
}
