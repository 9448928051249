import { environment, PROTOCOL_SAFE, IP_REMOTE } from '../../../environments/environment';

export const defaultUrl = '/calendar';
export const defaultLoginUrl = '/login';
export const defaultOrigin = 'Site';
export const defaultCommunicationChannel = 'Internet';
export const defaultUrlWebSocket = `/reports-wss/socket.io`;

export const TENANT = 'b07d033e-da0e-43b4-b5e4-86030ec7f0ca';
// FIXME: ALl these configs must be verified on deployment
export const genTypes = {
  SYSTEM: '5e88fc19-a10d-11ea-949c-0242ac120002',
  CONSULTATION_APPOINTMENT_TREATMENT_CATEGORY: '9fb5c0d3-f030-11e9-9b08-0242ac120002',
  EVALUATION_APPOINTMENT_TREATMENT_CATEGORY: '3fd1ec29-e5c7-11e8-9e28-0242ac120003',
  DEFAULT_STATUS: '8577e9d7-c993-11e8-8224-0242ac12000b',
  DEFAULT_OPPORTUNITIES_STATUS_ID: 'a7c37694-ca3f-11e8-9bda-0242ac120003',
  DEFAULT_COMMUNICATION_CHANNEL_ID: 'df7a7691-05ea-40a1-8b59-434ebe72dca0',
  DEFAULT_CLINIC_ID: 'df7a7691-05ea-40a1-8b59-434ebe72dca0', // this HAS TO GO
  DEFAULT_ORIGIN_ID: 'df7a7691-05ea-40a1-8b59-434ebe72dca0',
  DEFAULT_PROFESSION_ID: '064ed699-8313-4786-9d32-1fea5655fea1',
  DEFAULT_VAT_ID: '123456789',
  DEFAULT_INITIATE_ID: 'e3e291c2-ca7c-11e8-9bda-0242ac120003',
  DEFAULT_SCHEDULED: '9ab4232a-c3ff-4504-984b-ada6c7650801',
  DEFAULT_RESCHEDULED: 'cf6c4c10-7f85-4c1d-b7fd-63bdb90187f6',
  DEFAULT_TROCAS_RESPONSIBLE: '3d27c5a2-3030-4f5b-aa22-f175a94dca4a',
  DEFAULT_TECNICAL_DEPARTMENT: {
    scheduledBy: '0a08fb21-c983-11e8-9bda-0242ac120003',
    type: 'b32dc882-ca3d-11e8-9bda-0242ac120003',
    origin: 'c0c8943a-c981-11e8-9bda-0242ac120003',
  },
  LEAD_STATE: {
    FINISHED: '54394d31-78f7-4be8-b493-a6d77bc51a11',
  },
  TREATMENT_TYPES: {
    FOLLOWUP_ID: '9366b148-2f7a-11e9-9fb1-0242ac12000a',
    FACE: 'fd3c9df8-d93f-11e8-9e28-0242ac120003',
    BODY: 'fd174eb2-d93f-11e8-9e28-0242ac120003',
    DEPI: 'fd234b19-d93f-11e8-9e28-0242ac120003',
    AESTHETIC: '3726ca83-4ebd-11eb-ba5e-5ccd5ba725d8',
  },
  CONSULTATIONS: {
    AESTHETIC: 'd5db8ab0-f031-11e9-9b08-0242ac120002',
    TECHNICAL: 'd59d6c91-f031-11e9-9b08-0242ac120002',
    YIELD: 'd583aa4e-f031-11e9-9b08-0242ac120002',
    MARKETING: 'd55e26f8-f031-11e9-9b08-0242ac120002',
  },
  CONSULTATIONS_TYPE_LIST: {
    '3726ca83-4ebd-11eb-ba5e-5ccd5ba725d8': 'Medicina Estética - Avaliação',
    'd59d6c91-f031-11e9-9b08-0242ac120002': 'Departamento Interno',
    'd583aa4e-f031-11e9-9b08-0242ac120002': 'Cedência',
    'd55e26f8-f031-11e9-9b08-0242ac120002': 'Marketing',
    '9366b148-2f7a-11e9-9fb1-0242ac12000a': 'Follow-Up',
  },
  TREATMENT_TYPES_LIST: {
    'fd3c9df8-d93f-11e8-9e28-0242ac120003': 'Rosto - Avaliação',
    'fd174eb2-d93f-11e8-9e28-0242ac120003': 'Corpo - Avaliação',
    'fd234b19-d93f-11e8-9e28-0242ac120003': 'Depilação - Avaliação',
  },
  OPPORTUNITIES_PARTIAL: '239c1c86-6ce4-11e9-9d0f-0242ac120002',
  OPPORTUNITIES_LOST: 'b6206879-35ca-11e9-9fb1-0242ac12000a',
  GEN_CONFIG_TYPES: {
    CALENDAR_VIEW_TYPE: '329c2d5c-e868-11ea-8fe4-0242ac120002',
    CALENDAR_STATE: 'c4983961-ca7b-11e8-9bda-0242ac120003',
    COM_CHANNEL_ID: '565f2d63-70df-45b2-8aac-e1a6ed35b70d',
    CONTRAINDICATION_ID: 'b43a990c-acb5-4940-a9ce-aeaa25d899ab',
    DOCUMENT_TYPES: 'f32e7aaa-f53a-471f-894c-6c6924c1aaa5',
    OTHER_DOCUMENT: '9d8e0fae-cc78-11e8-9bda-0242ac120003',
    INFO_TYPES: '4f239b77-d37e-44c0-b77e-0d87be94d1d0',
    INFO_CONSULTANT_TYPE: '49e39173-2fae-11e9-97c3-0242ac120004',
    LEADS_STATE_CONFIG_ID: '185b13e2-20f0-40d6-9c02-e97482634413',
    LEADS_STATUS_ID: '185b13e2-20f0-40d6-9c02-e97482634413',
    LEADS_FINAL_STATUS_ID: '54394d31-78f7-4be8-b493-a6d77bc51a11',
    LEADS_BILLED_STATUS_ID: 'be804ce6-1038-11ea-9b08-0242ac120002',
    LEADS_NO_SHOW_STATUS_ID: '706fade5-7fcd-464c-8a7f-720cbbea26f5',
    LEADS_LOST_STATUS_ID: '9510d754-3426-11e9-8c6a-0242ac12000c',
    OPPORTUNITIES_STATUS_ID: '01fa1805-81de-4918-89ec-3feaaf4154dc',
    OPPORTUNITIES_DEFAULT_STATUS: 'a7a7006c-ca3f-11e8-9bda-0242ac120003',
    OPPORTUNITIES_TYPE_ID: '058c053b-d0d9-4390-9bde-61ecd54da395',
    OPPORTUNITIES_LOST_REASON_TYPE_ID: 'g32e7aaa-f53a-471f-894c-6c6924c1aaa5',
    OPPORTUNITIES_WON_STATUS_ID: 'a27fa4d4-3a72-11e9-8c6a-0242ac12000c',
    OPPORTUNITIES_SEDE_STATUS_ID: 'a800fff5-ca3f-11e8-9bda-0242ac120003',
    OPPORTUNITIES_LOST_STATUS_ID: 'b6206879-35ca-11e9-9fb1-0242ac12000a',
    ORIGIN_ID: '6902bdf9-f2b9-45a3-bc8a-f99ad8e89f22',
    ORIGIN_MARKETING_ID: 'ef0d4f38-50da-478e-a27c-6eccf3b3c550',
    ORIGIN_INFLUENCER_ID: 'a3905ddd-c9e8-4b07-983a-382fdeddf8e9',
    PAYMENT_STATUS_ID: '67ee9a77-5a76-4dca-977b-5c74a271f3ef',
    PAYMENT_ACCOUNTS: '0f76f2e8-1e39-11e9-81c7-0242ac120006',
    PROFESSION_ID: '1dacecc9-a168-4b95-af19-0bb595c8ede3',
    PROBLEM_ID: 'baf36778-184c-4815-8673-1cddcc618c34',
    TASK_STATUS: '99726b65-98f9-4702-baa3-c81af1215bfa',
    TASK_TYPES: '6e2dfb11-5f9e-43e4-a790-b589e61ca407',
    DEDUCTION_TYPES: 'db0f63ab-c6cf-498a-b559-ca68ad876f99',
    TAX_CONFIGURATION_TYPE_ID: '808ec0da-1013-4ee8-8a28-9e9ba724ca21',
  },
  MARKETING_GEN_STATUS: {
    FACEBOOK: 'da271970-1750-4282-9bb6-5b164b1fd749',
    FACEBOOK_CONTENT: 'da271970-1750-4282-9bb6-5b164b1fd749',
    GOOGLE: 'd2e9d112-6658-4a9c-9457-bd9d70d25ce1',
    EGOI: '0598f8b2-a317-4466-91c1-6c0444e604a3',
  },
};

export const etapaTypes = {
  initial: '9a5a3cf9-e2a6-11ea-8fe4-0242ac120002',
  temporary: 'a6899868-e2a6-11ea-8fe4-0242ac120002',
  lost: 'add7ef19-e2a6-11ea-8fe4-0242ac120002',
  win: '25eaed93-e3d5-11ea-8fe4-0242ac120002',
  confirmSede: '2cf20820-e3d5-11ea-8fe4-0242ac120002',
};

export const leadStateypes = {
  initial: '5f04ed2c-02f0-11eb-9994-0242ac120008',
  temporary: '5f25cdad-02f0-11eb-9994-0242ac120008',
  lost: '5f4718c5-02f0-11eb-9994-0242ac120008',
  won: '5f67ad17-02f0-11eb-9994-0242ac120008',
  calendar: '5f8814d7-02f0-11eb-9994-0242ac120008',
};

export const resourceTypes = {
  room: 'c8217948-b5e9-11e8-8fdb-0242ac120002',
  machine: 'c82812b4-b5e9-11e8-8fdb-0242ac120002',
  problem: 'a5fd24c0-c301-11e8-b347-0242ac120002',
};

export const Endpoints = {
  TENANT: {
    GET_TENANT_BY_ID: `${PROTOCOL_SAFE}://${IP_REMOTE}/api/tenants/`,
  },
  AUTH: {
    AUTH: environment.AUTH_URL,
    USER: (id: string) => `${PROTOCOL_SAFE}://${IP_REMOTE}/api/staff/${id}`,
    USER2: (id: string) => `${PROTOCOL_SAFE}://${IP_REMOTE}/control/register/${id}`,
    LOGIN: `${PROTOCOL_SAFE}://${IP_REMOTE}/control/login`,
    CONSENT: `${PROTOCOL_SAFE}://${IP_REMOTE}/control/consent`,
    REGISTER: (id: string) => `${PROTOCOL_SAFE}://${IP_REMOTE}/control/register/${id}`,
    PRE_REGISTER: `${PROTOCOL_SAFE}://${IP_REMOTE}/api/staff`, // GET staff, POST staff
    RESET: (id: string) => `${PROTOCOL_SAFE}://${IP_REMOTE}/register?user_id=${id}`,
  },
  FILES: {
    UPLOAD: `${PROTOCOL_SAFE}://${IP_REMOTE}/api/files`,
    DOWNLOAD: url => `${PROTOCOL_SAFE}://${IP_REMOTE}${url}`,
  },
  TENANTS: {
    TENANTS: () => `${PROTOCOL_SAFE}://${IP_REMOTE}/api/tenants`,
    TENANT: (tId: string) => `${PROTOCOL_SAFE}://${IP_REMOTE}/api/tenants/${tId}`,
  },
  STAFF: {
    STAFF: `${PROTOCOL_SAFE}://${IP_REMOTE}/api/staff`, // GET staff, POST staff
    STAFF_ID: id => `${PROTOCOL_SAFE}://${IP_REMOTE}/api/staff/${id}`, // GET staff, POST staff
    STAFF_INFO: `${PROTOCOL_SAFE}://${IP_REMOTE}/api/staff/self`, // GET
    GET_STAFF: (sId: string) => `${PROTOCOL_SAFE}://${IP_REMOTE}/api/staff/${sId}`, // GET staff, POST staff
    UPDATE_STAFF: (sId: string) => `${PROTOCOL_SAFE}://${IP_REMOTE}/api/staff/${sId}/update`, // GET staff, POST staff
    STAFF_ROLE: (sId: string) => `${PROTOCOL_SAFE}://${IP_REMOTE}/api/staff/${sId}/role`, // GET staff, POST staff
    DELETE_STAFF: (sId: string) => `${PROTOCOL_SAFE}://${IP_REMOTE}/api/staff/${sId}/hide`, // PUT staff
    STAFF_BATCH: `${PROTOCOL_SAFE}://${IP_REMOTE}/api/staff/batch`, // POST staff
    STAFF_SEARCH: name => `${PROTOCOL_SAFE}://${IP_REMOTE}/api/staff?&name=${name}`,
    STAFF_AVAILABILITY: sId => `${PROTOCOL_SAFE}://${IP_REMOTE}/api/staff/${sId}/availabilities`,
    STAFF_AVAILABILITY_DELETE: (sId, aId) => `${PROTOCOL_SAFE}://${IP_REMOTE}/api/staff/${sId}/availabilities/${aId}`,
    STAFF_UNAVAILABILITY: sId => `${PROTOCOL_SAFE}://${IP_REMOTE}/api/staff/${sId}/unavailabilities`,
    STAFF_UNAVAILABILITY_DELETE: (sId, aId) =>
      `${PROTOCOL_SAFE}://${IP_REMOTE}/api/staff/${sId}/unavailabilities/${aId}`,
    STAFF_TREATMENT: (sId, tId) => `${PROTOCOL_SAFE}://${IP_REMOTE}/api/staff/${sId}/treatment/${tId}`,
  },
  CLIENTS: {
    HISTORY: (cId: string) => `${PROTOCOL_SAFE}://${IP_REMOTE}/api/audit/clients/${cId}`,
    USERS: {
      USERS: `${PROTOCOL_SAFE}://${IP_REMOTE}/api/clients/users`, // GET POST
      USERS_PAGINATION: `${PROTOCOL_SAFE}://${IP_REMOTE}/api/clients/users/pagination`, // GET POST
      USERS_FILTER: (filter: string) => `${PROTOCOL_SAFE}://${IP_REMOTE}/api/clients/users/${filter}`, // GET POST
      USER: (uId: string) => `${PROTOCOL_SAFE}://${IP_REMOTE}/api/clients/users/${uId}`, // GET user; PUT edita
      USER_EVENTS: (uId: string) => `${PROTOCOL_SAFE}://${IP_REMOTE}/api/clients/users/${uId}/events `, // GET user; PUT edita
      USER_TASKS: (uId: string) => `${PROTOCOL_SAFE}://${IP_REMOTE}/api/clients/users/${uId}/tasks`, // GET lista; POST cria
      USER_LEADS: (uId: string) => `${PROTOCOL_SAFE}://${IP_REMOTE}/api/clients/users/${uId}/leads`, // GET lista
      USER_TASKS_UPDATE: (uId: string, tId: string) =>
        `${PROTOCOL_SAFE}://${IP_REMOTE}/api/clients/users/${uId}/tasks/${tId}`, // PUT update, DELETE remove
      USER_TASKS_BATCH: (uId: string) => `${PROTOCOL_SAFE}://${IP_REMOTE}/api/clients/users/${uId}/tasks/batch`, // POST [ids] tasks
      USER_NOTES: (uId: string) => `${PROTOCOL_SAFE}://${IP_REMOTE}/api/clients/users/${uId}/notes`, // GET lista; POST cria
      USER_NOTES_UPDATE: (uId: string, nId: string) =>
        `${PROTOCOL_SAFE}://${IP_REMOTE}/api/clients/users/${uId}/notes/${nId}`, // PUT update, DELETE remove
      USER_NOTES_BATCH: (uId: string) => `${PROTOCOL_SAFE}://${IP_REMOTE}/api/clients/users/${uId}/notes/batch`, // POST [ids] notes
      USER_NOTES_FAVORITE: (uId: string) => `${PROTOCOL_SAFE}://${IP_REMOTE}/api/clients/users/${uId}/notes/favorite`, // POST [ids] notes
      USER_SESSIONS: (uId: string) => `${PROTOCOL_SAFE}://${IP_REMOTE}/api/clients/users/${uId}/sessions`, // GET
      USER_DEDUCTION: (oId: string) => `${PROTOCOL_SAFE}://${IP_REMOTE}/api/opportunities/${oId}/deductions`, // POST Create deduction
      USER_DEDUCTIONS: (uId: string, dId: string) =>
        `${PROTOCOL_SAFE}://${IP_REMOTE}/api/clients/users/${uId}/deductions/${dId}`, // DELETE remove
      USER_DEDUCTIONS_BATCH: (uId: string) =>
        `${PROTOCOL_SAFE}://${IP_REMOTE}/api/clients/users/${uId}/deductions/batch`, // POST [ids] users
      USER_OPPORTUNITIES: (uId: string) => `${PROTOCOL_SAFE}://${IP_REMOTE}/api/clients/users/${uId}/opportunities`, // GET
      USERS_BATCH: `${PROTOCOL_SAFE}://${IP_REMOTE}/api/clients/users/batch`, // POST [ids] users
      FILES_BATCH: (cId: string) => `${PROTOCOL_SAFE}://${IP_REMOTE}/api/clients/users/${cId}/documents/batch`, // RealTime
      FILES: (cId: string) => `${PROTOCOL_SAFE}://${IP_REMOTE}/api/clients/users/${cId}/documents/`,
      FILE: (cId: string, dId: string) => `${PROTOCOL_SAFE}://${IP_REMOTE}/api/clients/users/${cId}/documents/${dId}`, // GET file, DELETE file
      CHECKING: (cId: string) => `${PROTOCOL_SAFE}://${IP_REMOTE}/api/clients/users/${cId}/currentAccount`,
      CREDITS: (cId: string) => `${PROTOCOL_SAFE}://${IP_REMOTE}/api/clients/users/${cId}/credits`,
      POINTS: (cId: string) => `${PROTOCOL_SAFE}://${IP_REMOTE}/api/clients/users/${cId}/points`,
      POINTS_BATCH: (cId: string) => `${PROTOCOL_SAFE}://${IP_REMOTE}/api/clients/users/${cId}/points/batch`,
      AGGREGATE: (aggregatingUser: string, aggregatedUser: string) =>
        `${PROTOCOL_SAFE}://${IP_REMOTE}/api/clients/users/${aggregatingUser}/aggregate/${aggregatedUser}`,
      USER_ADD_CREDIT: (uId: string) => `${PROTOCOL_SAFE}://${IP_REMOTE}/api/clients/users/${uId}/addCredit`,
    },
    LEADS: {
      LEADS: `${PROTOCOL_SAFE}://${IP_REMOTE}/api/clients/leads`,
      LEADS_PAGINATION: `${PROTOCOL_SAFE}://${IP_REMOTE}/api/clients/leads/pagination`,
      LEADS_EXPORT: (filters: string) => `${PROTOCOL_SAFE}://${IP_REMOTE}/api/clients/leads/export/${filters}`, // ?????
      LEADS_FILTER: (filters: string) => `${PROTOCOL_SAFE}://${IP_REMOTE}/api/clients/leads/${filters}`,
      LEAD: (lId: string) => `${PROTOCOL_SAFE}://${IP_REMOTE}/api/clients/leads/${lId}`, // GET lead; PUT edita
      LEAD_DEAGGREGATE: (lId: string) => `${PROTOCOL_SAFE}://${IP_REMOTE}/api/clients/leads/${lId}/deaggregate`, // GET lead; PUT edita
      LEAD_TASKS: (lId: string) => `${PROTOCOL_SAFE}://${IP_REMOTE}/api/clients/leads/${lId}/tasks`, // GET tasks; POST cria
      LEAD_TASKS_BATCH: (lId: string) => `${PROTOCOL_SAFE}://${IP_REMOTE}/api/clients/leads/${lId}/tasks/batch`, // POST [ids] tasks
      LEAD_NOTES: (lId: string) => `${PROTOCOL_SAFE}://${IP_REMOTE}/api/clients/leads/${lId}/notes`, // GET lista; POST cria
      LEAD_NOTES_BATCH: (lId: string) => `${PROTOCOL_SAFE}://${IP_REMOTE}/api/clients/leads/${lId}/notes/batch`, // POST [ids] notes
      LEADS_BATCH: `${PROTOCOL_SAFE}://${IP_REMOTE}/api/clients/leads/batch`, // POST [ids] leads
      FILES_BATCH: (cId: string) => `${PROTOCOL_SAFE}://${IP_REMOTE}/api/clients/leads/${cId}/documents/batch`,
      FILES: (cId: string) => `${PROTOCOL_SAFE}://${IP_REMOTE}/api/clients/leads/${cId}/documents/`,
      FILE: (cId: string, dId: string) => `${PROTOCOL_SAFE}://${IP_REMOTE}/api/clients/leads/${cId}/documents/${dId}`, // GET file, DELETE file
      LEAD_PRODUCTS_BATCH: (cId: string) => `${PROTOCOL_SAFE}://${IP_REMOTE}/api/clients/leads/${cId}/products/batch`, // GET, POST
      LEAD_PRODUCTS: (cId: string) => `${PROTOCOL_SAFE}://${IP_REMOTE}/api/clients/leads/${cId}/products`, // GET, POST
      LEAD_PRODUCT: (cId: string, pId: string) =>
        `${PROTOCOL_SAFE}://${IP_REMOTE}/api/clients/leads/${cId}/products/${pId}`, // GET, PUT, DELETE
      LEAD_SERVICES_BATCH: (cId: string) => `${PROTOCOL_SAFE}://${IP_REMOTE}/api/clients/leads/${cId}/services/batch`, // GET, POST
      LEAD_SERVICES: (cId: string) => `${PROTOCOL_SAFE}://${IP_REMOTE}/api/clients/leads/${cId}/services`, // GET, POST
      LEAD_SERVICE: (cId: string, pId: string) =>
        `${PROTOCOL_SAFE}://${IP_REMOTE}/api/clients/leads/${cId}/services/${pId}`, // GET, PUT, DELETE
      LEAD_OPPORTUNITIES: (cId: string) => `${PROTOCOL_SAFE}://${IP_REMOTE}/api/clients/leads/${cId}/opportunities`, // GET
    },
  },
  CALENDAR: {
    HISTORY: (cId: string) => `${PROTOCOL_SAFE}://${IP_REMOTE}/api/audit/events/${cId}`,
    APPOINTMENTS: `${PROTOCOL_SAFE}://${IP_REMOTE}/api/calendar/appointments`,
    GET_APPOINTMENT: (aId: string) => `${PROTOCOL_SAFE}://${IP_REMOTE}/api/calendar/appointments/${aId}`,
    AVAILABILITY: (aId: string) => `${PROTOCOL_SAFE}://${IP_REMOTE}/api/calendar/availabilities/${aId}`,
    EVENTS: `${PROTOCOL_SAFE}://${IP_REMOTE}/api/calendar/events`,
    EVENTS_PAGINATION: `${PROTOCOL_SAFE}://${IP_REMOTE}/api/calendar/events/pagination`,
    GET_EVENTS: (eId: string) => `${PROTOCOL_SAFE}://${IP_REMOTE}/api/calendar/events/${eId}`,
    CLINIC_EVENTS: (cId: string) => `${PROTOCOL_SAFE}://${IP_REMOTE}/api/calendar/events?idClinic=${cId}`,
    CLINIC_EVENTS_BATCH: `${PROTOCOL_SAFE}://${IP_REMOTE}/api/calendar/events/batch`,
    CLINIC_EVENTS_BY_DATES: (cId: string, start: string, end: string) =>
      `${PROTOCOL_SAFE}://${IP_REMOTE}/api/calendar/events?idClinic=${cId}&startingTime=${start}&endingTime=${end}`,
    CLINIC_RESOURCES: (cId: string, rId: string) =>
      `${PROTOCOL_SAFE}://${IP_REMOTE}/api/configurations/resources?idClinic=${cId}&idType=${rId}`,
    CANCEL_EVENT: (eId: string) => `${PROTOCOL_SAFE}://${IP_REMOTE}/api/calendar/events/${eId}/cancel`,
    RESCHEDULE_EVENT: (eId: string) => `${PROTOCOL_SAFE}://${IP_REMOTE}/api/calendar/events/${eId}/reschedule`,
    UPDATE_EVENT: (eId: string) => `${PROTOCOL_SAFE}://${IP_REMOTE}/api/calendar/appointments/${eId}`,
    EVENT_STATE: (eId: string) => `${PROTOCOL_SAFE}://${IP_REMOTE}/api/calendar/events/${eId}/state`,
    EVENT_ISONLINE: (eId: string) => `${PROTOCOL_SAFE}://${IP_REMOTE}/api/calendar/events/${eId}/isonline`,
    EVENT_DURATION: (eId: string) => `${PROTOCOL_SAFE}://${IP_REMOTE}/api/calendar/events/${eId}/duration`,
    EVENT_MISSING: (idClinic: string) =>
      `${PROTOCOL_SAFE}://${IP_REMOTE}/api/calendar/events/missing/resource/${idClinic}`,
    EVENT_RESPONSIBLE: (eId: string) => `${PROTOCOL_SAFE}://${IP_REMOTE}/api/calendar/events/${eId}/responsible`,
    EXPORT_EVENTS: (uId: string) => `${PROTOCOL_SAFE}://${IP_REMOTE}/api/calendar/events/export/${uId}`,
  },
  OPPORTUNITIES: {
    HISTORY: (oId: string) => `${PROTOCOL_SAFE}://${IP_REMOTE}/api/audit/opportunities/${oId}`,
    OPPORTUNITIES: `${PROTOCOL_SAFE}://${IP_REMOTE}/api/opportunities/`, // ?????
    OPPORTUNITIES_GET_ALL: `${PROTOCOL_SAFE}://${IP_REMOTE}/api/opportunities/getAllOpportunities`, // ?????
    SUM_PAGINATION: `${PROTOCOL_SAFE}://${IP_REMOTE}/api/opportunities/sumPagination`, // ?????
    OPPORTUNITIES_EXPORT: (filters: string) => `${PROTOCOL_SAFE}://${IP_REMOTE}/api/opportunities/export/${filters}`, // ?????
    OPPORTUNITIES_FILTER: (filters: string) => `${PROTOCOL_SAFE}://${IP_REMOTE}/api/opportunities/${filters}`, // ?????
    OPPORTUNITY: (oId: string) => `${PROTOCOL_SAFE}://${IP_REMOTE}/api/opportunities/${oId}`,
    OPPORTUNITY_DELETE: (oId: string) => `${PROTOCOL_SAFE}://${IP_REMOTE}/api/opportunities/${oId}`,
    OPPORTUNITIES_BATCH: `${PROTOCOL_SAFE}://${IP_REMOTE}/api/opportunities/batch`, // POST [ids] leads
    OPPORTUNITY_OFFERS: (oId: string) => `${PROTOCOL_SAFE}://${IP_REMOTE}/api/opportunities/${oId}/getOffer`,
    OPPORTUNITY_DEDUCTIONS: (oId: string) => `${PROTOCOL_SAFE}://${IP_REMOTE}/api/opportunities/${oId}/deductions`,
    OPPORTUNITY_DEDUCTIONS_BATCH: (oId: string) =>
      `${PROTOCOL_SAFE}://${IP_REMOTE}/api/opportunities/${oId}/deductions/batch`,
    OPPORTUNITY_PRODUCTS: (cId: string) => `${PROTOCOL_SAFE}://${IP_REMOTE}/api/opportunities/${cId}/products`,
    OPPORTUNITY_SERVICES: (cId: string) => `${PROTOCOL_SAFE}://${IP_REMOTE}/api/opportunities/${cId}/services`,
    EXCHANGE_CREDIT: (cId: string) => `${PROTOCOL_SAFE}://${IP_REMOTE}/api/opportunities/${cId}/exchange/credit`,
    EXCHANGE_YIELD: (cId: string) => `${PROTOCOL_SAFE}://${IP_REMOTE}/api/opportunities/${cId}/exchange/yield`,
    EXCHANGE_SERVICE: (cId: string) => `${PROTOCOL_SAFE}://${IP_REMOTE}/api/opportunities/${cId}/exchange/service`,
    OPPORTUNITY_SERVICES_DELETE: (cId: string, sId: string) =>
      `${PROTOCOL_SAFE}://${IP_REMOTE}/api/opportunities/${cId}/services/${sId}`,
    OPPORTUNITY_SERVICES_UPDATE: (cId: string, sId: string) =>
      `${PROTOCOL_SAFE}://${IP_REMOTE}/api/opportunities/${cId}/services/${sId}`,
    OPPORTUNITY_PRODUCTS_DELETE: (cId: string, sId: string) =>
      `${PROTOCOL_SAFE}://${IP_REMOTE}/api/opportunities/${cId}/products/${sId}`,
    OPPORTUNITY_PAYMENTS: (oId: string) => `${PROTOCOL_SAFE}://${IP_REMOTE}/api/opportunities/${oId}/payments`,
    OPPORTUNITY_PAYMENTS_POINTS: (oId: string) =>
      `${PROTOCOL_SAFE}://${IP_REMOTE}/api/opportunities/${oId}/payments/points`,
    OPPORTUNITY_PAYMENT: (oId: string, pId: string) =>
      `${PROTOCOL_SAFE}://${IP_REMOTE}/api/opportunities/${oId}/payments/${pId}`,
    OPPORTUNITY_PAYMENTS_DIARY: (oId: string) =>
      `${PROTOCOL_SAFE}://${IP_REMOTE}/api/opportunities/${oId}/payments/diary`,
    OPPORTUNITY_DOCUMENTS: (oId: string, pId: string) =>
      `${PROTOCOL_SAFE}://${IP_REMOTE}/api/opportunities/${oId}/payments/${pId}/documents`, // POST
    OPPORTUNITY_PAYMENTS_INBOX: (oId: string, pId: string) =>
      `${PROTOCOL_SAFE}://${IP_REMOTE}/api/opportunities/${oId}/payments/${pId}/inbox`, // PUT marca como "Em Caixa"
    OPPORTUNITY_PAYMENTS_RECEIVED: (oId: string, pId: string, aid: string) =>
      `${PROTOCOL_SAFE}://${IP_REMOTE}/api/opportunities/${oId}/payments/${pId}/received/${aid}`, // PUT marca como "Confirmado na sede"
    OPPORTUNITY_FOLLOWER: (oId: string, sId: string) =>
      `${PROTOCOL_SAFE}://${IP_REMOTE}/api/opportunities/${oId}/follower/${sId}`,
    OPPORTUNITY_COMMERCIAL: (oId: string, cId: string) =>
      `${PROTOCOL_SAFE}://${IP_REMOTE}/api/opportunities/${oId}/commercial/${cId}`,
    OPPORTUNITY_CONVERT_PAYMENT: (oId: string) =>
      `${PROTOCOL_SAFE}://${IP_REMOTE}/api/opportunities/${oId}/convertPaymentToCredit`,
    OPPORTUNITY_INFORMATIONS_SERVICES: (oId: string) =>
      `${PROTOCOL_SAFE}://${IP_REMOTE}/api/opportunities/${oId}/getInformationAboutServices`,
  },
  CONFIGURATIONS: {
    PONTOS: `${PROTOCOL_SAFE}://${IP_REMOTE}/api/configurations/points`,
    PONTOS_SPECIFIC: (pId: string) => `${PROTOCOL_SAFE}://${IP_REMOTE}/api/configurations/points/${pId}`,
    ORIGEM: `${PROTOCOL_SAFE}://${IP_REMOTE}/api/configurations/origens`,
    ORIGEM_DELETE: `${PROTOCOL_SAFE}://${IP_REMOTE}/api/configurations/origens/delete`,
    ORIGEM_SPECIFIC: (pId: string) => `${PROTOCOL_SAFE}://${IP_REMOTE}/api/configurations/origens/${pId}`,
    VERSION_API: `${PROTOCOL_SAFE}://${IP_REMOTE}/api/version`,
    VERSION_FRONT: `${PROTOCOL_SAFE}://${IP_REMOTE}/package.json`,
    CLINIC: id => `${PROTOCOL_SAFE}://${IP_REMOTE}/api/configurations/clinics/${id}`,
    CLINICS: `${PROTOCOL_SAFE}://${IP_REMOTE}/api/configurations/clinics`,
    CATEGORIA_TRATAMENTO: (pId: string) =>
      `${PROTOCOL_SAFE}://${IP_REMOTE}/api/configurations/categoriasTratamento/${pId}`,
    DELETE_CATEGORIA_TRATAMENTO: `${PROTOCOL_SAFE}://${IP_REMOTE}/api/configurations/categoriasTratamento/delete`,
    DELETE_CLINICS: `${PROTOCOL_SAFE}://${IP_REMOTE}/api/configurations/clinicas/delete`,
    CATEGORIAS_TRATAMENTOS: `${PROTOCOL_SAFE}://${IP_REMOTE}/api/configurations/categoriasTratamento`,
    CLINICS_GENERIC: `${PROTOCOL_SAFE}://${IP_REMOTE}/api/configurations/clinicas`,
    GET_CLINIC: id => `${PROTOCOL_SAFE}://${IP_REMOTE}/api/configurations/clinicas/${id}`,
    CLINICS_PAGINATION: `${PROTOCOL_SAFE}://${IP_REMOTE}/api/configurations/clinicas`,
    CLINICS_UNAVAILABILITIES: cId =>
      `${PROTOCOL_SAFE}://${IP_REMOTE}/api/configurations/clinics/${cId}/unavailabilities`,
    CLINICS_UNAVAILABILITY: (cId, uId) =>
      `${PROTOCOL_SAFE}://${IP_REMOTE}/api/configurations/clinics/${cId}/unavailabilities/${uId}`,
    RESOURCES: `${PROTOCOL_SAFE}://${IP_REMOTE}/api/configurations/resources`,
    DELETE_RESOURCES: tId => `${PROTOCOL_SAFE}://${IP_REMOTE}/api/configurations/resources/${tId}/hide`,
    GET_RESOURCES: tId => `${PROTOCOL_SAFE}://${IP_REMOTE}/api/configurations/resources?idType=${tId}`,
    GET_PROBLEMAS: `${PROTOCOL_SAFE}://${IP_REMOTE}/api/configurations/problemas`,
    UPDATE_RESOURCES: tId => `${PROTOCOL_SAFE}://${IP_REMOTE}/api/configurations/resources/${tId}/update`,
    PROBLEMS: `${PROTOCOL_SAFE}://${IP_REMOTE}/api/configurations/problemas`,
    PROBLEMS_DELETE: `${PROTOCOL_SAFE}://${IP_REMOTE}/api/configurations/problemas/delete`,
    PROBLEMS_SPECIFIC: (pId: string) => `${PROTOCOL_SAFE}://${IP_REMOTE}/api/configurations/problemas/${pId}`,
    TENANT: `${PROTOCOL_SAFE}://${IP_REMOTE}/api/configurations/tenant`,
    TENANT_SPECIFIC: (id: string) => `${PROTOCOL_SAFE}://${IP_REMOTE}/api/configurations/tenant/${id}`,
    OCCUPATIONS: `${PROTOCOL_SAFE}://${IP_REMOTE}/api/configurations/profissoes`,
    OCCUPATIONS_DELETE: `${PROTOCOL_SAFE}://${IP_REMOTE}/api/configurations/profissoes/delete`,
    OCCUPATIONS_SPECIFIC: (oId: string) => `${PROTOCOL_SAFE}://${IP_REMOTE}/api/configurations/profissoes/${oId}`,
    TYPES_PAYMENT: `${PROTOCOL_SAFE}://${IP_REMOTE}/api/configurations/tipo-pagamento`,
    TYPES_PAYMENT_DELETE: `${PROTOCOL_SAFE}://${IP_REMOTE}/api/configurations/tipo-pagamento/delete`,
    TYPES_PAYMENT_SPECIFIC: (oId: string) => `${PROTOCOL_SAFE}://${IP_REMOTE}/api/configurations/tipo-pagamento/${oId}`,
    LOSS_REASONS: `${PROTOCOL_SAFE}://${IP_REMOTE}/api/configurations/oportunidades/razaoperda`,
    LOSS_REASONS_DELETE: `${PROTOCOL_SAFE}://${IP_REMOTE}/api/configurations/oportunidades/razaoperda/delete`,
    LOSS_REASONS_SPECIFIC: (pId: string) =>
      `${PROTOCOL_SAFE}://${IP_REMOTE}/api/configurations/oportunidades/razaoperda/${pId}`,
    CONTRAINDICATIONS: `${PROTOCOL_SAFE}://${IP_REMOTE}/api/configurations/contraindicacao`,
    CONTRAINDICATIONS_DELETE: `${PROTOCOL_SAFE}://${IP_REMOTE}/api/configurations/contraindicacao/delete`,
    CONTRAINDICATIONS_SPECIFIC: (cId: string) =>
      `${PROTOCOL_SAFE}://${IP_REMOTE}/api/configurations/contraindicacao/${cId}`,
    TAXES: `${PROTOCOL_SAFE}://${IP_REMOTE}/api/configurations/impostos`,
    TAXES_DELETE: `${PROTOCOL_SAFE}://${IP_REMOTE}/api/configurations/impostos/delete`,
    TAXES_SPECIFIC: (cId: string) => `${PROTOCOL_SAFE}://${IP_REMOTE}/api/configurations/impostos/${cId}`,
    PRODUCTS_CRUD: `${PROTOCOL_SAFE}://${IP_REMOTE}/api/configurations/produtos`,
    PRODUCTS_CRUD_DELETE: `${PROTOCOL_SAFE}://${IP_REMOTE}/api/configurations/produtos/delete`,
    PRODUCTS_CRUD_SPECIFIC: (cId: string) => `${PROTOCOL_SAFE}://${IP_REMOTE}/api/configurations/produtos/${cId}`,
    SERVICES_CRUD: `${PROTOCOL_SAFE}://${IP_REMOTE}/api/configurations/servicos`,
    SERVICES_CRUD_DELETE: `${PROTOCOL_SAFE}://${IP_REMOTE}/api/configurations/servicos/delete`,
    SERVICES_CRUD_SPECIFIC: (cId: string) => `${PROTOCOL_SAFE}://${IP_REMOTE}/api/configurations/servicos/${cId}`,
    OPPORTUNITYTYPE: `${PROTOCOL_SAFE}://${IP_REMOTE}/api/configurations/tipo-oportunidade`,
    OPPORTUNITYTYPE_DELETE: `${PROTOCOL_SAFE}://${IP_REMOTE}/api/configurations/tipo-oportunidade/delete`,
    OPPORTUNITYTYPE_SPECIFIC: (cId: string) =>
      `${PROTOCOL_SAFE}://${IP_REMOTE}/api/configurations/tipo-oportunidade/${cId}`,
    RESOURCES_SEARCH: (name, tId) =>
      `${PROTOCOL_SAFE}://${IP_REMOTE}/api/configurations/resources?idType=${tId}&name=${name}`,
    RESOURCES_AVAILABILITY: rId => `${PROTOCOL_SAFE}://${IP_REMOTE}/api/configurations/resources/${rId}/availabilities`,
    RESOURCES_AVAILABILITY_DELETE: (rId, aId) =>
      `${PROTOCOL_SAFE}://${IP_REMOTE}/api/configurations/resources/${rId}/availabilities/${aId}`,
    RESOURCES_UNAVAILABILITY: rId =>
      `${PROTOCOL_SAFE}://${IP_REMOTE}/api/configurations/resources/${rId}/unavailabilities`,
    RESOURCES_UNAVAILABILITY_DELETE: (rId, aId) =>
      `${PROTOCOL_SAFE}://${IP_REMOTE}/api/configurations/resources/${rId}/unavailabilities/${aId}`,
    RESOURCES_TREATMENT: (rId, tId) =>
      `${PROTOCOL_SAFE}://${IP_REMOTE}/api/configurations/resources/${rId}/treatment/${tId}`,
    CAMPAIGNS: `${PROTOCOL_SAFE}://${IP_REMOTE}/api/configurations/campaigns`,
    PRODUCTS: `${PROTOCOL_SAFE}://${IP_REMOTE}/api/configurations/products`,
    PRODUCTS_ID: id => `${PROTOCOL_SAFE}://${IP_REMOTE}/api/configurations/products/${id}`,
    SERVICES: `${PROTOCOL_SAFE}://${IP_REMOTE}/api/configurations/treatments`,
    SERVICES_CATEGOTY: `${PROTOCOL_SAFE}://${IP_REMOTE}/api/configurations/treatmentCategory`,
    SERVICES_SEARCH: (param: string) => `${PROTOCOL_SAFE}://${IP_REMOTE}/api/configurations/treatments?${param}`,
    PRODUCTS_BATCH: `${PROTOCOL_SAFE}://${IP_REMOTE}/api/configurations/products/batch`,
    SERVICES_BATCH: `${PROTOCOL_SAFE}://${IP_REMOTE}/api/configurations/treatments/batch`,
    PAYMENTS: `${PROTOCOL_SAFE}://${IP_REMOTE}/api/configurations/payments`,
    ESTADO_LEADS: `${PROTOCOL_SAFE}://${IP_REMOTE}/api/configurations/estado-lead`,
    DELETE_ESTADO_LEADS: `${PROTOCOL_SAFE}://${IP_REMOTE}/api/configurations/estado-lead/delete`,
    ESTADO_LEADS_ID: (id: string) => `${PROTOCOL_SAFE}://${IP_REMOTE}/api/configurations/estado-lead/${id}`,
    ETAPAS: `${PROTOCOL_SAFE}://${IP_REMOTE}/api/configurations/etapa`,
    DELETE_ETAPAS: `${PROTOCOL_SAFE}://${IP_REMOTE}/api/configurations/etapa/delete`,
    ETAPA: (id: string) => `${PROTOCOL_SAFE}://${IP_REMOTE}/api/configurations/etapa/${id}`,
    GOALS: `${PROTOCOL_SAFE}://${IP_REMOTE}/api/configurations/objetivos`,
    DELETE_GOALS: `${PROTOCOL_SAFE}://${IP_REMOTE}/api/configurations/objetivos/delete`,
    GOAL: (id: string) => `${PROTOCOL_SAFE}://${IP_REMOTE}/api/configurations/objetivos/${id}`,
    STATES: `${PROTOCOL_SAFE}://${IP_REMOTE}/api/configurations/estado-lead`,
    DELETE_STATES: `${PROTOCOL_SAFE}://${IP_REMOTE}/api/configurations/estado-lead/delete`,
    STAGE: (id: string) => `${PROTOCOL_SAFE}://${IP_REMOTE}/api/configurations/estado-lead/${id}`,
    PAYMENT: (id: string) => `${PROTOCOL_SAFE}://${IP_REMOTE}/api/configurations/payments/${id}`,
    PERMISSIONS: `${PROTOCOL_SAFE}://${IP_REMOTE}/control/permissions`,
    PRESCRIPTIONS: `${PROTOCOL_SAFE}://${IP_REMOTE}/api/configurations/prescricoes`,
    PRESCRIPTIONS_ALL: `${PROTOCOL_SAFE}://${IP_REMOTE}/api/configurations/prescricoes/all`,
    PRESCRIPTIONS_DELETE: `${PROTOCOL_SAFE}://${IP_REMOTE}/api/configurations/prescricoes/delete`,
    PRESCRIPTIONS_SERVICE: `${PROTOCOL_SAFE}://${IP_REMOTE}/api/configurations/prescricoes/servico`,
    PRESCRIPTIONS_SPECIFIC: (pId: string) => `${PROTOCOL_SAFE}://${IP_REMOTE}/api/configurations/prescricoes/${pId}`,
    PRESCRIPTIONS_SERVICE_SPECIFIC: (pId: string) =>
      `${PROTOCOL_SAFE}://${IP_REMOTE}/api/configurations/prescricoes/servico/${pId}`,
    SALA: `${PROTOCOL_SAFE}://${IP_REMOTE}/api/configurations/sala`,
    SALA_SPECIFIC: (pId: string) => `${PROTOCOL_SAFE}://${IP_REMOTE}/api/configurations/sala/${pId}`,
    SALA_DELETE: `${PROTOCOL_SAFE}://${IP_REMOTE}/api/configurations/sala/delete`,
    MAQUINA: `${PROTOCOL_SAFE}://${IP_REMOTE}/api/configurations/maquina`,
    MAQUINA_SPECIFIC: (pId: string) => `${PROTOCOL_SAFE}://${IP_REMOTE}/api/configurations/maquina/${pId}`,
    MAQUINA_DELETE: `${PROTOCOL_SAFE}://${IP_REMOTE}/api/configurations/maquina/delete`,
    GET_SERVICES: (tId: string) => `${PROTOCOL_SAFE}://${IP_REMOTE}/api/configurations/treatments/${tId}`,
    GET_RESOURCE: (rId: string) => `${PROTOCOL_SAFE}://${IP_REMOTE}/api/configurations/resources/${rId}`,
    ROLES: `${PROTOCOL_SAFE}://${IP_REMOTE}/api/configurations/roles`,
    ROLES_COMPLETE: `${PROTOCOL_SAFE}://${IP_REMOTE}/api/configurations/roles/complete`,
    ROLES_RESOURCES: `${PROTOCOL_SAFE}://${IP_REMOTE}/api/configurations/roles/resources`,
    OFFICES: `${PROTOCOL_SAFE}://${IP_REMOTE}/api/configurations/offices`,
    ROLES_SPECIFIC: (rId: string) => `${PROTOCOL_SAFE}://${IP_REMOTE}/api/configurations/roles/${rId}`,
    ROLES_UPDATE_LIST: `${PROTOCOL_SAFE}://${IP_REMOTE}/api/configurations/roles/list`,
    GET_BY_TYPE: (cId: string) => `${PROTOCOL_SAFE}://${IP_REMOTE}/api/configurations/generic/type/${cId}`,
    CREATE_GENERIC: `${PROTOCOL_SAFE}://${IP_REMOTE}/api/configurations/generic/`,
    UPDATE_GENERIC: (cId: string) => `${PROTOCOL_SAFE}://${IP_REMOTE}/api/configurations/generic/${cId}`,
    COMMENTS: `${PROTOCOL_SAFE}://${IP_REMOTE}/api/configurations/comments`,
    COMMENTS_ID: id => `${PROTOCOL_SAFE}://${IP_REMOTE}/api/configurations/comments/${id}`,
    TYPES: {
      TYPES: `${PROTOCOL_SAFE}://${IP_REMOTE}/api/configurations/types`,
      TYPE: (cId: string) => `${PROTOCOL_SAFE}://${IP_REMOTE}/api/configurations/types/${cId}`,
    },
    GENERIC_TYPES: {
      // /configurations/generic/type/&{id tipo de configuração }?idTenant={di do tenant}
      TYPES: `${PROTOCOL_SAFE}://${IP_REMOTE}/api/configurations/generic`,
      TYPE: (confType: string) => `${PROTOCOL_SAFE}://${IP_REMOTE}/api/configurations/generic/type/${confType}`,
      CALENDAR_VIEW_TYPES: `${PROTOCOL_SAFE}://${IP_REMOTE}/api/configurations/generic/type/${genTypes.GEN_CONFIG_TYPES.CALENDAR_VIEW_TYPE}`,
      CALENDAR_STATE: `${PROTOCOL_SAFE}://${IP_REMOTE}/api/configurations/generic/type/${genTypes.GEN_CONFIG_TYPES.CALENDAR_STATE}`,
      LEAD_STATUS: `${PROTOCOL_SAFE}://${IP_REMOTE}/api/configurations/generic/type/${genTypes.GEN_CONFIG_TYPES.LEADS_STATUS_ID}`,
      INFO_TYPES: `${PROTOCOL_SAFE}://${IP_REMOTE}/api/configurations/generic/type/${genTypes.GEN_CONFIG_TYPES.INFO_TYPES}`,
      DOCUMENT_TYPES: `${PROTOCOL_SAFE}://${IP_REMOTE}/api/configurations/generic/type/${genTypes.GEN_CONFIG_TYPES.DOCUMENT_TYPES}`,
      TASK_TYPES: `${PROTOCOL_SAFE}://${IP_REMOTE}/api/configurations/generic/type/${genTypes.GEN_CONFIG_TYPES.TASK_TYPES}`,
      TASK_STATUS: `${PROTOCOL_SAFE}://${IP_REMOTE}/api/configurations/generic/type/${genTypes.GEN_CONFIG_TYPES.TASK_STATUS}`,
      OPPORTUNITIES_STATUS: `${PROTOCOL_SAFE}://${IP_REMOTE}/api/configurations/generic/type/${genTypes.GEN_CONFIG_TYPES.OPPORTUNITIES_STATUS_ID}`,
      OPPORTUNITIES_TYPES: `${PROTOCOL_SAFE}://${IP_REMOTE}/api/configurations/generic/type/${genTypes.GEN_CONFIG_TYPES.OPPORTUNITIES_TYPE_ID}`,
      OPPORTUNITIES_LOST_REASON_TYPE: `${PROTOCOL_SAFE}://${IP_REMOTE}/api/configurations/generic/type/${genTypes.GEN_CONFIG_TYPES.OPPORTUNITIES_LOST_REASON_TYPE_ID}`,
      PAYMENT_STATUS: `${PROTOCOL_SAFE}://${IP_REMOTE}/api/configurations/generic/type/${genTypes.GEN_CONFIG_TYPES.PAYMENT_STATUS_ID}`,
      PAYMENT_ACCOUNTS: `${PROTOCOL_SAFE}://${IP_REMOTE}/api/configurations/generic/type/${genTypes.GEN_CONFIG_TYPES.PAYMENT_ACCOUNTS}`,
      PROFESSIONS: `${PROTOCOL_SAFE}://${IP_REMOTE}/api/configurations/generic/type/${genTypes.GEN_CONFIG_TYPES.PROFESSION_ID}`,
      PROBLEMS: `${PROTOCOL_SAFE}://${IP_REMOTE}/api/configurations/generic/type/${genTypes.GEN_CONFIG_TYPES.PROBLEM_ID}`,
      COMMUNICATION_CHANNELS: `${PROTOCOL_SAFE}://${IP_REMOTE}/api/configurations/generic/type/${genTypes.GEN_CONFIG_TYPES.COM_CHANNEL_ID}`,
      CONTRAINDICATION: `${PROTOCOL_SAFE}://${IP_REMOTE}/api/configurations/generic/type/${genTypes.GEN_CONFIG_TYPES.CONTRAINDICATION_ID}`,
      ORIGINS: `${PROTOCOL_SAFE}://${IP_REMOTE}/api/configurations/generic/type/${genTypes.GEN_CONFIG_TYPES.ORIGIN_ID}`,
      DEDUCTIONS: `${PROTOCOL_SAFE}://${IP_REMOTE}/api/configurations/generic/type/${genTypes.GEN_CONFIG_TYPES.DEDUCTION_TYPES}`,
      MARKETING_STATUS_FACEBOOK: `${PROTOCOL_SAFE}://${IP_REMOTE}/api/configurations/generic/type/${genTypes.MARKETING_GEN_STATUS.FACEBOOK}`,
      MARKETING_STATUS_FACEBOOK_CONTENT: `${PROTOCOL_SAFE}://${IP_REMOTE}/api/configurations/generic/type/${genTypes.MARKETING_GEN_STATUS.FACEBOOK_CONTENT}`,
      MARKETING_STATUS_GOOGLE: `${PROTOCOL_SAFE}://${IP_REMOTE}/api/configurations/generic/type/${genTypes.MARKETING_GEN_STATUS.GOOGLE}`,
      MARKETING_STATUS_EGOI: `${PROTOCOL_SAFE}://${IP_REMOTE}/api/configurations/generic/type/${genTypes.MARKETING_GEN_STATUS.EGOI}`,
    },
  },
  CLINICAL_RECORDS: {
    AVAILABLE_TYPES: (uId: string) => `${PROTOCOL_SAFE}://${IP_REMOTE}/api/clients/users/${uId}/records/types`,
    RECORDS: (uId: string) => `${PROTOCOL_SAFE}://${IP_REMOTE}/api/clients/users/${uId}/records`,
    UPDATE_RECORDS: (uId: string, rId: string) =>
      `${PROTOCOL_SAFE}://${IP_REMOTE}/api/clients/users/${uId}/records/${rId}`,
    ENTRY_TYPE: (uId: string, type: string) => {
      switch (type) {
        case 'pa':
          return `${PROTOCOL_SAFE}://${IP_REMOTE}/api/clients/users/${uId}/records/pa`;
        case 'ftc':
          return `${PROTOCOL_SAFE}://${IP_REMOTE}/api/clients/users/${uId}/records/ftc`;
        case 'ftr':
          return `${PROTOCOL_SAFE}://${IP_REMOTE}/api/clients/users/${uId}/records/ftr`;
        case 'fc':
          return `${PROTOCOL_SAFE}://${IP_REMOTE}/api/clients/users/${uId}/records/fc`;
        case 'acc':
          return `${PROTOCOL_SAFE}://${IP_REMOTE}/api/clients/users/${uId}/records/acc`;
      }
    },
    ENTRIES: (uId: string, rId: string) =>
      `${PROTOCOL_SAFE}://${IP_REMOTE}/api/clients/users/${uId}/records/${rId}/entry`,
  },
  EVALUATIONS: {
    AVAILABLE_TYPES: (uId: string) => `${PROTOCOL_SAFE}://${IP_REMOTE}/api/clients/users/${uId}/evaluations/types`,
    EVALUATIONS: (uId: string) => `${PROTOCOL_SAFE}://${IP_REMOTE}/api/clients/users/${uId}/evaluations`,
    UPDATE_EVALUATIONS: (uId: string, rId: string) =>
      `${PROTOCOL_SAFE}://${IP_REMOTE}/api/clients/users/${uId}/evaluations/${rId}`,
    ENTRY_TYPE: (uId: string, type: string) => {
      switch (type) {
        case 'nutricional':
          return `${PROTOCOL_SAFE}://${IP_REMOTE}/api/clients/users/${uId}/evaluations/nutricional`;
        case 'body':
          return `${PROTOCOL_SAFE}://${IP_REMOTE}/api/clients/users/${uId}/evaluations/body`;
        case 'face':
          return `${PROTOCOL_SAFE}://${IP_REMOTE}/api/clients/users/${uId}/evaluations/face`;
        case 'hairRemoval':
          return `${PROTOCOL_SAFE}://${IP_REMOTE}/api/clients/users/${uId}/evaluations/hairRemoval`;
        case 'history':
          return `${PROTOCOL_SAFE}://${IP_REMOTE}/api/clients/users/${uId}/evaluations/history`;
      }
    },
    ENTRIES: (uId: string, type: string, evId: string) => {
      switch (type) {
        case 'nutricional':
          return `${PROTOCOL_SAFE}://${IP_REMOTE}/api/clients/users/${uId}/evaluations/nutricional/${evId}`;
        case 'body':
          return `${PROTOCOL_SAFE}://${IP_REMOTE}/api/clients/users/${uId}/evaluations/body/${evId}`;
        case 'face':
          return `${PROTOCOL_SAFE}://${IP_REMOTE}/api/clients/users/${uId}/evaluations/face/${evId}`;
        case 'hairRemoval':
          return `${PROTOCOL_SAFE}://${IP_REMOTE}/api/clients/users/${uId}/evaluations/hairremoval/${evId}`;
        case 'history':
          return `${PROTOCOL_SAFE}://${IP_REMOTE}/api/clients/users/${uId}/evaluations/history/${evId}`;
      }
    },
    UPDATE_ENTRIES: (uId: string, type: string, evId: string) => {
      switch (type) {
        case 'nutricional':
          return `${PROTOCOL_SAFE}://${IP_REMOTE}/api/clients/users/${uId}/evaluations/nutricional/${evId}/entries`;
        case 'body':
          return `${PROTOCOL_SAFE}://${IP_REMOTE}/api/clients/users/${uId}/evaluations/body/${evId}/entries`;
        case 'face':
          return `${PROTOCOL_SAFE}://${IP_REMOTE}/api/clients/users/${uId}/evaluations/face/${evId}/entries`;
        case 'hairRemoval':
          return `${PROTOCOL_SAFE}://${IP_REMOTE}/api/clients/users/${uId}/evaluations/hairremoval/${evId}/entries`;
        case 'history':
          return `${PROTOCOL_SAFE}://${IP_REMOTE}/api/clients/users/${uId}/evaluations/history/${evId}/entries`;
      }
    },
  },
  REPORTS: {
    AVAILABLE_TYPES: (uId: string) => `${PROTOCOL_SAFE}://${IP_REMOTE}/api/clients/users/${uId}/reports/types`,
    REPORTS: (uId: string) => `${PROTOCOL_SAFE}://${IP_REMOTE}/api/clients/users/${uId}/reports`,
    UPDATE_REPORTS: (uId: string, rId: string) =>
      `${PROTOCOL_SAFE}://${IP_REMOTE}/api/clients/users/${uId}/reports/${rId}`,
    ENTRY_TYPE: (uId: string, type: string) => {
      switch (type) {
        case 'acc':
          return `${PROTOCOL_SAFE}://${IP_REMOTE}/api/clients/users/${uId}/reports/acc`;
        case 'et':
          return `${PROTOCOL_SAFE}://${IP_REMOTE}/api/clients/users/${uId}/reports/et`;
      }
    },
    ENTRIES: (uId: string, type: string, evId: string) => {
      switch (type) {
        case 'acc':
          return `${PROTOCOL_SAFE}://${IP_REMOTE}/api/clients/users/${uId}/reports/acc/${evId}`;
        case 'et':
          return `${PROTOCOL_SAFE}://${IP_REMOTE}/api/clients/users/${uId}/reports/et/${evId}`;
      }
    },
    UPDATE_ENTRIES: (uId: string, type: string, rId: string) => {
      switch (type) {
        case 'acc':
          return `${PROTOCOL_SAFE}://${IP_REMOTE}/api/clients/users/${uId}/reports/acc/${rId}/entries`;
        case 'et':
          return `${PROTOCOL_SAFE}://${IP_REMOTE}/api/clients/users/${uId}/reports/et/${rId}/entries`;
      }
    },
    METRICAS_RECEBIMENTO: `${PROTOCOL_SAFE}://${IP_REMOTE}/api/relatorio`,
    METRICAS_PAGAMENTO: `${PROTOCOL_SAFE}://${IP_REMOTE}/api/relatorio/pagamento`,
  },
  MARKETING: {
    ACCOUNTS_AD: `${PROTOCOL_SAFE}://${IP_REMOTE}/api/marketing/accounts-ad`,
    ACCOUNTS_AD_PUT: uuid => `${PROTOCOL_SAFE}://${IP_REMOTE}/api/marketing/accounts-ad/${uuid}`,
    DASHBOARD_METRICS: () => `${PROTOCOL_SAFE}://${IP_REMOTE}/api/marketing/dashboard/metrics`,
    DASHBOARD_METRICS_MONTH: () => `${PROTOCOL_SAFE}://${IP_REMOTE}/api/marketing/dashboard/metrics-month`,
    DASHBOARD_METRICS_LAST_MONTH: () => `${PROTOCOL_SAFE}://${IP_REMOTE}/api/marketing/dashboard/metrics-last-month`,
    DASHBOARD_METRICS_PERIOD: `${PROTOCOL_SAFE}://${IP_REMOTE}/api/marketing/dashboard/metrics-period`,
    DASHBOARD_PIPELINE: `${PROTOCOL_SAFE}://${IP_REMOTE}/api/marketing/dashboard/pipeline`,
    DASHBOARD_METRICS_TYPE_ACCOUNT_AD: `${PROTOCOL_SAFE}://${IP_REMOTE}/api/marketing/dashboard/metrics-type-accounts-ad`,
    DASHBOARD_INFLUENCER: `${PROTOCOL_SAFE}://${IP_REMOTE}/api/marketing/dashboard/influencer`,
    DASHBOARD_CAMPAIGNS: `${PROTOCOL_SAFE}://${IP_REMOTE}/api/marketing/campaigns`,
    DASHBOARD_CAMPAIGNS_INSIGHTS: `${PROTOCOL_SAFE}://${IP_REMOTE}/api/marketing/campaigns/insights`,
    DASHBOARD_CAMPAIGNS_SUMMARY: `${PROTOCOL_SAFE}://${IP_REMOTE}/api/marketing/campaigns/insights/summary`,
    DASHBOARD_CAMPAIGNS_GOALS: `${PROTOCOL_SAFE}://${IP_REMOTE}/api/marketing/campaigns/goals`,
  },
  DASHBOARD: {
    EVENTS: `${PROTOCOL_SAFE}://${IP_REMOTE}/api/dashboard/events`,
    FINANCES: `${PROTOCOL_SAFE}://${IP_REMOTE}/api/dashboard/finances`,
    OPPORTUNITIES: `${PROTOCOL_SAFE}://${IP_REMOTE}/api/dashboard/opportunities`,
    OPPORTUNITIES_PORCENTAGEM: `${PROTOCOL_SAFE}://${IP_REMOTE}/api/dashboard/opportunities/porcentagem`,
  },
};

// Mapping between database and frontend translations
export const Gender = {
  '0': 'male',
  '1': 'female',
  '-1': 'unknown',
  // UNKNOWN: -1,
  // MALE: 0,
  // FEMALE: 1
};

export const OpportunityStatus = {
  '-1': 'Aberto',
  '0': 'Perdido',
  '1': 'Ganho',
};

/**
 * Status for calendar events (and colors)
 */
export const calStatus = [
  {
    id: 'scheduled',
    name: 'Marcado - Não Confirmado',
    color: 'blue-border',
    bdID: 'e3a0bd2f-ca7c-11e8-9bda-0242ac120003',
  },
  { id: 'confirmed', name: 'Confirmado', color: 'pink-border', bdID: 'e3b0d1e7-ca7c-11e8-9bda-0242ac120003' },
  { id: 'confirmed_sms', name: 'Confirmado - SMS', color: 'pink-border', bdID: '4cb466bb-248a-11e9-81c7-0242ac120006' },
  { id: 'arrived', name: 'Chegou', color: 'green-border', bdID: 'e3cb612a-ca7c-11e8-9bda-0242ac120003' },
  { id: 'scheduled', name: 'Dedução', color: 'purple-border', bdID: '' },
  {
    id: 'arrived_without_scheduled_event',
    name: 'Chegou s/ Consulta',
    color: 'bolt-border',
    bdID: 'e3d7a8f4-ca7c-11e8-9bda-0242ac120003',
  },
  { id: 'initialize_serice', name: 'Iniciado', color: 'lime-border', bdID: 'e3e291c2-ca7c-11e8-9bda-0242ac120003' },
  { id: 'finished', name: 'Finalizar', color: 'lime-border', bdID: 'e3ed4be7-ca7c-11e8-9bda-0242ac120003' },
  {
    id: 'canceled_programed',
    name: 'Cancelou - Avisou',
    color: 'orange-border',
    bdID: 'e3f714cc-ca7c-11e8-9bda-0242ac120003',
  },
  {
    id: 'canceled_system',
    name: 'Cancelou Pelo Sistema',
    color: 'orange-border',
    bdID: '92ab81e0-d59b-11ea-8fe4-0242ac120002',
  },
  {
    id: 'canceled_rescheduled',
    name: 'Cancelou - Remarcou',
    color: 'red-border',
    bdID: 'e401d466-ca7c-11e8-9bda-0242ac120003',
  },
  {
    id: 'canceled_no_confirmation',
    name: 'Cancelado Sem Confirmação',
    color: 'brown-border',
    bdID: 'e410d972-ca7c-11e8-9bda-0242ac120003',
  },
  { id: 'missed', name: 'Não Compareceu', color: 'yellow-border', bdID: 'e41a2de4-ca7c-11e8-9bda-0242ac120003' },
];

export const eventStates = {
  cancel: 'e3f714cc-ca7c-11e8-9bda-0242ac120003',
  cancel_system: '92ab81e0-d59b-11ea-8fe4-0242ac120002',
  reschedule: 'e249ac61-cb1e-11e8-9bda-0242ac120003',
  scheduled: 'e3a0bd2f-ca7c-11e8-9bda-0242ac120003',
  clientConfirm: '4cb466bb-248a-11e9-81c7-0242ac120006',
  clinicConfirm: 'e3b0d1e7-ca7c-11e8-9bda-0242ac120003',
};

/**
 * used to display month names
 */
export const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

/**
 * Used to display types of events in calendar and cal service
 * And left column schedule in calendar view
 */
export const schedule = {
  types: ['none', 'single', 'multiple-start', 'multiple-mid', 'multiple-end'],
  long: {
    hourly: [
      '0:00',
      '1:00',
      '2:00',
      '3:00',
      '4:00',
      '5:00',
      '6:00',
      '7:00',
      '8:00',
      '9:00',
      '10:00',
      '11:00',
      '12:00',
      '13:00',
      '14:00',
      '15:00',
      '16:00',
      '17:00',
      '18:00',
      '19:00',
      '20:00',
      '21:00',
      '22:00',
      '23:00',
    ],
    quarterly: [
      '0:00',
      '0:15',
      '0:30',
      '0:45',
      '1:00',
      '1:15',
      '1:30',
      '1:45',
      '2:00',
      '2:15',
      '2:30',
      '2:45',
      '3:00',
      '3:15',
      '3:30',
      '3:45',
      '4:00',
      '4:15',
      '4:30',
      '4:45',
      '5:00',
      '5:15',
      '5:30',
      '5:45',
      '6:00',
      '6:15',
      '6:30',
      '6:45',
      '7:00',
      '7:15',
      '7:30',
      '7:45',
      '8:00',
      '8:15',
      '8:30',
      '8:45',
      '9:00',
      '9:15',
      '9:30',
      '9:45',
      '10:00',
      '10:15',
      '10:30',
      '10:45',
      '11:00',
      '11:15',
      '11:30',
      '11:45',
      '12:00',
      '12:15',
      '12:30',
      '12:45',
      '13:00',
      '13:15',
      '13:30',
      '13:45',
      '14:00',
      '14:15',
      '14:30',
      '14:45',
      '15:00',
      '15:15',
      '15:30',
      '15:45',
      '16:00',
      '16:15',
      '16:30',
      '16:45',
      '17:00',
      '17:15',
      '17:30',
      '17:45',
      '18:00',
      '18:15',
      '18:30',
      '18:45',
      '19:00',
      '19:15',
      '19:30',
      '19:45',
      '20:00',
      '20:15',
      '20:30',
      '20:45',
      '21:00',
      '21:15',
      '21:30',
      '21:45',
      '22:00',
      '22:15',
      '22:30',
      '22:45',
      '23:00',
      '23:15',
      '23:30',
      '23:45',
    ],
  },
};

export const regex = {
  email: '^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:.[a-zA-Z0-9-]+)*$',
  phone: '^[0-9]{9}',
  number: '^[0-9]',
  vat: '^[0-9]{9}',
};

export const FileTypes = [
  '3ds',
  'aac',
  'ai',
  'avi',
  'bmp',
  'cad',
  'cdr',
  'css',
  'dat',
  'dll',
  'dmg',
  'doc',
  'eps',
  'fla',
  'flv',
  'gif',
  'html',
  'indd',
  'iso',
  'jpg',
  'jpeg',
  'js',
  'json',
  'midi',
  'mov',
  'mp3',
  'mpg',
  'mpeg',
  'pdf',
  'php',
  'png',
  'ppt',
  'ps',
  'psd',
  'raw',
  'sql',
  'svg',
  'tif',
  'txt',
  'wmv',
  'xls',
  'xml',
  'zip',
];

export const RecordTypes = {
  ftc: {
    id: '719939bc-c55b-11e8-97a1-0242ac120002',
    og: '719ad385-c55b-11e8-97a1-0242ac120002',
    ender: '719b8051-c55b-11e8-97a1-0242ac120002',
    bodyshape: '719c1532-c55b-11e8-97a1-0242ac120002',
    bodywave: '719cc5e6-c55b-11e8-97a1-0242ac120002',
    presso: '719e980a-c55b-11e8-97a1-0242ac120002',
    radio: '71a00ced-c55b-11e8-97a1-0242ac120002',
    lipo: '71a1771a-c55b-11e8-97a1-0242ac120002',
    depi: '71a21ea7-c55b-11e8-97a1-0242ac120002',
  },
  fc: {
    id: '71a2b27c-c55b-11e8-97a1-0242ac120002',
    fc: '71a31ae4-c55b-11e8-97a1-0242ac120002',
  },
  acc: {
    id: '71a520fc-c55b-11e8-97a1-0242ac120002',
    acc: '97a567c9-c7f6-11e8-8224-0242ac12000b',
  },
  pa: {
    id: '71a58d4b-c55b-11e8-97a1-0242ac120002',
    pa: '7967fc7b-c7f6-11e8-8224-0242ac12000b',
  },
  ftr: {
    id: '71a5f042-c55b-11e8-97a1-0242ac120002',
    depi: '71a9c94c-c55b-11e8-97a1-0242ac120002',
    peelingU: '71a6e244-c55b-11e8-97a1-0242ac120002',
    peelingQ: '71a67106-c55b-11e8-97a1-0242ac120002',
    micro: '71a76e3d-c55b-11e8-97a1-0242ac120002',
    skin: '71a7e900-c55b-11e8-97a1-0242ac120002',
    vitC: '71a8528e-c55b-11e8-97a1-0242ac120002',
    radio: '71a8db5d-c55b-11e8-97a1-0242ac120002',
    mask: '71a95b11-c55b-11e8-97a1-0242ac120002',
  },
};

export const EvaluationTypes = {
  nutricional: {
    id: '6a273470-c594-11e8-97a1-0242ac120002',
    nutricional: 'd75e0a4e-d389-11e8-8f3e-0242ac120002',
  },
  body: {
    id: '6a2cb95e-c594-11e8-97a1-0242ac120002',
    body: 'd75f48a7-d389-11e8-8f3e-0242ac120002',
  },
  face: {
    id: '6a2d566f-c594-11e8-97a1-0242ac120002',
    face: 'd75ea951-d389-11e8-8f3e-0242ac120002',
  },
  hairRemoval: {
    id: '6a2dd5bf-c594-11e8-97a1-0242ac120002',
    hairRemoval: 'd7607aff-d389-11e8-8f3e-0242ac120002',
  },
};

export const ReportTypes = {
  acc: {
    id: '3b34473a-d389-11e8-8f3e-0242ac120002',
    acc: 'd759d2cc-d389-11e8-8f3e-0242ac120002',
  },
  et: {
    id: '3b3b0c57-d389-11e8-8f3e-0242ac120002',
    et: 'd75d1507-d389-11e8-8f3e-0242ac120002',
  },
};

export const AccountsAdStatus = {
  Ativo: 'ab39b279-2cff-40d4-934e-762ce49c0c94',
  Desativado: 'ff918bf1-d031-4f46-8f7a-00c85e191e63',
  'Desativado Relogin': '94930a27-b0ee-4c7a-ad9c-285f2ebc956b',
};

export const AccountsAdTypes = {
  facebook: '3d758187-8749-463f-94d5-df9b24b73349',
  google: '84f50fd6-be99-4dde-a7ce-508627f02cde',
  egoi: 'dc78f9dc-0640-46cc-9e2b-1a36ad9efae0',
  facebookContent: 'f4060eb8-063d-4a35-9bbd-9202ac0a1de0',
};
export const paymentsTypes = {
  credito: 'bb97b32c-69ed-11ea-996a-0242ac120008',
};
export const exchangesTypes = {
  credit: '01dd1435-84d5-11ea-a0fe-0242ac120002',
  yield: '82998a82-84d5-11ea-a0fe-0242ac120002',
  serviceProduct: '6ffe79b3-84d5-11ea-a0fe-0242ac120002',
  payment: '00b7de03-9087-11ea-8016-0242ac120002',
  yieldAssignor: '20cfdfdd-908a-11ea-8016-0242ac120002',
};

export const firstVisitType = [
  { label: 'Corpo - Avaliação', value: genTypes.TREATMENT_TYPES.BODY },
  { label: 'Rosto - Avaliação', value: genTypes.TREATMENT_TYPES.FACE },
  { label: 'Depilação - Avaliação', value: genTypes.TREATMENT_TYPES.DEPI },
  // { label: 'Cedência', value: genTypes.CONSULTATIONS.YIELD },
  // { label: 'Marketing', value: genTypes.CONSULTATIONS.MARKETING },
  { label: 'Medicina Estética - Avaliação', value: genTypes.TREATMENT_TYPES.AESTHETIC },
  { label: 'Departamento Interno', value: genTypes.CONSULTATIONS.TECHNICAL },
  { label: 'Follow-Up', value: genTypes.TREATMENT_TYPES.FOLLOWUP_ID },
];

export const timeoutRevert = 2500;

export const opportunitiesTypes = {
  'Processo Trocas': '4b26298e-a982-11ea-9088-0242ac120002',
};

export const PARCIAL_ID = '09cd9b10-e690-11ea-8fe4-0242ac120002';
export const MA_ID = 'd2d1dad6-936d-401d-9af2-47759fd44dd1';
export const SYSTEM_USER = '90f5f527-8c33-11e9-9d0f-0242ac120002';
export const NAME_MA_ID = 'Marcações Automáticas';
export const NAME_SYSTEM_USER = 'Sistema gr8';

export const PAYMENT_STATUS = [
  'dd9ca69a-ce35-11e8-9bda-0242ac120003',
  'dddc4cc2-ce35-11e8-9bda-0242ac120003',
  'ddd19e81-ce35-11e8-9bda-0242ac120003',
];

export const PAYMENT_IN_NEGOTIATION = 'dd9ca69a-ce35-11e8-9bda-0242ac120003';
