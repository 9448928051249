import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {TypesExchange} from "../interfaces";

@Component({
  selector: 'app-exchange-success',
  templateUrl: './exchange-success.component.html',
  styleUrls: ['./exchange-success.component.scss']
})
export class ExchangeSuccessComponent implements OnInit {
  types = TypesExchange;
  constructor(
    public thisDialogRef: MatDialogRef<ExchangeSuccessComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {

  }

  ngOnInit() {

  }


  onSubmit() {

  }

  onCloseCancel() {
    this.thisDialogRef.close('Cancel');
  }
}
